import { IOLTGoogleMapData } from 'store/types/googleMaps.types';
import { TREE_CHART_NODE_TYPE } from 'store/types/splitter.types';

export const OLT_GOOGLE_MAPS_MOCK: IOLTGoogleMapData[] = [
  {
    geoLabel: 'Shollinganallur',
    type: TREE_CHART_NODE_TYPE.SPLITTER,
    lat: 12.901,
    lng: 80.2279,
  },
  {
    geoLabel: 'Accenture',
    type: TREE_CHART_NODE_TYPE.SPLITTER,
    lat: 12.9073,
    lng: 80.2283,
  },
  {
    geoLabel: 'Dollar',
    type: TREE_CHART_NODE_TYPE.SPLITTER,
    lat: 12.9057,
    lng: 80.2283,
  },
  {
    geoLabel: 'Vivanta',
    type: TREE_CHART_NODE_TYPE.SPLITTER,
    lat: 12.896942,
    lng: 80.227638,
  },
  {
    geoLabel: 'HCL',
    type: TREE_CHART_NODE_TYPE.SPLITTER,
    lat: 12.9043,
    lng: 80.2177,
  },
  {
    geoLabel: 'Perumbakkam',
    type: TREE_CHART_NODE_TYPE.SPLITTER,
    lat: 12.8923,
    lng: 80.1889,
  },
  {
    geoLabel: 'Medavakkam',
    type: TREE_CHART_NODE_TYPE.SPLITTER,
    lat: 12.92,
    lng: 80.192,
  },
];

export const OLT_GOOGLE_MAP_BY_SUBSCRIBER_ID: IOLTGoogleMapData[] = [
  {
    geoLabel: 'Greens Techonology',
    label: 'Subscriber',
    type: TREE_CHART_NODE_TYPE.SUBSCRIBER,
    lat: 12.907548461913837,
    lng: 80.20106708981331,
  },
  {
    geoLabel: 'Puthu Nagar',
    label: 'Splitter 1',
    type: TREE_CHART_NODE_TYPE.SPLITTER,
    lat: 12.910760107242798,
    lng: 80.19658364651359,
  },
  {
    geoLabel: 'Medavakkam Junction',
    type: TREE_CHART_NODE_TYPE.SPLITTER,
    lat: 12.91701091216837,
    lng: 80.19442950794519,
  },
  {
    geoLabel: 'Medavakkam Koot road',
    type: TREE_CHART_NODE_TYPE.SPLITTER,
    lat: 12.920453825632514,
    lng: 80.18520620130556,
  },
  {
    geoLabel: 'Santhosapuram',
    type: TREE_CHART_NODE_TYPE.OLT,
    lat: 12.920008182509672,
    lng: 80.17093659055975,
  },
];
