import './styles/App.less';

import {
  AuthActions,
  CommonActions,
  RootState,
  useAppDispatch,
} from './store/redux';
import { BrowserTabTitle, Loader } from './components/utilities';
import { Suspense, useEffect, useState } from 'react';
import {
  storeRedirectToDomain,
  storeSessionToken,
} from 'store/redux/sessionStore';

import AppNotificationBar from 'components/utilities/AppNotificationBar';
import AppVersionChecker from 'components/twasupport/appVersionChecker';
import { FirebaseNotifications } from 'components/utilities/FirebaseNotifications';
import { IconContext } from 'react-icons';
// import BetaNotification from 'components/utilities/BetaNotification';
import InternetConnectivityStatus from 'components/utilities/InternetConnectivityStatus';
import { Layout } from 'antd';
import React from 'react';
import { SUBSCRIPTION_STATE } from 'store/types/subscription.types';
import Sidebar from './components/layout/Sidebar';
import { THEME } from 'store/types';
import { setClientInfo } from 'apiUtils/api';
import { useDetectDevice } from 'components/hooks/useDetectDevice';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Content = React.lazy(() => import('./components/layout/Content'));
const Header = React.lazy(() => import('./components/layout/Header'));

const theme = process.env?.THEME;

Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

const App = () => {
  const { auth, common } = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [isPasswordReset] = useState(() => {
    return window.location.pathname.startsWith('/app/reset-password');
  });

  useDetectDevice();

  useEffect(() => {
    // Notes: Subscription update and Payment handling: - CCAvenue Payment Integration
    // 1. On Subscription update - Payment should be handled in fiiviz.com domain
    // 2. While navigating from other domain need to share accessToken and current domain url (for redirection purpose)
    // 3. These values will be maintained in local storage.

    const accessToken = searchParams.get('accessToken');
    const redirectToDomain = searchParams.get('redirectToDomain');
    if (accessToken) {
      storeSessionToken(accessToken);
    }
    if (redirectToDomain) {
      storeRedirectToDomain(redirectToDomain);
    }

    dispatch(CommonActions.setTheme(theme as THEME));

    if (localStorage.getItem('isTWAApp') === 'true') {
      dispatch(CommonActions.setTWA(true));
      setClientInfo('DIAG');
    } else {
      setClientInfo('VIZ');
    }
    dispatch(CommonActions.setLogoClassName());
  }, []);

  useEffect(() => {
    if (!isPasswordReset) {
      dispatch(AuthActions.loadExistingSession(null));
    }
  }, []);

  useEffect(() => {
    dispatch(
      CommonActions.setShowMenuAndHeader(
        auth?.subscriptionStatus === SUBSCRIPTION_STATE.ACTIVE,
      ),
    );
    // dispatch(
    //   CommonActions.setAppNotifications({
    //     key: APP_NOTIFICATION_KEYS.MAINTENANCE,
    //     type: APP_NOTIFICATION_TYPES.MAINTENANCE,
    //     message: t('messages.maintenanceInProgress'),
    //   }),
    // );
  }, [auth?.subscriptionStatus]);

  return (
    <Layout className='baseLayout'>
      <AppVersionChecker />
      <InternetConnectivityStatus />
      <BrowserTabTitle />
      <IconContext.Provider value={{ className: 'react-icons' }}>
        <Suspense fallback={<Loader />}>
          {(auth.loading && !isPasswordReset && <Loader />) || (
            <Layout>
              <Header />
              <Layout>
                <Sidebar />
                <Suspense fallback={<Loader />}>
                  <Layout.Content
                    id={'content-container'}
                    className={`content-container ${
                      common.showMenuAndHeader ? 'menu-header-shown' : ''
                    }`}
                  >
                    {auth.authorized && <AppNotificationBar />}
                    <Content />
                  </Layout.Content>
                </Suspense>
              </Layout>

              {auth.authorized && <FirebaseNotifications />}
              {/* {auth.authorized && <BetaNotification />} */}
            </Layout>
          )}
        </Suspense>
      </IconContext.Provider>
    </Layout>
  );
};

export default App;
