import {
  DEFAULT_SUBSCRIBER_MOCK,
  SUBSCRIBERS_LIST_MOCK,
} from './subscribers/subscriber.mock';
import { LOGIN_INIT_MOCK, WHO_AM_I_MOCK } from './auth/auth.mock';
import {
  OLT_DASHBOARD_DEVICE_STATS_MOCK,
  OLT_DASHBOARD_MOCK,
  OLT_OPTICAL_TX_PON_OVERVIEW_STATS_MOCK,
  OLT_OPTICAL_TX_PON_PORT_TIME_SERIES_MOCK,
  ONT_DEVICES_LIST_MOCK,
} from './olt/oltDashboard.mock';
import {
  OLT_DROPDOWN_LIST_MOCK,
  SPLITTER_DROPDOWN_LIST_MOCK,
} from './dropdownList.mock';
import { mockGetPathParams, mockRouteMatcher } from './mock.utils';

import { API_END_POINTS } from 'apiUtils/urls';
import AxiosMockAdapter from 'axios-mock-adapter';
import { OLT_GOOGLE_MAPS_MOCK } from './olt/oltGoogleMaps.mock';
import { OLT_LIST_MOCK } from './olt/olt.mock';
import { PON_LABEL } from 'store/types';
import { SPLITTER_TREE_CHART_DATA_MOCK } from './splitter/splitter.mock';
import { VLAN_LIST_MOCK } from './vlan/vlan.mock';
import { VLAN_TABS } from 'store/types/vlan.types';
import axios from 'axios';

const axiosMockRequester = axios.create();

export const axiosMockAdapterInstance = new AxiosMockAdapter(
  axiosMockRequester,
  { delayResponse: 200 },
);

//  #region - Auth
axiosMockAdapterInstance
  .onPost(API_END_POINTS.AUTH.LOGIN_INIT)
  .reply(200, LOGIN_INIT_MOCK);

axiosMockAdapterInstance
  .onPost(API_END_POINTS.AUTH.LOGIN_VERIFY_PASSWORD)
  .reply(200, {});

axiosMockAdapterInstance.onPost(API_END_POINTS.AUTH.logout).reply(204, {});

axiosMockAdapterInstance
  .onGet(API_END_POINTS.OPERATORS.whoAmI)
  .reply(200, WHO_AM_I_MOCK);

axiosMockAdapterInstance
  .onGet(API_END_POINTS.MESSAGE.notifications)
  .reply(200, {});
//  #endregion - Auth

//  #region - Dropdowns List
axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.VIZ.oltList))
  .reply((config) => {
    return new Promise((resolve) => resolve([200, OLT_DROPDOWN_LIST_MOCK]));
  });

axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.OPERATORS.splitters))
  .reply((config) => {
    return new Promise((resolve) =>
      resolve([200, SPLITTER_DROPDOWN_LIST_MOCK]),
    );
  });
//  #endregion - Dropdowns List

//  #region - Subscriber List
axiosMockAdapterInstance
  .onGet(API_END_POINTS.VIZ.subscribers)
  .reply((config) => {
    const pageNumber = config?.params?.pageToken || 1;
    const pageSize = config?.params?.pageSize;
    let response = SUBSCRIBERS_LIST_MOCK;
    response = {
      ...response,
      items: response.items?.slice(
        (pageNumber - 1) * pageSize,
        pageNumber * pageSize,
      ),
    };
    return new Promise((resolve) => resolve([200, response]));
  });

axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.SUBSCRIBER.subscribersByID))
  .reply((config) => {
    const pathParams = mockGetPathParams(
      config.url,
      API_END_POINTS.SUBSCRIBER.subscribersByID,
    );
    const response =
      SUBSCRIBERS_LIST_MOCK.items?.find(
        (v) => v.subscriberId === pathParams?.userGroupId,
      ) ||
      SUBSCRIBERS_LIST_MOCK.items?.[0] ||
      DEFAULT_SUBSCRIBER_MOCK;
    return new Promise((resolve) => resolve([200, response]));
  });

//  #endregion - Subscriber List

//  #region - OLT List
axiosMockAdapterInstance.onGet(API_END_POINTS.VIZ.olt).reply((config) => {
  return new Promise((resolve) => resolve([200, OLT_LIST_MOCK]));
});
//  #endregion - OLT List

//  #region - OLT Dashboard

//  #region - Overview
axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.VIZ.oltDashboardCardStats))
  .reply((config) => {
    return new Promise((resolve) => resolve([200, OLT_DASHBOARD_MOCK]));
  });

axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.VIZ.oltDashboardDevicesStats))
  .reply((config) => {
    return new Promise((resolve) =>
      resolve([200, OLT_DASHBOARD_DEVICE_STATS_MOCK]),
    );
  });

axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.VIZ.oltOpticalStats))
  .reply((config) => {
    let response: any = OLT_OPTICAL_TX_PON_OVERVIEW_STATS_MOCK;
    if (config?.params?.portIndex) {
      response = OLT_OPTICAL_TX_PON_PORT_TIME_SERIES_MOCK;
    }
    return new Promise((resolve) => resolve([200, response]));
  });
//  #endregion - Overview

//#region - ONUs Tab

axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.VIZ.ontList))
  .reply((config) => {
    return new Promise((resolve) => resolve([200, ONT_DEVICES_LIST_MOCK]));
  });

//#endregion - ONUs Tab

// #region - Splitter

axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.OPERATORS.splitterById))
  .reply((config) => {
    const pathParams = mockGetPathParams(
      config.url,
      API_END_POINTS.OPERATORS.splitterById,
    );
    const response = SPLITTER_DROPDOWN_LIST_MOCK?.find(
      (v) => v.splitterId === pathParams?.splitterId,
    );
    return new Promise((resolve) => resolve([200, [response]]));
  });

axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.VIZ.networkHierarchy))
  .reply((config) => {
    const portIndex = config?.params?.portIndex;
    const response = SPLITTER_TREE_CHART_DATA_MOCK;
    if (response) {
      response.title = `${PON_LABEL} ${portIndex?.charAt(
        portIndex?.length - 1,
      )}`;
      response.port = `${portIndex}`;
    }
    return new Promise((resolve) => resolve([200, response]));
  });
//  #endregion- Splitter

axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.VIZ.oltGoogleMap))
  .reply((config) => {
    return new Promise((resolve) => resolve([200, OLT_GOOGLE_MAPS_MOCK]));
  });

//  #endregion - OLT Dashboard

//  #region - VLAN
axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.VIZ.vlans))
  .reply((config) => {
    const type = config?.params?.type || VLAN_TABS.INTERNET;
    let response = VLAN_LIST_MOCK;
    response = response?.filter((val) => val?.type === type);
    return new Promise((resolve) => resolve([200, response]));
  });
axiosMockAdapterInstance
  .onGet(mockRouteMatcher(API_END_POINTS.VIZ.vlanById))
  .reply((config) => {
    const pathParams = mockGetPathParams(
      config.url,
      API_END_POINTS.VIZ.vlanById,
    );
    const vlanData =
      VLAN_LIST_MOCK?.find((v) => v?.vlanId === pathParams) ||
      VLAN_LIST_MOCK?.[0];
    return new Promise((resolve) => resolve([200, vlanData]));
  });
axiosMockAdapterInstance
  .onPost(mockRouteMatcher(API_END_POINTS.OPERATORS.vlans))
  .reply(() => {
    return new Promise((resolve) => resolve([201, {}]));
  });
axiosMockAdapterInstance
  .onPut(mockRouteMatcher(API_END_POINTS.OPERATORS.vlanById))
  .reply(() => {
    return new Promise((resolve) => resolve([200, {}]));
  });
axiosMockAdapterInstance
  .onDelete(mockRouteMatcher(API_END_POINTS.OPERATORS.vlans))
  .reply(() => {
    return new Promise((resolve) => resolve([204, {}]));
  });
//  #endregion - VLAN

export default axiosMockRequester;
