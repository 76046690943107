import { EntityType, IChannelPartners, IPagination } from 'store/types';
import {
  IFranchiseeList,
  IFranchiseeListFilters,
  IFranchiseeListReduxState,
  IFranchiseeSubscriptionStats,
} from 'store/types/franchisee.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IISP } from 'store/types/isp.types';
import { OperatorService } from 'apiUtils/services/operator.service';
import { formatError } from 'apiUtils/api';
import i18n from 'i18n';
import { isEmpty } from 'lodash';
import { removeEmptyValuesFromObject } from 'utils/utils';

const initialState: IFranchiseeListReduxState = {
  franchiseeSubscriptionStats: {
    loading: true,
    error: '',
    data: {} as IFranchiseeSubscriptionStats,
  },
  franchiseeList: {
    hardRefresh: false,
    loading: true,
    error: '',
    data: {} as IFranchiseeList,
  },
  franchiseeListPagination: {},
  franchiseeListSearch: {},
  franchiseeListFilters: {} as IFranchiseeListFilters,
  isFilterOpen: false,
  franchiseeListFilterDropDownValues: {
    resellerList: {
      loading: false,
      error: null,
      data: [] as IChannelPartners[],
    },
    ispList: {
      loading: false,
      error: null,
      data: [] as IISP[],
    },
  },
};

const fetchFranchiseeSubscriptionStats = createAsyncThunk(
  'franchiseeSubscriptionStats/fetch',
  async (_, thunkAPI) => {
    const response = await OperatorService.v1.GetOperatorSubscriptionStats({
      params: {
        type: EntityType.FRANCHISEE,
      },
    });

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.statsData'),
      };
    }
  },
);

const fetchResellerList = createAsyncThunk(
  'franchisee/resellerList/fetch',
  async (_, thunkAPI) => {
    const response = await OperatorService.v1.GetResellerList({
      params: { pageToken: 1, pageSize: 1000 },
    });
    if (response.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.resellerList'),
      };
    }
  },
);

const fetchISPList = createAsyncThunk(
  'franchisee/ispList/fetch',
  async (_, thunkAPI) => {
    const response = await OperatorService.v1.GetISPList({
      params: { pageToken: 1, pageSize: 1000 },
    });
    if (response.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.ispList'),
      };
    }
  },
);

const fetchFranchiseeList = createAsyncThunk(
  'franchiseeList/fetch',
  async (_, thunkAPI) => {
    let {
      franchiseeListState,
    }: { franchiseeListState: IFranchiseeListReduxState } =
      thunkAPI.getState() as any;

    const response = await OperatorService.v1.GetFranchiseeList({
      params: removeEmptyValuesFromObject({
        ...franchiseeListState?.franchiseeListPagination,
        ...franchiseeListState?.franchiseeListFilters,
        ...franchiseeListState?.franchiseeListSearch,
        trial: 'false',
      }),
    });
    if (response.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.franchiseeList'),
      };
    }
  },
);

export const FranchiseeListSlice = createSlice({
  name: 'FranchiseeList',
  initialState,
  reducers: {
    resetToInit: () => initialState,
    resetFranchiseeListState: (state: IFranchiseeListReduxState) => {
      state.franchiseeSubscriptionStats = {
        loading: true,
        error: '',
        data: {} as IFranchiseeSubscriptionStats,
      };
      state.franchiseeList = {
        hardRefresh: false,
        loading: true,
        error: '',
        data: {} as IFranchiseeList,
      };
      state.franchiseeListPagination = {} as IPagination;
      state.franchiseeListSearch = {};
      state.franchiseeListFilters = {} as IFranchiseeListFilters;
      state.isFilterOpen = false;
    },
    setShowFilterForm: (state, { payload }: { payload: boolean }) => {
      state.showFilterForm = payload;
    },
    setSearchText: (
      state,
      { payload }: { payload: string | null | undefined },
    ) => {
      state.franchiseeListSearch = { searchText: payload };
      state.franchiseeListPagination.pageToken = 1;
    },
    setPageToken: (state, { payload }: { payload: number }) => {
      state.franchiseeListPagination.pageToken = payload;
    },
    setPagination: (state, { payload }: { payload: IPagination }) => {
      state.franchiseeListPagination = payload;
    },
    setIsFilterOpen: (state, { payload }: { payload: boolean }) => {
      state.isFilterOpen = payload;
    },
    setFranchiseeListFilters: (
      state: IFranchiseeListReduxState,
      { payload }: { payload: IFranchiseeListFilters },
    ) => {
      if (isEmpty(payload)) {
        state.franchiseeListFilters = {};
      } else {
        state.franchiseeListFilters = Object.assign(
          state.franchiseeListFilters,
          payload,
        );
      }
      state.franchiseeList.hardRefresh = true;
      state.franchiseeListPagination.pageToken = 1;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchFranchiseeSubscriptionStats.pending, (state) => {
      state.franchiseeSubscriptionStats.loading = true;
      state.franchiseeSubscriptionStats.error = '';
      state.franchiseeSubscriptionStats.data =
        {} as IFranchiseeSubscriptionStats;
    });
    builder.addCase(
      fetchFranchiseeSubscriptionStats.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.franchiseeSubscriptionStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.franchiseeSubscriptionStats.data = payload;
        } else {
          state.franchiseeSubscriptionStats.error = payload?.error;
        }
      },
    );

    builder.addCase(fetchResellerList.pending, (state) => {
      state.franchiseeListFilterDropDownValues.resellerList.loading = true;
      state.franchiseeListFilterDropDownValues.resellerList.error = '';
      state.franchiseeListFilterDropDownValues.resellerList.data =
        [] as IChannelPartners[];
    });
    builder.addCase(
      fetchResellerList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.franchiseeListFilterDropDownValues.resellerList.loading = false;
        if (isEmpty(payload?.error)) {
          state.franchiseeListFilterDropDownValues.resellerList.data =
            payload?.items;
        } else {
          state.franchiseeListFilterDropDownValues.resellerList.error =
            payload?.error;
        }
      },
    );

    builder.addCase(fetchISPList.pending, (state) => {
      state.franchiseeListFilterDropDownValues.ispList.loading = true;
      state.franchiseeListFilterDropDownValues.ispList.error = '';
      state.franchiseeListFilterDropDownValues.ispList.data = [] as IISP[];
    });
    builder.addCase(
      fetchISPList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.franchiseeListFilterDropDownValues.ispList.loading = false;
        if (isEmpty(payload?.error)) {
          state.franchiseeListFilterDropDownValues.ispList.data =
            payload?.items;
        } else {
          state.franchiseeListFilterDropDownValues.ispList.error =
            payload?.error;
        }
      },
    );

    builder.addCase(fetchFranchiseeList.pending, (state) => {
      state.franchiseeList.loading = true;
      state.franchiseeList.error = '';
      state.franchiseeList.data = {} as IFranchiseeList;
    });
    builder.addCase(
      fetchFranchiseeList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.franchiseeList.loading = false;
        state.franchiseeList.hardRefresh = false;
        if (isEmpty(payload?.error)) {
          state.franchiseeList.data = payload;
        } else {
          state.franchiseeList.error = payload?.error;
        }
      },
    );
  },
});

export const FranchiseeListActions = {
  ...FranchiseeListSlice.actions,
  fetchFranchiseeSubscriptionStats,
  fetchResellerList,
  fetchISPList,
  fetchFranchiseeList,
};
export const FranchiseeListSliceReducer = FranchiseeListSlice.reducer;
