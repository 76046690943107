import { Path } from 'react-router';
import { createSlice } from '@reduxjs/toolkit';

interface ISubscriptionReduxState {
  managementEntityId: string;
  redirectToRoute: string | Partial<Path> | null;
  homeLinkButtonTitle: string;
}

const initialState: ISubscriptionReduxState = {
  managementEntityId: '',
  redirectToRoute: null,
  homeLinkButtonTitle: 'Home',
};

export const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    resetToInit: () => initialState,
    setManagementEntityId: (
      state: ISubscriptionReduxState,
      { payload }: { payload: string },
    ) => {
      state.managementEntityId = payload;
    },
    setRedirectToRoute: (
      state: ISubscriptionReduxState,
      {
        payload,
      }: {
        payload: {
          redirectToRoute: string | Partial<Path> | null;
          homeLinkButtonTitle?: string;
        };
      },
    ) => {
      state.redirectToRoute = payload?.redirectToRoute;
      state.homeLinkButtonTitle = payload?.homeLinkButtonTitle || 'Home';
    },
  },
});

export const SubscriptionSliceActions = {
  ...subscriptionSlice.actions,
};

export const SubscriptionReducer = subscriptionSlice.reducer;
