import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { ICall } from './../../types';
import { ITicketState } from 'store/types';
import { SKIPPED_CALLS_STORAGE_KEY } from '../../../constants';
import { SupportRequester } from 'apiUtils/api';

const initialState: ITicketState = {
  call: {} as ICall,
  skippedCallIDs:
    localStorage.getItem(SKIPPED_CALLS_STORAGE_KEY)?.split(',') || [],
};

const getCall = createAsyncThunk('tickets/getCall', async () => {
  const response = await SupportRequester.get(
    API_END_POINTS.TICKETS.calls,
  ).catch(({ response }) => response);
  if (response?.status >= 200 && response?.status < 300) {
    return response.data;
  } else {
    return {
      error: true,
    };
  }
});

export const ticketSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    updateSkipedCallIds: (
      state: ITicketState,
      { payload }: { payload: string },
    ) => {
      localStorage.setItem(
        SKIPPED_CALLS_STORAGE_KEY,
        [state.skippedCallIDs, payload].join(','),
      );
      state.skippedCallIDs.push(payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(
      getCall.fulfilled,
      (state, { payload }: { payload: ICall }) => {
        state.call = payload;
      },
    );
  },
});

export const TicketReducer = ticketSlice.reducer;
export const TicketActions = { ...ticketSlice.actions, getCall };
