import { IVLANItem, VLAN_TYPES } from 'store/types/vlan.types';
import {
  generateRandomInteger,
  generateRandomIp,
  generateRandomMAC,
} from 'mocks/mock.utils';

import { MANUFACTURERS_LIST_MOCK } from 'mocks/subscribers/subscriber.mock';
import { VLAN_ID_MAX_VALUE } from 'store/types';

const vlanListTotalCount = 164;
const manufacturerListLength = MANUFACTURERS_LIST_MOCK.length;

export const VLAN_LIST_MOCK: IVLANItem[] = Array.from(
  { length: vlanListTotalCount },
  (_, idx) => {
    const manufacturer =
      MANUFACTURERS_LIST_MOCK[generateRandomInteger(manufacturerListLength)]
        ?.label;
    return {
      vlanId: generateRandomInteger(VLAN_ID_MAX_VALUE),
      type: idx > 10 && idx % 2 === 0 ? VLAN_TYPES.TR069 : VLAN_TYPES.INTERNET,
      associatedOLTs: Array.from(
        { length: generateRandomInteger(5) },
        (_, idx) => {
          return {
            oltLabel: `OLT-${manufacturer}`,
            oltMac: generateRandomMAC(),
            ipAddress: generateRandomIp(),
          };
        },
      ),
    };
  },
);
