import './i18n';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { store } from './store/redux/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <HelmetProvider context={{}}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </HelmetProvider>,
);

serviceWorkerRegistration.register({
  onSuccess: (registration) => {
    console.log('Registration Success');
  },
  onUpdate: (registration) => {
    console.log('Update is pending. Force Activating');
    registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
  },
});
