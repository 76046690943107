import { AuthActions, CommonActions, useAppDispatch } from 'store/redux';
import { Modal, Space, Typography } from 'antd';
import React, { MutableRefObject, useRef, useState } from 'react';
import { blue, gold } from '@ant-design/colors';
import { faCloudBolt, faRocket } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const AppVersionChecker = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const [processingOk, setProcessingOk] = useState(false);
  const dispatch = useAppDispatch();
  const intervalRef: MutableRefObject<NodeJS.Timer | undefined> = useRef();
  const manifestJsonRef = useRef({} as any);
  const skippedBuildIdRef = useRef('' as string);

  useEffect(() => {
    checkLatestBuild();
    return () => {
      clearTimeout(intervalRef.current);
    };
  }, []);

  const scheduleTheCheck = () => {
    clearTimeout(intervalRef.current);
    intervalRef.current = setTimeout(() => {
      checkLatestBuild();
    }, 20 * 60 * 1000);
  };

  const checkLatestBuild = async () => {
    const response = await axios
      .get('/asset-manifest.json', {
        params: {
          random: Math.random(),
        },
      })
      .catch(({ response }) => {
        console.log('Error in fecthing asset-manifest.json');
        return response;
      });
    if (response?.status === 200) {
      manifestJsonRef.current = response.data.files['main.js'];
      const localStoreBuildId = localStorage.getItem('dynamicBuildId');
      if (isEmpty(localStoreBuildId)) {
        localStorage.setItem('dynamicBuildId', manifestJsonRef.current);
        scheduleTheCheck();
        dispatch(CommonActions.setVersionCheckCompleted(true));
      } else {
        if (
          localStoreBuildId !== manifestJsonRef.current &&
          manifestJsonRef.current !== skippedBuildIdRef.current
        ) {
          setTimeout(() => {
            setIsModalOpen(true);
          }, 3000);
        } else {
          scheduleTheCheck();
          dispatch(CommonActions.setVersionCheckCompleted(true));
        }
      }
    }
  };

  const handleOk = async () => {
    localStorage.setItem('dynamicBuildId', manifestJsonRef.current);
    skippedBuildIdRef.current = '';
    setProcessingOk(true);
    setTimeout(() => {
      setIsModalOpen(false);
      dispatch(AuthActions.resetToInit());
      window.location.reload();
    }, 2000);
  };

  const handleCancel = async () => {
    if (!processingOk) {
      skippedBuildIdRef.current = manifestJsonRef.current;
      setIsModalOpen(false);
      scheduleTheCheck();
    }
  };

  return (
    <Modal
      title={
        <Space>
          <FontAwesomeIcon
            size={'lg'}
            icon={faCloudBolt}
            color={blue.primary}
          />
          <Typography.Text>{t('New update is available')}</Typography.Text>
        </Space>
      }
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      centered
      okText={t('Update')}
      cancelText={t('Skip Now')}
      cancelButtonProps={{ hidden: true }}
      keyboard={false}
      maskClosable={false}
      destroyOnClose={true}
      className={'app-update-modal'}
    >
      <Space direction={'vertical'} align={'center'} style={{ width: '100%' }}>
        <FontAwesomeIcon
          size={'5x'}
          icon={faRocket}
          color={gold.primary}
          className={`update-rocket ${processingOk && 'launch'}`}
        />
        <Typography.Text>
          {t('Click on update to use the latest')}
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default React.memo(AppVersionChecker);
