import { CommonActions, useAppDispatch } from 'store/redux';
import {
  deleteToken,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';
import { useLocation, useNavigate } from 'react-router';

import { ALERT_TYPE } from 'store/types/alarm.types';
import { DeviceSliceActions } from 'store/redux/slices/deviceSlice';
import { PERMISSIONS } from 'utils/roles&Permissions';
import { ROUTES } from 'routes/routes';
import { SubscriberDashboardActions } from 'store/redux/slices/dashboards/subscriberSlice';
import { createSearchParams } from 'react-router-dom';
import { hasPermission } from './RoleBasedComponent';
import { initializeApp } from 'firebase/app';
import { isEmpty } from 'lodash';
import { notification } from 'antd';
import { removeEmptyValuesFromObject } from 'utils/utils';
import { resetFCMToken } from 'store/redux/sessionStore';
import { useEffect } from 'react';

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  vapidKey: string;
}

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  appId: process.env.FIREBASE_APP_ID,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  vapidKey: process.env.FIREBASE_VAPID_KEY,
};

enum NOTIFICATION_TYPE {
  TRIAL = 'TRIAL',
  ONT_ONBOARDING = 'ONT_ONBOARDING',
  ALERTS = 'ALERTS',
  SUBSCRIPTION = 'SUBSCRIPTION',
  OLT = 'OLT',
  REPORTS = 'REPORTS',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
let messaging: any; // = getMessaging(app);

(async () => {
  if (await isSupported()) {
    messaging = getMessaging(app);
  } else {
    notification.open({
      message: 'Warning!',
      type: 'info',
      description:
        'Notifications feature is not yet available for your device.',
    });
  }
})();

export const getFirebaseToken = async () => {
  return Notification.requestPermission().then(async (permission) => {
    if (permission === 'granted') {
      console.log('Notification User Permission Granted.');
      return await getToken(messaging, {
        vapidKey: firebaseConfig?.vapidKey,
      })
        .then((currentToken: any) => {
          if (currentToken) {
            console.log('current token for client: ', currentToken);
            return currentToken;
          } else {
            console.log(
              'Empty token returned. Request permission to generate one.',
            );
          }
        })
        .catch((err: any) => {
          console.log('An error occurred while creating client token. ', err);
          // catch error while creating client token
        });
    } else {
      notification.open({
        message: 'Warning!',
        type: 'info',
        description:
          'Notifications feature is not yet available for your device.',
      });
    }
  });
};

export const deleteFCMToken = async () => {
  if (!isEmpty(messaging)) {
    await deleteToken(messaging)
      ?.then((res) => {
        resetFCMToken();
        return res;
      })
      ?.catch((err) => {
        console.error('Delete Firebase Token Error - ', err);
      });
  }
};

export const FirebaseNotifications = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    let unsubscribe: any;
    (async () => {
      if (await isSupported()) {
        unsubscribe = onMessage(messaging, onMessageHandler);
      }
    })();
    return unsubscribe;
  }, []);

  const onMessageHandler = (payload: any) => {
    console.log(payload);
    if (payload?.data?.notificationType === NOTIFICATION_TYPE.ONT_ONBOARDING) {
      handleOntOnboardingNotifications(payload);
    } else if (payload?.data?.notificationType === NOTIFICATION_TYPE.OLT) {
      let url = window?.location?.pathname?.split('/'); //pathname in location?.pathname is not changing properly so we used window?.location?.pathname
      let subscriberId = url[url.length - 2];
      if (subscriberId === payload?.data?.subscriberId) {
        setTimeout(() => {
          dispatch(SubscriberDashboardActions.resetToInit());
          dispatch(SubscriberDashboardActions.setIsOnuByOltTriggered(false));
        }, 1000);
      }
      notification.open({
        message: payload?.data?.title || payload?.notification?.title,
        description: payload?.data?.body || payload?.notification?.body,
        onClick: () => {
          let params = {
            mode:
              `/app/dashboards/subscriber/${payload?.data?.subscriberId}/overview` !==
              window.location.pathname
                ? 'PUSH_NOTIFICATION'
                : undefined,
          };
          navigate({
            pathname: `/app/dashboards/subscriber/${payload?.data?.subscriberId}/overview`,
            search: createSearchParams({
              ...(removeEmptyValuesFromObject({
                ...params,
              }) as URLSearchParams),
            }).toString(),
          });
        },
      });
    } else {
      notification.open({
        message: payload?.data?.title || payload?.notification?.title,
        description: payload?.data?.body || payload?.notification?.body,
        onClick: () => {
          if (payload?.data?.notificationType === NOTIFICATION_TYPE.TRIAL) {
            navigate(
              `/app/dashboards/${payload?.data?.managementEntityType}/${payload?.data?.managementEntityId}`,
            );
          }
          if (
            payload?.data?.notificationType === NOTIFICATION_TYPE.ALERTS &&
            payload?.data?.notificationEventType === ALERT_TYPE.FII_LINK_HEALTH
          ) {
            dispatch(CommonActions.loadOLTBridgeStatus());
          } else if (
            payload?.data?.notificationType === NOTIFICATION_TYPE.ALERTS
          ) {
            navigate(`/app/alerts`);
          }
          if (
            payload?.data?.notificationType === NOTIFICATION_TYPE.SUBSCRIPTION
          ) {
            navigate(`/app/subscriptions/${payload?.data?.managementEntityId}`);
          }

          if (payload?.data?.notificationType === NOTIFICATION_TYPE.REPORTS) {
            navigate(ROUTES.APP.REPORTS.REPORTS);
          }
        },
      });
    }
  };

  const handleOntOnboardingNotifications = (notificationPayload: any) => {
    if (hasPermission(PERMISSIONS.DEVICES.VIEW)) {
      if (location?.pathname === '/app/devices/overview') {
        dispatch(
          DeviceSliceActions.updateOntOnboardingStatusByTransactionId(
            notificationPayload?.data,
          ),
        );
      } else {
        notification.open({
          message: notificationPayload?.notification?.title,
          description: notificationPayload?.notification?.body,
          onClick: () => {
            navigate(`/app/devices/overview`);
          },
        });
      }
    }
  };

  return <></>;
};
