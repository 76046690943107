export const ROUTES = {
  APP: {
    APP: '/app',
    DEVICES: {
      KEY: '/app/devices',
      TABS: ':tabId',
      BULK_ENROLLMENT: 'bulk-enrollment',
      RETRY_DEVICE_LIST: 'retry-device-list',
      ONBOARDING_CATEGORY_TABS: ':onboardingCategoryTabId',
      MOBILE: {
        OUI_GROUPING_CATEGORY: 'mobileView/:ouiGroupingCategory',
      },
    },
    FRANCHISEE: {
      FRANCHISEE: '/app/franchisee',
      NEW: 'new',
      EDIT: ':franchiseeId/edit',
      DETAILS: ':franchiseeId/details',
      CREATE_OLT: ':franchiseeId/olt/new',
      ADVANCED_OLT_CREATE: ':franchiseeId/olt/advanced-OLT-create',
      EXTEND_SUBSCRIPTION: ':managementEntityId/extend-subscription',
      SUBSCRIPTION_MARK_AS_EXPIRED:
        ':managementEntityId/subscription-mark-as-expired',
    },
    ISP: {
      ISP: '/app/isps',
      NEW: 'new',
      EDIT: ':ispId/edit',
      DETAILS: ':ispId/details',
      CREATE_FRANCHISEE: ':ispId/franchisee/new',
      CREATE_OLT: ':ispId/olt/new',
      ADVANCED_OLT_CREATE: ':ispId/olt/advanced-OLT-create',
      EXTEND_SUBSCRIPTION: ':managementEntityId/extend-subscription',
      SUBSCRIPTION_MARK_AS_EXPIRED:
        ':managementEntityId/subscription-mark-as-expired',
    },
    OLT: {
      OLT: '/app/olts',
      SPLITTER: {
        NEW: 'splitter/new',
        EDIT: 'splitter/:splitterId/edit',
      },
      TABS: ':tabId',
      NEW: 'new',
      EDIT: ':oltMac/edit',
      UPDATE_TELEMETRY_CONFIG: ':oltMac/update-telemetry-config',
      PENDING_EDIT: ':ipAddress/pending-edit',
      ADVANCED_OLT_CREATE: 'advanced-OLT-create',
      DASHBOARD: '/app/dashboards/olt/:oltMac',
      MOBILE: {
        ONT_DEVICES: 'mobileView/ont-devices',
      },
    },
    OPERATORS: {
      OPERATORS: '/app/operators',
      EXTEND_SUBSCRIPTION: ':managementEntityId/extend-subscription',
    },
    REPORTS: {
      REPORTS: '/app/reports',
      GENERATE_REPORT: 'generate-report',
    },
    SUBSCRIBER: {
      DASHBOARD: {
        KEY: '/app/dashboards/subscriber/:subscriberId',
        TABS: ':tabId',
      },
      LIST: {
        SCREEN: '/app/subscribers',
        TABS: ':tabId',
        NEW: 'new',
        EDIT: ':subscriberId/edit',
        DETAILS: ':subscriberId/details',
        BULK_UPLOAD: 'bulk-upload',
      },
    },
    VLAN: {
      VLAN: '/app/vlans',
      TABS: ':tabId',
      NEW: 'new',
      EDIT: ':vlanId/edit',
    },
  },
};
