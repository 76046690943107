import {
  IActionDropdownList,
  IActionList,
  IActionListState,
  IActionsListFilters,
  IListSorting,
  IManagementEntityDetails,
} from 'store/types';
import { Subscriber2Requester, formatError } from 'apiUtils/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { removeEmptyValuesFromObject } from 'utils/utils';

interface IActionListQueryParams {
  params?: IActionsListFilters | null;
}

const initialState: IActionListState = {
  actionList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as IActionList,
  },
  actionListPagination: {},
  actionListFilters: {},
  actionListSorting: {} as IListSorting,
  actionListPathName: null,
  isFilterOpen: false,
  actionsListFilterDropDownList: {
    managementEntityList: [],
    actionsList: [],
  },
};

export const loadActionList = createAsyncThunk(
  'actionList/fetch',
  async (payload: IActionListQueryParams | void, thunkAPI) => {
    const { actionListState }: { actionListState: IActionListState } =
      thunkAPI.getState() as any;
    const response = await Subscriber2Requester.get(
      API_END_POINTS.SUBSCRIBER.actionResult,
      {
        params: !isEmpty(payload?.params)
          ? payload?.params
          : removeEmptyValuesFromObject({
              //  When fetching the actions list from Subscriber Dashboard "Configuration Updates" Tab - Should Not pass pagination values in query params
              ...(actionListState?.actionListPathName?.pathname?.includes(
                '/app/action-result',
              )
                ? actionListState?.actionListPagination
                : {}),
              ...actionListState?.actionListFilters,
              ...actionListState?.actionListSorting,
            }),
      },
    ).catch(({ response }) => response);

    if (response?.status === 200) {
      //  When fetching the actions list from Subscriber Dashboard "Configuration Updates" Tab - Need to update the total based on items count
      if (
        !actionListState?.actionListPathName?.pathname?.includes(
          '/app/action-result',
        )
      ) {
        response.data.total = response?.data?.items?.length;
      }
      return response?.data;
    } else {
      return {
        error: formatError(response) || 'messages.error.action',
      };
    }
  },
);

export const actionListSlice = createSlice({
  name: 'actionList',
  initialState,
  reducers: {
    resetToInit: (state: IActionListState) => {
      state.actionList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IActionList,
      };
      state.actionListPagination = {};
      state.actionListFilters = {};
      state.actionListSorting = {} as IListSorting;
      state.actionListPathName = null;
      state.isFilterOpen = false;
      state.actionsListFilterDropDownList = {
        managementEntityList: [],
        actionsList: [],
      };
    },

    resetActionList: (state) => {
      state.actionList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IActionList,
      };
      state.actionListPathName = null;
    },

    setActionListSorting: (
      state: IActionListState,
      { payload }: { payload: string | null | undefined },
    ) => {
      state.actionListSorting.sort = payload || undefined;
    },

    setIsFilterOpen: (state, { payload }: { payload: boolean }) => {
      state.isFilterOpen = payload;
    },

    setManagementEntityList: (
      state: IActionListState,
      { payload }: { payload: IManagementEntityDetails[] },
    ) => {
      state.actionsListFilterDropDownList.managementEntityList = payload;
    },

    setActionsList: (
      state: IActionListState,
      { payload }: { payload: IActionDropdownList[] },
    ) => {
      state.actionsListFilterDropDownList.actionsList = payload;
    },

    setActionListPageToken: (state, { payload }: { payload: number }) => {
      state.actionListPagination.pageToken = payload;
    },
    setActionListPageSize: (
      state: IActionListState,
      { payload }: { payload: number },
    ) => {
      state.actionListPagination.pageSize = payload;
    },
    setActionListPagination: (state, action) => {
      state.actionListPagination = action.payload;
    },
    setActionListFilter: (state, action) => {
      if (isEmpty(action?.payload)) {
        state.actionListFilters = {};
      } else {
        state.actionListFilters = Object.assign(
          state.actionListFilters,
          action.payload,
        );
      }
      state.actionList.hardReload = true;
    },

    setHardReload: (state, action) => {
      state.actionList.hardReload = action.payload;
    },

    setActionListPathName: (state, action) => {
      state.actionListPathName = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadActionList.pending, (state: IActionListState) => {
      state.actionList.loading = true;
      state.actionList.error = '';
      state.actionList.data = {} as IActionList;
    });
    builder.addCase(
      loadActionList.fulfilled,
      (state: any, { payload }: { payload: AxiosResponse | any }) => {
        state.actionList.loading = false;
        state.actionList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.actionList.data = payload;
        } else {
          state.actionList.error = payload?.error;
        }
      },
    );
  },
});

export const ActionListSliceActions = {
  ...actionListSlice.actions,
  loadActionList,
};

export const ActionListStateReducer = actionListSlice.reducer;
