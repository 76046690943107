import {
  Alarm_Tabs,
  IAlarmEventData,
  IAlarmState,
  IAlarmSummaryList,
  IAlarmSummaryStats,
  IAlarmsFilter,
  ISupportedAlerts,
} from 'store/types/alarm.types';
import {
  AlertsRequester,
  VizRequester,
  formatError,
  formatURL,
} from 'apiUtils/api';
import { AuthState, IManagementEntityDetails, IPagination } from 'store/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { IOLTListItems } from 'store/types/olt.types';
import { PERMISSIONS } from 'utils/roles&Permissions';
import { ROUTES } from 'routes/routes';
import { formatRouterOutlet } from 'routes/utils';
import { hasPermission } from 'components/utilities/RoleBasedComponent';
import i18n from 'i18n';
import { isEmpty } from 'lodash';
import { message } from 'antd';
import { removeEmptyValuesFromObject } from 'utils/utils';

const initialState: IAlarmState = {
  autoRefresh: true,
  alarmEventList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as IAlarmEventData,
  },
  alarmSummaryList: {
    loading: true,
    hardReload: true,
    error: '',
    data: [] as IAlarmSummaryList[],
  },
  alarmSummaryStats: {
    loading: true,
    error: '',
    stats: {} as IAlarmSummaryStats,
  },
  alarmEventListPagination: {} as IPagination,
  alarmEventListPageTokens: [''],
  hasNextPageToken: false,
  alarmFilters: {} as IAlarmsFilter,
  currentTabId: '',
  isFilterOpen: false,
  alarmFilterDropDownList: {
    oltFilter: {
      loading: true,
      error: '',
      oltList: [] as IOLTListItems[],
    },
    managementEntityList: [] as IManagementEntityDetails[],
    supportAlerts: [] as ISupportedAlerts[],
  },
  subscriberIdLoading: false,
};

export const loadAlarmEventList = createAsyncThunk(
  'alarmEventList/fetch',
  async (_, thunkAPI) => {
    const { alarmState }: { alarmState: IAlarmState } =
      thunkAPI.getState() as any;

    const response: AxiosResponse = await AlertsRequester.get(
      API_END_POINTS.ALERTS.alertEvents,
      {
        params: {
          pageSize: alarmState?.alarmEventListPagination?.pageSize,
          pageToken:
            alarmState?.alarmEventListPageTokens[
              (alarmState?.alarmEventListPagination?.pageNumber || 1) - 1
            ],
          ...removeEmptyValuesFromObject(alarmState?.alarmFilters),
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || 'Error in fetching events. Please try again',
      };
      // return ALARM_EVENT_LIST_MOCK;
    }
  },
);

export const loadAlarmSummaryList = createAsyncThunk(
  'alarmSummaryList/fetch',
  async (_, thunkAPI) => {
    const { alarmState }: { alarmState: IAlarmState } =
      thunkAPI.getState() as any;

    const response: AxiosResponse = await AlertsRequester.get(
      API_END_POINTS.ALERTS.alarmSummary,
      {
        params: {
          managementEntityIds: alarmState?.alarmFilters?.managementEntityIds,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
      // return ALARM_SUMMARY_LIST_MOCK;
    } else {
      return {
        error:
          formatError(response) ||
          'Error in fetching alarm stats. Please try again',
      };
    }
  },
);

export const loadAlarmSummaryStats = createAsyncThunk(
  'alarmSummaryStats/fetch',
  async (_, thunkAPI) => {
    const { alarmState }: { alarmState: IAlarmState } =
      thunkAPI.getState() as any;

    const response: AxiosResponse = await AlertsRequester.get(
      API_END_POINTS.ALERTS.alertStat,
      {
        params: {
          managementEntityIds: alarmState?.alarmFilters?.managementEntityIds,
        },
      },
    ).catch(({ response }) => response);

    if (response?.status === 200) {
      return response?.data;
    } else {
      // return ALARM_STATS_COUNT_MOCK;
      return {
        error:
          formatError(response) ||
          'Error in fetching OLT Stats. Please try again',
      };
    }
  },
);

export const loadOLTList = createAsyncThunk(
  'oltList/fetch',
  async (_, thunkAPI) => {
    const { auth }: { auth: AuthState } = thunkAPI.getState() as any;
    const response = await VizRequester.get(
      formatURL(API_END_POINTS.VIZ.oltList, {
        managementEntityId: auth?.userInfo?.managementEntity?.id,
      }),
    ).catch(({ response }) => response);

    if (response?.status === 200) {
      return response?.data;
    } else {
      const errMsg = i18n.t('messages.error.oltList');
      return {
        error: formatError(response) || errMsg,
      };
    }
  },
);

export const loadSubscriberId = createAsyncThunk(
  'subscriberId/fetch',
  async (
    payload: {
      cpeId?: string;
      oltMac?: string;
      portIndex?: string;
      ontNumber?: string;
    },
    thunkAPI,
  ) => {
    if (hasPermission(PERMISSIONS.SUBSCRIBERS.DASHBOARD)) {
      const response = await VizRequester.get(API_END_POINTS.VIZ.subscriberId, {
        params: payload,
      }).catch(({ response }) => response);

      if (response?.status === 200) {
        window.open(
          `${formatRouterOutlet(ROUTES.APP.SUBSCRIBER.DASHBOARD.KEY, {
            subscriberId: response?.data?.subscriberId,
          })}`,
        );
      } else {
        const errMsg = i18n.t(
          response?.status === 404
            ? 'No subscriber available for this Serial Number/MAC'
            : 'messages.error.somethingWentWrong',
        );

        message.error(formatError(response) || errMsg, 5);
      }
    }
  },
);

export const alarmsSlice = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    resetToInit: (state: IAlarmState) => {
      state.autoRefresh = true;
      state.alarmEventList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IAlarmEventData,
      };
      state.alarmSummaryList = {
        loading: true,
        hardReload: true,
        error: '',
        data: [] as IAlarmSummaryList[],
      };
      state.alarmSummaryStats = {
        loading: true,
        error: '',
        stats: {} as IAlarmSummaryStats,
      };
      state.alarmEventListPagination = {} as IPagination;
      state.alarmEventListPageTokens = [''];
      state.hasNextPageToken = false;
      state.alarmFilters = {} as IAlarmsFilter;
      state.isFilterOpen = false;
      state.currentTabId = '';
      state.alarmFilterDropDownList = {
        oltFilter: {
          loading: true,
          error: '',
          oltList: [] as IOLTListItems[],
        },
        managementEntityList: [] as IManagementEntityDetails[],
        supportAlerts: [] as ISupportedAlerts[],
      };
    },
    resetEventList: (state: IAlarmState) => {
      state.alarmEventList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IAlarmEventData,
      };
    },
    setManagementEntityList: (
      state: IAlarmState,
      { payload }: { payload: IManagementEntityDetails[] },
    ) => {
      state.alarmFilterDropDownList.managementEntityList = payload;
    },
    setSupportedAlerts: (
      state: IAlarmState,
      { payload }: { payload: ISupportedAlerts[] },
    ) => {
      state.alarmFilterDropDownList.supportAlerts = payload;
    },
    setAutoRefresh: (state: IAlarmState, { payload }: { payload: boolean }) => {
      state.autoRefresh = payload;
    },
    setAlarmEventListLoading: (
      state: IAlarmState,
      { payload }: { payload: boolean },
    ) => {
      state.alarmEventList.loading = payload;
      state.alarmEventList.data = {} as IAlarmEventData;
    },
    setAlarmEventListHardReload: (
      state: IAlarmState,
      { payload }: { payload: boolean },
    ) => {
      state.alarmEventList.hardReload = payload;
    },
    setAlarmEventListPageNumber: (
      state: IAlarmState,
      { payload }: { payload: number },
    ) => {
      state.alarmEventListPagination.pageToken =
        state.alarmEventListPageTokens[payload - 1];
      state.alarmEventListPagination.pageNumber = payload;
      state.alarmEventList.loading = true;
    },
    setAlarmEventListPageSize: (
      state: IAlarmState,
      { payload }: { payload: number },
    ) => {
      state.alarmEventListPagination.pageSize = payload;
      state.alarmEventListPageTokens = [''];
    },
    setAlarmEventListPagination: (
      state: IAlarmState,
      action: PayloadAction<IPagination>,
    ) => {
      state.alarmEventListPagination = action.payload;
      state.alarmEventListPageTokens = [''];
    },
    setAlarmEventListFilters: (
      state: IAlarmState,
      action: PayloadAction<IAlarmsFilter>,
    ) => {
      if (isEmpty(action?.payload)) {
        state.alarmFilters = {} as IAlarmsFilter;
      } else {
        state.alarmFilters = Object.assign(state.alarmFilters, action.payload);
      }
      state.alarmEventList.hardReload = true;
    },
    setIsFilterOpen: (
      state: IAlarmState,
      { payload }: { payload: boolean },
    ) => {
      state.isFilterOpen = payload;
    },
    setAlarmSummaryAndStatsLoading: (state: IAlarmState) => {
      state.alarmSummaryList.loading = true;
      state.alarmSummaryStats.loading = true;
      state.alarmSummaryList.data = [] as IAlarmSummaryList[];
      state.alarmSummaryStats.stats = {} as IAlarmSummaryStats;
    },
    setCurrentTabId: (state: IAlarmState, { payload }: { payload: string }) => {
      state.currentTabId = payload;
      state.alarmFilters = {
        managementEntityIds: state?.alarmFilters?.managementEntityIds,
      };
      if (state.currentTabId === Alarm_Tabs.EVENTS) {
        state.alarmEventList = {
          loading: true,
          hardReload: true,
          error: '',
          data: {} as IAlarmEventData,
        };
      } else if (state.currentTabId === Alarm_Tabs.SUMMARY) {
        state.alarmSummaryList = {
          loading: true,
          hardReload: true,
          error: '',
          data: [] as IAlarmSummaryList[],
        };
        state.alarmSummaryStats = {
          loading: true,
          error: '',
          stats: {} as IAlarmSummaryStats,
        };
      }
    },
    resetAdvancedAlarmFilter: (state: IAlarmState) => {
      state.alarmFilters = {
        oltMac: '',
        oltPortIndex: '',
        status: '',
        eventName: '',
        eventType: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAlarmEventList.pending, (state: IAlarmState) => {
      if (!state.autoRefresh) {
        state.alarmEventList.loading = true;
      }
      state.alarmEventList.error = '';
    });
    builder.addCase(
      loadAlarmEventList.fulfilled,
      (state: IAlarmState, { payload }: { payload: AxiosResponse | any }) => {
        state.alarmEventList.loading = false;
        state.alarmEventList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.alarmEventList.data = payload;
          if (payload?.pageToken) {
            const currentPageIndex =
              state.alarmEventListPagination.pageNumber || 1;
            state.alarmEventListPageTokens[currentPageIndex] =
              payload?.pageToken || '';
          }
          state.hasNextPageToken = !!payload?.pageToken;
        } else {
          state.alarmEventList.error = payload?.error;
        }
      },
    );

    builder.addCase(loadAlarmSummaryList.pending, (state: IAlarmState) => {
      if (!state.autoRefresh) {
        state.alarmSummaryList.loading = true;
      }
      state.alarmSummaryList.error = '';
    });
    builder.addCase(
      loadAlarmSummaryList.fulfilled,
      (state: IAlarmState, { payload }: { payload: AxiosResponse | any }) => {
        state.alarmSummaryList.loading = false;
        state.alarmSummaryList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.alarmSummaryList.data = payload;
        } else {
          state.alarmSummaryList.data = [] as IAlarmSummaryList[];
          state.alarmSummaryList.error = payload?.error;
        }
      },
    );

    builder.addCase(loadAlarmSummaryStats.pending, (state: IAlarmState) => {
      if (!state.autoRefresh) {
        state.alarmSummaryStats.loading = true;
      }
      state.alarmSummaryStats.error = '';
    });
    builder.addCase(
      loadAlarmSummaryStats.fulfilled,
      (state: IAlarmState, { payload }: { payload: AxiosResponse | any }) => {
        state.alarmSummaryStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.alarmSummaryStats.stats = payload;
        } else {
          state.alarmSummaryStats.error = payload?.error;
        }
      },
    );

    builder.addCase(loadOLTList.pending, (state: IAlarmState) => {
      state.alarmFilterDropDownList.oltFilter.loading = true;
      state.alarmFilterDropDownList.oltFilter.error = '';
    });
    builder.addCase(
      loadOLTList.fulfilled,
      (state: IAlarmState, { payload }: { payload: AxiosResponse | any }) => {
        state.alarmFilterDropDownList.oltFilter.loading = false;
        if (isEmpty(payload?.error)) {
          state.alarmFilterDropDownList.oltFilter.oltList = payload;
        } else {
          state.alarmFilterDropDownList.oltFilter.error = payload?.error;
        }
      },
    );

    builder.addCase(loadSubscriberId.pending, (state: IAlarmState) => {
      state.subscriberIdLoading = true;
    });
    builder.addCase(
      loadSubscriberId.fulfilled,
      (state: IAlarmState, { payload }: { payload: AxiosResponse | any }) => {
        state.subscriberIdLoading = false;
      },
    );
  },
});

export const AlarmsSliceActions = {
  ...alarmsSlice.actions,
  loadAlarmEventList,
  loadAlarmSummaryList,
  loadAlarmSummaryStats,
  loadOLTList,
  loadSubscriberId,
};

export const AlarmsReducer = alarmsSlice.reducer;
export const excludedFilters = [
  'managementEntityIds',
  'startTime',
  'endTime',
  'duration',
  'dateRange',
];
