import { IPagination } from 'store/types';

// SMS - Subscriber Management System
export interface ISMSTransactionsReduxState {
  smsTransactionsList: {
    loading: boolean;
    error?: string | null;
    data: ISMSTransactionsList | null;
    hardReload?: boolean;
  };
  hasNextPageToken: boolean;
  smsTransactionsListPagination: IPagination;
  smsTransactionsListPageTokens: [''];
  smsTransactionListFilters: ISMSTransactionListFilters;
}

export interface ISMSTransactionListFilters {
  status?: SMS_TRANSACTION_STATUS | null;
  subscriberId?: string | null;
}
export interface ISMSTransactionsList {
  items: ISMSTransactionItem[];
  pageSize: number;
  pageToken: number;
  total: number;
}

export interface ISMSTransactionItem {
  transactionId: string;
  status: SMS_TRANSACTION_STATUS;
  createdTime: string;
  initiatedBy: ISMSTransactionUserDetails;
  closedBy: ISMSTransactionUserDetails;
  ticketDetails: ISMSTransactionTicketDetails;
  device: ISMSTransactionDeviceDetails;
  managementEntityId: string;
  updatedTime: string;
}

export interface ISMSTransactionUserDetails {
  id: string;
  name: string;
}

export interface ISMSTransactionDeviceDetails {
  mac: string;
  wanMac: string;
  hardwareVersion: string;
  hardwareModel: string;
  firmwareVersion: string;
  agentVersion: string;
  serialNumber: string;
  olt: ISMSTransactionDeviceOltDetails;
}

export interface ISMSTransactionDeviceOltDetails {
  mac: string;
  ontNumber: string;
  portIndex: string;
  portNumber: number;
}

export interface ISMSTransactionTicketDetails {
  ticketId: string;
  customerId: string;
  userName: string;
  failureReason: string;
}

export enum SMS_TRANSACTION_STATUS {
  INITIATED = 'INITIATED',
  CREATED = 'CREATED',
  CLOSED = 'CLOSED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  INVALID = 'INVALID',
}

export enum SMS_TRANSACTION_STATUS_VIEW {
  INITIATED = 'Initiated',
  CREATED = 'Created',
  CLOSED = 'Closed',
  FAILED = 'Failed',
  PENDING = 'Pending',
  INVALID = 'Invalid',
}
