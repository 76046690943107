import {
  ISupportedEnumValues,
  IWANConfigForm,
  WAN_CONNECTION_TYPE_VALUES,
} from 'store/types/subscriber.types';

export const WAN_CONFIG_LIST_MOCK: IWANConfigForm[] = [
  {
    connectionName: '1_TR069_INTERNET_R_VID_202',
    connectionType: WAN_CONNECTION_TYPE_VALUES.BRIDGE,
    id: '1',
    ipId: '2',
    pppoeId: '3',
    ipMode: 'IPv4',
    addressingType: '',
    natEnabled: false,
    vlanEnabled: true,
    vlanId: '200',
    priority: '7',
    mtu: '1500',
    serviceList: 'INTERNET',
    // static: {
    //   externalIpAddress: '192.168.1.11',
    //   subnetMask: '',
    //   defaultGateway: '',
    //   primaryDNS: '8.8.8.8',
    //   secondaryDNS: '1.1.1.1',
    // },
    // pppoe: {
    //   username: 'test',
    //   password: 'test@123',
    //   serviceName: '',
    //   pppName: 'Continuous',
    // },
    portBinding: {
      lan: [
        {
          id: '1',
          name: '1',
          enabled: true,
        },
        {
          id: '2',
          enabled: false,
          name: '1',
        },
        {
          id: '3',
          enabled: true,
          name: '1',
        },
        {
          id: '4',
          enabled: true,
          name: '1',
        },
      ],
      wlan: [
        { name: 'WLAN1', id: '1', band: '2.4 GHz', enabled: true },
        { name: 'WLAN2', id: '2', band: '5.0 GHz', enabled: true },
        { name: 'WLAN3', id: '3', band: '5.0 GHz', enabled: false },
        { name: 'WLAN3', id: '4', band: '2.4 GHz', enabled: false },
      ],
    },
  },
  {
    connectionName: '1_TR069_INTERNET_R_VID_201',
    connectionType: WAN_CONNECTION_TYPE_VALUES.PPPOE_ROUTE,
    id: '1',
    ipId: '2',
    pppoeId: '3',
    ipMode: 'IPv4',
    addressingType: 'PPPoE Static',
    natEnabled: false,
    vlanEnabled: true,
    vlanId: '200',
    priority: '7',
    mtu: '1500',
    serviceList: 'INTERNET',
    static: {
      externalIpAddress: '192.168.1.18',
      subnetMask: '',
      defaultGateway: '',
      primaryDNS: '8.8.8.8',
      secondaryDNS: '1.1.1.1',
    },
    pppoe: {
      username: 'test',
      password: 'test@123',
      serviceName: '',
      pppName: 'Continuous',
    },
    portBinding: {
      lan: [
        {
          id: '1',
          name: '1',
          enabled: true,
        },
        {
          id: '2',
          enabled: false,
          name: '1',
        },
        {
          id: '3',
          enabled: true,
          name: '1',
        },
        {
          id: '4',
          enabled: true,
          name: '1',
        },
      ],
      wlan: [
        { name: 'WLAN1', id: '1', band: '2.4 GHz', enabled: true },
        { name: 'WLAN2', id: '2', band: '5.0 GHz', enabled: true },
        { name: 'WLAN3', id: '3', band: '5.0 GHz', enabled: false },
        { name: 'WLAN3', id: '4', band: '2.4 GHz', enabled: false },
      ],
    },
  },
  {
    connectionName: '1_TR069_INTERNET_R_VID_200',
    connectionType: WAN_CONNECTION_TYPE_VALUES.PPPOE_ROUTE,
    id: '1',
    ipId: '2',
    pppoeId: '3',
    ipMode: 'IPv4',
    addressingType: 'PPPoE Static',
    natEnabled: false,
    vlanEnabled: true,
    vlanId: '200',
    priority: '7',
    mtu: '1500',
    serviceList: 'INTERNET',
    static: {
      externalIpAddress: '192.168.1.18',
      subnetMask: '',
      defaultGateway: '',
      primaryDNS: '8.8.8.8',
      secondaryDNS: '1.1.1.1',
    },
    pppoe: {
      username: 'test',
      password: 'test@123',
      serviceName: '',
      pppName: 'Continuous',
    },
    portBinding: {
      lan: [
        {
          id: '1',
          name: '1',
          enabled: true,
        },
        {
          id: '2',
          enabled: false,
          name: '1',
        },
        {
          id: '3',
          enabled: true,
          name: '1',
        },
        {
          id: '4',
          enabled: true,
          name: '1',
        },
      ],
      wlan: [
        { name: 'WLAN1', id: '1', band: '2.4 GHz', enabled: true },
        { name: 'WLAN2', id: '2', band: '5.0 GHz', enabled: true },
        { name: 'WLAN3', id: '3', band: '5.0 GHz', enabled: false },
        { name: 'WLAN3', id: '4', band: '2.4 GHz', enabled: false },
      ],
    },
  },
  {
    connectionName: '1_TR069_INTERNET_R_VID_205',
    connectionType: WAN_CONNECTION_TYPE_VALUES.PPPOE_ROUTE,
    id: '1',
    ipId: '2',
    pppoeId: '3',
    ipMode: 'IPv4',
    addressingType: 'PPPoE Static',
    natEnabled: false,
    vlanEnabled: true,
    vlanId: '200',
    priority: '7',
    mtu: '1500',
    serviceList: 'INTERNET',
    static: {
      externalIpAddress: '192.168.1.18',
      subnetMask: '',
      defaultGateway: '',
      primaryDNS: '8.8.8.8',
      secondaryDNS: '1.1.1.1',
    },
    pppoe: {
      username: 'test',
      password: 'test@123',
      serviceName: '',
      pppName: 'Continuous',
    },
    portBinding: {
      lan: [
        {
          id: '1',
          name: '1',
          enabled: true,
        },
        {
          id: '2',
          enabled: false,
          name: '1',
        },
        {
          id: '3',
          enabled: true,
          name: '1',
        },
        {
          id: '4',
          enabled: true,
          name: '1',
        },
      ],
      wlan: [
        { name: 'WLAN1', id: '1', band: '2.4 GHz', enabled: true },
        { name: 'WLAN2', id: '2', band: '5.0 GHz', enabled: true },
        { name: 'WLAN3', id: '3', band: '5.0 GHz', enabled: false },
        { name: 'WLAN3', id: '4', band: '2.4 GHz', enabled: false },
      ],
    },
  },
  {
    connectionName: '1_TR069_INTERNET_R_VID_206',
    connectionType: WAN_CONNECTION_TYPE_VALUES.PPPOE_ROUTE,
    id: '1',
    ipId: '2',
    pppoeId: '3',
    ipMode: 'IPv4',
    addressingType: 'PPPoE Static',
    natEnabled: false,
    vlanEnabled: true,
    vlanId: '200',
    priority: '7',
    mtu: '1500',
    serviceList: 'INTERNET',
    static: {
      externalIpAddress: '192.168.1.18',
      subnetMask: '',
      defaultGateway: '',
      primaryDNS: '8.8.8.8',
      secondaryDNS: '1.1.1.1',
    },
    pppoe: {
      username: 'test',
      password: 'test@123',
      serviceName: '',
      pppName: 'Continuous',
    },
    portBinding: {
      lan: [
        {
          id: '1',
          name: '1',
          enabled: true,
        },
        {
          id: '2',
          enabled: false,
          name: '1',
        },
        {
          id: '3',
          enabled: true,
          name: '1',
        },
        {
          id: '4',
          enabled: true,
          name: '1',
        },
      ],
      wlan: [
        { name: 'WLAN1', id: '1', band: '2.4 GHz', enabled: true },
        { name: 'WLAN2', id: '2', band: '5.0 GHz', enabled: true },
        { name: 'WLAN3', id: '3', band: '5.0 GHz', enabled: false },
        { name: 'WLAN3', id: '4', band: '2.4 GHz', enabled: false },
      ],
    },
  },
  {
    connectionName: '1_TR069_VOICE_INTERNET_R_VID_100',
    connectionType: WAN_CONNECTION_TYPE_VALUES.STATIC_ROUTE,
    ipMode: 'IPv4/v6',
    id: '1',
    ipId: '1',
    pppoeId: null,
    addressingType: 'STATIC',
    natEnabled: true,
    vlanEnabled: true,
    vlanId: '100',
    priority: '0',
    mtu: '1500',
    serviceList: 'TR069,INTERNET,VOIP',
    ipAddress: '10.200.10.27',
    static: {
      externalIpAddress: '10.200.10.27',
      subnetMask: '255.255.255.0',
      defaultGateway: '',
      primaryDNS: '8.8.8.8',
      secondaryDNS: '8.8.4.4',
    },
    pppoe: null,
    mac: '4c:ae:1c:3a:b4:82',
    connectionStatus: 'Connected',
    portBinding: {
      lan: [
        {
          id: '1',
          name: null,
          enabled: true,
        },
        {
          id: '2',
          name: null,
          enabled: true,
        },
      ],
      wlan: [
        {
          name: 'DBC-Wifi-5G',
          id: '1',
          band: '5.0 GHz',
          enabled: true,
        },
        {
          name: 'DBC-Wifi-2.4G',
          id: '5',
          band: '2.4 GHz',
          enabled: false,
        },
      ],
    },
  },
];

export const WAN_CONFIG_MOCK: IWANConfigForm = {
  connectionName: '1_TR069_INTERNET_R_VID_205',
  connectionType: WAN_CONNECTION_TYPE_VALUES.PPPOE_ROUTE,
  id: '1',
  ipId: '2',
  pppoeId: '3',
  ipMode: 'IPv4',
  addressingType: 'PPPoE Static',
  natEnabled: false,
  vlanEnabled: true,
  vlanId: '200',
  priority: '7',
  mtu: '1500',
  serviceList: 'INTERNET',
  static: {
    externalIpAddress: '192.168.1.18',
    subnetMask: '',
    defaultGateway: '',
    primaryDNS: '8.8.8.8',
    secondaryDNS: '1.1.1.1',
  },
  pppoe: {
    username: 'test',
    password: 'test@123',
    serviceName: '',
    pppName: 'Continuous',
  },
  portBinding: {
    lan: [
      {
        id: '1',
        name: 'Port 1',
        enabled: true,
      },
      {
        id: '2',
        enabled: false,
        name: 'Port 2',
      },
      {
        id: '3',
        enabled: true,
        name: 'Port 3',
      },
      {
        id: '4',
        enabled: true,
        name: 'Port 4',
      },
    ],
    wlan: [
      { name: 'WLAN1', id: '1', band: '2.4 GHz', enabled: true },
      { name: 'WLAN2', id: '2', band: '5.0 GHz', enabled: true },
      { name: 'WLAN3', id: '3', band: '5.0 GHz', enabled: false },
      { name: 'WLAN3', id: '4', band: '2.4 GHz', enabled: false },
    ],
  },
};

export const WLAN_PORTS_MOCK = {
  totalLAN: 4,
  LAN: [
    { id: '1', name: 'Port 1', enabled: true },
    { id: '2', name: 'Port 2', enabled: false },
    { id: '3', name: 'Port 3', enabled: true },
    { id: '4', name: 'Port 4', enabled: false },
  ],
  WLANs: [
    { name: 'WLAN1', id: '1', band: '2.4 GHz', enabled: true },
    { name: 'WLAN2', id: '2', band: '5.0 GHz', enabled: false },
    { name: 'WLAN3', id: '3', band: '5.0 GHz', enabled: true },
    { name: 'WLAN4', id: '4', band: '2.4 GHz', enabled: false },
  ],
};

export const SUPPORTED_ENUM_VALUES_MOCK: ISupportedEnumValues = {
  manufacturer: 'netlink',
  hardwareModel: 'XPON+2GE+1POTS+2WIFI',
  fields: {
    channel: [
      {
        key: 'Auto',
        label: 'Auto',
        value: 'auto',
      },
      {
        key: '6',
        label: '6',
        value: '6',
      },
      {
        key: '7',
        label: '7',
        value: '7',
      },
      {
        key: '12',
        label: '12',
        value: '12',
      },
      {
        key: '2',
        label: '2',
        value: '2',
      },
      {
        key: '3',
        label: '3',
        value: '3',
      },
      {
        key: '5',
        label: '5',
        value: '5',
      },
      {
        key: '9',
        label: '9',
        value: '9',
      },
      {
        key: '10',
        label: '10',
        value: '10',
      },
      {
        key: '11',
        label: '11',
        value: '11',
      },
      {
        key: '13',
        label: '13',
        value: '13',
      },
      {
        key: '1',
        label: '1',
        value: '1',
      },
      {
        key: '4',
        label: '4',
        value: '4',
      },
      {
        key: '8',
        label: '8',
        value: '8',
      },
    ],
    channelWidth: [
      {
        key: '20MHz',
        label: '20MHz',
        value: '0',
      },
      {
        key: '40MHz',
        label: '40MHz',
        value: '1',
      },
      {
        key: '80MHz',
        label: '80MHz',
        value: '2',
      },
      {
        key: '20/40MHz',
        label: '20/40MHz',
        value: '3',
      },
    ],
    connectionType: [
      {
        key: 'PPPOE_ROUTE',
        label: 'PPPOE_ROUTE',
        value: 'PPPoE_Bridged',
      },
      {
        key: 'BRIDGE_ROUTE',
        label: 'BRIDGE_ROUTE',
        value: 'PPPoE_Bridged',
      },
      {
        key: 'STATIC_ROUTE',
        label: 'STATIC_ROUTE',
        value: 'IP_Routed',
      },
      {
        key: 'DHCP_ROUTE',
        label: 'DHCP_ROUTE',
        value: 'IP_Routed',
      },
    ],
    encryption: [
      {
        key: 'WPA',
        label: 'WPA',
        value: 'WPA',
      },
      {
        key: 'WPA2',
        label: 'WPA2',
        value: '11i',
      },
      {
        key: 'WPA2 Mixed',
        label: 'WPA2 Mixed',
        value: 'WPAand11i',
      },
    ],
    ieee11iEncryptionMode: [
      {
        key: 'TKIPEncryption',
        label: 'TKIPEncryption',
        value: 'TKIPEncryption',
      },
      {
        key: 'AESEncryption',
        label: 'AESEncryption',
        value: 'AESEncryption',
      },
      {
        key: 'TKIPandAESEncryption',
        label: 'TKIPandAESEncryption',
        value: 'TKIPandAESEncryption',
      },
    ],
    ipMode: [
      {
        key: 'IPV4',
        label: 'IPV4',
        value: '1',
      },
      {
        key: 'IPV6',
        label: 'IPV6',
        value: '2',
      },
      {
        key: 'IPV4_IPV6',
        label: 'IPV4_IPV6',
        value: '3',
      },
    ],
    natEnabled: [
      {
        key: 'True',
        label: 'True',
        value: '1',
      },
      {
        key: 'False',
        label: 'False',
        value: '0',
      },
    ],
    powerLevel: [
      {
        key: '50',
        label: '50',
        value: '3',
      },
      {
        key: '35',
        label: '35',
        value: '4',
      },
      {
        key: '15',
        label: '15',
        value: '5',
      },
      {
        key: '100',
        label: '100',
        value: '1',
      },
      {
        key: '70',
        label: '70',
        value: '2',
      },
    ],
    pppName: [
      {
        key: 'autoConnectWhenLinkUp',
        label: 'autoConnectWhenLinkUp',
        value: 'OnDemand',
      },
      {
        key: 'autoConnect',
        label: 'autoConnect',
        value: 'AlwaysOn',
      },
    ],
    regulatoryDomain: [
      {
        key: 'MKK1',
        label: 'MKK1',
        value: 'MKK1',
      },
      {
        key: 'MKK3',
        label: 'MKK3',
        value: 'MKK3',
      },
      {
        key: 'ETSI',
        label: 'ETSI',
        value: 'ETSI',
      },
      {
        key: 'SPAIN',
        label: 'SPAIN',
        value: 'SPAIN',
      },
      {
        key: 'IC',
        label: 'IC',
        value: 'IC',
      },
      {
        key: 'FRANCE',
        label: 'FRANCE',
        value: 'FRANCE',
      },
      {
        key: 'MKK2',
        label: 'MKK2',
        value: 'MKK2',
      },
      {
        key: 'RUSSIAN',
        label: 'RUSSIAN',
        value: 'RUSSIAN',
      },
      {
        key: 'CN',
        label: 'CN',
        value: 'CN',
      },
      {
        key: 'WORLD_WIDE',
        label: 'WORLD_WIDE',
        value: 'WORLD-WIDE',
      },
      {
        key: 'FCC',
        label: 'FCC',
        value: 'FCC',
      },
      {
        key: 'ISREAL',
        label: 'ISREAL',
        value: 'ISREAL',
      },
      {
        key: 'NCC',
        label: 'NCC',
        value: 'NCC',
      },
      {
        key: 'GLOBAL',
        label: 'GLOBAL',
        value: 'GLOBAL',
      },
      {
        key: 'MKK',
        label: 'MKK',
        value: 'MKK',
      },
    ],
    serviceList: [
      {
        key: 'INTERNET',
        label: 'INTERNET',
        value: 'INTERNET',
      },
      {
        key: 'TR069',
        label: 'TR069',
        value: 'TR069',
      },
      {
        key: 'TR069_INTERNET',
        label: 'TR069_INTERNET',
        value: 'TR069_INTERNET',
      },
      {
        key: 'OTHER',
        label: 'OTHER',
        value: 'other',
      },
    ],
    vlanEnabled: [
      {
        key: 'True',
        label: 'True',
        value: '1',
      },
      {
        key: 'False',
        label: 'False',
        value: '0',
      },
    ],
    wpaEncryptionMode: [
      {
        key: 'TKIPEncryption',
        label: 'TKIPEncryption',
        value: 'TKIPEncryption',
      },
      {
        key: 'AESEncryption',
        label: 'AESEncryption',
        value: 'AESEncryption',
      },
      {
        key: 'TKIPandAESEncryption',
        label: 'TKIPandAESEncryption',
        value: 'TKIPandAESEncryption',
      },
    ],
  },
};
