import { CommonActions, RootState, useAppDispatch } from 'store/redux';
import React, { useEffect } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ReactComponent as Offline } from '../../styles/images/offline.svg';
import { red } from '@ant-design/colors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const InternetConnectivityStatus = () => {
  const offline = useSelector((state: RootState) => state.common.offline);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOffline = () => {
    dispatch(CommonActions.setOffline(true));
  };
  const handleOnline = () => {
    dispatch(CommonActions.setOffline(false));
  };
  useEffect(() => {
    if (!window.navigator.onLine) {
      handleOffline();
    }
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
  }, []);

  return (
    <Modal
      visible={offline}
      closable={false}
      maskStyle={{ background: 'white ' }}
      footer={false}
      wrapClassName={'offline-message'}
      width={'100vw'}
    >
      <div className={'ant-result'}>
        <div className='ant-result-icon ant-result-image'>
          <Offline />
        </div>
        <div className='ant-result-title'>{t("You're offline!")}</div>
        <div className='ant-result-subtitle'>
          {t('Please check your WiFi or Network Connection.')}
        </div>
        <div className='ant-result-extra'>
          <LoadingOutlined style={{ color: red.primary, fontSize: 45 }} />
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(InternetConnectivityStatus);
