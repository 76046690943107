import { EntityType, IAuthUserInfo, ROLES } from 'store/types';
import {
  IPackage,
  PACKAGE_NAME,
  SUBSCRIPTION_CATEGORIES,
  SUBSCRIPTION_FEATURES,
  SUBSCRIPTION_STATE,
} from 'store/types/subscription.types';

export const LOGIN_INIT_MOCK = {
  userInfos: [
    {
      userId: 'c80b7fe315bc4fd4b429f1defddc7ca1',
      name: 'Fii Admin',
      tenantName: 'PLATFORM',
      tenantId: 'PLATFORM',
      tenantAncestors: [],
      role: 'PLATFORM_ADMIN',
      credType: 'ID_PASSWORD_CREDENTIAL',
      credId: 'admin@fiianalytics.com',
      userStatus: 'ACTIVE',
      deactivateReason: null,
    },
  ],
};

export const LOGIN_VERIFY_MOCK = {
  email: 'jenifer@fiianalytics.com',
  username: 'jenifer@fiianalytics.com',
  accessToken:
    'ada71007f8d1c8e91327145d9f328a0246fad92bea4bfd4b5a1bfd1797378be62eb26de8262465d9674b28822b5d6ce5fe25f873af77977071e56856357ecef21724607837387441240',
  tokenExpiryTime: '2025-08-25T17:43:57Z',
};

export const SILVER_SUBSCRIPTION_MOCK: IPackage = {
  id: '37a51602f5ab498482ecfa817a00dd3c',
  name: PACKAGE_NAME.SILVER,
  displayName: 'Silver',
  category: [
    {
      name: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
      features: [
        {
          id: 'c1745b58fa464ce799ee3afd02b10193',
          name: SUBSCRIPTION_FEATURES.ONLINE_OFFLINE_STATUS,
          displayName: 'Online / Offline Status',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '1fe70a7f0d584a53a52dade7b6c87cf8',
          name: SUBSCRIPTION_FEATURES.PON_ACCESS_TYPE,
          displayName: 'Pon Accces Type',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '13db07ee793e4f1982e5b24bd6fce64f',
          name: SUBSCRIPTION_FEATURES.UPTIME,
          displayName: 'Uptime',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '72cdc2efe30845659463513c3611e13e',
          name: SUBSCRIPTION_FEATURES.CPU,
          displayName: 'CPU',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'c0ec882bfb6f4b08a3652a682bf7672d',
          name: SUBSCRIPTION_FEATURES.MEMORY,
          displayName: 'Memory',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '00ac94a9f398438bb3b7a6bbd5b24751',
          name: SUBSCRIPTION_FEATURES.CLIENT_DEVICES,
          displayName: 'Client Devices',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'a2dab7d311cf40adb3eaecc61a31e7d7',
          name: SUBSCRIPTION_FEATURES.RADIO_INFORMATION,
          displayName: 'Radio Information',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '296e5fd8b1b848ccb447bbdab322698f',
          name: SUBSCRIPTION_FEATURES.NETWORK_INFORMATION,
          displayName: 'Network Information',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '9cf1e657a357446f91b5cfdbeff7b60d',
          name: SUBSCRIPTION_FEATURES.ETHERNET_PORT_STATUS,
          displayName: 'Ethernet Port Status',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'd868a65e197d489cb16fdecdf0c4ec60',
          name: SUBSCRIPTION_FEATURES.OPTICAL_STRENGTH,
          displayName: 'Optical Strength',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'e9dc8378d2cc49a98e1b309648c2b0fa',
          name: SUBSCRIPTION_FEATURES.WAN_THROUGHPUT,
          displayName: 'WAN Throughput',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '67680f238a5e4a2eb8c43a04242f1c38',
          name: SUBSCRIPTION_FEATURES.SYSTEM_EVENT_AND_LOGS,
          displayName: 'System Event And Logs',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
      features: [
        {
          id: '334c2e5b5bb54163bfd24e8a250d9184',
          name: SUBSCRIPTION_FEATURES.LAN_SETTINGS,
          displayName: 'LAN Settings',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: 'fe8bc78c9c0947f7907dcbe73e325a57',
          name: SUBSCRIPTION_FEATURES.PING,
          displayName: 'Ping',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: 'c774f1a86414460c818cb3adeb2b045b',
          name: SUBSCRIPTION_FEATURES.TRACEROUTE,
          displayName: 'Traceroute',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: '790afa37a1114d34a11c7b5a8167acaa',
          name: SUBSCRIPTION_FEATURES.SPEEDTEST,
          displayName: 'Speedtest',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: 'a919dcb48f734df1a2a5fb10681f81d2',
          name: SUBSCRIPTION_FEATURES.REBOOT,
          displayName: 'Reboot',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
      features: [
        {
          id: '4ca7a6a2684345879143f657160b67d7',
          name: SUBSCRIPTION_FEATURES.PAUSE_PLAY,
          displayName: 'Pause / Play',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '4ca7a6a2684345879143f657160b67d7',
          name: SUBSCRIPTION_FEATURES.SSID_PASSWORD,
          displayName: 'SSID Password',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '9832346e236d4a788b315ee7e9f05f0f',
          name: SUBSCRIPTION_FEATURES.SCAN_AND_WIFI_OPTIMIZATION,
          displayName: 'Wi-Fi Optimization',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          // @ts-ignore
          name: 'SiteSurvey',
          displayName: 'Site Survey',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          // @ts-ignore
          name: 'WifiOptimize',
          displayName: 'Wifi Optimize',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '610e92a43dab4e229b6e2b89f294f1f6',
          name: SUBSCRIPTION_FEATURES.CHANNEL_SWITCH,
          displayName: 'Channel Switch',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          name: SUBSCRIPTION_FEATURES.CHANNEL_WIDTH,
          displayName: 'Channel Width',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          name: SUBSCRIPTION_FEATURES.WIFI_THROUGHPUT,
          displayName: 'Wi-Fi Throughput',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '081c3cfa9a9f4592ac18bc58268396eb',
          name: SUBSCRIPTION_FEATURES.CLIENT_RSSI_STATISTICS,
          displayName: 'Client RSSI Statistics',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '882bf2efe30845659463513c3611e13e',
          name: SUBSCRIPTION_FEATURES.CLIENT_THROUGHPUT,
          displayName: 'Client Throughput',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
      ],
    },
    {
      // @ts-ignore
      name: 'FRANCHISEE_MANAGEMENT',
      features: [
        {
          id: '15099fb8cdee4fb9bfe431a2eb5ae55c',
          // @ts-ignore
          name: 'FranchiseeManagement',
          displayName: 'Franchisee Management',
          // @ts-ignore
          category: 'FRANCHISEE_MANAGEMENT',
        },
      ],
    },
    {
      // @ts-ignore
      name: 'AUTO_FIRMWARE_UPDATES',
      features: [
        {
          id: 'a2be344f884b4c66b6381bed05d1240c',
          // @ts-ignore
          name: 'AUTO_FIRMWARE_UPDATES',
          displayName: 'Auto Firmware Updates',
          // @ts-ignore
          category: 'AUTO_FIRMWARE_UPDATES',
        },
      ],
    },
  ],
};

export const GOLD_SUBSCRIPTION_MOCK: IPackage = {
  id: '37a51602f5ab498482ecfa817a00dd3c',
  name: PACKAGE_NAME.GOLD,
  displayName: 'Gold',
  category: [
    {
      name: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
      features: [
        {
          id: 'c1745b58fa464ce799ee3afd02b10193',
          name: SUBSCRIPTION_FEATURES.ONLINE_OFFLINE_STATUS,
          displayName: 'Online / Offline Status',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '1fe70a7f0d584a53a52dade7b6c87cf8',
          name: SUBSCRIPTION_FEATURES.PON_ACCESS_TYPE,
          displayName: 'Pon Accces Type',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '13db07ee793e4f1982e5b24bd6fce64f',
          name: SUBSCRIPTION_FEATURES.UPTIME,
          displayName: 'Uptime',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '72cdc2efe30845659463513c3611e13e',
          name: SUBSCRIPTION_FEATURES.CPU,
          displayName: 'CPU',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'c0ec882bfb6f4b08a3652a682bf7672d',
          name: SUBSCRIPTION_FEATURES.MEMORY,
          displayName: 'Memory',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '00ac94a9f398438bb3b7a6bbd5b24751',
          name: SUBSCRIPTION_FEATURES.CLIENT_DEVICES,
          displayName: 'Client Devices',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'a2dab7d311cf40adb3eaecc61a31e7d7',
          name: SUBSCRIPTION_FEATURES.RADIO_INFORMATION,
          displayName: 'Radio Information',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '296e5fd8b1b848ccb447bbdab322698f',
          name: SUBSCRIPTION_FEATURES.NETWORK_INFORMATION,
          displayName: 'Network Information',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '9cf1e657a357446f91b5cfdbeff7b60d',
          name: SUBSCRIPTION_FEATURES.ETHERNET_PORT_STATUS,
          displayName: 'Ethernet Port Status',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'd868a65e197d489cb16fdecdf0c4ec60',
          name: SUBSCRIPTION_FEATURES.OPTICAL_STRENGTH,
          displayName: 'Optical Strength',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'e9dc8378d2cc49a98e1b309648c2b0fa',
          name: SUBSCRIPTION_FEATURES.WAN_THROUGHPUT,
          displayName: 'WAN Throughput',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '67680f238a5e4a2eb8c43a04242f1c38',
          name: SUBSCRIPTION_FEATURES.SYSTEM_EVENT_AND_LOGS,
          displayName: 'System Event And Logs',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
      features: [
        {
          id: '334c2e5b5bb54163bfd24e8a250d9184',
          name: SUBSCRIPTION_FEATURES.LAN_SETTINGS,
          displayName: 'LAN Settings',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: 'fe8bc78c9c0947f7907dcbe73e325a57',
          name: SUBSCRIPTION_FEATURES.PING,
          displayName: 'Ping',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: 'c774f1a86414460c818cb3adeb2b045b',
          name: SUBSCRIPTION_FEATURES.TRACEROUTE,
          displayName: 'Traceroute',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: '790afa37a1114d34a11c7b5a8167acaa',
          name: SUBSCRIPTION_FEATURES.SPEEDTEST,
          displayName: 'Speedtest',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: 'a919dcb48f734df1a2a5fb10681f81d2',
          name: SUBSCRIPTION_FEATURES.REBOOT,
          displayName: 'Reboot',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
      features: [
        {
          id: '4ca7a6a2684345879143f657160b67d7',
          name: SUBSCRIPTION_FEATURES.SSID_PASSWORD,
          displayName: 'SSID Password',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '9832346e236d4a788b315ee7e9f05f0f',
          name: SUBSCRIPTION_FEATURES.SCAN_AND_WIFI_OPTIMIZATION,
          displayName: 'Wi-Fi Optimization',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          // @ts-ignore
          name: 'SiteSurvey',
          displayName: 'Site Survey',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          // @ts-ignore
          name: 'WifiOptimize',
          displayName: 'Wifi Optimize',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '610e92a43dab4e229b6e2b89f294f1f6',
          name: SUBSCRIPTION_FEATURES.CHANNEL_SWITCH,
          displayName: 'Channel Switch',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          name: SUBSCRIPTION_FEATURES.CHANNEL_WIDTH,
          displayName: 'Channel Width',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          name: SUBSCRIPTION_FEATURES.WIFI_THROUGHPUT,
          displayName: 'Wi-Fi Throughput',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '081c3cfa9a9f4592ac18bc58268396eb',
          name: SUBSCRIPTION_FEATURES.CLIENT_RSSI_STATISTICS,
          displayName: 'Client RSSI Statistics',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '882bf2efe30845659463513c3611e13e',
          name: SUBSCRIPTION_FEATURES.CLIENT_THROUGHPUT,
          displayName: 'Client Throughput',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
      ],
    },
    {
      // @ts-ignore
      name: 'FRANCHISEE_MANAGEMENT',
      features: [
        {
          id: '15099fb8cdee4fb9bfe431a2eb5ae55c',
          // @ts-ignore
          name: 'FranchiseeManagement',
          displayName: 'Franchisee Management',
          // @ts-ignore
          category: 'FRANCHISEE_MANAGEMENT',
        },
      ],
    },
    {
      // @ts-ignore
      name: 'AUTO_FIRMWARE_UPDATES',
      features: [
        {
          id: 'a2be344f884b4c66b6381bed05d1240c',
          // @ts-ignore
          name: 'AUTO_FIRMWARE_UPDATES',
          displayName: 'Auto Firmware Updates',
          // @ts-ignore
          category: 'AUTO_FIRMWARE_UPDATES',
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
      features: [
        {
          id: '6d4971ed9e154fab88fdcfa837367ee5',
          name: SUBSCRIPTION_FEATURES.BRIDGE_ONU_SUPPORT,
          displayName: 'Bridge ONU Support',
          category:
            SUBSCRIPTION_CATEGORIES.COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
        },
        {
          id: '796f672338bc470698ad5b552681faea',
          name: SUBSCRIPTION_FEATURES.OPTICAL_STRENGTH,
          displayName: 'Optical Strength',
          category:
            SUBSCRIPTION_CATEGORIES.COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
        },
        {
          id: '90e90d7c1293424ea1853269cb7f4c51',
          name: SUBSCRIPTION_FEATURES.REBOOT,
          displayName: 'Reboot',
          category:
            SUBSCRIPTION_CATEGORIES.COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
        },
        {
          id: 'bb006234edaf4d58a03dcc7a72b83d30',
          name: SUBSCRIPTION_FEATURES.RTT,
          displayName: 'RTT',
          category:
            SUBSCRIPTION_CATEGORIES.COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.REAL_TIME_ALERTS,
      features: [
        {
          id: '1aad271982504979847d924b130072ed',
          name: SUBSCRIPTION_FEATURES.CUSTOM_THRESHOLD_CONFIGURATION,
          displayName: 'Custom Threshold Configuration',
          category: SUBSCRIPTION_CATEGORIES.REAL_TIME_ALERTS,
        },
        {
          id: '1aad271982504979847d924b130072ed',
          // @ts-ignore
          name: 'MOBILE_PUSH_NOTIFICATIONS',
          displayName: 'Mobile Push Notifications',
          category: SUBSCRIPTION_CATEGORIES.REAL_TIME_ALERTS,
        },
        {
          id: '8e0d4de0b1a1473da15283f471490d6c',
          name: SUBSCRIPTION_FEATURES.LOS_ALERTS,
          displayName: 'LOS Alerts',
          category: SUBSCRIPTION_CATEGORIES.REAL_TIME_ALERTS,
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.REPORTS,
      features: [
        {
          id: '71aa43e8f1834d1e821f1ba172771fd2',
          name: SUBSCRIPTION_FEATURES.REPORTS_PENDING_SUBSCRIBERS,
          displayName: 'Reports Pending Subscribers',
          category: SUBSCRIPTION_CATEGORIES.REPORTS,
        },
        {
          id: '71aa43e8f1834d1e821f1ba172771fd2',
          name: SUBSCRIPTION_FEATURES.REPORTS_OFFLINE_SUBSCRIBERS,
          displayName: 'Reports Offline Subscribers',
          category: SUBSCRIPTION_CATEGORIES.REPORTS,
        },
        {
          id: '42f22afef9ea4a7cbd5ffdaaae52c27a',
          name: SUBSCRIPTION_FEATURES.REPORTS_ONT_OPTICAL_SIGNAL,
          displayName: 'Reports Optical SIgnal',
          category: SUBSCRIPTION_CATEGORIES.REPORTS,
        },
        {
          id: '42f22afef9ea4a7cbd5ffdaaae52c27a',
          name: SUBSCRIPTION_FEATURES.REPORTS_OLT_OPTICAL_SIGNAL,
          displayName: 'Reports Optical SIgnal',
          category: SUBSCRIPTION_CATEGORIES.REPORTS,
        },
        {
          id: '42f22afef9ea4a7cbd5ffdaaae52c27a',
          name: SUBSCRIPTION_FEATURES.REPORTS_DATA_USAGE,
          displayName: 'Reports Data Usage',
          category: SUBSCRIPTION_CATEGORIES.REPORTS,
        },
      ],
    },
  ],
};

export const PLATINUM_SUBSCRIPTION_MOCK: IPackage = {
  id: 'f66a6adaafcd47b18b7cbf1da80eaa8c',
  name: PACKAGE_NAME.PLATINUM,
  displayName: 'Platinum',
  category: [
    {
      name: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
      features: [
        {
          id: 'c1745b58fa464ce799ee3afd02b10193',
          name: SUBSCRIPTION_FEATURES.ONLINE_OFFLINE_STATUS,
          displayName: 'Online / Offline Status',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '1fe70a7f0d584a53a52dade7b6c87cf8',
          name: SUBSCRIPTION_FEATURES.PON_ACCESS_TYPE,
          displayName: 'Pon Accces Type',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '13db07ee793e4f1982e5b24bd6fce64f',
          name: SUBSCRIPTION_FEATURES.UPTIME,
          displayName: 'Uptime',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '72cdc2efe30845659463513c3611e13e',
          name: SUBSCRIPTION_FEATURES.CPU,
          displayName: 'CPU',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'c0ec882bfb6f4b08a3652a682bf7672d',
          name: SUBSCRIPTION_FEATURES.MEMORY,
          displayName: 'Memory',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '00ac94a9f398438bb3b7a6bbd5b24751',
          name: SUBSCRIPTION_FEATURES.CLIENT_DEVICES,
          displayName: 'Client Devices',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'a2dab7d311cf40adb3eaecc61a31e7d7',
          name: SUBSCRIPTION_FEATURES.RADIO_INFORMATION,
          displayName: 'Radio Information',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '296e5fd8b1b848ccb447bbdab322698f',
          name: SUBSCRIPTION_FEATURES.NETWORK_INFORMATION,
          displayName: 'Network Information',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '9cf1e657a357446f91b5cfdbeff7b60d',
          name: SUBSCRIPTION_FEATURES.ETHERNET_PORT_STATUS,
          displayName: 'Ethernet Port Status',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'd868a65e197d489cb16fdecdf0c4ec60',
          name: SUBSCRIPTION_FEATURES.OPTICAL_STRENGTH,
          displayName: 'Optical Strength',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: 'e9dc8378d2cc49a98e1b309648c2b0fa',
          name: SUBSCRIPTION_FEATURES.WAN_THROUGHPUT,
          displayName: 'WAN Throughput',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
        {
          id: '67680f238a5e4a2eb8c43a04242f1c38',
          name: SUBSCRIPTION_FEATURES.SYSTEM_EVENT_AND_LOGS,
          displayName: 'System Event And Logs',
          category: SUBSCRIPTION_CATEGORIES.ONT_MONITORING,
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
      features: [
        {
          id: '334c2e5b5bb54163bfd24e8a250d9184',
          name: SUBSCRIPTION_FEATURES.LAN_SETTINGS,
          displayName: 'LAN Settings',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: 'fe8bc78c9c0947f7907dcbe73e325a57',
          name: SUBSCRIPTION_FEATURES.PING,
          displayName: 'Ping',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: 'c774f1a86414460c818cb3adeb2b045b',
          name: SUBSCRIPTION_FEATURES.TRACEROUTE,
          displayName: 'Traceroute',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: '790afa37a1114d34a11c7b5a8167acaa',
          name: SUBSCRIPTION_FEATURES.SPEEDTEST,
          displayName: 'Speedtest',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
        {
          id: 'a919dcb48f734df1a2a5fb10681f81d2',
          name: SUBSCRIPTION_FEATURES.REBOOT,
          displayName: 'Reboot',
          category: SUBSCRIPTION_CATEGORIES.ONT_REMOTE_TROUBLESHOOTING,
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
      features: [
        {
          id: '4ca7a6a2684345879143f657160b67d7',
          name: SUBSCRIPTION_FEATURES.SSID_PASSWORD,
          displayName: 'SSID Password',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '9832346e236d4a788b315ee7e9f05f0f',
          name: SUBSCRIPTION_FEATURES.SCAN_AND_WIFI_OPTIMIZATION,
          displayName: 'Wi-Fi Optimization',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          // @ts-ignore
          name: 'SiteSurvey',
          displayName: 'Site Survey',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          // @ts-ignore
          name: 'WifiOptimize',
          displayName: 'Wifi Optimize',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '610e92a43dab4e229b6e2b89f294f1f6',
          name: SUBSCRIPTION_FEATURES.CHANNEL_SWITCH,
          displayName: 'Channel Switch',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          name: SUBSCRIPTION_FEATURES.CHANNEL_WIDTH,
          displayName: 'Channel Width',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '376c9743da5a442eade21968fb2eb9a0',
          name: SUBSCRIPTION_FEATURES.WIFI_THROUGHPUT,
          displayName: 'Wi-Fi Throughput',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '081c3cfa9a9f4592ac18bc58268396eb',
          name: SUBSCRIPTION_FEATURES.CLIENT_RSSI_STATISTICS,
          displayName: 'Client RSSI Statistics',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
        {
          id: '882bf2efe30845659463513c3611e13e',
          name: SUBSCRIPTION_FEATURES.CLIENT_THROUGHPUT,
          displayName: 'Client Throughput',
          category: SUBSCRIPTION_CATEGORIES.ONT_WIFI_MANAGEMENT,
        },
      ],
    },
    {
      // @ts-ignore
      name: 'FRANCHISEE_MANAGEMENT',
      features: [
        {
          id: '15099fb8cdee4fb9bfe431a2eb5ae55c',
          // @ts-ignore
          name: 'FranchiseeManagement',
          displayName: 'Franchisee Management',
          // @ts-ignore
          category: 'FRANCHISEE_MANAGEMENT',
        },
      ],
    },
    {
      // @ts-ignore
      name: 'AUTO_FIRMWARE_UPDATES',
      features: [
        {
          id: 'a2be344f884b4c66b6381bed05d1240c',
          // @ts-ignore
          name: 'AUTO_FIRMWARE_UPDATES',
          displayName: 'Auto Firmware Updates',
          // @ts-ignore
          category: 'AUTO_FIRMWARE_UPDATES',
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
      features: [
        {
          id: '6d4971ed9e154fab88fdcfa837367ee5',
          name: SUBSCRIPTION_FEATURES.BRIDGE_ONU_SUPPORT,
          displayName: 'Bridge ONU Support',
          category:
            SUBSCRIPTION_CATEGORIES.COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
        },
        {
          id: '796f672338bc470698ad5b552681faea',
          name: SUBSCRIPTION_FEATURES.OPTICAL_STRENGTH,
          displayName: 'Optical Strength',
          category:
            SUBSCRIPTION_CATEGORIES.COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
        },
        {
          id: '90e90d7c1293424ea1853269cb7f4c51',
          name: SUBSCRIPTION_FEATURES.REBOOT,
          displayName: 'Reboot',
          category:
            SUBSCRIPTION_CATEGORIES.COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
        },
        {
          id: 'bb006234edaf4d58a03dcc7a72b83d30',
          name: SUBSCRIPTION_FEATURES.RTT,
          displayName: 'RTT',
          category:
            SUBSCRIPTION_CATEGORIES.COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.REAL_TIME_ALERTS,
      features: [
        {
          id: '1aad271982504979847d924b130072ed',
          name: SUBSCRIPTION_FEATURES.CUSTOM_THRESHOLD_CONFIGURATION,
          displayName: 'Custom Threshold Configuration',
          category: SUBSCRIPTION_CATEGORIES.REAL_TIME_ALERTS,
        },
        {
          id: '1aad271982504979847d924b130072ed',
          // @ts-ignore
          name: 'MOBILE_PUSH_NOTIFICATIONS',
          displayName: 'Mobile Push Notifications',
          category: SUBSCRIPTION_CATEGORIES.REAL_TIME_ALERTS,
        },
        {
          id: '8e0d4de0b1a1473da15283f471490d6c',
          name: SUBSCRIPTION_FEATURES.LOS_ALERTS,
          displayName: 'LOS Alerts',
          category: SUBSCRIPTION_CATEGORIES.REAL_TIME_ALERTS,
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.REPORTS,
      features: [
        {
          id: '71aa43e8f1834d1e821f1ba172771fd2',
          name: SUBSCRIPTION_FEATURES.REPORTS_PENDING_SUBSCRIBERS,
          displayName: 'Reports Pending Subscribers',
          category: SUBSCRIPTION_CATEGORIES.REPORTS,
        },
        {
          id: '71aa43e8f1834d1e821f1ba172771fd2',
          name: SUBSCRIPTION_FEATURES.REPORTS_OFFLINE_SUBSCRIBERS,
          displayName: 'Reports Offline Subscribers',
          category: SUBSCRIPTION_CATEGORIES.REPORTS,
        },
        {
          id: '42f22afef9ea4a7cbd5ffdaaae52c27a',
          name: SUBSCRIPTION_FEATURES.REPORTS_OLT_OPTICAL_SIGNAL,
          displayName: 'Reports Optical SIgnal',
          category: SUBSCRIPTION_CATEGORIES.REPORTS,
        },
        {
          id: '42f22afef9ea4a7cbd5ffdaaae52c27a',
          name: SUBSCRIPTION_FEATURES.REPORTS_ONT_OPTICAL_SIGNAL,
          displayName: 'Reports Optical SIgnal',
          category: SUBSCRIPTION_CATEGORIES.REPORTS,
        },
        {
          id: '42f22afef9ea4a7cbd5ffdaaae52c27a',
          name: SUBSCRIPTION_FEATURES.REPORTS_DATA_USAGE,
          displayName: 'Reports Data Usage',
          category: SUBSCRIPTION_CATEGORIES.REPORTS,
        },
      ],
    },
    {
      // @ts-ignore
      name: 'ZERO_TOUCH_PROVISIONING',
      features: [
        {
          id: '7c6fc863e96d44809dfee95e0640c448',
          // @ts-ignore
          name: 'ZERO_TOUCH_PROVISIONING',
          displayName: 'Zero Touch Provisioning',
          // @ts-ignore
          category: 'ZERO_TOUCH_PROVISIONING',
        },
      ],
    },
    {
      name: SUBSCRIPTION_CATEGORIES.OLT_MONITORING,
      features: [
        {
          id: '0c0ae3fb784e4ccb99aedca961175316',
          name: SUBSCRIPTION_FEATURES.OLT_INFORMATION,
          displayName: 'OLT Information',
          category: SUBSCRIPTION_CATEGORIES.OLT_MONITORING,
        },
      ],
    },
    {
      // @ts-ignore
      name: 'EXTERNAL_API_INTEGRATION',
      features: [
        {
          id: 'ca773c5758b3428b831e609f5b0a3988',
          // @ts-ignore
          name: 'JAZE_INTEGRATION',
          displayName: 'Jaze API Integration',
          // @ts-ignore
          category: 'EXTERNAL_API_INTEGRATION',
        },
      ],
    },
    {
      // @ts-ignore
      name: 'APP_THEMING',
      features: [
        {
          id: '799628de3c8a46a6aae9d182025561a6',
          // @ts-ignore
          name: 'CONNECT_APP_THEMING',
          displayName: 'Connect App Theming',
          // @ts-ignore
          category: 'APP_THEMING',
        },
        {
          id: 'a2b4ade3b57d4c27b61f306484004873',
          // @ts-ignore
          name: 'DIAG_APP_THEMING',
          displayName: 'Diag App Theming',
          // @ts-ignore
          category: 'APP_THEMING',
        },
      ],
    },
  ],
};

export const WHO_AM_I_MOCK: IAuthUserInfo = {
  id: 'c80b7fe315bc4fd4b429f1defddc7ca1',
  role: ROLES.PLATFORM_ADMIN,
  firstName: 'Jenifer',
  lastName: '',
  email: 'admin@fiianalytics.com',
  phoneNumber: '+911212312123',
  managementEntity: {
    id: 'PLATFORM',
    type: EntityType.PLATFORM,
    name: 'PLATFORM',
  },
  supportNumber: [],
  parentManagementEntity: {
    id: 'PLATFORM',
    type: EntityType.PLATFORM,
    name: 'PLATFORM',
  },
  friendly: false,
  subscription: {
    id: '',
    price: 5,
    duration: 0,
    state: SUBSCRIPTION_STATE.ACTIVE,
    endDate: '2023-09-13T00:00:00',
    expiryDate: '2023-09-23T00:00:00',
    subscriberCount: 100,
    package: PLATINUM_SUBSCRIPTION_MOCK,
  },
  ticketingEnabled: false,
  // "reseller": {},
  accounts: [
    {
      userId: 'c80b7fe315bc4fd4b429f1defddc7ca1',
      userName: 'Jenifer ',
      managementEntityId: 'PLATFORM',
      managementEntityName: 'PLATFORM',
      managementEntityType: 'PLATFORM',
      role: 'PLATFORM_ADMIN',
    },
  ],
  allowAddressableCWMPConfig: true,
  rootUser: false,
  allowFibermap: true,
};
