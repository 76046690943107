import { EntityType, IChannelPartners, IPagination } from 'store/types';
import {
  IISPList,
  IISPListFilters,
  IISPListReduxState,
  IISPSubscriptionStats,
} from 'store/types/isp.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { OperatorService } from 'apiUtils/services/operator.service';
import { formatError } from 'apiUtils/api';
import i18n from 'i18n';
import { isEmpty } from 'lodash';
import { removeEmptyValuesFromObject } from 'utils/utils';

const initialState: IISPListReduxState = {
  ispSubscriptionStats: {
    loading: true,
    error: '',
    data: {} as IISPSubscriptionStats,
  },
  ispList: {
    hardRefresh: false,
    loading: true,
    error: '',
    data: {} as IISPList,
  },
  ispListPagination: {},
  ispListSearch: {},
  ispListFilters: {} as IISPListFilters,
  isFilterOpen: false,
  ispListFilterDropDownValues: {
    resellerList: {
      loading: false,
      error: null,
      data: [] as IChannelPartners[],
    },
  },
};

const fetchISPSubscriptionStats = createAsyncThunk(
  'ispSubscriptionStats/fetch',
  async (_, thunkAPI) => {
    const response = await OperatorService.v1.GetOperatorSubscriptionStats({
      params: {
        type: EntityType.ISP,
      },
    });

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.statsData'),
      };
    }
  },
);

const fetchISPList = createAsyncThunk('ispList/fetch', async (_, thunkAPI) => {
  let { ispListState }: { ispListState: IISPListReduxState } =
    thunkAPI.getState() as any;

  const response = await OperatorService.v1.GetISPList({
    params: removeEmptyValuesFromObject({
      ...ispListState?.ispListPagination,
      ...ispListState?.ispListFilters,
      ...ispListState?.ispListSearch,
      trial: 'false',
    }),
  });
  if (response.status === 200) {
    return response?.data;
  } else {
    return {
      error: formatError(response) || i18n.t('messages.error.ispList'),
    };
  }
});

const fetchResellerList = createAsyncThunk(
  'isp/resellerList/fetch',
  async (_, thunkAPI) => {
    const response = await OperatorService.v1.GetResellerList({
      params: { pageToken: 1, pageSize: 1000 },
    });
    if (response.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.resellerList'),
      };
    }
  },
);

export const ISPListSlice = createSlice({
  name: 'ISPList',
  initialState,
  reducers: {
    resetToInit: () => initialState,
    resetISPListState: (state: IISPListReduxState) => {
      state.ispSubscriptionStats = {
        loading: true,
        error: '',
        data: {} as IISPSubscriptionStats,
      };
      state.ispList = {
        hardRefresh: false,
        loading: true,
        error: '',
        data: {} as IISPList,
      };
      state.ispListPagination = {};
      state.ispListSearch = {};
      state.ispListFilters = {} as IISPListFilters;
      state.isFilterOpen = false;
    },
    setShowFilterForm: (state, { payload }: { payload: boolean }) => {
      state.showFilterForm = payload;
    },
    setSearchText: (
      state,
      { payload }: { payload: string | null | undefined },
    ) => {
      state.ispListSearch = { searchText: payload };
      state.ispListPagination.pageToken = 1;
    },
    setPageToken: (state, { payload }: { payload: number }) => {
      state.ispListPagination.pageToken = payload;
    },
    setPagination: (state, { payload }: { payload: IPagination }) => {
      state.ispListPagination = payload;
    },
    setIsFilterOpen: (state, { payload }: { payload: boolean }) => {
      state.isFilterOpen = payload;
    },
    setISPListFilters: (
      state: IISPListReduxState,
      { payload }: { payload: IISPListFilters },
    ) => {
      if (isEmpty(payload)) {
        state.ispListFilters = {};
      } else {
        state.ispListFilters = Object.assign(state.ispListFilters, payload);
      }
      state.ispList.hardRefresh = true;
      state.ispListPagination.pageToken = 1;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchISPSubscriptionStats.pending, (state) => {
      state.ispSubscriptionStats.loading = true;
      state.ispSubscriptionStats.error = '';
      state.ispSubscriptionStats.data = {} as IISPSubscriptionStats;
    });

    builder.addCase(
      fetchISPSubscriptionStats.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.ispSubscriptionStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.ispSubscriptionStats.data = payload;
        } else {
          state.ispSubscriptionStats.error = payload?.error;
        }
      },
    );
    builder.addCase(fetchISPList.pending, (state) => {
      state.ispList.loading = true;
      state.ispList.error = '';
      state.ispList.data = {} as IISPList;
    });

    builder.addCase(fetchResellerList.pending, (state) => {
      state.ispListFilterDropDownValues.resellerList.loading = true;
      state.ispListFilterDropDownValues.resellerList.error = '';
      state.ispListFilterDropDownValues.resellerList.data =
        [] as IChannelPartners[];
    });

    builder.addCase(
      fetchResellerList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.ispListFilterDropDownValues.resellerList.loading = false;
        if (isEmpty(payload?.error)) {
          state.ispListFilterDropDownValues.resellerList.data = payload?.items;
        } else {
          state.ispListFilterDropDownValues.resellerList.error = payload?.error;
        }
      },
    );

    builder.addCase(
      fetchISPList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.ispList.loading = false;
        state.ispList.hardRefresh = false;
        if (isEmpty(payload?.error)) {
          state.ispList.data = payload;
        } else {
          state.ispList.error = payload?.error;
        }
      },
    );
  },
});

export const ISPListActions = {
  ...ISPListSlice.actions,
  fetchISPSubscriptionStats,
  fetchResellerList,
  fetchISPList,
};
export const ISPListSliceReducer = ISPListSlice.reducer;
