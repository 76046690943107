import { IOLTListItems, PON_TYPE } from 'store/types/olt.types';

import { ISplitterDropdown } from 'store/types/splitter.types';

export const FIRMWARE_POLICIES_MOCK = [
  {
    id: 'DNLD_OPERATOR_ONLY',
    name: 'DNLD_OPERATOR_ONLY',
    displayName: 'Operator Only',
    description: ' ',
  },
  {
    id: 'DNLD_SUBSCRIBER_ONLY',
    name: 'DNLD_SUBSCRIBER_ONLY',
    displayName: 'Subscriber Only',
    description: ' ',
  },
];

export const OLT_DROPDOWN_LIST_MOCK: IOLTListItems[] = [
  {
    label: 'OLT-label1',
    MAC: '14:A7:2B:A2:AF:75',
    ipAddress: '192.168.8.200',
    ponPortCount: 4,
    ponPorts: [
      {
        name: 'gpon_1/1/1',
        number: '1',
        portIndex: '1/1/1',
        linkStatus: 'up',
      },
      {
        name: 'gpon_1/1/2',
        number: '2',
        portIndex: '1/1/2',
        linkStatus: 'up',
      },
      {
        name: 'gpon_1/1/3',
        number: '3',
        portIndex: '1/1/3',
        linkStatus: 'up',
      },
      {
        name: 'gpon_1/1/4',
        number: '4',
        portIndex: '1/1/4',
        linkStatus: 'down',
      },
    ],
    ponType: PON_TYPE.EPON,
    manufacturer: 'cdata',
    model: 'FD1208',
  },
  {
    label: 'OLT-label2',
    MAC: '14:A7:2B:C3:BC:98',
    ipAddress: '192.168.8.201',
    ponPortCount: 8,
    ponPorts: [
      {
        name: 'PON 1/1/1',
        number: '1',
        portIndex: '1/1/1',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/2',
        number: '2',
        portIndex: '1/1/2',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/3',
        number: '3',
        portIndex: '1/1/3',
        linkStatus: 'down',
      },
      {
        name: 'PON 1/1/4',
        number: '4',
        portIndex: '1/1/4',
        linkStatus: 'down',
      },
      {
        name: 'PON 1/1/5',
        number: '5',
        portIndex: '1/1/5',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/6',
        number: '6',
        portIndex: '1/1/6',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/7',
        number: '7',
        portIndex: '1/1/7',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/8',
        number: '8',
        portIndex: '1/1/8',
        linkStatus: 'up',
      },
    ],
    ponType: PON_TYPE.EPON,
    manufacturer: 'cdata',
    model: 'FD1208',
  },
  {
    label: 'OLT-label3',
    MAC: '14:A7:2B:A2:3F:3D',
    ipAddress: '192.168.8.202',
    ponPortCount: 4,
    ponPorts: [
      {
        name: 'PON 1/1/1',
        number: '1',
        portIndex: '1/1/1',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/2',
        number: '2',
        portIndex: '1/1/2',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/3',
        number: '3',
        portIndex: '1/1/3',
        linkStatus: 'down',
      },
      {
        name: 'PON 1/1/4',
        number: '4',
        portIndex: '1/1/4',
        linkStatus: 'up',
      },
    ],
    ponType: PON_TYPE.GPON,
    manufacturer: 'netlink',
    model: 'V1600G',
  },
  {
    label: 'OLT-label4',
    MAC: '14:A7:2B:2A:1F:22',
    ipAddress: '192.168.8.203',
    ponPortCount: 8,
    ponPorts: [
      {
        name: 'PON 1/1/1',
        number: '1',
        portIndex: '1/1/1',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/2',
        number: '2',
        portIndex: '1/1/2',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/3',
        number: '3',
        portIndex: '1/1/3',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/4',
        number: '4',
        portIndex: '1/1/4',
        linkStatus: 'down',
      },
      {
        name: 'PON 1/1/5',
        number: '5',
        portIndex: '1/1/5',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/6',
        number: '6',
        portIndex: '1/1/6',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/7',
        number: '7',
        portIndex: '1/1/7',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/8',
        number: '8',
        portIndex: '1/1/8',
        linkStatus: 'down',
      },
    ],
    ponType: PON_TYPE.GPON,
    manufacturer: 'netlink',
    model: 'V1600G',
  },
  {
    label: 'OLT-label5',
    MAC: '14:A7:2B:4E:2B:34',
    ipAddress: '192.168.8.204',
    ponPortCount: 16,
    ponPorts: [
      {
        name: 'PON 1/1/1',
        number: '1',
        portIndex: '1/1/1',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/2',
        number: '2',
        portIndex: '1/1/2',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/3',
        number: '3',
        portIndex: '1/1/3',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/4',
        number: '4',
        portIndex: '1/1/4',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/5',
        number: '5',
        portIndex: '1/1/5',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/6',
        number: '6',
        portIndex: '1/1/6',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/7',
        number: '7',
        portIndex: '1/1/7',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/8',
        number: '8',
        portIndex: '1/1/8',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/9',
        number: '9',
        portIndex: '1/1/9',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/10',
        number: '10',
        portIndex: '1/1/10',
        linkStatus: 'up',
      },
      {
        name: 'PON 1/1/11',
        number: '11',
        portIndex: '1/1/11',
        linkStatus: 'down',
      },
      {
        name: 'PON 1/1/12',
        number: '12',
        portIndex: '1/1/12',
        linkStatus: 'down',
      },
      {
        name: 'PON 1/1/13',
        number: '13',
        portIndex: '1/1/13',
        linkStatus: 'down',
      },
      {
        name: 'PON 1/1/14',
        number: '14',
        portIndex: '1/1/14',
        linkStatus: 'down',
      },
      {
        name: 'PON 1/1/15',
        number: '15',
        portIndex: '1/1/15',
        linkStatus: 'down',
      },
      {
        name: 'PON 1/1/16',
        number: '16',
        portIndex: '1/1/16',
        linkStatus: 'down',
      },
    ],
    ponType: PON_TYPE.GPON,
    manufacturer: 'netlink',
    model: 'V1600G',
  },
];

export const SPLITTER_DROPDOWN_LIST_MOCK: ISplitterDropdown[] = Array.from(
  { length: 5 },
  (_, idx) => {
    return {
      splitterId: `SPL-${idx}`,
      name: `Splitter-${idx + 1}`,
      ports: [1, 2, 3, 4],
    };
  },
);
