export const ENABLE_OFFLINE_APP = false;

export const mockRouteMatcher = (url = '') => {
  let s = url;
  const paths = s.split('/').slice(1);
  for (var key in paths) {
    if (paths[key].includes('{')) {
      s = s.replace(paths[key], '[^/]+');
    }
  }
  return new RegExp(s);
};

export const mockGetPathParams = (url = '', endpoint = '') => {
  const pathsList = url?.split('/');
  const endpointsList = endpoint?.split('/');
  let pathParams: any = {};
  endpointsList?.forEach((val, idx) => {
    if (val !== pathsList[idx]) {
      const key = val?.replace('{', '')?.replace('}', '');
      pathParams = { ...pathParams, [key]: pathsList[idx] };
    }
  });
  return pathParams;
};

// declare all characters
const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateRandomInteger = (max: number, min = 0, float = false) => {
  if (max || min) {
    min = Math.ceil(min);
    max = Math.floor(max);

    const value = Math.random() * (max - min) + min;

    return float ? value : Math.floor(value);
  }
  return Math.floor(Math.random());
};

export const generateRandomStringId = (
  length: number = 16,
  upperCase: boolean = false,
) => {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return upperCase ? result?.toUpperCase() : result;
};

export const generateRandomMAC = (type: 'oui' | 'mac' = 'mac') => {
  const pattern = type === 'mac' ? 'XX:XX:XX:XX:XX:XX' : 'XX:XX:XX';
  return pattern.replace(/X/g, function () {
    return '0123456789ABCDEF'.charAt(Math.floor(Math.random() * 16));
  });
};

export const generateRandomIp = () => {
  return Array(4)
    .fill(0)
    .map((_, i) => Math.floor(Math.random() * 255) + (i === 0 ? 1 : 0))
    .join('.');
};
