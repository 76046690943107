import '@fortawesome/fontawesome-svg-core/styles.css';

import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { PiNetworkXFill, PiTreeStructureFill } from 'react-icons/pi';
import { RiGalleryView2, RiTableView } from 'react-icons/ri';
import { TbCalendarDollar, TbNetwork } from 'react-icons/tb';
import { Tooltip, TooltipProps } from 'antd';
import { blue, green, grey, red } from '@ant-design/colors';

import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { BiSolidCalendarExclamation } from 'react-icons/bi';
import { BsFillStopFill } from 'react-icons/bs';
import { EntityTypeIcons } from '../../constants';
import { FaNetworkWired } from 'react-icons/fa6';
import { FcInfo } from 'react-icons/fc';
import { IconBaseProps } from 'react-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Ref } from 'react';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18n';

type TAnimate =
  | 'spin'
  | 'spin-pulse'
  | 'spin-reverse'
  | 'pulse'
  | 'beat'
  | 'fade'
  | 'beat-fade'
  | 'bounce'
  | 'shake';

interface IReactIconProps extends IconBaseProps {
  tooltip?: TooltipProps;
  clickable?: boolean;
  animate?: TAnimate;
}
interface IAntdIconProps extends AntdIconProps {
  tooltip?: TooltipProps;
  clickable?: boolean;
  animate?: TAnimate;
  ref?: Ref<HTMLSpanElement>; // To avoid type error
}
interface IFontAwesomeIconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  tooltip?: TooltipProps;
  icon?: IconProp;
  clickable?: boolean;
}

export const FranchiseeIcon = (props: IFontAwesomeIconProps) => {
  const { tooltip, clickable = false, ...iconProps } = props;

  return (
    <Tooltip {...tooltip}>
      <span className={'icon-span'}>
        <FontAwesomeIcon
          icon={EntityTypeIcons.FRANCHISEE}
          {...iconProps}
          cursor={clickable ? 'pointer' : undefined}
        />
      </span>
    </Tooltip>
  );
};

export const SubscribersIcon = (props: IFontAwesomeIconProps) => {
  const { tooltip, clickable = false, ...iconProps } = props;

  return (
    <Tooltip {...tooltip}>
      <span className={'icon-span'}>
        <FontAwesomeIcon
          icon={faUsers}
          {...iconProps}
          cursor={clickable ? 'pointer' : undefined}
        />
      </span>
    </Tooltip>
  );
};

export const MoreVerticalEllipsisIcon = (props: IAntdIconProps) => {
  const { tooltip, clickable = false, animate, ...iconProps } = props;

  return (
    <Tooltip {...tooltip}>
      <MoreOutlined
        {...iconProps}
        className={`${clickable ? 'clickable' : undefined} ${
          animate ? `fa-${animate}` : ''
        } ${props?.className || ''}`}
      />
    </Tooltip>
  );
};

export const ViewDetailIcon = (props: IAntdIconProps) => {
  const { tooltip, clickable = false, animate, ...iconProps } = props;

  return (
    <Tooltip {...tooltip}>
      <EyeOutlined
        {...iconProps}
        className={`${clickable ? 'clickable' : undefined} ${
          animate ? `fa-${animate}` : ''
        } ${props?.className || ''}`}
        style={{
          color: props?.color || green[6],
        }}
      />
    </Tooltip>
  );
};

export const EditIcon = (props: IAntdIconProps) => {
  const { tooltip, clickable = false, animate, ...iconProps } = props;

  return (
    <Tooltip title={tooltip?.title || `${i18n.t('labels.edit')}`} {...tooltip}>
      <EditOutlined
        {...iconProps}
        className={`${clickable ? 'clickable' : undefined} ${
          animate ? `fa-${animate}` : ''
        } ${props?.className || ''}`}
        style={{
          color: props?.color || blue[6],
        }}
      />
    </Tooltip>
  );
};

export const DeleteIcon = (props: IAntdIconProps) => {
  const { tooltip, clickable = false, animate, ...iconProps } = props;

  return (
    <Tooltip
      title={tooltip?.title || `${i18n.t('labels.delete')}`}
      {...tooltip}
    >
      <DeleteOutlined
        {...iconProps}
        className={`${clickable ? 'clickable' : undefined} ${
          animate ? `fa-${animate}` : ''
        } ${props?.className || ''}`}
        style={{
          color: props?.color || red[5],
        }}
      />
    </Tooltip>
  );
};

export const QuestionCircledIcon = (props: IAntdIconProps) => {
  const { tooltip, clickable = false, animate, ...iconProps } = props;

  return (
    <Tooltip {...tooltip}>
      <QuestionCircleOutlined
        {...iconProps}
        className={`${clickable ? 'clickable' : undefined} ${
          animate ? `fa-${animate}` : ''
        } ${props?.className || ''}`}
        style={{
          color: props?.color || red[5],
        }}
      />
    </Tooltip>
  );
};

export const ExtendSubscriptionIcon = (props: IReactIconProps) => {
  const { tooltip, clickable = true, animate, ...iconProps } = props;

  return (
    <Tooltip {...tooltip}>
      <TbCalendarDollar
        {...iconProps}
        cursor={clickable ? 'pointer' : undefined}
        className={`${animate ? `fa-${animate}` : ''} ${
          props?.className || ''
        }`}
      />
    </Tooltip>
  );
};

export const SubscriptionExpiredIcon = (props: IReactIconProps) => {
  const { tooltip, clickable = true, animate, ...iconProps } = props;

  return (
    <Tooltip {...tooltip}>
      <BiSolidCalendarExclamation
        {...iconProps}
        cursor={clickable ? 'pointer' : undefined}
        color={props?.color || red[6]}
        className={`${animate ? `fa-${animate}` : ''} ${
          props?.className || ''
        }`}
      />
    </Tooltip>
  );
};

export const SquareIcon = (props: IReactIconProps) => {
  const { tooltip, clickable = false, animate, ...iconProps } = props;
  return (
    <Tooltip {...tooltip}>
      <BsFillStopFill
        {...iconProps}
        cursor={clickable ? 'pointer' : undefined}
        className={`${animate ? `fa-${animate}` : ''} ${
          props?.className || ''
        }`}
        fontSize={iconProps?.fontSize || 18}
      />
    </Tooltip>
  );
};

export const NetworkIcon = (props: IReactIconProps) => {
  const { tooltip, clickable = false, animate, ...iconProps } = props;
  return (
    <Tooltip {...tooltip}>
      <TbNetwork
        {...iconProps}
        cursor={clickable ? 'pointer' : undefined}
        className={`${animate ? `fa-${animate}` : ''} ${
          props?.className || ''
        }`}
      />
    </Tooltip>
  );
};

export const VLANIcon = (props: IReactIconProps) => {
  const { tooltip, clickable = false, animate, ...iconProps } = props;
  return (
    <Tooltip {...tooltip}>
      <FaNetworkWired
        {...iconProps}
        cursor={clickable ? 'pointer' : undefined}
        className={`${animate ? `fa-${animate}` : ''} ${
          props?.className || ''
        }`}
      />
    </Tooltip>
  );
};

export const VLANDeleteIcon = (props: IReactIconProps) => {
  const { tooltip, clickable = false, animate, ...iconProps } = props;
  return (
    <Tooltip {...tooltip}>
      <PiNetworkXFill
        {...iconProps}
        cursor={clickable ? 'pointer' : undefined}
        className={`${animate ? `fa-${animate}` : ''} ${
          props?.className || ''
        }`}
      />
    </Tooltip>
  );
};

export const TableViewIcon = (props: IReactIconProps) => {
  const { tooltip, clickable = true, animate, ...iconProps } = props;

  return (
    <Tooltip {...tooltip}>
      <RiTableView
        {...iconProps}
        color={props?.color || !clickable ? blue[4] : grey[4]}
        cursor={clickable ? 'pointer' : undefined}
        className={`${animate ? `fa-${animate}` : ''} ${
          props?.className || ''
        }`}
      />
    </Tooltip>
  );
};

export const CardViewIcon = (props: IReactIconProps) => {
  const { tooltip, clickable = true, animate, ...iconProps } = props;

  return (
    <Tooltip {...tooltip}>
      <RiGalleryView2
        {...iconProps}
        color={props?.color || !clickable ? blue[4] : grey[4]}
        cursor={clickable ? 'pointer' : undefined}
        className={`${animate ? `fa-${animate}` : ''} ${
          props?.className || ''
        }`}
      />
    </Tooltip>
  );
};

export const TreeStructureIcon = (props: IReactIconProps) => {
  const { tooltip, clickable = true, animate, ...iconProps } = props;

  return (
    <Tooltip {...tooltip}>
      <PiTreeStructureFill
        {...iconProps}
        cursor={clickable ? 'pointer' : undefined}
        color={props?.color || !clickable ? blue[4] : grey[3]}
        className={`${animate ? `fa-${animate}` : ''} ${
          props?.className || ''
        }`}
        fontSize={iconProps?.fontSize || 18}
        style={{
          ...iconProps?.style,
          transform: `rotate(${iconProps?.rotate}deg)`,
        }}
      />
    </Tooltip>
  );
};

export const InfoCircleIcon = (props: IReactIconProps) => {
  const { tooltip, clickable = true, animate, ...iconProps } = props;

  return (
    <Tooltip {...tooltip}>
      <FcInfo
        {...iconProps}
        cursor={clickable ? 'pointer' : undefined}
        className={`${animate ? `fa-${animate}` : ''} ${
          props?.className || ''
        }`}
        fontSize={iconProps?.fontSize || 18}
      />
    </Tooltip>
  );
};
