import {
  ISplitterChartData,
  TREE_CHART_NODE_TYPE,
} from 'store/types/splitter.types';
import {
  generateRandomInteger,
  generateRandomMAC,
  generateRandomStringId,
} from 'mocks/mock.utils';

import { PON_LABEL } from 'store/types';

const splitterPortCount = 4;

export const SPLITTER_TREE_CHART_DATA_MOCK: ISplitterChartData = {
  id: generateRandomMAC(),
  type: TREE_CHART_NODE_TYPE.OLT,
  title: `${PON_LABEL} 1`,
  name: `${PON_LABEL} 1`,
  isActive: true,
  reasonCode: undefined,
  port: '1',
  capacity: null,
  availablePorts: null,
  portsUsed: [1],
  subscriberCount: 5,
  childSplitterCount: 2,
  path: Array.from({ length: 1 }, (_, parentIndex) => {
    return {
      id: generateRandomStringId(),
      type: TREE_CHART_NODE_TYPE.SPLITTER,
      title: `Splitter-${parentIndex + 1}`,
      name: `Splitter-${parentIndex + 1}`,
      isActive: true,
      port: '1', // Parent Port number
      capacity: 4,
      availablePorts: [3, 4],
      portsUsed: [1, 2],
      subscriberCount: 5,
      childSplitterCount: 2,
      path: Array.from({ length: splitterPortCount }, (_, childIndex) => {
        // childIndex % 2 === 0
        //   ? {
        //       id: generateRandomStringId(),
        //       port: `${childIndex + 1}`,
        //       title: `SUB-${childIndex + 1}`,
        //       type: TREE_CHART_NODE_TYPE.SUBSCRIBER,
        //       isActive: true,
        //     }
        //   :
        return {
          id: generateRandomStringId(),
          type: TREE_CHART_NODE_TYPE.SPLITTER,
          title: `SPL-${childIndex + 1}`,
          name: `SPL-${childIndex + 1}`,
          isActive: childIndex % 2 === 0,
          reasonCode: childIndex % 2 === 0 ? undefined : 'LOS',
          port: `${childIndex + 1}`,
          capacity: 4,
          availablePorts: [1],
          portsUsed: [1, 3, 4],
          subscriberCount: 5,
          childSplitterCount: 2,
          path: Array.from(
            { length: splitterPortCount },
            (_, subChildIndex) => {
              return subChildIndex % 2 === 0
                ? {
                    id: generateRandomStringId(),
                    type: TREE_CHART_NODE_TYPE.SUBSCRIBER,
                    title: `SUB-${subChildIndex + 1}`,
                    name: `SUB-${subChildIndex + 1}`,
                    isActive: childIndex % 2 === 0,
                    reasonCode: childIndex % 2 === 0 ? undefined : 'LOS',
                    port: `${subChildIndex + 1}`,
                    mac: generateRandomMAC(),
                    portIndex: `1/1/${generateRandomInteger(16)}`,
                  }
                : {
                    id: generateRandomStringId(),
                    type: TREE_CHART_NODE_TYPE.SPLITTER,
                    title: `SubSPL-${subChildIndex + 1}`,
                    name: `SubSPL-${subChildIndex + 1}`,
                    isActive: childIndex % 2 === 0,
                    reasonCode: childIndex % 2 === 0 ? undefined : 'Offline',
                    port: `${subChildIndex + 1}`,
                    capacity: 4,
                    availablePorts: [1, 2, 3],
                    portsUsed: [4],
                    subscriberCount: 5,
                    path: [
                      {
                        id: generateRandomStringId(),
                        type: TREE_CHART_NODE_TYPE.SUBSCRIBER,
                        title: `SUB-${childIndex + 1}-${subChildIndex + 1}`,
                        name: `SUB-${childIndex + 1}-${subChildIndex + 1}`,
                        isActive: childIndex % 2 === 0,
                        reasonCode:
                          childIndex % 2 === 0 ? undefined : 'Power Off',
                        port: `${subChildIndex + 1}`,
                        mac: generateRandomMAC(),
                        portIndex: `1/1/${generateRandomInteger(16)}`,
                      },
                    ],
                  };
            },
          ),
        };
      }),
    };
  }),
};
