import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  getSessionToken,
  resetSecurityToken,
} from './../store/redux/sessionStore';

import { API_END_POINTS } from 'apiUtils/urls';
import { ENABLE_OFFLINE_APP } from 'mocks/mock.utils';
import { SECURITY_HEADER_NAME } from '../constants';
import axiosMockRequester from 'mocks/mockRequester';
import qs from 'qs';

const SKIP_401 = [
  API_END_POINTS.OPERATORS.whoAmI,
  API_END_POINTS.TRIAL.signUp,
  API_END_POINTS.AUTH.LOGIN_INIT,
  API_END_POINTS.AUTH.SEND_OTP,
  API_END_POINTS.AUTH.LOGIN_VERIFY_OTP,
  API_END_POINTS.AUTH.LOGIN_VERIFY_PASSWORD,

  API_END_POINTS.AUTH.forgotPasswordInit,
  API_END_POINTS.AUTH.forgotPasswordRequestOTP,
  API_END_POINTS.AUTH.forgotPasswordVerifyOTP,
  API_END_POINTS.AUTH.forgotPassword,
];

export const formatURL = (
  url: string,
  dynamicParams: { [key: string]: any },
) => {
  Object.keys(dynamicParams).forEach((key) => {
    url = url.replace(`{${key}}`, dynamicParams[key]);
  });
  return url;
};

export const formatError = (response: AxiosResponse) => {
  let message: string | undefined = '';
  if (response?.data?.response) {
    response = response?.data?.response;
  }

  if (response?.data?.info?.msg) {
    message = response?.data?.info?.msg;
  }
  if (response?.data?.message) {
    message = response?.data?.message;
  }

  if (response?.data?.detail && typeof response?.data?.detail === 'string') {
    message = response?.data?.detail?.includes('BAD_GATEWAY')
      ? 'Something went wrong. Please try again'
      : response?.data?.detail;
  }
  if (response?.data?.errors) {
    if (response?.data?.errors instanceof Array)
      message = response?.data?.errors?.length
        ? response?.data?.errors[0]?.code
        : '';
    else if (response?.data?.errors instanceof Object) {
      message = response?.data?.errors?.code || '';
    }
  }
  if (response?.data?.errorMessage) {
    message = response?.data?.errorMessage;
  }

  if (
    message &&
    ['"', "'"].includes(message[0]) &&
    ['"', "'"].includes(message[message.length - 1])
  ) {
    message = message.substring(1, message.length - 1);
  }
  if (response?.status === 500) {
    message = '';
  }
  return message;
};

let clientInfo = 'VIZ';
export function setClientInfo(value: string) {
  clientInfo = value;
}

const AXIOS_DEFAULTS: AxiosRequestConfig = {
  withCredentials: false,
  timeout: 60000,
  transformResponse: [
    function (data) {
      if (!data) {
        return { message: '' };
      }
      try {
        return JSON.parse(data || {});
      } catch (e) {
        console.log('JSON formart error', e);
        try {
          console.log('Trying with eval');
          /*eslint no-eval: "off"*/
          return eval(data);
        } catch (e) {
          console.log('Eval also failed', e);
          return {
            dataFormatError: true,
            message: 'Error in processing the request',
          };
        }
      }
    },
  ],
};

export const createAxiosInstance = (
  baseURL: string = '',
  options: AxiosRequestConfig | {} = {},
): AxiosInstance => {
  const instance = ENABLE_OFFLINE_APP
    ? axiosMockRequester
    : axios.create({
        baseURL,
        ...AXIOS_DEFAULTS,
        ...options,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });

  instance.interceptors.request.use(
    async function (config: AxiosRequestConfig) {
      if (config?.headers) {
        config.headers[
          'x-fii-client-info'
        ] = `name:${clientInfo};version:${process.env.VERSION_CODE}_${process.env.BUILD_VERSION}`;
        const token = getSessionToken();
        if (token && !config.headers[SECURITY_HEADER_NAME]) {
          config.headers[SECURITY_HEADER_NAME] = token;
        }
      }
      return config;
    },
    function (error) {
      console.log(error);
      return Promise.reject(error);
    },
  );
  instance.interceptors.response.use(
    function (axiosResponse: AxiosResponse<any, any>) {
      if (axiosResponse.data.dataFormatError) {
        return Promise.reject(axiosResponse);
      }
      // console.log('Success Response', axiosResponse.config?.url, axiosResponse);
      return axiosResponse;
    },
    function (error) {
      if (error?.response?.data) {
        if (typeof error.response.data === 'string') {
          error.response.data = {
            message: error.response.data,
          };
        }
        error.response.data.error = true;
      }
      if (
        ([401].includes(error?.response?.status) &&
          !SKIP_401.includes(error?.response?.config?.url)) ||
        error?.config?.dontSkipMe
      ) {
        resetSecurityToken();
        window.location.reload();
      }

      // else if (error?.response?.status === 0) {
      //   try {
      //     error.response?.status = 500;
      //   } catch (e) {
      //     console.log(e);
      //   }
      //   // verifySessionOnce();
      // }

      return Promise.reject(error);
    },
  );
  return instance;
};

export const AuthRequester = createAxiosInstance(process.env.API_AUTH);
export const SubscriberRequester = createAxiosInstance(
  process.env.API_SUBSCRIBER,
);
export const Subscriber2Requester = createAxiosInstance(
  process.env.API_SUBSCRIBER_2,
);
export const BetaSubscriberRequester = createAxiosInstance(
  process.env.API_BETA_SUBSCRIBER,
);
export const OperatorRequester = createAxiosInstance(process.env.API_OPERATOR);
export const SupportRequester = createAxiosInstance(process.env.API_SUPPORT);
export const HardwareRequester = createAxiosInstance(process.env.API_HARDWARE);
export const FirmwareRequester = createAxiosInstance(process.env.API_FIRMWARE);
export const ConfigRequester = createAxiosInstance(process.env.API_CONFIG);
export const VizRequester = createAxiosInstance(process.env.API_VIZ);
export const CentralisedLoggingRequester = createAxiosInstance(
  process.env.API_CENTRALISED_LOGGING,
);
export const TrialRequester = createAxiosInstance(process.env.API_TRIAL);
export const SubscriptionRequester = createAxiosInstance(
  process.env.API_SUBSCRIPTION,
);
export const BillingRequester = createAxiosInstance(process.env.API_BILLING);
export const OLTOnboardingRequester = createAxiosInstance(
  process.env.API_OLT_ONBOARDING,
);
export const ReportsRequester = createAxiosInstance(process.env.API_REPORTS);
export const AlertsRequester = createAxiosInstance(process.env.API_ALERTS);
export const OLTGatewayRequester = createAxiosInstance(
  process.env.API_OLT_GATEWAY,
);

export const MessageRequester = createAxiosInstance(process.env.API_MESSAGE);
export const TicketManagerRequester = createAxiosInstance(
  process.env.API_TICKET_MANAGER,
);

// const verifySessionOnce = () => {
//   return AuthRequester.get(API_END_POINTS.OPERATORS.whoAmI, {
//     //@ts-ignore
//     dontSkipMe: true,
//   });
// };
