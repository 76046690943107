import {
  ISubscriberGroupList,
  ISubscriberGroupReduxState,
} from 'store/types/subscriber.types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { VizRequester, formatError } from 'apiUtils/api';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { IPagination } from 'store/types';
import { isEmpty } from 'lodash';

const initialState: ISubscriberGroupReduxState = {
  subscriberGroupList: {
    loading: true,
    hardReload: false,
    error: '',
    data: {} as ISubscriberGroupList,
  },
  subscriberGroupListPagination: {} as IPagination,
};

const loadSubscriberGroupList = createAsyncThunk(
  'subscriberGroupList/fetch',
  async (_, thunkAPI) => {
    const {
      subscriberGroupState,
    }: { subscriberGroupState: ISubscriberGroupReduxState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.subscriberGroupList,
      {
        params: {
          ...subscriberGroupState?.subscriberGroupListPagination,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) ||
          'Error in fetching list of Subscriber Group. Please try again',
      };
    }
  },
);

export const SubscriberGroupSlice = createSlice({
  name: 'SubscriberGroup',
  initialState,
  reducers: {
    resetToInit: () => initialState,
    setSubscriberGroupListPageToken: (
      state: ISubscriberGroupReduxState,
      { payload }: { payload: number },
    ) => {
      state.subscriberGroupListPagination.pageToken = payload;
    },
    setSubscriberGroupListPageSize: (
      state: ISubscriberGroupReduxState,
      { payload }: { payload: number },
    ) => {
      state.subscriberGroupListPagination.pageSize = payload;
    },
    setSubscriberGroupListPagination: (
      state: ISubscriberGroupReduxState,
      action: PayloadAction<IPagination>,
    ) => {
      state.subscriberGroupListPagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadSubscriberGroupList.pending,
      (state: ISubscriberGroupReduxState) => {
        state.subscriberGroupList.loading = true;
        state.subscriberGroupList.error = '';
      },
    );
    builder.addCase(
      loadSubscriberGroupList.fulfilled,
      (
        state: ISubscriberGroupReduxState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.subscriberGroupList.loading = false;
        state.subscriberGroupList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.subscriberGroupList.data = payload;
        } else {
          state.subscriberGroupList.error = payload?.error;
        }
      },
    );
  },
});

export const SubscriberGroupSliceActions = {
  ...SubscriberGroupSlice.actions,
  loadSubscriberGroupList,
};

export const subscriberGroupReducer = SubscriberGroupSlice.reducer;
