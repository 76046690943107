const STORAGE_KEY_NAME = 'session_token';
const FCM_TOKEN_KEY_NAME = 'fcm_token';
const REDIRECT_TO_DOMAIN_KEY_NAME = 'redirect_to_domain';

export const storeSessionToken = (sessionToken: any) => {
  localStorage.setItem(STORAGE_KEY_NAME, sessionToken);
};

export const getSessionToken = () => {
  return localStorage.getItem(STORAGE_KEY_NAME) || '';
};

export const resetSecurityToken = () => {
  localStorage.removeItem(STORAGE_KEY_NAME);
};

//#region FCM Token
export const storeFCMToken = (fcmToken: any) => {
  localStorage.setItem(FCM_TOKEN_KEY_NAME, fcmToken);
};

export const getFCMToken = () => {
  return localStorage.getItem(FCM_TOKEN_KEY_NAME) || '';
};

export const resetFCMToken = () => {
  localStorage.removeItem(FCM_TOKEN_KEY_NAME);
};

//#endregion

//  #region - Redirect to domain
export const storeRedirectToDomain = (value: any) => {
  localStorage.setItem(REDIRECT_TO_DOMAIN_KEY_NAME, value);
};

export const getRedirectToDomain = () => {
  return localStorage.getItem(REDIRECT_TO_DOMAIN_KEY_NAME) || '';
};

export const resetRedirectToDomain = () => {
  localStorage.removeItem(REDIRECT_TO_DOMAIN_KEY_NAME);
};
//  #endregion - Redirect to domain
