import { DeviceState, ISubscriber } from 'store/types/subscriber.types';
import { MANAGEMENT_PROTOCOL, OnlineStatus } from 'store/types';

//  #region - Subscriber Dashboard
export interface ISubscriberDashboard {
  refreshInterval: number;
  autoRefresh: boolean;
  reloading: boolean;
  overview: Overview;
  natsDetails?: INatsDetails;
  clientInfo: ClientInfo;
  opticalRXStats: IOpticalStats;
  opticalTXStats: IOpticalStats;
  opticalSFPStats: IOpticalStats;
  opticalBiasCurrentStats: IOpticalStats;
  opticalSupplyVoltageStats: IOpticalStats;
  clientsList: ISubscriberClientsList;
  addressableConfig?: IAddressableConfig;
  addressableConfigLoading?: boolean;
  reloadPingHistory?: boolean;
  reloadTraceHistory?: boolean;
  reloadSpeedTestHistory?: boolean;
  isDeviceOffline?: boolean;
  friendly?: boolean;
  firmwareStatus: IFirmwareStatus;
  onuByOlt: {
    loading: boolean;
    error: string;
  };
  isOnuByOltTriggered: boolean;
  overviewFilters?: ISubscriberOverviewFilters;
}
export interface ISubscriberOverviewFilters {
  opticalTimeRange?: string | null;
}
export interface IFirmwareStatus {
  loading: boolean;
  error: string;
  data: IFirmwareStatusData;
  isFirmwareNotUptoDate: boolean;
}
export interface IFirmwareStatusData {
  status: FIRMWARE_UPGRADE_STATUS;
  latestFirmwareVersion: string;
}
export interface ISubscriberClientsList {
  loading: boolean;
  error: string;
  data: IClientsListData;
}
export interface IClientsListData {
  items: IClient[];
  page?: number;
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface ClientInfo {
  loading: boolean;
  error: boolean;
  data: ClientInfoResponse;
}
export interface ClientInfoResponse {
  clients: {
    totalClients: number;
    active: number;
  };
  systemResourcesUtilizationStatistics: CPUMemoryDatum[];
}
export interface IOpticalStats {
  loading: boolean;
  error: boolean | string | null;
  data: OpticalFiberStatistics;
}
export interface IChannelSwitch {
  radioBands: string[];
  hourlyFreqChannel: IHourlyFreqChannel[];
}
export interface IHourlyFreqChannel {
  time: string;
  '2.4 GHz': string;
  '5.0 GHz': string;
}
export interface CPUMemory {
  loading: boolean;
  error: boolean;
  data: CPUMemoryDatum[];
}
export interface CPUMemoryDatum {
  time: string;
  cpu: number;
  memory: number;
}
export interface Overview {
  loading: boolean;
  error: boolean;
  details: ISubscriber;
}
export interface IDeviceInfo {
  mac: string;
  deviceId: string;
  name: string;
  deviceState: DeviceState;
  serialNumber: string;
  hardwareId: string;
  hardwareModel: string;
  hardwareVersion: string;
  manufacturer: string;
  managementProtocol: MANAGEMENT_PROTOCOL;
}

type SubscriberStats =
  // | 'Channel'
  | 'ChannelSwitch'
  | 'ChannelWidth'
  | 'ClientDeviceRSSI'
  | 'ClientDeviceThroughput'
  | 'ConnectedClients'
  | 'ConnectedPorts'
  | 'CpuUsage'
  // | 'DeviceLogs'
  // | 'EthernetPortStatus'
  | 'MemoryUsage'
  // | 'NetworkInformation'
  // | 'OLTInformation'
  // | 'OnlineOfflineStatus'
  // | 'OpticalLevel'
  | 'OpticalStrength'
  // | 'PONAccessType'
  | 'ProfileUsage'
  // | 'RadioInformation'
  // | 'RTT'
  | 'SystemEventAndLogs'

  // | 'Uptime'
  // | 'WanIP'
  // | 'WanPublicIP'
  | 'WanThroughput'
  | 'WanType'
  | 'WiFiThroughput';

export type StatsSupported = {
  [k in SubscriberStats]: boolean;
};

type SubscriberFeatures =
  // | 'AdBlock'
  // | 'AppBlock'
  // | 'DeviceManagement'
  // | 'FirmwareManagement'
  // | 'FirmwareUpdate'
  // | 'InternetSecurity'
  | 'LanConfig'
  // | 'ParentalControl'
  | 'PausePlay'
  | 'Ping'
  // | 'Profile'
  | 'Reboot'
  // | 'Safesearch'
  | 'ScanAndOptimizeWifi'
  | 'SiteSurvey'
  | 'Speedtest'
  // | 'SSID'
  | 'SSIDPassword'
  // | 'Stats'
  | 'Traceroute'
  | 'WifiOptimize';

export type featuresAuthorized = {
  [k in SubscriberFeatures]: boolean;
};

type subscriberFeaturesSupported = 'WanConfig' | 'WlanConfig' | 'VoipConfig';

export type featuresSupported = {
  [k in subscriberFeaturesSupported]: boolean;
};
export interface IPowerRating {
  temperature: number;
  voltage: number;
  biasCurrent: number;
}
export interface Profile {
  profileId: string;
  profileName: string;
  parentalControl: number;
  clients: { cmacAddress: string }[];
  ispaused: boolean;
  adblock: boolean;
  safeSearchEnabled: boolean;
  siteBlock: boolean;
}
export interface IClient {
  name: string;
  friendlyName: string;
  ipAddress: string;
  macAddress: string;
  deviceModel: string;
  deviceType: string;
  connectionType: ClientsConnectionType;
  accessPointId: string;
  accessPointName?: string;
  authenticationType: string;
  ssid: string;
  radioBand: string;
  connectionTime: number;
  active: boolean;
  lastSeenTime: string;
  // paused: boolean;
  dhcpLeaseTime: number;
  // profileName?: string;
  // profileId?: string;
  // profileAvatarId: number;
  // throughputStats: any[];
  // rssiStats: any[];
  currentRssi?: CurrentRssi;
  dataConsumption?: DataConsumption;
  internetAccessStatus: INTERNET_ACCESS_STATUS;
}
export interface DataConsumption {
  time: string;
  download: number | null;
  upload: number | null;
  total?: number;
}
export interface CurrentRssi {
  time: string;
  signal: number;
  signalAverage: number;
  download: number;
  upload: number;
}
export interface INatsDetails {
  connectionInfo: INatsConnectionInfo;
  subjects: INatsSubjects;
}
export interface INatsConnectionInfo {
  url: string;
  wsPort: string;
  port?: string;
  ip?: string;
}
export interface INatsSubjects {
  speedTest: string;
  ping: string;
  trace: string;
}
export interface IAddressableConfig {
  isAddressable: boolean;
  managementProtocol: string;
  lastInform: string;
  periodicInterval: string;
}
export interface IPingHistory {
  size?: number;
  from?: number;
  total?: number;
  items: IAddressablePingResponse[];
}
export interface ITraceHistory {
  size?: number;
  from?: number;
  total?: number;
  items: IAddressableTraceResponse[];
}
export interface ICWMPSpeedTestHistory {
  size?: number;
  from?: number;
  total?: number;
  result: IAddressableSpeedTestResponse[];
}
export interface IAgentSpeedTestHistory {
  deviceid: string;
  time: string;
  serverId?: string;
  serverLocation?: string;
  upload: string | number;
  download: string | number;
  ping: string | number;
  subscriberId: string;
}
export interface IAddressablePingResponse {
  host: string;
  numberOfRepetitions: string;
  successCount: string;
  failureCount: string;
  averageResponseTime: string;
  minimumResponseTime: string;
  maximumResponseTime: string;
  serialNumber: string;
  deviceId: string;
  time: string;
  subscriberId: string;
  transactionId: string;
  isAddressable?: boolean;
  initiatedBy?: string;
  origin?: string;
  diagnosticsState?: string;
  status?: string;
}
export interface IAddressableTraceResponse {
  transactionId: string;
  serialNumber: string;
  deviceId: string;
  subscriberId: string;
  diagnosticsState: string;
  status: string;
  interface: string;
  host: string;
  numberOfTries: string;
  timeout: string;
  maxHopCount: string;
  responseTime: string;
  routeHopsNumberOfEntries: string;
  hops?: IAddressableTraceHops[];
  time: string;
  isAddressable?: boolean;
  initiatedBy?: string;
  origin?: string;
}
export interface IAddressableTraceHops {
  host: string;
  hostAddress: string;
  rtTime: string;
}
export interface IAddressableSpeedTestResponse {
  transactionId: string;
  ping: string | null;
  upload: string | null;
  download: string | null;
  time: string;
  diagnosticsState: string | null;
  status: ADDRESSABLE_STATUS;
}
export interface ITransactionData {
  transactionId: string;
  serialNumber: string;
  isAddressable: boolean;
  status: CWMP_ACTION_STATUS;
  action: string;
  initiatedBy: string;
  subscriberId: string;
  deviceId: string;
  app: string;
  time: string;
}
export interface IScanResult {
  error: string;
  neighbouringWifi: IACSChannel[];
}
export interface IOptimizeResult {
  error: string;
  oldChannel: string;
  newChannel: string;
}
export interface ITransactionResult {
  result: {
    scan: IScanResult;
    optimize: IOptimizeResult;
  };
  status: string;
  action: string;
  transactionId: string;
}
export interface IACSChannel {
  ssid: string;
  channel: number;
  fromChannel: null;
  toChannel: null;
  currentRSSI: number;
  channelWidth: number | string;
  band: '2.4 GHz' | '5.0 GHz' | string;
  bssid: string;
  mode: string;
  noise: number;
  beaconPeriod: number;
  dtimPeriod: number;
  standard: string;
  securityMode: string;
  encryptionMode: string;
}
export interface IOptimizeChannelResult {
  items: {
    oldChannel: number;
    newChannel: number;
  };
  status: 'SUCCESS' | 'PENDING';
  action: string;
  transactionId: string;
}
export interface IPingMessage {
  ping: Event;
  time: string;
  deviceId: string;
  pingdata?: string;
}
export interface ITraceMessage {
  trace: Event;
  time: string;
  deviceId: string;
  tracedata?: string;
}
export interface ISpeedTestMessage {
  event: string;
  deviceid?: string;
  local_time?: string;
  server_id?: string;
  server_location?: string;
  test_type: SPEED_TEST_ACTIONS;
  value: number;
  subscriber_id?: string;
  duration?: number;
  speed?: number;
}
export interface Radio {
  frequency: '2.4 GHz' | '5.0 GHz';
  band: '2.4 GHz' | '5.0 GHz';
  channel: number;
  clientCount: number;
  channelWidth: string;
  interfaceName?: string;
  ssid?: string; // To display on ACS Graph
}
export interface LastReceivedValue {
  txPower: number;
  rxPower: number;
  time?: string;
  strength: SIGNAL_STRENGTH;
  color?: string;
  opacity?: number;
  temperature?: number;
  biasCurrent?: number;
  supplyVoltage?: number;
}
export interface OpticalFiberStatistics {
  timeSeries: LastReceivedValue[];
  lastReceivedValue?: LastReceivedValue;
  allStats: AllStats;
  inference?: SIGNAL_STRENGTH;
  modePerRange?: ModePerRange;
}
export interface ModePerRange {
  AVERAGE: IRange[];
  POOR: IRange[];
  GOOD: IRange[];
}
export interface IRange {
  from?: number | string;
  to?: number | string;
  unit?: string;
  doc_count?: number;
}
export interface AllStats {
  min: LastReceivedValue;
  avg: LastReceivedValue;
  max: LastReceivedValue;
  count: number;
  sum: number;
}
export interface IAgentLogs {
  id: string;
  time: string;
  severity: string;
  module: string;
  macAddress: string;
  message: string;
}
export interface ILogsFilters {
  module: ILogsFilterOptions[];
  severity: ILogsFilterOptions[];
}
export interface ILogsFilterOptions {
  value: string;
  displayValue: string;
}
export interface IDeviceStatusList {
  createdTime: string;
  event: string;
  oltMac: string;
  onuState: OnlineStatus;
  reasonCode: string;
  portNumber: string;
  portIndex: string;
  ontNumber: string;
  elapsedTimeInSeconds: number;
}
export interface IMapToOLTList {
  oltMac: string;
  oltLabel: string;
  portNumber: string;
  portIndex: string;
  createdTime: string;
  ontNumber: string;
  oltIp: string;
  ontMac: string;
  serialNumber: string;
  id?: string;
}
//  #endregion - Subscriber Dashbaord

//  #region - Enums and Constants

export enum VIEW_ONBOARDING_STATE {
  LOCATION_MAPPED = 'Location mapped',
  CPE_SWAP_IN_PROGRESS = 'CPE swap in progress',
  CONFIGURED = 'Provisioned',
  PROVISIONED = 'Provisioned',
  PENDING = 'Pending',
  CONFIGURATION_PENDING = 'Configuration Pending',
}

export enum SIGNAL_STRENGTH {
  EXCELLENT = 'EXCELLENT',
  AVERAGE = 'AVERAGE',
  POOR = 'POOR',
  GOOD = 'GOOD',
}
export enum SIGNAL_STRENGTH_VIEW {
  EXCELLENT = 'Excellent',
  AVERAGE = 'Average',
  POOR = 'Poor',
  GOOD = 'Good',
}
export enum ACCESS_TYPE_DISPLAY_NAME {
  GPON = 'GPON',
  EPON = 'EPON',
}

export enum CONFIGURATION_STATUS_DISPLAY_NAME {
  NOT_CONFIGURED = 'Configuration Pending',
  CONFIGURED = 'Configured',
  NOT_SUPPORTED = 'Not Supported',
}

export enum OPTICAL_STRENGTH_GRAPH_TYPE {
  RX_POWER = 'RX_POWER',
  TX_POWER = 'TX_POWER',
  TEMPERATURE = 'TEMPERATURE',
  SUPPLY_VOLTAGE = 'SUPPLY_VOLTAGE',
  BIAS_CURRENT = 'BIAS_CURRENT',
}

export enum FIRMWARE_UPGRADE_STATUS {
  FW_UP_TO_DATE = 'FW_UPTODATE',
  FW_NOT_UP_TO_DATE_UPGRADE_NOT_ALLOWED = 'FW_NOT_UPTODATE_UPGRADE_NOT_ALLOWED',
  FW_NOT_UP_TO_DATE_UPGRADE_ALLOWED = 'FW_NOT_UPTODATE_UPGRADE_ALLOWED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum RadioTypeView {
  SingleBand = 'Single',
  DualBand = 'Dual',
}

export enum ClientsConnectionType {
  Wired = 'Wired',
  Wireless = 'Wireless',
}

export enum INTERNET_ACCESS_STATUS {
  BLOCKED = 'Blocked',
  ALLOWED = 'Allowed',
  SCHEDULED = 'Scheduled',
}

export enum CWMP_ACTIONS {
  SPEED_TEST = 'SPEED_TEST',
  PING = 'PING',
  FW_UPDATE = 'FW_UPDATE',
  TRACE = 'TRACE',
  REBOOT = 'REBOOT',
}

export enum CWMP_ACTION_STATUS {
  APPLIED = 'APPLIED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum CPEActions {
  PING = 'Ping',
  TRACE = 'Trace Route',
  SPEED_TEST = 'Speed test',
  REBOOT = 'Reboot',
  ACS = 'Wi-Fi Optimize', //  ACS - Auto Channel Selection
}

export enum ACTIONS {
  PING = 'PING',
  TRACE = 'TRACE',
  SPEED_TEST = 'SPEED_TEST',
  REBOOT = 'REBOOT',
  CHANGE_SSID_PWD = 'CHANGE_SSID_PWD',
  CHANGE_LAN_CONFIG = 'CHANGE_LAN_CONFIG',
  SITE_SURVEY = 'SITE_SURVEY',
  OPTIMIZE_WIFI = 'OPTIMIZE_WIFI',
}

export enum ADDRESSABLE_STATUS {
  Failed = 'Failed',
  Success = 'Success',
  Complete = 'Complete',
  Completed = 'Completed',
  Timeout = 'Timeout',
  Requested = 'Requested',
}

export enum SPEED_TEST_ACTIONS {
  PING = 'ping',
  DOWNLOAD = 'download',
  UPLOAD = 'upload',
}

export enum SPEED_TEST_EVENTS {
  START = 'start',
  PROGRESS = 'progress',
  FINISH = 'finish',
  ERROR = 'error',
  TEST_IN_PROGRESS = 'test-in-proress',
  UNABLE_TO_CONNECT = 'unable-to-connect',
}

export enum SPEED_TEST_COLOR_CODES {
  PING = '#00c8fe',
  PROGRESS_TEXT = '#767676',
  UPLOAD = '#970081',
  DOWNLOAD = '#008f00',
}

type Event = 'start' | 'progress' | 'error' | 'end' | 'finish';

// NOTE: error messages are for users
// error codes can be used to send the log to the backend or for other diagnostics
// NOTE: strings can be repleaced with functions to generate more meaningful messages using stacktrace
export const SpeedTestErrorCodes = {
  AP_ERROR: 'AP_ERROR',
  API_ERROR: 'API_ERROR',
  INVALID_MESSAGE: 'INVALID_MESSAGE',
  MESSAGE_WAITING_TIME_OUT: 'MESSAGE_WAITING_TIME_OUT',
  NATS_CONNECTION_LOST: 'Lost connection', // 'NATS_CONNECTION_LOST',
  TIME_OUT: 'Time out',
  UNABLE_TO_INITIALIZE: 'Unable to initialize SpeedTest', //'UNABLE_TO_INITIALIZE',
  MESSAGE_BUFFER_EMPTY: 'Message buffer empty',
};

//  #endregion - Enums and Constants
