import {
  ALLOWED_PERMISSIONS,
  PERMISSIONS,
  ROLE_BASED_PERMISSIONS,
  THEME_BASED_PERMISSIONS,
  getAllKeysInObject,
} from 'utils/roles&Permissions';
import {
  Action,
  AuthState,
  EntityType,
  IAuthUserInfo,
  IUserRoles,
  Subscriber_Tabs,
  THEME,
  TRIAL_STATUS,
  TTHEME_VALUE,
} from 'store/types';
import { EntityDashboards, ROLES } from './../../types';
import {
  MessageRequester,
  OperatorRequester,
  TrialRequester,
  formatURL,
} from './../../../apiUtils/api';
import {
  PACKAGE_NAME,
  SUBSCRIPTION_CATEGORIES,
  SUBSCRIPTION_FEATURES,
  SUBSCRIPTION_STATE,
} from 'store/types/subscription.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getSessionToken,
  storeFCMToken,
  storeSessionToken,
} from '../sessionStore';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { SECURITY_HEADER_NAME } from './../../../constants';
import { UserInfo } from 'store/types/users.types';
import { deleteFCMToken } from 'components/utilities/FirebaseNotifications';
import { getKeyFromEnumValue } from 'utils/utils';
import { isEmpty } from 'lodash';
import { resetSecurityToken } from 'store/redux/sessionStore';
import { setAllowedPermissions } from 'components/utilities/RoleBasedComponent';

const loadExistingSession = createAsyncThunk(
  'auth/loadExistingSession',
  async (payload: any, thunkAPI) => {
    let token = payload?.accessToken || getSessionToken();
    if (token) {
      const response: AxiosResponse = await OperatorRequester.get(
        API_END_POINTS.OPERATORS.whoAmI,
        {
          headers: {
            [SECURITY_HEADER_NAME]: token,
          },
        },
      ).catch(({ request }) => request);

      if (response?.status === 200) {
        let trialDetails = null;
        const authData: UserInfo | any = response?.data;
        if (!isEmpty(authData)) {
          if (
            [ROLES.TRIAL_ISP_ADMIN, ROLES.TRIAL_FRANCHISEE_ADMIN].includes(
              authData?.role,
            )
          ) {
            const trialResponse = await TrialRequester.get(
              formatURL(API_END_POINTS.TRIAL.trialByMeId, {
                meId: authData?.managementEntity?.id,
              }),
            ).catch(({ response }) => response);
            trialDetails = trialResponse;
          }
          return { ...authData, trialDetails: trialDetails?.data };
        } else {
          return false;
        }
      }
    }
    return false;
  },
);

const registerFirebaseToken = createAsyncThunk(
  'firebase/register',
  async (payload: { token: string; userId: string }, thunkAPI) => {
    if (payload.token) {
      const response: AxiosResponse = await MessageRequester.post(
        API_END_POINTS.MESSAGE.notifications,
        payload,
      ).catch(({ response }) => response);
      if (response?.status === 200) {
        storeFCMToken(payload.token);
        console.log('Notification token registered successfully');
      } else {
        console.error('Error in registering Notification token');
      }
    }
  },
);

const emptyUser: IAuthUserInfo = {} as IAuthUserInfo;

const getRoleBasedHome = (payload: IAuthUserInfo) => {
  switch (payload.role) {
    case ROLES.PLATFORM_ADMIN:
      return '/app/channel-partners';
    case ROLES.RESELLER_ADMIN:
    case ROLES.RESELLER_BILLING_ADMIN:
    case ROLES.SUPPORT_EXECUTIVE:
      return `/app/dashboards/reseller/${payload?.reseller?.id}`;
    case ROLES.CP_ADMIN:
    case ROLES.SUPPORT_ENGINEER:
      // case ROLES.DISTRIBUTOR_ADMIN:
      return `/app/dashboards/${
        EntityDashboards[
          payload?.managementEntity?.type as keyof typeof EntityDashboards
        ]
      }/${payload.managementEntity?.id}`;
    case ROLES.ISP_ADMIN:
    case ROLES.TRIAL_ISP_ADMIN:
    case ROLES.FRANCHISEE_ADMIN:
    case ROLES.TRIAL_FRANCHISEE_ADMIN:
    case ROLES.BILLING_ADMIN:
      if (
        isEmpty(payload?.subscription) ||
        ([ROLES.TRIAL_ISP_ADMIN, ROLES.TRIAL_FRANCHISEE_ADMIN].includes(
          payload?.role,
        ) &&
          payload?.trialDetails?.status === TRIAL_STATUS.COMPLETED) ||
        [SUBSCRIPTION_STATE.SUSPENDED, SUBSCRIPTION_STATE.EXPIRED].includes(
          payload?.subscription?.state,
        )
      ) {
        if ([ROLES.ISP_ADMIN, ROLES.FRANCHISEE_ADMIN].includes(payload?.role)) {
          return '/app/subscription-suspended';
        }
        return `/app/subscriptions/${payload?.managementEntity?.id}`;
      }
      return `/app/dashboards/${
        EntityDashboards[
          payload.managementEntity?.type as keyof typeof EntityDashboards
        ]
      }/${payload.managementEntity?.id}`;
    case ROLES.FIELD_AGENT:
      return isEmpty(payload?.subscription)
        ? '/app/subscription-suspended'
        : `/app/dashboards/${
            EntityDashboards[
              payload.managementEntity?.type as keyof typeof EntityDashboards
            ]
          }/${payload.managementEntity?.id}`;
    case ROLES.SUBSCRIBER_GROUP_ADMIN:
    case ROLES.SUPPORT_AGENT:
      return isEmpty(payload?.subscription)
        ? '/app/subscription-suspended'
        : `/app/subscribers/${Subscriber_Tabs.CONFIGURED}`;
    case ROLES.FIRMWARE_MANAGEMENT:
      return isEmpty(payload?.subscription)
        ? '/app/subscription-suspended'
        : '/app/firmware';
    default:
      return '';
  }
};

const initialState: AuthState = {
  loading: true,
  authorized: false,
  mannualTrigger: false,
  returnTo: localStorage.getItem('returnTo') || '',
  userInfo: emptyUser,
  homeLink: '',
  selectedLoginUserRole: {} as IUserRoles,
  subscriptionStatus: SUBSCRIPTION_STATE.PENDING,
  allowedPermissions: [],
};

const updateRolesAndPermissions = (userInfo: UserInfo) => {
  let role = userInfo?.role;
  if (userInfo?.role === ROLES.FIELD_AGENT) {
    role =
      userInfo?.managementEntity?.type === EntityType.ISP
        ? ROLES.ISP_FIELD_AGENT
        : ROLES.FRANCHISEE_FIELD_AGENT;
  }
  if (userInfo?.role === ROLES.BILLING_ADMIN) {
    role =
      userInfo?.managementEntity?.type === EntityType.ISP
        ? ROLES.ISP_BILLING_ADMIN
        : ROLES.FRANCHISEE_BILLING_ADMIN;
  }

  // Handle Theme / Tenant based Permissions
  const themeValue = process.env?.THEME || THEME.FIIANALYTICS;
  const themeBasedPermissions =
    ALLOWED_PERMISSIONS[themeValue as TTHEME_VALUE]?.[role] || [];
  let allowedPermissions = themeBasedPermissions;

  const themeList = THEME_BASED_PERMISSIONS[themeValue as TTHEME_VALUE]?.[role];
  const themeBasedPermission = themeList?.INCLUDE_KEYS?.SUBSCRIPTIONS || [];
  const roleBasedSubscriptions = [
    ...themeBasedPermission,
    ...getAllKeysInObject([
      ...ROLE_BASED_PERMISSIONS[role]?.SUBSCRIPTIONS,
      ...(themeList?.IGNORE_KEYS?.SUBSCRIPTIONS || []),
    ]),
  ];

  // Handle Subscription based Features and Categories
  if (!isEmpty(userInfo?.subscription)) {
    let subscriptionBasedPermissions: string[] = [];
    userInfo?.subscription?.package?.category?.forEach((categoryData) => {
      const categoryKey = getKeyFromEnumValue(
        categoryData?.name,
        SUBSCRIPTION_CATEGORIES,
      );
      if (categoryKey) {
        const categoryValue =
          PERMISSIONS.SUBSCRIPTION_BASED_FEATURES[
            categoryKey as keyof typeof SUBSCRIPTION_CATEGORIES
          ]?.KEY;
        if (
          categoryValue &&
          roleBasedSubscriptions?.indexOf(categoryValue) > -1
        )
          subscriptionBasedPermissions.push(categoryValue);
      }
      categoryData?.features?.forEach((featureData) => {
        const featureKey = getKeyFromEnumValue(
          featureData?.name,
          SUBSCRIPTION_FEATURES,
        );
        if (featureKey) {
          const featureValue =
            // @ts-ignore
            PERMISSIONS.SUBSCRIPTION_BASED_FEATURES[
              categoryKey as keyof typeof SUBSCRIPTION_CATEGORIES
            ]?.FEATURES[featureKey as keyof typeof SUBSCRIPTION_FEATURES];
          if (
            featureValue &&
            roleBasedSubscriptions?.indexOf(featureValue) > -1
          ) {
            subscriptionBasedPermissions.push(featureValue);
          }
        }
      });
    });
    allowedPermissions = [
      ...allowedPermissions,
      ...subscriptionBasedPermissions,
    ];
  }

  //  Update Roles based Menus
  if (userInfo?.parentManagementEntity?.type === EntityType.CHANNEL_PARTNER) {
    if (
      [ROLES.FRANCHISEE_BILLING_ADMIN, ROLES.FRANCHISEE_ADMIN]?.includes(role)
    ) {
      const subscriberActions = [
        ...getAllKeysInObject(PERMISSIONS.SUBSCRIBERS, [
          PERMISSIONS.SUBSCRIBERS.DASHBOARD,
          PERMISSIONS.SUBSCRIBERS.VIEW,
          PERMISSIONS.SUBSCRIBERS.SEARCH,
        ]),
      ];
      let franchiseeAdminPermissions = [
        PERMISSIONS.MENUS.SUBSCRIBER_GROUP,
        PERMISSIONS.MENUS.DEVICES,
        PERMISSIONS.MENUS.VLAN,
        PERMISSIONS.MENUS.BULK_ACTIONS,
        PERMISSIONS.SUBSCRIBER_FILTERS.SUBSCRIBER_GROUP,
        ...Object.values(PERMISSIONS.SUBSCRIBER_GROUP),
        ...Object.values(PERMISSIONS.DEVICES),
        PERMISSIONS.DEVICE_FILTERS.OLT,
        ...Object.values(PERMISSIONS.VLAN),
        PERMISSIONS.SUBSCRIPTIONS.SUSPEND_NOTIFY,
        PERMISSIONS.OLT.CREATE,
        PERMISSIONS.OLT.EDIT,
        PERMISSIONS.OLT.DELETE,
      ];
      if (role === ROLES.FRANCHISEE_BILLING_ADMIN) {
        franchiseeAdminPermissions = [
          ...franchiseeAdminPermissions,
          PERMISSIONS.MENUS.PAYMENTS,
          ...Object.values(PERMISSIONS.PAYMENTS),
          PERMISSIONS.SUBSCRIPTIONS.VIEW,
        ];
      }
      allowedPermissions = [
        // Set method - To Avoid Subscriber Actions if already present
        ...new Set([
          ...allowedPermissions,
          ...franchiseeAdminPermissions,
          ...subscriberActions,
        ]),
      ];
    } else if (role === ROLES.FRANCHISEE_FIELD_AGENT) {
      let franchiseeFieldAgentPermissions = [
        PERMISSIONS.MENUS.VLAN,
        ...Object.values(PERMISSIONS.VLAN),
      ];
      allowedPermissions = [
        ...allowedPermissions,
        ...franchiseeFieldAgentPermissions,
      ];
    }
  }

  if (
    userInfo?.ticketingEnabled &&
    [
      ROLES.BILLING_ADMIN,
      ROLES.ISP_ADMIN,
      ROLES.FRANCHISEE_ADMIN,
      ROLES.FIELD_AGENT,
    ].includes(userInfo?.role)
  ) {
    allowedPermissions = [
      ...allowedPermissions,
      PERMISSIONS.MENUS.TICKETS,
      ...Object.values(PERMISSIONS.TICKETS),
    ];
  }

  if (
    userInfo?.allowFibermap &&
    allowedPermissions.includes(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.OLT_MONITORING.KEY,
    )
  ) {
    let roleBasedPermission: string[] = [];
    Object.values(PERMISSIONS.OLT.DASHBOARD)?.forEach(
      (oltDashboardValue: string) => {
        if (roleBasedSubscriptions?.indexOf(oltDashboardValue) > -1) {
          roleBasedPermission.push(oltDashboardValue);
        }
      },
    );
    Object.values(PERMISSIONS.SPLITTER)?.forEach((splitterData: string) => {
      if (roleBasedSubscriptions?.indexOf(splitterData) > -1) {
        roleBasedPermission.push(splitterData);
      }
    });
    allowedPermissions = [...allowedPermissions, ...roleBasedPermission];
  }

  //  Handle Subscription and Role based Additional Features
  if (
    allowedPermissions.includes(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES
        .COMPLETE_OPTICAL_NETWORK_OBSERVABILITY.KEY,
    ) &&
    ([
      ROLES.ISP_BILLING_ADMIN,
      ROLES.ISP_ADMIN,
      ROLES.TRIAL_ISP_ADMIN,
      ROLES.TRIAL_FRANCHISEE_ADMIN,
    ].includes(role) ||
      ([ROLES.FRANCHISEE_BILLING_ADMIN, ROLES.FRANCHISEE_ADMIN].includes(
        role,
      ) &&
        userInfo?.parentManagementEntity?.type === EntityType.CHANNEL_PARTNER))
  ) {
    allowedPermissions = [
      ...allowedPermissions,
      PERMISSIONS.MENUS.OLT_SCHEDULE,
      ...Object.values(PERMISSIONS.OLT_SCHEDULE),
    ];
  }

  // Enable Franchisee for Trial ISP with Platinum Plan
  if (
    userInfo?.role === ROLES.TRIAL_ISP_ADMIN &&
    userInfo?.subscription?.package?.name === PACKAGE_NAME.PLATINUM
  ) {
    allowedPermissions = [
      ...allowedPermissions,
      PERMISSIONS.MENUS.FRANCHISEE,
      PERMISSIONS.FRANCHISEE.DASHBOARD,
      PERMISSIONS.FRANCHISEE.VIEW,
      PERMISSIONS.FRANCHISEE.CREATE,
      PERMISSIONS.FRANCHISEE.EDIT,
      PERMISSIONS.FRANCHISEE.DELETE,
    ];
  }

  setAllowedPermissions(allowedPermissions);
  return allowedPermissions;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetToInit: (state: AuthState) => {
      state.loading = true;
    },
    setSession: (state: AuthState, { payload }: Action) => {
      storeSessionToken(payload.accessToken);
    },
    setReturnTo: (state: AuthState, { payload }: { payload: string }) => {
      state.returnTo = payload;
      localStorage.setItem('returnTo', payload);
    },
    destroySession: (state: AuthState) => {
      state.authorized = false;
      state.userInfo = emptyUser;
      state.mannualTrigger = true;
      state.homeLink = '';
      state.selectedLoginUserRole = {} as IUserRoles;
      state.subscriptionStatus = SUBSCRIPTION_STATE.PENDING;
      resetSecurityToken();
      deleteFCMToken();
    },
    correctHomeLink: (state: AuthState | any) => {
      state.homeLink = getRoleBasedHome(state);
    },
    setLoginUserRole: (state: AuthState, { payload }: Action) => {
      state.selectedLoginUserRole = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      loadExistingSession.fulfilled,
      (state, { payload }: { payload: IAuthUserInfo | any }) => {
        state.loading = false;
        if (payload) {
          state.userInfo = payload;
          state.allowedPermissions = updateRolesAndPermissions(payload);
          state.homeLink = getRoleBasedHome(payload);
          if (
            [
              ROLES.PLATFORM_ADMIN,
              ROLES.CP_ADMIN,
              // ROLES.DISTRIBUTOR_ADMIN,
            ].includes(payload.role)
          ) {
            state.subscriptionStatus = SUBSCRIPTION_STATE.ACTIVE;
          } else if (
            ([
              ROLES.ISP_ADMIN,
              ROLES.FRANCHISEE_ADMIN,
              ROLES.BILLING_ADMIN,
              ROLES.SUBSCRIBER_GROUP_ADMIN,
              ROLES.FIELD_AGENT,
              ROLES.FIRMWARE_MANAGEMENT,
              ROLES.SUPPORT_AGENT,
              ROLES.TRIAL_ISP_ADMIN,
              ROLES.TRIAL_FRANCHISEE_ADMIN,
            ].includes(payload.role) &&
              isEmpty(payload?.subscription)) ||
            ([ROLES.TRIAL_ISP_ADMIN, ROLES.TRIAL_FRANCHISEE_ADMIN].includes(
              payload.role,
            ) &&
              payload?.trialDetails?.status === TRIAL_STATUS.COMPLETED)
          ) {
            state.subscriptionStatus = SUBSCRIPTION_STATE.PENDING;
          } else {
            state.subscriptionStatus =
              payload?.subscription?.state || SUBSCRIPTION_STATE.ACTIVE;
          }
          state.authorized = true;
        } else {
          state.authorized = false;
          state.userInfo = emptyUser;
        }
        state.mannualTrigger = false;
      },
    );
  },
});

export const AuthActions = {
  ...authSlice.actions,
  loadExistingSession,
  registerFirebaseToken,
};

export const AuthReducer = authSlice.reducer;
