import {
  IIpReachability,
  IOLTBasicDetailsForm,
  IOLTConfigurationForm,
  IOLTFormReduxState,
  IOLTTelnetReachabilityStatus,
  IPendingOLTData,
  ISNMPReachabilityStatus,
  OLTFormTabs,
} from 'store/types/olt.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AuthState } from 'store/types';
import { AxiosResponse } from 'axios';
import { OperatorService } from 'apiUtils/services/operator.service';
import { formatError } from 'apiUtils/api';
import i18n from 'i18n';
import { isEmpty } from 'lodash';

interface IDropdownListQueryParams {
  managementEntityId?: string;
}
const initialState: IOLTFormReduxState = {
  ipReachabilityStatus: {} as IIpReachability,
  telnetReachabilityData: {} as IOLTTelnetReachabilityStatus,
  SNMPReachabilityStatus: {} as ISNMPReachabilityStatus,
  oltBasicDetailsForm: {} as IOLTBasicDetailsForm,
  oltConfigurationForm: {} as IOLTConfigurationForm,
  pendingOLTData: {} as IPendingOLTData,
  currentStepId: 0,
  currentTabId: OLTFormTabs.BASIC_DETAILS,
  formValidationListenerKey: null,
  submitRequestError: '',
  isSbmitLoading: false,
  isNextButtonDisabled: false,
  dropdownList: { vlanList: { loading: false, error: null, data: null } },
};

const fetchVLANList = createAsyncThunk(
  'oltForm/vlanList/fetch',
  async (payload: IDropdownListQueryParams | void, thunkAPI) => {
    const { auth }: { auth: AuthState } = thunkAPI.getState() as any;
    const managementEntityId =
      payload?.managementEntityId || auth?.userInfo?.managementEntity?.id;
    const response = await OperatorService.v1.GetVLANListByMgmtEntity({
      managementEntityId,
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.vlanList'),
      };
    }
  },
);

export const OLTFormSlice = createSlice({
  name: 'OLTFormSlice',
  initialState,
  reducers: {
    resetToInit: () => initialState,

    setIpReachabilityStatus: (
      state,
      { payload }: { payload: IIpReachability },
    ) => {
      state.ipReachabilityStatus = payload;
    },

    setTelnetReachabilityState: (
      state,
      { payload }: { payload: IOLTTelnetReachabilityStatus },
    ) => {
      state.telnetReachabilityData = payload;
    },

    setSNMPReachabilityState: (
      state,
      { payload }: { payload: ISNMPReachabilityStatus },
    ) => {
      state.SNMPReachabilityStatus = payload;
    },
    setCurrentStepId: (state, { payload }: { payload: number }) => {
      state.formValidationListenerKey = null;
      state.submitRequestError = '';
      state.currentStepId = payload;
      state.isSbmitLoading = false;
    },
    setCurrentTabId: (state, { payload }: { payload: string }) => {
      state.formValidationListenerKey = null;
      state.submitRequestError = '';
      state.currentTabId = payload;
      state.isSbmitLoading = false;
      state.isNextButtonDisabled = false;
    },
    setOLTFormValues: (
      state,
      {
        payload,
      }: {
        payload: {
          formName: OLTFormTabs;
          values: any;
        };
      },
    ) => {
      if (payload?.formName === OLTFormTabs.BASIC_DETAILS) {
        state.oltBasicDetailsForm = payload.values;
      } else if (payload?.formName === OLTFormTabs.CONFIGURATION) {
        state.oltConfigurationForm = payload.values;
      }
    },
    setFormValidationListenerKey: (state) => {
      state.formValidationListenerKey = Math.random();
    },

    setForSubmitRequestError: (state, { payload }: { payload: string }) => {
      state.submitRequestError = payload;
    },

    setIsNextButtonDisabled: (state, { payload }: { payload: boolean }) => {
      state.isNextButtonDisabled = payload;
    },

    setIsFormSubmitLoading: (state, { payload }: { payload: boolean }) => {
      state.isSbmitLoading = payload;
    },

    setPendingOLTData: (state, { payload }: { payload: IPendingOLTData }) => {
      state.pendingOLTData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVLANList.pending, (state: IOLTFormReduxState) => {
      state.dropdownList.vlanList = { loading: true, error: null, data: null };
    });
    builder.addCase(
      fetchVLANList.fulfilled,
      (
        state: IOLTFormReduxState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.dropdownList.vlanList.loading = false;
        if (isEmpty(payload?.error)) {
          state.dropdownList.vlanList.data = payload?.vlanIds;
        } else {
          state.dropdownList.vlanList.error = payload?.error;
        }
      },
    );
  },
});

export const OLTFormAction = {
  ...OLTFormSlice.actions,
  fetchVLANList,
};

export const OLTFormSliceReducer = OLTFormSlice.reducer;
