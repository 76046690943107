import {
  IFiltersDropdown,
  IFiltersDropdownOptions,
  IManagementEntityDetails,
  IOptions,
  IPagination,
  OnboardingState,
  Subscriber_Tabs,
} from 'store/types';
import {
  ISubscriber,
  ISubscriberFilters,
  ISubscriberGroupListItem,
  ISubscriberList,
  ISubscriberListState,
  SUBSCRIBER_GROUP_ACTIONS,
  TSubscriberTab,
} from 'store/types/subscriber.types';
import {
  OperatorRequester,
  VizRequester,
  formatError,
  formatURL,
} from 'apiUtils/api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { IOLTListItems } from 'store/types/olt.types';
import { PERMISSIONS } from 'utils/roles&Permissions';
import { SUBSCRIPTION_STATE } from 'store/types/subscription.types';
import { hasPermission } from 'components/utilities/RoleBasedComponent';
import { isEmpty } from 'lodash';

const fetchSubscriberList = createAsyncThunk(
  'subscriberList/fetch',
  async (_, thunkAPI) => {
    let {
      subscribersListState,
    }: { subscribersListState: ISubscriberListState } =
      thunkAPI.getState() as any;

    let subscriberGroupParams = undefined;
    if (
      subscribersListState?.subscriberGroupOptions?.actionType ===
      SUBSCRIBER_GROUP_ACTIONS.ADD_SUBSCRIBERS
    ) {
      subscriberGroupParams = {
        excludeBySubGroupId: subscribersListState?.subscriberGroupOptions?.sgId,
      };
    }

    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.subscribers,
      {
        params: {
          onboardingStatus:
            subscribersListState?.currentTabId === Subscriber_Tabs.PENDING
              ? OnboardingState.PENDING
              : OnboardingState.CONFIGURED,
          ...subscriberGroupParams,
          ...subscribersListState?.subscriberListPagination,
          ...subscribersListState?.subscriberListFilter,
        },
      },
    ).catch(({ response }) => response);
    if (response.status === 200) {
      return response.data;
    } else {
      return {
        error:
          formatError(response) || 'Something went wrong. Please try again',
      };
    }
  },
);

const getSubscriberGroupData = createAsyncThunk(
  'getSubscriberGroupById/get',
  async (_, thunkAPI) => {
    let {
      subscribersListState,
    }: { subscribersListState: ISubscriberListState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await OperatorRequester.get(
      formatURL(API_END_POINTS.OPERATORS.subscriberGroupById, {
        subscriberGroupId: subscribersListState?.subscriberGroupOptions?.sgId,
      }),
    ).catch(({ response }) => response);
    if (response.status === 200) {
      return response.data;
    } else {
      return {
        error:
          formatError(response) || 'Error in fetching Subscriber Group details',
      };
    }
  },
);

const initialState: ISubscriberListState = {
  subscriberFilterDropDownList: {
    managementEntitiesList: [] as IManagementEntityDetails[],
    OLTList: [] as IOLTListItems[],
    vlanList: [] as string[],
    subscriberGroupDropDownList: [] as IFiltersDropdownOptions[],
    offlineDurationList: [] as IOptions[],
    offlineReasonCodeList: [] as IFiltersDropdownOptions[],
    manufacturerList: [] as string[],
    hardwareVersionList: [] as IFiltersDropdownOptions[],
    hardwareModelList: {} as IFiltersDropdown,
    pincodeList: [] as string[],
  },
  hardRefresh: false,
  loading: true,
  error: '',
  data: {} as ISubscriberList,
  subscriberListFilter: {} as ISubscriberFilters,
  subscriberListPagination: { pageNumber: 1, pageSize: 10, pageToken: '' },
  currentTabId: '',
  subscriberGroupOptions: null,
  selectedSubscriberGroupData: {
    loading: false,
    error: '',
    data: {} as ISubscriberGroupListItem,
  },
  selectedSubscriberRows: [] as ISubscriber[],
  isFilterOpen: false,
};

export const SubscriberListSlice = createSlice({
  name: 'SubscriberList',
  initialState,
  reducers: {
    resetToInit: () => initialState,

    resetSubscriberListState: (state: ISubscriberListState) => {
      state.hardRefresh = true;
      state.error = '';
      state.data = {} as ISubscriberList;
      state.subscriberListFilter = {} as ISubscriberFilters;
      state.subscriberListPagination = {
        pageNumber: 1,
        pageSize: 10,
        pageToken: '',
      };
      state.subscriberGroupOptions = null;
      state.selectedSubscriberGroupData = {
        loading: false,
        error: '',
        data: {} as ISubscriberGroupListItem,
      };
      state.selectedSubscriberRows = [] as ISubscriber[];
    },

    setCurrentTabId: (
      state: ISubscriberListState,
      { payload }: { payload: TSubscriberTab | string },
    ) => {
      state.currentTabId = payload;
      state.hardRefresh = true;
      state.error = '';
      state.data = {} as ISubscriberList;
      state.subscriberListPagination = {
        pageNumber: 1,
        pageSize: 10,
        pageToken: '',
      };
      //On tab change to set subscription active state as default for PLATFORM Users
      state.subscriberListFilter = hasPermission(
        PERMISSIONS.SUBSCRIBER_FILTERS.SUBSCRIPTION_STATE,
      )
        ? {
            subscriptionState: SUBSCRIPTION_STATE.ACTIVE,
          }
        : {};

      state.selectedSubscriberRows = [] as ISubscriber[];
    },

    setPageNumber: (
      state: ISubscriberListState,
      { payload }: { payload: number },
    ) => {
      state.subscriberListPagination.pageNumber = payload;
      state.data.items = [];
    },

    setPageSize: (
      state: ISubscriberListState,
      { payload }: { payload: number },
    ) => {
      state.subscriberListPagination.pageNumber = 1;
      state.subscriberListPagination.pageToken = '';
      state.subscriberListPagination.pageSize = payload;
    },

    setSubscriberListPagination: (
      state: ISubscriberListState,
      action: PayloadAction<IPagination>,
    ) => {
      state.subscriberListPagination = action.payload;
    },

    setSubscriberListFilters: (state: ISubscriberListState, action) => {
      if (isEmpty(action?.payload)) {
        if (
          state.subscriberGroupOptions?.actionType ===
          SUBSCRIBER_GROUP_ACTIONS.DROP_SUBSCRIBERS
        ) {
          state.subscriberListFilter = {
            subGroupId: state.subscriberListFilter?.subGroupId,
          };
        } else {
          state.subscriberListFilter = {};
        }
      } else {
        state.subscriberListFilter = Object.assign(
          state.subscriberListFilter,
          action.payload,
        );
      }
      state.hardRefresh = true;
      state.error = '';
      state.subscriberListPagination.pageNumber = 1;
      state.subscriberListPagination.pageToken = '';
    },

    setManagementEntitiesList: (
      state: ISubscriberListState,
      { payload }: { payload: IManagementEntityDetails[] },
    ) => {
      state.subscriberFilterDropDownList.managementEntitiesList = payload;
    },

    setOLTList: (
      state: ISubscriberListState,
      { payload }: { payload: IOLTListItems[] },
    ) => {
      state.subscriberFilterDropDownList.OLTList = payload;
    },

    setVlanList: (
      state: ISubscriberListState,
      { payload }: { payload: string[] },
    ) => {
      state.subscriberFilterDropDownList.vlanList = payload;
    },

    setSubscriberGroupDropDownList: (
      state: ISubscriberListState,
      { payload }: { payload: IFiltersDropdownOptions[] },
    ) => {
      state.subscriberFilterDropDownList.subscriberGroupDropDownList = payload;
    },

    setOfflineDurationList: (
      state: ISubscriberListState,
      { payload }: { payload: IOptions[] },
    ) => {
      state.subscriberFilterDropDownList.offlineDurationList = payload;
    },

    setOfflineReasonCodeList: (
      state: ISubscriberListState,
      { payload }: { payload: IOptions[] },
    ) => {
      state.subscriberFilterDropDownList.offlineReasonCodeList = payload;
    },

    setManufacturerList: (
      state: ISubscriberListState,
      { payload }: { payload: string[] },
    ) => {
      state.subscriberFilterDropDownList.manufacturerList = payload;
    },
    setPincodeList: (
      state: ISubscriberListState,
      { payload }: { payload: string[] },
    ) => {
      state.subscriberFilterDropDownList.pincodeList = payload;
    },

    setHardwareModel: (
      state: ISubscriberListState,
      { payload }: { payload: IFiltersDropdown },
    ) => {
      state.subscriberFilterDropDownList.hardwareModelList = payload;
    },

    setHardwareVersionList: (
      state: ISubscriberListState,
      { payload }: { payload: IFiltersDropdownOptions[] },
    ) => {
      state.subscriberFilterDropDownList.hardwareVersionList = payload;
    },

    setHardRefresh: (
      state: ISubscriberListState,
      { payload }: { payload: boolean },
    ) => {
      state.hardRefresh = payload;
    },

    setSubscriberGroupOptions: (
      state: ISubscriberListState,
      {
        payload,
      }: {
        payload: {
          sgId?: string | null;
          actionType: SUBSCRIBER_GROUP_ACTIONS | null;
        } | null;
      },
    ) => {
      state.subscriberGroupOptions = payload;
    },

    setSelectedSubscriberRows: (
      state: ISubscriberListState,
      { payload }: { payload: ISubscriber[] },
    ) => {
      state.selectedSubscriberRows = payload;
    },

    setIsFilterOpen: (
      state: ISubscriberListState,
      { payload }: { payload: boolean },
    ) => {
      state.isFilterOpen = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      fetchSubscriberList.pending,
      (state: ISubscriberListState) => {
        state.loading = true;
        state.error = '';
        state.data = {} as ISubscriberList;
      },
    );

    builder.addCase(
      fetchSubscriberList.fulfilled,
      (state, { payload }: { payload: ISubscriberList | any }) => {
        state.loading = false;
        state.hardRefresh = false;
        if (isEmpty(payload?.error)) {
          state.data = payload;
          state.subscriberListPagination.pageToken = payload?.pageToken || '';
        } else {
          state.error = payload?.error;
        }
      },
    );

    builder.addCase(
      getSubscriberGroupData.pending,
      (state: ISubscriberListState) => {
        state.selectedSubscriberGroupData.loading = true;
      },
    );

    builder.addCase(
      getSubscriberGroupData.fulfilled,
      (state, { payload }: { payload: ISubscriberList | any }) => {
        state.selectedSubscriberGroupData.loading = false;
        if (isEmpty(payload?.error)) {
          state.selectedSubscriberGroupData.data = payload;
        } else {
          state.selectedSubscriberGroupData.error = payload?.error;
        }
      },
    );
  },
});

export const subscriberListActions = {
  ...SubscriberListSlice.actions,
  fetchSubscriberList,
  getSubscriberGroupData,
};

export const subscriberListReducer = SubscriberListSlice.reducer;

export const DefaultSubscriberFilterFields = [
  'pageSize',
  'pageNumber',
  'pageToken',
  'oltPortNumber',
  'onboardingStatus',
  'excludeBySubGroupId',
];
