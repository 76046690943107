import '../../styles/AppNotificationBar.less';

import {
  APP_NOTIFICATION_COLORS,
  APP_NOTIFICATION_ICONS,
} from '../../constants';
import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import { CommonActions, RootState, useAppDispatch } from 'store/redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAppNotifications } from 'store/types';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AppNotificationBar = () => {
  const { common } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    (!isEmpty(common.appNotificationMessages) && (
      <Space
        direction='vertical'
        size={1}
        className={
          common.appNotificationMessages?.length
            ? 'app-notify-banner-container'
            : ''
        }
      >
        {common.appNotificationMessages?.map(
          (notifyData: IAppNotifications, idx: number) => {
            return (
              <div
                id={'app-notification-bar'}
                key={`${notifyData?.key}-${idx}`}
                className='app-notification-bar'
                style={{
                  background: APP_NOTIFICATION_COLORS[notifyData?.type],
                }}
              >
                <Row justify={'space-between'} align={'middle'} wrap>
                  <Col
                    flex={
                      !common.mobileView && isEmpty(notifyData?.extraActions)
                        ? 1
                        : undefined
                    }
                    span={
                      common.mobileView && !isEmpty(notifyData?.extraActions)
                        ? 20
                        : undefined
                    }
                  >
                    <Space>
                      {/* 1. By default Icon should be visible. If it is null or undefined need to show Icon 
                          2. Hide icon only if showIcon value is "false".
                      */}
                      {notifyData?.showIcon === false ? null : (
                        <FontAwesomeIcon
                          icon={APP_NOTIFICATION_ICONS[notifyData?.type]}
                          className={'notification-type-icon'}
                        />
                      )}
                      <div className={'notification-message'}>
                        {typeof notifyData?.message === 'string' ? (
                          <Typography.Text>
                            {notifyData?.message}
                          </Typography.Text>
                        ) : (
                          notifyData?.message
                        )}
                      </div>
                    </Space>
                  </Col>
                  <Col
                    span={
                      common.mobileView && !isEmpty(notifyData?.extraActions)
                        ? 4
                        : undefined
                    }
                  >
                    <Space wrap size={4}>
                      {!isEmpty(notifyData?.extraActions) ? (
                        notifyData?.extraActions?.map((val) => {
                          return (
                            <div
                              key={val?.key}
                              className={'notify-action-buttons'}
                            >
                              <Button
                                size={common.mobileView ? 'small' : 'middle'}
                                onClick={() => {
                                  if (val?.navigateTo) {
                                    navigate(val?.navigateTo);
                                  }
                                }}
                                {...val.buttonProps}
                              >
                                {val?.buttonTitle}
                              </Button>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      {(notifyData?.closable && (
                        <Tooltip title={t('labels.close')}>
                          <FontAwesomeIcon
                            icon={faXmark}
                            className={'close-icon'}
                            onClick={() =>
                              dispatch(
                                CommonActions.removeItemFromAppNotifications(
                                  notifyData?.key,
                                ),
                              )
                            }
                          />
                        </Tooltip>
                      )) ||
                        null}
                    </Space>
                  </Col>
                </Row>
              </div>
            );
          },
        )}
      </Space>
    )) ||
    null
  );
};

export default AppNotificationBar;
