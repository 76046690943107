import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export const API_VERSIONS = {
  '0.1': '0.1',
  v1: 'v1',
  v2: 'v2',
};

export type TAPI_VERSION = keyof typeof API_VERSIONS;

export const getRequest = async (
  axiosInstance: AxiosInstance,
  url: string,
  config?: AxiosRequestConfig,
) => {
  const response: AxiosResponse = await axiosInstance
    .get(url, config)
    .catch(({ response }) => response);
  return response;
};

export const postRequest = async (
  axiosInstance: AxiosInstance,
  url: string,
  data: any,
  config?: AxiosRequestConfig,
) => {
  const response: AxiosResponse = await axiosInstance
    .post(url, data, config)
    .catch(({ response }) => response);
  return response;
};

export const putRequest = async (
  axiosInstance: AxiosInstance,
  url: string,
  data: any,
  config?: AxiosRequestConfig,
) => {
  const response: AxiosResponse = await axiosInstance
    .put(url, data, config)
    .catch(({ response }) => response);
  return response;
};

export const patchRequest = async (
  axiosInstance: AxiosInstance,
  url: string,
  data: any,
  config?: AxiosRequestConfig,
) => {
  const response: AxiosResponse = await axiosInstance
    .patch(url, data, config)
    .catch(({ response }) => response);
  return response;
};

export const deleteRequest = async (
  axiosInstance: AxiosInstance,
  url: string,
  config?: AxiosRequestConfig,
) => {
  const response: AxiosResponse = await axiosInstance
    .delete(url, config)
    .catch(({ response }) => response);
  return response;
};
