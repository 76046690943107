import {
  APP_NOTIFICATION_KEYS,
  AuthState,
  CommonState,
  IAppNotifications,
  THEME,
} from 'store/types';
import { IOLTBridgeStatus, OLT_BRIDGE_STATUS } from 'store/types/olt.types';
import { OperatorRequester, formatError, formatURL } from 'apiUtils/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { PERMISSIONS } from 'utils/roles&Permissions';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SUBSCRIPTION_STATE } from 'store/types/subscription.types';
import { hasPermission } from 'components/utilities/RoleBasedComponent';
import { isEmpty } from 'lodash';

const initialState: CommonState = {
  theme: THEME.FIIANALYTICS,
  tabTitle: '',
  sideBarCollapsed: true,
  betaWaning: false,
  mobileView: false,
  tabletView: false,
  landscape: false,
  offline: false,
  twa: false,
  versionCheckCompleted: false,
  appNotificationMessages: [] as IAppNotifications[],
  showMenuAndHeader: false,
  logoClassName: '',
  oltBridgeStatus: {
    data: {} as IOLTBridgeStatus,
  },
  isOLTBridgeStatusUp: false,
};

export const loadOLTBridgeStatus = createAsyncThunk(
  'oltBridgeStatus/fetch',
  async (_, thunkAPI) => {
    const { auth }: { auth: AuthState } = thunkAPI.getState() as any;

    if (
      !isEmpty(auth?.userInfo?.subscription) &&
      auth?.subscriptionStatus === SUBSCRIPTION_STATE.ACTIVE &&
      hasPermission(
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES
          .COMPLETE_OPTICAL_NETWORK_OBSERVABILITY.KEY,
      )
    ) {
      const response = await OperatorRequester.get(
        formatURL(API_END_POINTS.OPERATORS.OLTBridgeStatus, {
          managementEntityId: auth?.userInfo?.managementEntity?.id,
        }),
      ).catch(({ response }) => response);
      if (response?.status === 200) {
        return response?.data;
      } else {
        return {
          error:
            formatError(response) ||
            'Error in fetching OLT Bridge status. Please try again',
        };
      }
    }
  },
);

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    resetToInit: (state: CommonState) => {
      state.tabTitle = '';
    },
    clearAuthBasedState: (state: CommonState) => {
      state.appNotificationMessages = [] as IAppNotifications[];
      state.showMenuAndHeader = false;
    },
    toggleSideBar: (
      state: CommonState,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      if (payload !== undefined) {
        state.sideBarCollapsed = payload;
      } else {
        state.sideBarCollapsed = !state.sideBarCollapsed;
      }
    },
    setMobileView: (state: CommonState, { payload }: { payload: any }) => {
      state.mobileView = payload.mobileView;
      state.tabletView = payload.tabletView;
      state.landscape = payload.landscape;
    },
    setTitle: (state: CommonState, { payload }: PayloadAction<string>) => {
      state.tabTitle = payload;
    },
    setBetaWarning: (
      state: CommonState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.betaWaning = payload;
    },
    setOffline: (state: CommonState, { payload }: PayloadAction<boolean>) => {
      state.offline = payload;
    },
    setTWA: (state: CommonState, { payload }: PayloadAction<boolean>) => {
      state.twa = payload;
    },
    setTheme: (state: CommonState, { payload }: PayloadAction<THEME>) => {
      state.theme = payload || THEME.FIIANALYTICS;
    },
    setLogoClassName: (state: CommonState) => {
      //Temperory Logo Fix for kumarBbs and fiberX
      if (
        [THEME.FIIANALYTICS, THEME.FIBERX, THEME.KUMAR_BBS].includes(
          state.theme,
        )
      ) {
        state.logoClassName = state.twa ? 'fii-Logo-twa' : 'fii-Logo';
      }
    },
    setVersionCheckCompleted: (
      state: CommonState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.versionCheckCompleted = payload;
    },
    setAppNotifications: (
      state: CommonState,
      { payload }: PayloadAction<IAppNotifications>,
    ) => {
      const itemIndex = state?.appNotificationMessages?.findIndex(
        (val) => val?.key === payload?.key,
      );
      if (itemIndex === -1) {
        state.appNotificationMessages.push(payload);
      } else {
        state.appNotificationMessages[itemIndex] = payload;
      }
    },
    removeItemFromAppNotifications: (
      state: CommonState,
      { payload }: PayloadAction<APP_NOTIFICATION_KEYS>,
    ) => {
      const itemIndex = state?.appNotificationMessages?.findIndex(
        (val) => val?.key === payload,
      );
      if (itemIndex !== -1) {
        state.appNotificationMessages.splice(itemIndex, 1);
      }
    },
    setShowMenuAndHeader: (
      state: CommonState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.showMenuAndHeader = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadOLTBridgeStatus.pending, (state: any) => {
      state.oltBridgeStatus.data = {};
    });
    builder.addCase(
      loadOLTBridgeStatus.fulfilled,
      (state: CommonState, { payload }: { payload: AxiosResponse | any }) => {
        if (isEmpty(payload?.error)) {
          state.oltBridgeStatus.data = payload;
          state.isOLTBridgeStatusUp = payload?.status === OLT_BRIDGE_STATUS.UP;
        }
      },
    );
  },
});

export const CommonActions = { ...commonSlice.actions, loadOLTBridgeStatus };

export const CommonReducer = commonSlice.reducer;
