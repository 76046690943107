import { RootState, useAppDispatch } from './../../store/redux/store';
import { useDeviceSelectors, useMobileOrientation } from 'react-device-detect';
import { useEffect, useState } from 'react';

import { CommonActions } from 'store/redux';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

export const useDetectDevice = () => {
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const { common } = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const { isLandscape } = useMobileOrientation();
  const [windowWidth, setWindowWidth] = useState(0);
  const setBouncedWindowWidth = debounce(setWindowWidth, 100);

  const onResize = (e: any) => {
    setBouncedWindowWidth(
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0,
      ),
    );
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    dispatch(
      CommonActions.setMobileView({
        mobileView: selectors.isMobile,
        tabletView: selectors.isTablet,
        landscape: isLandscape,
      }),
    );
  }, [selectors.isMobile, selectors.isTablet, isLandscape]);

  useEffect(() => {
    if (windowWidth && windowWidth <= 1250) {
      dispatch(
        CommonActions.setMobileView({
          mobileView: true,
          tabletView: selectors.isTablet,
          landscape: isLandscape,
        }),
      );
    } else {
      dispatch(
        CommonActions.setMobileView({
          mobileView: selectors.isMobile,
          tabletView: selectors.isTablet,
          landscape: isLandscape,
        }),
      );
    }
  }, [windowWidth]);

  useEffect(() => {
    if (isLandscape) {
      window.document.body.classList.add('landscape');
    } else {
      window.document.body.classList.remove('landscape');
    }
  }, [isLandscape]);

  useEffect(() => {
    if (common.mobileView) {
      window.document.body.classList.add('mobileView');
    } else {
      window.document.body.classList.remove('mobileView');
    }
    if (common.tabletView) {
      window.document.body.classList.add('tabletView');
    } else {
      window.document.body.classList.remove('tabletView');
    }
  }, [common.mobileView, common.tabletView]);
};
