import { API_VERSIONS, TAPI_VERSION, putRequest } from 'apiUtils/axiosRequest';
import { createAxiosInstance, formatURL } from 'apiUtils/api';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { IOLTDetailsForm } from 'store/types/subscriber.types';

const OLT_ONBOARDING_BASE_URL = process.env.API_OLT_ONBOARDING?.replace(
  'v1/',
  '',
);
const OLTOnboardingInstance = createAxiosInstance(OLT_ONBOARDING_BASE_URL);

interface ISubscriberIdPathParam {
  subscriberId: string;
}

export interface IOLTOnboardingService {
  UpdateSubscriberOLTDetails: (
    pathParam: ISubscriberIdPathParam,
    payload: IOLTDetailsForm | null,
  ) => Promise<AxiosResponse>;
}

export const OLTOnboardingService: {
  [key in TAPI_VERSION]: IOLTOnboardingService;
} = Object.keys(API_VERSIONS)?.reduce((res: any, el: any) => {
  const version = API_VERSIONS[el as keyof typeof API_VERSIONS];

  return {
    ...res,
    [el]: {
      UpdateSubscriberOLTDetails: async (
        pathParam: ISubscriberIdPathParam,
        payload: IOLTDetailsForm | null,
      ) => {
        return await putRequest(
          OLTOnboardingInstance,
          `${version}${formatURL(
            API_END_POINTS.OLT_ONBOARDING.networkDetails,
            pathParam,
          )}`,
          payload,
        );
      },
    } as IOLTOnboardingService,
  };
}, {});
