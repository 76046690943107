import {
  IOLTDashboardDeviceStats,
  IOLTDashboardStats,
  IOLTOpticalTXPONOverviewStats,
  IOLTOpticalTXPONPortTimeSeries,
  IONTDevicesList,
  IPONTrafficStats,
  IPonPorts,
  IUplinkPortInfo,
  IUplinkTrafficStats,
  ONT_STATS_CONNECTION_STATUS,
} from 'store/types/olt.types';
import { PON_LABEL, VLAN_ID_MAX_VALUE } from 'store/types';

import { generateRandomInteger } from 'mocks/mock.utils';
import moment from 'moment';

const opticalTXPONOverviewTotal = 32;
const opticalTXPONPortTimeSeriesTotal = 10;

const oltCardsLength = 3;
const ponPortsLength = 16;
const ponPortsTotal = (oltCardsLength || 1) * ponPortsLength;
const uplinkPortsTotal = 8;
const portsList: IPonPorts[] = [];

for (let cardIndex = 0; cardIndex < oltCardsLength; cardIndex++) {
  for (let portIndex = 0; portIndex < ponPortsLength; portIndex++) {
    portsList.push({
      name: `PON ${portIndex + 1}`,
      number: portIndex + 1,
      portIndex: `1/${cardIndex + 1}/${portIndex + 1}`,
      linkStatus:
        portIndex < generateRandomInteger(ponPortsLength) ? 'up' : 'down',
    } as IPonPorts);
  }
}

export const OLT_DASHBOARD_MOCK: IOLTDashboardStats = {
  lastUpdatedAt: moment()?.subtract(1, 'd').toISOString(),
  // managementEntityId: 'f0f9ba21ed544c89aec54d4484cdd763',
  // ponType: PON_TYPE.EPON,
  // name: 'FiiLab-gpon-olt',
  manufacturer: 'Netlink',
  model: 'V1600G',
  ipAddress: '192.168.8.200',
  label: 'Fii-lab-gpon',
  mac: '14:A7:2B:A2:AF:75',
  uplinkPortsStats: {
    total: uplinkPortsTotal,
    up: generateRandomInteger(uplinkPortsTotal),
    down: generateRandomInteger(uplinkPortsTotal),
  },
  uplinkPorts: Array.from({ length: uplinkPortsTotal }, (_, idx: number) => {
    return {
      name: `GE${idx + 1}`,
      portIndex: `${idx + 1}`,
      number: idx + 1,
      linkStatus: idx % 2 === 0 ? 'up' : 'down',
      type: '',
      speed: '',
    } as IUplinkPortInfo;
  }),
  ponPortsStats: {
    total: ponPortsTotal,
    up: generateRandomInteger(ponPortsTotal),
    down: generateRandomInteger(ponPortsTotal),
  },
  ponPorts: portsList,
  cards: Array.from({ length: oltCardsLength }, (_, idx: number) => {
    return {
      hardwareVersion: 'V2.1.4',
      softwareVersion: 'V2.2.5R',
      uptime: moment()
        ?.subtract((idx + 1) * 2, 'day')
        ?.millisecond(),
      // uptime: 797622, //9d:5h:33m
      // // uptime: 466815, //5d:9h:40m
      type: idx % 2 === 0 ? 'GTGH' : 'SMXA',
      ports: ponPortsTotal,
      status: idx % 2 === 0 ? 'Online' : 'Offline',
      role: idx % 2 === 0 ? 'Main' : 'Standby',
      cpuUsage: generateRandomInteger(100, 0, true),
      memoryUsage: generateRandomInteger(100, 0, true), //61.843911839094304,
      temperature: generateRandomInteger(100, 0, true), // 32.703125,
      rack: 1,
      shelf: idx + 1,
      slot: 1,
    };
  }),
  configuredVLANs: Array.from({ length: generateRandomInteger(100) }, () => {
    return generateRandomInteger(VLAN_ID_MAX_VALUE);
  }),
  allowedVLANs: Array.from({ length: generateRandomInteger(100) }, () => {
    return generateRandomInteger(VLAN_ID_MAX_VALUE);
  }),
};

export const OLT_DASHBOARD_DEVICE_STATS_MOCK: IOLTDashboardDeviceStats = {
  deviceStats: { total: 20, online: 12, offline: 8 },
  offlineStats: {
    total: 8,
    poweredOff: 2,
    linkFault: 2,
    LOS: 2,
    unknown: 2,
  },
  poorSignal: 2,
};

export const OLT_OPTICAL_TX_PON_OVERVIEW_STATS_MOCK: IOLTOpticalTXPONOverviewStats[] =
  Array.from({ length: opticalTXPONOverviewTotal }, (_, idx) => {
    return {
      name: `${PON_LABEL} ${idx + 1}`,
      value: Number(Math.random()?.toString().substring(2, 4)),
    };
  });

export const OLT_OPTICAL_TX_PON_PORT_TIME_SERIES_MOCK: IOLTOpticalTXPONPortTimeSeries[] =
  Array.from({ length: opticalTXPONPortTimeSeriesTotal }, (_, idx) => {
    return {
      time: moment().add(idx, 'minutes').toISOString(),
      value: Number(Math.random()?.toString().substring(2, 4)),
    };
  });

export const UPLINK_TRAFFIC_DOWNLOAD_MOCK: IUplinkTrafficStats[] = [
  {
    time: '2023-12-13T01:00:00.000Z',
    ports: {
      GE1: 10624,
      GE2: 13042,
      GE3: 10153,
      GE4: 7578,
      GE5: 9986,
      GE6: 9410,
      GE7: 14953,
      GE8: 15656,
    },
  },
  {
    time: '2023-12-13T02:00:00.000Z',
    ports: {
      GE1: 9923,
      GE2: 12659,
      GE3: 10821,
      GE4: 10624,
      GE5: 5742,
      GE6: 9410,
      GE7: 15656,
      GE8: 14953,
    },
  },
  {
    time: '2023-12-13T03:00:00.000Z',
    ports: {
      GE1: 4414,
      GE2: 40345,
      GE3: 4823,
      GE4: 52134,
      GE5: 32555,
      GE6: 5654,
      GE7: 8665,
      GE8: 65544,
    },
  },
  {
    time: '2023-12-13T04:00:00.000Z',
    ports: {
      GE1: 4599,
      GE2: 175687,
      GE3: 175865,
      GE4: 5684,
      GE5: 3545,
      GE6: 5778,
      GE7: 246587,
      GE8: 54554,
    },
  },
  {
    time: '2023-12-13T05:00:00.000Z',
    ports: {
      GE1: 41645,
      GE2: 27944,
      GE3: 5555,
      GE4: 34455,
      GE5: 24545,
      GE6: 45645,
      GE7: 24878,
      GE8: 35445,
    },
  },
  {
    time: '2023-12-13T06:00:00.000Z',
    ports: {
      GE1: 81452,
      GE2: 10724,
      GE3: 35453,
      GE4: 53423,
      GE5: 45544,
      GE6: 23544,
      GE7: 4545,
      GE8: 42345,
    },
  },
  {
    time: '2023-12-13T07:00:00.000Z',
    ports: {
      GE1: 5589,
      GE2: 220181,
      GE3: 456456,
      GE4: 45646,
      GE5: 56874,
      GE6: 45686,
      GE7: 35655,
      GE8: 456868,
    },
  },
  {
    time: '2023-12-13T08:00:00.000Z',
    ports: {
      GE1: 8412,
      GE2: 4525,
      GE3: 2345,
      GE4: 6355,
      GE5: 5477,
      GE6: 4548,
      GE7: 7786,
      GE8: 24885,
    },
  },
  {
    time: '2023-12-13T09:00:00.000Z',
    ports: {
      GE1: 8145,
      GE2: 6279,
      GE3: 4575,
      GE4: 4576,
      GE5: 24574,
      GE6: 4535,
      GE7: 6856,
      GE8: 35657,
    },
  },
  {
    time: '2023-12-13T10:00:00.000Z',
    ports: {
      GE1: 69623,
      GE2: 54623,
      GE3: 25645,
      GE4: 42345,
      GE5: 28823,
      GE6: 32254,
      GE7: 45356,
      GE8: 35675,
    },
  },
  {
    time: '2023-12-13T11:00:00.000Z',
    ports: {
      GE1: 37478,
      GE2: 5087,
      GE3: 24577,
      GE4: 5377,
      GE5: 45354,
      GE6: 35378,
      GE7: 24544,
      GE8: 45345,
    },
  },
  {
    time: '2023-12-13T12:00:00.000Z',
    ports: {
      GE1: 44820,
      GE2: 1236871,
      GE3: 15454,
      GE4: 45345,
      GE5: 4534,
      GE6: 456345,
      GE7: 34534,
      GE8: 45454,
    },
  },
  {
    time: '2023-12-13T13:00:00.000Z',
    ports: {
      GE1: 11821,
      GE2: 839198,
      GE3: 452445,
      GE4: 78545,
      GE5: 45344,
      GE6: 6845,
      GE7: 8655,
      GE8: 45364,
    },
  },
  {
    time: '2023-12-13T14:00:00.000Z',
    ports: {
      GE1: 18866,
      GE2: 812075,
      GE3: 35564,
      GE4: 45456,
      GE5: 76878,
      GE6: 456876,
      GE7: 78656,
      GE8: 12442,
    },
  },
  {
    time: '2023-12-13T15:00:00.000Z',
    ports: {
      GE1: 9598,
      GE2: 486914,
      GE3: 1212,
      GE4: 424212,
      GE5: 24445,
      GE6: 4244,
      GE7: 45345,
      GE8: 4444,
    },
  },
  {
    time: '2023-12-13T16:00:00.000Z',
    ports: {
      GE1: 7077,
      GE2: 98568,
      GE3: 78456,
      GE4: 78656,
      GE5: 65645,
      GE6: 45678,
      GE7: 6895,
      GE8: 7865,
    },
  },
  {
    time: '2023-12-13T17:00:00.000Z',
    ports: {
      GE1: 10972,
      GE2: 645101,
      GE3: 7564,
      GE4: 456454,
      GE5: 653534,
      GE6: 784545,
      GE7: 766456,
      GE8: 45678,
    },
  },
  {
    time: '2023-12-13T18:00:00.000Z',
    ports: {
      GE1: 28801,
      GE2: 468404,
      GE3: 786456,
      GE4: 456456,
      GE5: 78654,
      GE6: 45457,
      GE7: 78645,
      GE8: 45678,
    },
  },
  {
    time: '2023-12-13T19:00:00.000Z',
    ports: {
      GE1: 14989,
      GE2: 609798,
      GE3: 5645,
      GE4: 4545,
      GE5: 454512,
      GE6: 45445,
      GE7: 78678,
      GE8: 45646,
    },
  },
  {
    time: '2023-12-13T20:00:00.000Z',
    ports: {
      GE1: 9345,
      GE2: 646184,
      GE3: 453453,
      GE4: 98645,
      GE5: 45678,
      GE6: 78944,
      GE7: 97866,
      GE8: 56856,
    },
  },
  {
    time: '2023-12-13T21:00:00.000Z',
    ports: {
      GE1: 12269,
      GE2: 676794,
      GE3: 786454,
      GE4: 768780,
      GE5: 96846,
      GE6: 78956,
      GE7: 45654,
      GE8: 78644,
    },
  },
  {
    time: '2023-12-13T22:00:00.000Z',
    ports: {
      GE1: 9571,
      GE2: 451492,
      GE3: 456865,
      GE4: 56566,
      GE5: 56588,
      GE6: 89865,
      GE7: 786545,
      GE8: 45686,
    },
  },
  {
    time: '2023-12-13T23:00:00.000Z',
    ports: {
      GE1: 9571,
      GE2: 451492,
      GE3: 53534,
      GE4: 45668,
      GE5: 865546,
      GE6: 456868,
      GE7: 8566,
      GE8: 45646,
    },
  },
  {
    time: '2023-12-13T24:00:00.000Z',
    ports: {
      GE1: 15601,
      GE2: 951649,
      GE3: 45644,
      GE4: 78456,
      GE5: 565345,
      GE6: 45662,
      GE7: 865356,
      GE8: 565564,
    },
  },
];

export const UPLINK_TRAFFIC_UPLOAD_MOCK: IUplinkTrafficStats[] = [
  {
    time: '2023-12-13T01:00:00.000Z',
    ports: {
      GE1: 12545,
      GE2: 47929,
      GE3: 11790,
      GE4: 21105,
      GE5: 7286,
      GE6: 22710,
      GE7: 4732,
      GE8: 3247,
    },
  },
  {
    time: '2023-12-13T02:00:00.000Z',
    ports: {
      GE1: 13010,
      GE2: 26541,
      GE3: 7545,
      GE4: 84554,
      GE5: 45245,
      GE6: 34532,
      GE7: 45344,
      GE8: 4534,
    },
  },
  {
    time: '2023-12-13T03:00:00.000Z',
    ports: {
      GE1: 12454,
      GE2: 45345,
      GE3: 45342,
      GE4: 2558,
      GE5: 4545,
      GE6: 45357,
      GE7: 54578,
      GE8: 12365,
    },
  },
  {
    time: '2023-12-13T04:00:00.000Z',
    ports: {
      GE1: 56332,
      GE2: 7545,
      GE3: 45345,
      GE4: 78645,
      GE5: 3534,
      GE6: 78645,
      GE7: 5464,
      GE8: 78645,
    },
  },
  {
    time: '2023-12-13T05:00:00.000Z',
    ports: {
      GE1: 12354,
      GE2: 45345,
      GE3: 34533,
      GE4: 45347,
      GE5: 45354,
      GE6: 47635,
      GE7: 38555,
      GE8: 5366,
    },
  },
  {
    time: '2023-12-13T06:00:00.000Z',
    ports: {
      GE1: 56356,
      GE2: 45356,
      GE3: 98945,
      GE4: 9856,
      GE5: 87654,
      GE6: 4564,
      GE7: 45364,
      GE8: 23855,
    },
  },
  {
    time: '2023-12-13T07:00:00.000Z',
    ports: {
      GE1: 78546,
      GE2: 45386,
      GE3: 9565,
      GE4: 8645,
      GE5: 4535,
      GE6: 45653,
      GE7: 65545,
      GE8: 35355,
    },
  },
  {
    time: '2023-12-13T08:00:00.000Z',
    ports: {
      GE1: 38454,
      GE2: 4535,
      GE3: 3786,
      GE4: 34556,
      GE5: 27856,
      GE6: 63456,
      GE7: 45356,
      GE8: 45688,
    },
  },
  {
    time: '2023-12-13T09:00:00.000Z',
    ports: {
      GE1: 7556,
      GE2: 86856,
      GE3: 7545,
      GE4: 7845,
      GE5: 4568,
      GE6: 34535,
      GE7: 47865,
      GE8: 7565,
    },
  },
  {
    time: '2023-12-13T10:00:00.000Z',
    ports: {
      GE1: 75653,
      GE2: 786454,
      GE3: 453,
      GE4: 275646,
      GE5: 56896,
      GE6: 45355,
      GE7: 45345,
      GE8: 8655,
    },
  },
  {
    time: '2023-12-13T11:00:00.000Z',
    ports: {
      GE1: 123665,
      GE2: 45345,
      GE3: 45456,
      GE4: 6565,
      GE5: 45343,
      GE6: 453856,
      GE7: 5438,
      GE8: 28765,
    },
  },
  {
    time: '2023-12-13T12:00:00.000Z',
    ports: {
      GE1: 385634,
      GE2: 38456,
      GE3: 45645,
      GE4: 78645,
      GE5: 45367,
      GE6: 6953,
      GE7: 45687,
      GE8: 64564,
    },
  },
  {
    time: '2023-12-13T13:00:00.000Z',
    ports: {
      GE1: 35665,
      GE2: 53356,
      GE3: 543684,
      GE4: 453455,
      GE5: 45356,
      GE6: 453453,
      GE7: 3453456,
      GE8: 45454,
    },
  },
  {
    time: '2023-12-13T14:00:00.000Z',
    ports: {
      GE1: 45355,
      GE2: 32388,
      GE3: 65345,
      GE4: 23213,
      GE5: 68965,
      GE6: 39846,
      GE7: 45668,
      GE8: 56877,
    },
  },
  {
    time: '2023-12-13T15:00:00.000Z',
    ports: {
      GE1: 45645,
      GE2: 37451,
      GE3: 45689,
      GE4: 89985,
      GE5: 45384,
      GE6: 38546,
      GE7: 456899,
      GE8: 56456,
    },
  },
  {
    time: '2023-12-13T16:00:00.000Z',
    ports: {
      GE1: 10180,
      GE2: 29858,
      GE3: 235473,
      GE4: 785654,
      GE5: 876456,
      GE6: 35388,
      GE7: 53895,
      GE8: 45378,
    },
  },
  {
    time: '2023-12-13T17:00:00.000Z',
    ports: {
      GE1: 45378,
      GE2: 78645,
      GE3: 456378,
      GE4: 74653,
      GE5: 453689,
      GE6: 56453,
      GE7: 35878,
      GE8: 453788,
    },
  },
  {
    time: '2023-12-13T18:00:00.000Z',
    ports: {
      GE1: 378799,
      GE2: 87645,
      GE3: 456748,
      GE4: 78645,
      GE5: 54645,
      GE6: 645645,
      GE7: 76866,
      GE8: 45678,
    },
  },
  {
    time: '2023-12-13T19:00:00.000Z',
    ports: {
      GE1: 786456,
      GE2: 898645,
      GE3: 456356,
      GE4: 84563,
      GE5: 786554,
      GE6: 56878,
      GE7: 78455,
      GE8: 78665,
    },
  },
  {
    time: '2023-12-13T20:00:00.000Z',
    ports: {
      GE1: 43456,
      GE2: 37865,
      GE3: 53456,
      GE4: 78454,
      GE5: 45678,
      GE6: 786546,
      GE7: 65896,
      GE8: 53435,
    },
  },
  {
    time: '2023-12-13T21:00:00.000Z',
    ports: {
      GE1: 38645,
      GE2: 95345,
      GE3: 7865,
      GE4: 7845,
      GE5: 7865,
      GE6: 45689,
      GE7: 6545,
      GE8: 45689,
    },
  },
  {
    time: '2023-12-13T22:00:00.000Z',
    ports: {
      GE1: 45389,
      GE2: 35638,
      GE3: 9655,
      GE4: 5469,
      GE5: 9878,
      GE6: 58889,
      GE7: 56566,
      GE8: 56689,
    },
  },
  {
    time: '2023-12-13T23:00:00.000Z',
    ports: {
      GE1: 38689,
      GE2: 98757,
      GE3: 78666,
      GE4: 38963,
      GE5: 45689,
      GE6: 387645,
      GE7: 689645,
      GE8: 638965,
    },
  },
  {
    time: '2023-12-13T24:00:00.000Z',
    ports: {
      GE1: 38545,
      GE2: 35356,
      GE3: 45639,
      GE4: 876456,
      GE5: 638965,
      GE6: 456899,
      GE7: 98656,
      GE8: 389456,
    },
  },
];

export const PON_TRAFFIC_DOWNLOAD_MOCK: IPONTrafficStats[] = [
  { time: '2023-12-26T01:00:00.000Z', value: 45245 },
  { time: '2023-12-26T02:00:00.000Z', value: 78645 },
  { time: '2023-12-26T03:00:00.000Z', value: 37546 },
  { time: '2023-12-26T04:00:00.000Z', value: 86545 },
  { time: '2023-12-26T05:00:00.000Z', value: 56778 },
  { time: '2023-12-26T06:00:00.000Z', value: 55457 },
  { time: '2023-12-26T07:00:00.000Z', value: 75535 },
  { time: '2023-12-26T08:00:00.000Z', value: 94555 },
  { time: '2023-12-26T09:00:00.000Z', value: 37854 },
  { time: '2023-12-26T10:00:00.000Z', value: 68645 },
  { time: '2023-12-26T11:00:00.000Z', value: 53454 },
  { time: '2023-12-26T12:00:00.000Z', value: 35435 },
  { time: '2023-12-26T13:00:00.000Z', value: 45357 },
  { time: '2023-12-26T14:00:00.000Z', value: 67864 },
  { time: '2023-12-26T15:00:00.000Z', value: 78654 },
  { time: '2023-12-26T16:00:00.000Z', value: 35445 },
  { time: '2023-12-26T17:00:00.000Z', value: 78455 },
  { time: '2023-12-26T18:00:00.000Z', value: 32754 },
  { time: '2023-12-26T19:00:00.000Z', value: 45637 },
  { time: '2023-12-26T20:00:00.000Z', value: 77554 },
  { time: '2023-12-26T21:00:00.000Z', value: 94554 },
  { time: '2023-12-26T22:00:00.000Z', value: 35411 },
  { time: '2023-12-26T23:00:00.000Z', value: 54512 },
  { time: '2023-12-26T24:00:00.000Z', value: 24545 },
];

export const PON_TRAFFIC_UPLOAD_MOCK: IPONTrafficStats[] = [
  { time: '2023-12-26T01:00:00.000Z', value: 20199 },
  { time: '2023-12-26T02:00:00.000Z', value: 12113 },
  { time: '2023-12-26T03:00:00.000Z', value: 11037 },
  { time: '2023-12-26T04:00:00.000Z', value: 10979 },
  { time: '2023-12-26T05:00:00.000Z', value: 21006 },
  { time: '2023-12-26T06:00:00.000Z', value: 9159 },
  { time: '2023-12-26T07:00:00.000Z', value: 21545 },
  { time: '2023-12-26T08:00:00.000Z', value: 27864 },
  { time: '2023-12-26T09:00:00.000Z', value: 74584 },
  { time: '2023-12-26T10:00:00.000Z', value: 24544 },
  { time: '2023-12-26T11:00:00.000Z', value: 38456 },
  { time: '2023-12-26T12:00:00.000Z', value: 64564 },
  { time: '2023-12-26T13:00:00.000Z', value: 245645 },
  { time: '2023-12-26T14:00:00.000Z', value: 64564 },
  { time: '2023-12-26T15:00:00.000Z', value: 74564 },
  { time: '2023-12-26T16:00:00.000Z', value: 12312 },
  { time: '2023-12-26T17:00:00.000Z', value: 45345 },
  { time: '2023-12-26T18:00:00.000Z', value: 45637 },
  { time: '2023-12-26T19:00:00.000Z', value: 95568 },
  { time: '2023-12-26T20:00:00.000Z', value: 88534 },
  { time: '2023-12-26T21:00:00.000Z', value: 35468 },
  { time: '2023-12-26T22:00:00.000Z', value: 94554 },
  { time: '2023-12-26T23:00:00.000Z', value: 65488 },
  { time: '2023-12-26T24:00:00.000Z', value: 75466 },
];

export const ONT_DEVICES_LIST_MOCK: IONTDevicesList = {
  items: [
    {
      onuId: '1:59',
      macAddress: '14:A7:2B:11:12:22',
      PONSerialNumber: 'PON 1/1',
      vlanId: '',
      profiles: [
        {
          lineProfiles: [
            {
              name: 'bSNL_128_138_1830',
            },
          ],
          serviceProfiles: [
            {
              name: 'FOR_WIFI',
            },
          ],
        },
      ],
      rtt: '',
      connectionStatus: ONT_STATS_CONNECTION_STATUS.OFFLINE,
      connectionStatusUpdatedTime: null,
      connectionResetCount: 0,
      rxPower: -27.19,
      txPower: 1.9,
      supplyVoltage: '',
      transceiverTemperature: 27.0,
      biasCurrent: 1.09,
    },
  ],
  pageSize: 10,
  pageToken: '1',
  total: 1,
};
