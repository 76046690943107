import {
  ICommandGroupFilters,
  ICommandGroupState,
  ICommandGroups,
} from 'store/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { VizRequester, formatError } from 'apiUtils/api';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

const loadCommandGroupUpcomingList = createAsyncThunk(
  'bulkActions-upcoming/fetch',
  async (_, thunkAPI) => {
    const { commandGroup }: { commandGroup: ICommandGroupState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.upComing,
      {
        params: { ...commandGroup?.commandGroupUpcomingFilters },
      },
    ).catch(({ response }) => response);
    if (response.status === 200) {
      return response.data;
    } else {
      return {
        error:
          formatError(response) || 'Something went wrong. Please try again',
      };
    }
  },
);

const loadCommandGroupHistoryList = createAsyncThunk(
  'bulkActions-history/fetch',
  async (_, thunkAPI) => {
    const { commandGroup }: { commandGroup: ICommandGroupState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.history,
      {
        params: { ...commandGroup?.commandGroupHistoryFilters },
      },
    ).catch(({ response }) => response);
    if (response.status === 200) {
      return response.data;
    } else {
      return {
        error:
          formatError(response) || 'Something went wrong. Please try again',
      };
    }
  },
);

const initialState: ICommandGroupState = {
  commandGroupUpcomingList: {
    hardRefresh: false,
    loading: true,
    error: '',
    data: {} as ICommandGroups,
  },
  commandGroupHistoryList: {
    hardRefresh: false,
    loading: true,
    error: '',
    data: {} as ICommandGroups,
  },
  commandGroupUpcomingFilters: {
    pageToken: 1,
    pageSize: 10,
  },
  commandGroupHistoryFilters: {
    pageToken: 1,
    pageSize: 10,
  },
};

export const commandGroupSlice = createSlice({
  name: 'command-group',
  initialState,
  reducers: {
    resetToInit: () => initialState,

    resetUpcomingListState: (state: ICommandGroupState) => {
      state.commandGroupUpcomingList = {
        hardRefresh: true,
        loading: true,
        error: '',
        data: {} as ICommandGroups,
      };
      state.commandGroupUpcomingFilters = {
        pageToken: 1,
        pageSize: 10,
      };
    },

    resetHistoryListState: (state: ICommandGroupState) => {
      state.commandGroupHistoryList = {
        hardRefresh: true,
        loading: true,
        error: '',
        data: {} as ICommandGroups,
      };
      state.commandGroupHistoryFilters = {
        pageToken: 1,
        pageSize: 10,
      };
    },
    setUpcomingHardRefresh: (
      state: ICommandGroupState,
      { payload }: { payload: boolean },
    ) => {
      state.commandGroupUpcomingList.hardRefresh = payload;
    },
    setHistoryHardRefresh: (
      state: ICommandGroupState,
      { payload }: { payload: boolean },
    ) => {
      state.commandGroupHistoryList.hardRefresh = payload;
    },
    setUpcomingPageToken: (
      state: ICommandGroupState,
      { payload }: { payload: number },
    ) => {
      state.commandGroupUpcomingFilters.pageToken = payload;
    },
    setUpcomingPageSize: (
      state: ICommandGroupState,
      { payload }: { payload: number },
    ) => {
      state.commandGroupUpcomingFilters.pageSize = payload;
    },
    setUpcomingListPagination: (
      state: ICommandGroupState,
      action: PayloadAction<ICommandGroupFilters>,
    ) => {
      state.commandGroupUpcomingFilters = action?.payload;
    },
    setHistoryPageToken: (
      state: ICommandGroupState,
      { payload }: { payload: number },
    ) => {
      state.commandGroupHistoryFilters.pageToken = payload;
    },
    setHistoryPageSize: (
      state: ICommandGroupState,
      { payload }: { payload: number },
    ) => {
      state.commandGroupHistoryFilters.pageSize = payload;
    },
    setHistoryListPagination: (
      state: ICommandGroupState,
      action: PayloadAction<ICommandGroupFilters>,
    ) => {
      state.commandGroupHistoryFilters = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadCommandGroupUpcomingList.pending,
      (state: ICommandGroupState) => {
        state.commandGroupUpcomingList.loading = true;
      },
    );
    builder.addCase(
      loadCommandGroupUpcomingList.fulfilled,
      (state, { payload }: { payload: ICommandGroups | any }) => {
        state.commandGroupUpcomingList.loading = false;
        state.commandGroupUpcomingList.hardRefresh = false;
        if (isEmpty(payload?.error)) {
          state.commandGroupUpcomingList.data = payload;
        } else {
          state.commandGroupUpcomingList.error = payload?.error;
        }
      },
    );
    builder.addCase(
      loadCommandGroupHistoryList.pending,
      (state: ICommandGroupState) => {
        state.commandGroupHistoryList.loading = true;
      },
    );
    builder.addCase(
      loadCommandGroupHistoryList.fulfilled,
      (state, { payload }: { payload: ICommandGroups | any }) => {
        state.commandGroupHistoryList.loading = false;
        state.commandGroupHistoryList.hardRefresh = false;
        if (isEmpty(payload?.error)) {
          state.commandGroupHistoryList.data = payload;
        } else {
          state.commandGroupHistoryList.error = payload?.error;
        }
      },
    );
  },
});

export const CommandGroupSliceActions = {
  ...commandGroupSlice.actions,
  loadCommandGroupUpcomingList,
  loadCommandGroupHistoryList,
};

export const CommandGroupReducer = commandGroupSlice.reducer;
