//  #region - Subscription & Pricing

import { ManagementEntity } from 'store/types';

export enum PACKAGE_NAME {
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
}

export enum PACKAGE_NAME_VIEW {
  SILVER = 'Silver',
  GOLD = 'Gold',
  PLATINUM = 'Platinum',
}

export enum SUBSCRIPTION_STATE {
  PENDING = 'PENDING', //  PENDING state -> To handle in UI side for subscription related restrictions
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  EXPIRED = 'EXPIRED',
  UNASSIGNED = 'UNASSIGNED',
}

export enum SUBSCRIPTION_STATE_VIEW {
  ACTIVE = 'Active',
  SUSPENDED = 'Suspended',
  EXPIRED = 'Expired',
  UNASSIGNED = 'Unassigned',
}
export interface ISubscription {
  duration: number;
  durationUnit?: string;
  endDate: string;
  id: string;
  package: IPackage;
  price: number;
  slabId?: string | null;
  startDate?: string;
  state: SUBSCRIPTION_STATE;
  subscriberCount: number;
  expiryDate: string;
  reason?: string | null;
  reasonCode?: string | null;
}

export interface IUpdateSubscriptionForm {
  slabId?: string;
  packageId: string;
  subscriberCount: number | null;
  duration: number | null;
  durationUnit: DURATION_UNIT | null;
  managementEntityId: string;
  redirectTo?: {
    url: string;
    queryParams?: string; // List of keys for query param as string with colon seperator (:) Eg: transactionId:status
  };
}

export interface IExtendSubscriptionForm {
  reason: string;
  duration: number;
  durationUnit: string;
  managementEntityId: string;
}
export enum DURATION_UNIT {
  DAYS = 'day',
  MONTHS = 'month',
}

export interface ISubscriptionMarkAsExpiredForm {
  managementEntityId: string;
  trialId?: string;
  forceExpiry?: boolean;
  reasonCode?: string;
  reason?: string;
}

export interface IPackage {
  id: string;
  name: PACKAGE_NAME;
  displayName: string;
  price?: number;
  category?: ICategory[] | null;
}

export interface ICategory {
  name: SUBSCRIPTION_CATEGORIES;
  features: IFeatures[];
}

export interface IFeatures {
  displayName: string;
  name: SUBSCRIPTION_FEATURES;
  id: string;
  category?: SUBSCRIPTION_CATEGORIES | null;
}

export interface IPricing {
  slabSupported: boolean;
  slabs?: ISlabItem[];
  packagePrice?: IPackagePrice[];
  managementEntity: ManagementEntity;
}

export interface ISlabItem {
  slabId: string;
  minSubscriber: number;
  maxSubscriber: number;
  packagePrice: IPackagePrice[];
}

export interface IPackagePrice {
  packageId: string;
  packageName: PACKAGE_NAME;
  price: number;
  displayName: string;
  description: string;
  features: IFeature[];
}
export interface IFeature {
  id: string;
  description: string;
  name: string;
  displayName: string;
}

export enum SUBSCRIPTION_CATEGORIES {
  // APP_THEMING = 'APP_THEMING',
  // AUTO_FIRMWARE_UPDATES = 'AUTO_FIRMWARE_UPDATES',
  COMPLETE_OPTICAL_NETWORK_OBSERVABILITY = 'COMPLETE_OPTICAL_NETWORK_OBSERVABILITY',
  // EXTERNAL_API_INTEGRATION = 'EXTERNAL_API_INTEGRATION',
  // FRANCHISEE_MANAGEMENT = 'FRANCHISEE_MANAGEMENT',
  OLT_MONITORING = 'OLT_MONITORING',
  ONT_MONITORING = 'ONT_MONITORING',
  ONT_REMOTE_TROUBLESHOOTING = 'ONT_REMOTE_TROUBLESHOOTING',
  ONT_WIFI_MANAGEMENT = 'ONT_WIFI_MANAGEMENT',
  REAL_TIME_ALERTS = 'REAL_TIME_ALERTS',
  REPORTS = 'REPORTS',
  ZERO_TOUCH_PROVISIONING = 'ZERO_TOUCH_PROVISIONING',
}

export enum SUBSCRIPTION_FEATURES {
  // AUTO_FIRMWARE_UPDATES = 'AutoFirmwareUpdates',
  BRIDGE_ONU_SUPPORT = 'BridgeONUSupport',
  CHANNEL_SWITCH = 'ChannelSwitch',
  CHANNEL_WIDTH = 'ChannelWidth',
  CLIENT_DEVICES = 'ConnectedClients',
  CLIENT_RSSI_STATISTICS = 'ClientDeviceRSSI',
  CLIENT_THROUGHPUT = 'ClientDeviceThroughput',
  // CONNECT_APP_THEMING = 'ConnectAppTheming',
  CPU = 'CpuUsage',
  CUSTOM_THRESHOLD_CONFIGURATION = 'CustomThresholdConfiguration',
  // DIAG_APP_THEMING = 'DiagAppTheming',
  ETHERNET_PORT_STATUS = 'EthernetPortStatus',
  // FRANCHISEE_MANAGEMENT = 'FranchiseeManagement',
  // JAZE_INTEGRATION = 'JazeIntegration',
  LAN_SETTINGS = 'LanConfig',
  LOS_ALERTS = 'LOSAlerts',
  MEMORY = 'MemoryUsage',
  // MOBILE_PUSH_NOTIFICATIONS = 'MobilePushNotifications',
  NETWORK_INFORMATION = 'NetworkInformation',
  OLT_INFORMATION = 'OLTInformation',
  ONLINE_OFFLINE_STATUS = 'OnlineOfflineStatus',
  OPTICAL_STRENGTH = 'OpticalStrength',
  PAUSE_PLAY = 'PausePlay',
  PING = 'Ping',
  PON_ACCESS_TYPE = 'PONAccessType',
  RADIO_INFORMATION = 'RadioInformation',
  REBOOT = 'Reboot',
  REPORTS_DATA_USAGE = 'ReportsDataUsage',
  REPORTS_OFFLINE_SUBSCRIBERS = 'ReportsOfflineSubscribers',
  REPORTS_OLT_OPTICAL_SIGNAL = 'ReportsOLTOpticalSignal',
  REPORTS_ONT_OPTICAL_SIGNAL = 'ReportsONTOpticalSignal',
  REPORTS_PENDING_SUBSCRIBERS = 'ReportsPendingSubscribers',
  RTT = 'RTT',
  SCAN_AND_WIFI_OPTIMIZATION = 'ScanAndOptimizeWifi',
  // SITE_SURVEY = 'SiteSurvey',
  SPEEDTEST = 'Speedtest',
  SSID_PASSWORD = 'SSIDPassword',
  SYSTEM_EVENT_AND_LOGS = 'SystemEventAndLogs',
  TRACEROUTE = 'Traceroute',
  UPTIME = 'Uptime',
  WAN_THROUGHPUT = 'WanThroughput',
  // WIFI_OPTIMIZE = 'WifiOptimize',
  WIFI_THROUGHPUT = 'WiFiThroughput',
  ZERO_TOUCH_PROVISIONING = 'ZeroTouchProvisioning',
}

// #region - Subscription Plan Summary

export enum SUMMARY_STATUS {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
export interface ISummary {
  items: ISummaryItem[];
  pageSize: number;
  pageToken: number;
  total: number;
}

export interface ISummaryItem {
  managementEntity: ManagementEntity;
  subscriberCount: number;
  duration: number;
  durationUnit: DURATION_UNIT;
  slabId?: string;
  package: IPackage;
  subtotal: number;
  total: number;
  CGST: IGST;
  SGST: IGST;
  IGST: IGST;
  UTGST: IGST;
  id?: string | null; //  Summary ID
  status?: SUMMARY_STATUS;
  appliedOffer?: IAppliedOffer;
  offerAmount?: number;
}

export interface IGST {
  percentage: number;
  amount: number;
}

export interface IAppliedOffer {
  id: string;
  name: string;
  code: string;
  offer: IOfferPriceData;
}

export interface IOfferPriceData {
  unit: OFFER_UNIT;
  value: number;
}

export enum OFFER_UNIT {
  PERCENTAGE = 'PERCENTAGE',
  ABSOLUTE = 'ABSOLUTE',
}

export enum OFFER_UNIT_VIEW {
  PERCENTAGE = '%',
  ABSOLUTE = '₹',
}
// #endregion - Subscription Plan Summary

// #endregion - Subscription & Pricing

//  #region - Payment
export interface IPaymentTransactionData {
  transactionId: string;
  referenceNumber: string;
  paymentMode: string;
  status: PAYMENT_STATUS;
  failureReason: string;
  amount: number;
  managementEntity: {
    id: string;
    name: string;
    email: string;
  };
}
export enum PAYMENT_STATUS {
  INITIATED = 'INITIATED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}
//  #endregion - Payment
