import { IPagination } from 'store/types';
import { ReactNode } from 'react';

export interface IVLANTabItems {
  key: string;
  title: ReactNode;
  icon?: ReactNode;
  hidden?: boolean;
  children: ReactNode;
}

export interface IVLANReduxState {
  vlanList: {
    loading: boolean;
    error?: string | null;
    data: IVLANItem[] | null;
  };
  currentTabId: string;
  bulkActionType?: VLAN_BULK_ACTIONS | null;
  bulkActionSelectedVLANs?: IBulkActionSelectedVLANs | null;
  vlanDeleteRequestStatus?: string | null;
  vlanListPagination: IPagination;
}

export interface IBulkActionSelectedVLANs {
  vlanIds: React.Key[] | number[];
  data?: IVLANItem[];
}

export interface IVLANItem {
  vlanId: number;
  type?: VLAN_TYPES;
  associatedOLTs?: IVLANOLTDetail[] | null;
}

export interface IVLANOLTDetail {
  ipAddress: string;
  oltLabel: string;
  oltMac: string;
}

export interface IVLANForm {
  managementEntityId?: string | null;
  internet: string[] | number[];
  tr069?: string[] | number[];
  // voip?: string[]|number[];
  associatedOLTs?: string[] | null;
}

export enum VLAN_TYPES {
  INTERNET = 'INTERNET',
  TR069 = 'TR069',
  OTHERS = 'OTHERS',
  VOIP = 'VOIP',
}

export const VLAN_TABS = {
  INTERNET: 'internet',
  OTHERS: 'others',
};

export enum VLAN_BULK_ACTIONS {
  BULK_DELETE = 'vlan-bulk-delete', // OLT Bulk Delete in OLT List Screen
}
export const VLAN_DEFAULT_PAGE_SIZE = 50;
export const VLAN_DEFAULT_PAGE_SIZE_OPTIONS = [50, 75, 100, 150, 200];
