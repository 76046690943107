import { IManagementEntityDetails, IPagination } from 'store/types';
import {
  IUserList,
  IUserListFilters,
  IUsersReduxState,
} from 'store/types/users.types';
import { OperatorRequester, formatError } from 'apiUtils/api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { removeEmptyValuesFromObject } from 'utils/utils';

const initialState: IUsersReduxState = {
  userList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as IUserList,
  },
  userListPagination: {},
  userListSearch: {} as any,
  userListPageTokens: [''],
  hasNextPageToken: false,
  isFilterOpen: false,
  userListFilters: {} as IUserListFilters,
  userListFilterDropDownList: {
    roleList: [] as string[],
    managementEntityList: [] as IManagementEntityDetails[],
  },
};

const loadUserList = createAsyncThunk('userList/fetch', async (_, thunkAPI) => {
  let { userState }: { userState: IUsersReduxState } =
    thunkAPI.getState() as any;

  const response: AxiosResponse = await OperatorRequester.get(
    API_END_POINTS.OPERATORS.users,
    {
      params: {
        pageSize: userState?.userListPagination?.pageSize,
        pageToken:
          userState?.userListPageTokens[
            (userState?.userListPagination?.pageNumber || 1) - 1
          ],
        managementEntityIds: [userState?.userListFilters?.managementEntityIds],
        role: userState?.userListFilters?.role,
        ...removeEmptyValuesFromObject({ ...userState?.userListSearch }),
      },
    },
  ).catch(({ response }) => response);
  if (response.status === 200) {
    return response.data;
  } else {
    return {
      error:
        formatError(response) ||
        'Error in fetching Users List. Please try again',
    };
  }
});

export const UserListSlice = createSlice({
  name: 'UserList',
  initialState,
  reducers: {
    resetToInit: (state: IUsersReduxState) => {
      state.userList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IUserList,
      };
      state.userListPagination = {};
      state.userListPageTokens = [''];
      state.hasNextPageToken = false;
      state.userListFilters = {};
      state.userListFilterDropDownList = {
        roleList: [] as string[],
        managementEntityList: [] as IManagementEntityDetails[],
      };
    },
    setUserListHardReload: (
      state: IUsersReduxState,
      { payload }: { payload: boolean },
    ) => {
      state.userList.hardReload = payload;
    },
    setUsersListPageNumber: (
      state: IUsersReduxState,
      { payload }: { payload: number },
    ) => {
      state.userListPagination.pageToken =
        state.userListPageTokens[payload - 1];
      state.userListPagination.pageNumber = payload;
    },
    setUserListPageSize: (
      state: IUsersReduxState,
      { payload }: { payload: number },
    ) => {
      state.userListPagination.pageSize = payload;
      state.userListPageTokens = [''];
    },
    setUserListPagination: (
      state: IUsersReduxState,
      action: PayloadAction<IPagination>,
    ) => {
      state.userListPagination = action.payload;
      state.userListPageTokens = [''];
    },
    setSearchText: (
      state,
      { payload }: { payload: string | null | undefined },
    ) => {
      state.userListSearch = { searchText: payload };
      state.userListPagination.pageToken = 1;
    },
    setIsFilterOpen: (state, { payload }: { payload: boolean }) => {
      state.isFilterOpen = payload;
    },
    setUserListFilters: (state: IUsersReduxState, action) => {
      if (isEmpty(action?.payload)) {
        state.userListFilters = {};
      } else {
        state.userListFilters = Object.assign(
          state.userListFilters,
          action.payload,
        );
      }
      state.userList.hardReload = true;
      state.userList.error = '';
      state.userListPageTokens = [''];
    },
    setRoleList: (
      state: IUsersReduxState,
      { payload }: { payload: string[] },
    ) => {
      state.userListFilterDropDownList.roleList = payload;
    },
    setManagementEntityList: (
      state: IUsersReduxState,
      { payload }: { payload: IManagementEntityDetails[] },
    ) => {
      state.userListFilterDropDownList.managementEntityList = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadUserList.pending, (state: IUsersReduxState) => {
      state.userList.loading = true;
      state.userList.error = '';
      state.userList.data = {} as IUserList;
    });

    builder.addCase(
      loadUserList.fulfilled,
      (state, { payload }: { payload: IUserList | any }) => {
        state.userList.loading = false;
        state.userList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.userList.data = payload;
          if (payload?.pageToken) {
            const currentPageIndex = state.userListPagination.pageNumber || 1;
            state.userListPageTokens[currentPageIndex] =
              payload?.pageToken || '';
          }
          state.hasNextPageToken = !!payload?.pageToken;
        } else {
          state.userList.error = payload?.error;
        }
      },
    );
  },
});

export const UserSliceActions = {
  ...UserListSlice.actions,
  loadUserList,
};

export const UserSliceReducer = UserListSlice.reducer;
