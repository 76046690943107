import {
  BOOLEAN,
  IListSorting,
  IManagementEntityDetails,
  IOptions,
  IPagination,
} from 'store/types';
import { IGoogleGeoCoordinates, IOLTGoogleMapData } from './googleMaps.types';
import {
  faGears,
  faList,
  faListCheck,
} from '@fortawesome/free-solid-svg-icons';

import { CSSProperties } from 'react';
import { ISplitterChartData } from './splitter.types';

export interface IOLTBridgeStatus {
  status: OLT_BRIDGE_STATUS;
  time: string;
  onuTelemetryConfig: ITelemetryConfigurations;
  oltTelemetryConfig: ITelemetryConfigurations;
}

//  #region - OLT List

export interface IOLTListReduxState {
  OLTList: {
    hardRefresh: boolean;
    loading: boolean;
    error: string;
    data: IOLTList;
  };
  OLTPendingList: {
    hardRefresh: boolean;
    loading: boolean;
    error: string;
    data: IOLTPendingList;
  };
  oltPendingListPagination: IPagination;
  oltListPagination: IPagination;
  oltListSearch: { searchText?: string | null };
  oltListFilters: IOLTListFilters;
  isFilterOpen: boolean;
  oltListFilterDropDownList: {
    manufacturerList?: IOptions[];
    modelList?: IOLTSupportedModel[];
    managementEntityList?: IManagementEntityDetails[];
  };
  oltListSorting: IListSorting;
  OLTListOptions: IOLTListItems[];
  OLTMigrationTransactions: IOLTMigrationTransaction;
  selectedOLTSubscriberStats: {
    loading: boolean;
    error: string;
    data: ISubscriberStatsByOLT;
  };
  currentTabId: string;
  selectedOLTRows: IOLTSelectedRows;
  bulkActionType?: OLT_BULK_ACTIONS | null;
  pendingOLTDeleteRequestStatus?: string | null;
}
export interface IOLTSelectedRows {
  rowKeys?: React.Key[] | string[];
  data?: IPendingOLTData[];
}

export interface IOLTPendingList {
  items: IPendingOLTData[];
  pageSize: number;
  pageToken: number;
  total: number;
}

export interface ISubscriberStatsByOLT {
  meId: string;
  oltMac: string;
  totalSubscribers: number;
}
export interface IOLTListFilters {
  manufacturer?: string;
  model?: string;
  ponPortCount?: string;
  ponType?: string;
  assignedTo?: string;
  telnetReachable?: string;
  SNMPReachable?: string;
}

export interface IOLTList {
  items: IOLTItem[];
  pageSize: number;
  pageToken: number;
  total: number;
}

export interface IOLTItem extends IGoogleGeoCoordinates {
  manufacturer: string;
  name?: string;
  model: string;
  ipAddress: string;
  label: string;
  sysName: string;
  serialNum?: string;
  hwVersion: string;
  swVersion: string;
  macAddress: string;
  license: string;
  numberOfUplinkPort: number;
  reachability: IOLTReachability;
  numberOfPonPort: number;
  managementEntityId: string;
  discoveryInProgress: boolean;
  discoveryTime: string;
  uplinkPortInfo: IUplinkPortInfo[];
  ponPortInfo: IPonPorts[];
  ponPorts?: IPonPorts[];
  lineProfileInfo: IOLTProfileInfo[];
  serviceProfileInfo: IOLTProfileInfo[];
  ponType: PON_TYPE;
  migration?: IOLTMigration;
  onboarding?: IOnboarding;
  allowedVLANs?: number[];

  // GET By OLT Id
  mac?: string;
  ponPortCount?: number;
  serialNumber?: string;

  deviceCount: {
    total: number;
    online: number;
    offline: number;
  };
  onuTelemetryConfig?: ITelemetryConfigurations | null;
  oltTelemetryConfig?: ITelemetryConfigurations | null;
}
export interface IOLTReachability {
  status: boolean;
  error?: string;
  errorReason?: string;
}

export interface IUplinkPortInfo {
  name: string;
  number: number;
  type: string;
  linkStatus: string;
  speed: string;
  portIndex: string;
}
export interface IOLTProfileInfo {
  name: string;
}
export interface IOnboarding {
  status: OLT_ONBOARDING_PROGRESS_STATUS;
}
export interface IOLTMigration {
  transactionId: string;
  status: OLT_MIGRATION_STATUS;
}
export enum OLT_MIGRATION_STATUS {
  INITIATED = 'INITIATED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum OLT_ONBOARDING_PROGRESS_STATUS {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export interface IOLTMigrationTransactionData {
  transactionId: string;
  oltMac: string;
  oltLabel: string;
  sourceMEId: string;
  sourceMEType: string;
  sourceMEName: string;
  destMEId: string;
  destMEName: string;
  destMEType: string;
  total: number;
  success: number;
  failure: number;
  pending: number;
}

export interface IOLTMigrationTransaction {
  loading: {
    [transactionId: string]: boolean;
  };
  error: {
    [transactionId: string]: string;
  };
  data: {
    [transactionId: string]: IOLTMigrationTransactionData;
  };
}
//  #endregion - OLT List

//  #region - OLT Form
export interface IOLTForm {
  manufacturer: string | null;
  model: string | null;
  ipAddress: string;
  mac: string;
  name: string;
  label: string;
  ponPortCount: string | null;
  SNMP: {
    // userName: string;
    // password: string;
    version: string;
    communityString: string;
  };
  accessType: string | null;
  telnet: {
    userName: string;
    password: string;
  };
  assignedTo: string | null;
  ponType: string | null;

  // Create OLT
  onuTelemetryConfig?: ITelemetryConfigurations | null;
  oltTelemetryConfig?: ITelemetryConfigurations | null;
}

export interface IUpdateONUTelemetryConfigForm {
  onu?: ITelemetryConfigurations | null;
  olt?: ITelemetryConfigurations | null;
}

export interface IOLTSupportedModel {
  model: string;
  telemetry: boolean;
}

export interface ITelemetryConfigurations {
  enable?: boolean | null;
  pollingInterval?: string | null;
  ponPortsCategory?: string | null;
  ponPorts?: string[] | null;
  ponPortsCount?: string | number | null;
}

export interface IIpReachability {
  ipAddress: string;
  managementEntityId: string;
  reachable: boolean;
  errorReason: string;
}

export interface IOLTBasicDetailsForm {
  managementEntityId?: string;
  ipAddress: string;
  name?: string;
  label?: string;
  assignedTo?: string | null;
  manufacturer?: string | null;
  telnet?: {
    username?: string;
    password?: string;
    reachable?: boolean;
    adminAccess?: boolean;
    errorReason?: string;
  };
  ipReachabilityStatus?: {
    reachable?: false;
    errorReason?: string;
  };
}

export interface IOLTConfigurationForm {
  SNMP: {
    version: string | null;
    communityString: string;
    username: string;
    password: string;
  };
}

export interface IOLTTelnetReachabilityStatus {
  managementEntityId?: string;
  ipAddress: string;
  reachable: boolean;
  adminAccess: boolean;
  errorReason: string;
  username?: string;
  password?: string;
  oltModel?: string;
  manufacturer?: string;
}

export interface IOLTFormReduxState {
  ipReachabilityStatus: IIpReachability;
  telnetReachabilityData: IOLTTelnetReachabilityStatus;
  SNMPReachabilityStatus: ISNMPReachabilityStatus;
  oltBasicDetailsForm: IOLTBasicDetailsForm;
  oltConfigurationForm: IOLTConfigurationForm;
  currentStepId: number;
  formValidationListenerKey: string | number | null;
  submitRequestError: string;
  isNextButtonDisabled: boolean;
  // isFormSubmitted: boolean;
  isSbmitLoading: boolean;
  currentTabId: string;
  pendingOLTData: IPendingOLTData;
  dropdownList: {
    vlanList: {
      loading: boolean;
      error?: string | null;
      data: number[] | null;
    };
  };
}

export interface ISNMPReachabilityStatus {
  ipAddress?: string;
  managementEntityId?: string;
  SNMP: ISNMP;
  oltMac?: string;
  oltModel?: string;
  ponPortCount?: number;
  ponPortType?: string;
  status?: string;
}

export interface ISNMP {
  configurable: boolean;
  trapEnabled: boolean;
  version: string;
  communityString: string;
  username: string;
  password: string;
  errorReason?: string;
  reachable: boolean;
}

//  #endregion - OLT Form

//  #region - OLT List

export interface IOLTListItems {
  label: string;
  MAC: string;
  ipAddress: string;
  ponPortCount: number;
  ponPorts: IPonPorts[];
  lineProfileInfo?: IOLTProfileInfo[];
  serviceProfileInfo?: IOLTProfileInfo[];
  ponType?: PON_TYPE;
  manufacturer?: string;
  model?: string;
  managementEntityId?: string;
}

export interface IPonPorts {
  name: string;
  number: string | number;
  linkStatus: TPonPortLinkStatus;
  portIndex?: string | null;
}

export interface IOLTDashboardState {
  ONTDeviceList: {
    hardRefresh: boolean;
    loading: boolean;
    error: string;
    data: any;
  };
  OLTListFilter: { oltMac?: string; portNumber?: string; portIndex?: string };
  OLTListPagination: { pageToken: number; pageSize: number };
  OLTList: IOLTListItems[];
  ONTStats: IOLTStatsByPort;
}

export interface IOLTStatsByPort {
  loading: boolean;
  error: boolean;
  data: IOLTStatsData;
}

export interface IOLTStatsData {
  total: number;
  offlineCount: number;
  onlineCount: number;
  losCount: number;
  dyingGaspCount: number;
}
export interface IMigrationPendingCountPieData {
  category: string;
  value: number;
  color: string;
}

export interface IPendingOLTData extends IGoogleGeoCoordinates {
  id: string;
  name: string;
  label: string;
  manufacturer: string;
  oltMac: string;
  oltModel: string;
  ponPortCount: number;
  ponPortType: string;
  managementEntity: {
    id: string;
    name: string;
    type: string;
  };
  assignedTo: {
    id: string;
    name: string;
    type: string;
  };
  ipAddress: string;
  ipReachable: boolean;
  telnet: IOLTTelnetReachabilityStatus;
  SNMP: ISNMP;
  ipReachabilityStatus: {
    reachable: boolean;
    errorReason: string;
  };
  status: string;
}

export interface IOLTActionsRecord {
  discoveryInProgress?: boolean;
  migration?: IOLTMigration;
  onboarding?: IOnboarding;
  ipAddress: string;
  macAddress: string;
  label: string;
}
//  #endregion - OLT List

//  #region - OLT Dashboard

export interface IOLTDashboardReduxState {
  overview: {
    loading: boolean;
    error: string;
    data: IOLTDashboardStats;
  };
  uplinkTrafficStats: {
    downloadStatsLoading: boolean;
    uploadStatsLoading: boolean;
  };
  opticalTXPONOverviewStats: {
    loading: boolean;
    error?: string | null;
    data: IOLTOpticalTXPONOverviewStats[];
  };
  deviceStats: {
    loading: boolean;
    error: string;
    data: IOLTDashboardDeviceStats;
  };
  ponTrafficStats: {
    downloadStatsLoading: boolean;
    uploadStatsLoading: boolean;
  };
  opticalTXPONPortTimeSeries: {
    loading: boolean;
    error?: string | null;
    data: IOLTOpticalTXPONPortTimeSeries[];
  };
  ontDevicesList: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IONTDevicesList;
  };
  ontDeviceListPagination: IPagination;
  oltDashboardFilters: IOLTDashboardFilters;
  oltDashboardFiltersDropdownList: {
    oltList: {
      loading: boolean;
      error?: string | null;
      data: IOLTListItems[];
    };
    ponPortsList: IPonPorts[];
  };
  mapViewDetails: {
    mapViewTabFilters: IOLTDashboardMapViewTabFilters; // Common for both Google Map View and Map Hierarchy chart
    splitterDetailsChartData: {
      loading: boolean;
      error?: string | null;
      data: ISplitterChartData[];
    };
  };
  oltGoogleMapData: {
    loading: boolean;
    error?: string | null;
    data: IOLTGoogleMapData[];
  };
  currentTabId: string;
  isMobileViewONTDeviceScreen?: boolean;
}
export interface IOLTDashboardFilters {
  oltMac?: string;
  portIndex?: string | null; //Eg: 1/1/1 -> contains SHELF, SLOT and PORT_NUMBER
}
export interface IOLTDashboardStatsPathParams {
  managementEntityId: string;
  oltMac: string;
}
export interface IOLTDashboardMapViewTabFilters {
  portIndex?: string | null;
  include_subscriber?: BOOLEAN;
  subscriberId?: string | null;
}
export interface IOLTDashboardStats {
  mac: string;
  ipAddress: string;
  model: string;
  manufacturer: string;
  label: string;
  lastUpdatedAt: string;
  ponPorts: IPonPorts[];
  uplinkPorts: IUplinkPortInfo[];
  ponPortsStats: IOLTPortsStats;
  uplinkPortsStats: IOLTPortsStats;
  cards: IOLTDashboardStatsCard[];
  configuredVLANs?: number[];
  allowedVLANs?: number[];
}

export interface IOLTDashboardStatsCard {
  status: 'Online' | 'Offline' | null;
  type: string;
  uptime: number;
  ports: number;
  role: string;
  hardwareVersion: string;
  softwareVersion: string;
  cpuUsage: number;
  memoryUsage: number;
  temperature: number;

  rack?: number;
  slot?: number;
  shelf?: number;
}

export interface IOLTPortsStats {
  total: number;
  up: number;
  down: number;
}
export interface IOLTDashboardDeviceStats {
  deviceStats: IOLTDeviceStats;
  offlineStats: IOLTDashboardOfflineStats;
  poorSignal: number;
}

export interface IOLTDeviceStats {
  total: number;
  online: number;
  offline: number;
}

export interface IOLTDashboardOfflineStats {
  total: number;
  poweredOff: number;
  linkFault: number;
  LOS: number;
  unknown: number;
}
export interface IOLTOpticalTXPONOverviewStats {
  name: string;
  value: number;
}
export interface IOLTOpticalTXPONPortTimeSeries {
  time: string;
  value: number;
}
export enum OLT_PORT_TRAFFIC_MODE {
  UPLOAD = 'UPLOAD',
  DOWNLOAD = 'DOWNLOAD',
}
export interface IUplinkTrafficStats {
  time: string;
  ports: { [key: string]: number };
}
export interface IPONTrafficStats {
  time: string;
  value: number;
}

export interface IONTDevicesList {
  items: IONTDeviceItem[];
  pageSize: number;
  pageToken: string;
  total: number;
}

export interface IONTDeviceItem {
  onuId: string;
  macAddress: string;
  vlanId: string;
  PONSerialNumber: string;
  profiles: IONTDeviceProfile[];
  rtt: string;
  connectionStatus: ONT_STATS_CONNECTION_STATUS;
  connectionStatusUpdatedTime: string | null;
  connectionResetCount: number;
  rxPower: number;
  txPower: number;
  supplyVoltage: string;
  transceiverTemperature: number;
  biasCurrent: number;
  subscriberId?: string;
}

export interface IONTDeviceProfile {
  lineProfiles: IOLTProfiles[];
  serviceProfiles: IOLTProfiles[];
}

export interface IOLTProfiles {
  name: string;
}

//  #endregion - OLT Dashboard

//  #region - Service

export interface IGetOLTPendingListParam {
  path: { managementEntityId: string };
  query: IOLTListFilters | IPagination;
}

export interface IOLTPathParams {
  managementEntityId: string;
  ipAddress?: string;
}

//  #endregion - Service

//  #region - Enums and Constants
export type TPonPortLinkStatus = 'up' | 'down';
export enum OLT_LINK_STATUS {
  UP = 'up',
  DOWN = 'down',
}

export enum ACCESS_TYPE {
  SNMP = 'SNMP',
  TELNET = 'TELNET',
  'SNMP&TELNET' = 'SNMP & TELNET',
}

export enum PON_PORTS_CATEGORY {
  ALL = 'ALL',
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
}

export enum PON_PORTS_CATEGORY_VIEW {
  ALL = 'All',
  INCLUDE = 'Include',
  EXCLUDE = 'Exclude',
}

export enum PON_TYPE {
  EPON = 'EPON',
  GPON = 'GPON',
  XPON = 'XPON',
  OTHERS = 'OTHERS',
}

export enum OLT_BRIDGE_STATUS {
  UP = 'UP',
  DOWN = 'DOWN',
  INIT = 'INIT',
  NOT_REGISTERED = 'NOT_REGISTERED',
}

export enum ONT_STATS_CONNECTION_STATUS {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  WORKING = 'WORKING',
  LOGGING = 'LOGGING',
  DYING_GASP = 'DYING_GASP',
  LOS = 'LOS',
}

export enum ONT_STATS_CONNECTION_STATUS_VIEW {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
  WORKING = 'Working',
  LOGGING = 'Logging',
  DYING_GASP = 'Dying Gasp',
  LOS = 'Loss Of Signal',
}

export const OLT_DASHBOARD_TABS = {
  OVERVIEW: 'overview',
  ONUS: 'onus',
  SPLITTER_MAP_VIEW: 'splitter-map-view',
  OLT_GOOGLE_MAP: 'olt-google-map',
};

export const OLTDashboardDescriptionContentStyle: CSSProperties = {
  fontSize: 16,
  fontWeight: 600,
};

export enum OLTFormTabs {
  BASIC_DETAILS = 'basic-details',
  CONFIGURATION = 'configuration',
  SUMMARY = 'summary',
}
export const OLTListTabs = {
  PENDING: 'pending',
  CONFIGURED: 'configured',
};
export const SNMP_VERSION = {
  V2c: 'v2c',
  V2: 'v2',
  V3: 'v3',
};

export const OLTFormStep = [
  {
    title: 'Basic Details',
    icon: faList,
    key: OLTFormTabs.BASIC_DETAILS,
  },
  {
    title: 'Configuration',
    icon: faGears,
    key: OLTFormTabs.CONFIGURATION,
  },
  {
    title: 'Summary',
    icon: faListCheck,
    key: OLTFormTabs.SUMMARY,
  },
];

export const SNMPVersionOptions = [
  { label: 'SNMP v2c', key: 'SNMPv2c', value: SNMP_VERSION.V2c },
  { label: 'SNMP v2', key: 'SNMPv2', value: SNMP_VERSION.V2 },
  { label: 'SNMP v3', key: 'SNMPv3', value: SNMP_VERSION.V3 },
];

export const SNMPStatus = {
  INITIATED: 'INITIATED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};
export enum OLT_BULK_ACTIONS {
  BULK_DELETE = 'olt-bulk-delete', // OLT Bulk Delete in OLT List Screen
}
//  #endregion - Enums and Constants
