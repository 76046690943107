import {
  IVoIPConfigResponse,
  IVoIPPortsSupportedResponse,
  VOIP_STATUS,
} from 'store/types/subscriber.types';

export const VOIP_PORTS_SUPPORTED_MOCK: IVoIPPortsSupportedResponse = {
  numberOfVoipPorts: 0,
  supported: true,
};

export const VOIP_CONFIG_MOCK: IVoIPConfigResponse = {
  voip: [
    {
      status: VOIP_STATUS.REGISTERING,
      voipPortId: '1',
      user: {
        account: '00914422230060',
        number: '00914422230060',
        password: 'ftth@0060',
      },
      primarySIP: {
        sipDomain: 'chn.ftth.utims.bsnl.in',
        sipRegisterAddress: 'chn.ftth.utims.bsnl.in',
        port: 5060,
        sipProxyAddress: 'chn.ftth.utims.bsnl.in',
        proxyPort: 5060,
        outboundProxyAddress: 'chn.ftth.utims.bsnl.in',
        outboundProxyPort: null,
      },
      secondarySIP: {
        sipDomain: 'chn.ftth.utims.bsnl.in',
        sipRegisterAddress: 'chn.ftth.utims.bsnl.in',
        port: 5060,
        sipProxyAddress: 'chn.ftth.utims.bsnl.in',
        proxyPort: 5060,
        outboundProxyAddress: 'chn.ftth.utims.bsnl.in',
        outboundProxyPort: null,
      },
    },
    {
      status: VOIP_STATUS.REGISTERED,
      voipPortId: '2',
      user: {
        account: '00914422230070',
        number: '00914422230070',
        password: 'ftth@0070',
      },
      primarySIP: {
        sipDomain: 'chn.ftth.utims.bsnl.in',
        sipRegisterAddress: 'chn.ftth.utims.bsnl.in',
        port: 5070,
        sipProxyAddress: 'chn.ftth.utims.bsnl.in',
        proxyPort: 5070,
        outboundProxyAddress: 'chn.ftth.utims.bsnl.in',
        outboundProxyPort: null,
      },
      secondarySIP: {
        sipDomain: 'chn.ftth.utims.bsnl.in',
        sipRegisterAddress: 'chn.ftth.utims.bsnl.in',
        port: 5070,
        sipProxyAddress: 'chn.ftth.utims.bsnl.in',
        proxyPort: 5070,
        outboundProxyAddress: 'chn.ftth.utims.bsnl.in',
        outboundProxyPort: null,
      },
    },
  ],
};
