import { Alert, Button, Result, Typography } from 'antd';
import React, { ReactNode } from 'react';

import { EntityType } from 'store/types';
import { NavLink } from 'react-router-dom';
import { PERMISSIONS } from 'utils/roles&Permissions';
import { RootState } from 'store/redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface IPermissionProps {
  permission: string | string[];
  children: ReactNode;
  withAlert?: boolean;
  showAlertComponent?: boolean;
  alertComponent?: ReactNode;
}

const RoleBasedComponent = (props: IPermissionProps) => {
  const { auth } = useSelector((state: RootState) => state);
  const { t } = useTranslation();

  let isAllowed = false;
  let isSubscriptionBasedFeature = false;
  if (typeof props?.permission === 'string') {
    isAllowed = !!auth?.allowedPermissions?.find(
      (val) => val === props.permission,
    );
    isSubscriptionBasedFeature = props.permission?.startsWith(
      'subscriptionCategories.',
    );
  } else {
    var result = auth?.allowedPermissions?.filter(function (item) {
      return props.permission?.indexOf(item) > -1;
    });
    isAllowed = result.length > 0;
    isSubscriptionBasedFeature =
      result?.filter((val) => val?.startsWith('subscriptionCategories.'))
        ?.length > 0;
  }

  // The conditional change should reflect in the function handling also (hasPermission method in the same file)
  if (isAllowed) {
    return <>{props?.children}</>;
  } else if (props.showAlertComponent) {
    if (
      isSubscriptionBasedFeature &&
      [EntityType.ISP, EntityType.FRANCHISEE]?.includes(
        auth?.userInfo?.managementEntity?.type,
      )
    ) {
      return <UpgradeSubscriptionAlertComponent />;
    } else {
      return props?.alertComponent ? (
        <>{props?.alertComponent}</>
      ) : (
        <UnauthorizedErrorComponent />
      );
    }
  } else if (props?.withAlert) {
    return (
      <Alert
        type='error'
        description={t('Current User is not Authorised to perform this action')}
      />
    );
  } else return <></>;
};

export default RoleBasedComponent;

// #region - Role based validation and return boolean value
let allowedPermissions: string[] = [PERMISSIONS.MENUS.HOME];
export function setAllowedPermissions(permissionsList: string[]) {
  allowedPermissions = permissionsList;
}

// Condition should be similar to RoleBasedComponent check
export function hasPermission(permissionKey: string | string[]) {
  let isAllowed = false;
  if (typeof permissionKey === 'string') {
    isAllowed = !!allowedPermissions?.find((val) => val === permissionKey);
  } else {
    var result = allowedPermissions?.filter(function (item) {
      return permissionKey?.indexOf(item) > -1;
    });
    isAllowed = result.length > 0;
  }
  return isAllowed;
}

// #endregion

export const UnauthorizedErrorComponent = () => {
  const { t } = useTranslation();
  return (
    <Result
      status='403'
      title='403'
      subTitle={t('Sorry, you are not authorized to access this page.')}
      extra={
        <NavLink to={'/'} type=''>
          <Button type='primary'>{'Back Home'}</Button>
        </NavLink>
      }
    />
  );
};

export const UpgradeSubscriptionAlertComponent = () => {
  const { t } = useTranslation();

  return (
    <Result
      status='500'
      title={
        <Typography.Text>
          {t(`Please upgrade your plan to unlock this feature `)}
        </Typography.Text>
      }
    />
  );
};
