import { AuthState, IPagination, LOADING } from 'store/types';
import {
  IBulkActionSelectedVLANs,
  IVLANItem,
  IVLANReduxState,
  VLAN_BULK_ACTIONS,
  VLAN_DEFAULT_PAGE_SIZE,
  VLAN_TABS,
} from 'store/types/vlan.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { OperatorService } from 'apiUtils/services/operator.service';
import { formatError } from 'apiUtils/api';
import { getKeyFromEnumValue } from 'utils/utils';
import i18n from 'i18n';
import { isEmpty } from 'lodash';

interface IQueryParams {
  managementEntityId?: string;
}
const initialState: IVLANReduxState = {
  vlanList: {
    loading: true,
    error: '',
    data: [] as IVLANItem[],
  },
  currentTabId: '',
  bulkActionType: null,
  vlanListPagination: {
    pageToken: 1,
    pageSize: VLAN_DEFAULT_PAGE_SIZE,
  },
};

const fetchVLANList = createAsyncThunk(
  'vlanList/fetch',
  async (payload: IQueryParams | void, thunkAPI) => {
    const { auth, vlanState }: { auth: AuthState; vlanState: IVLANReduxState } =
      thunkAPI.getState() as any;
    const typeValue = getKeyFromEnumValue(vlanState?.currentTabId, VLAN_TABS);
    const response = await OperatorService.v1.GetVLANList({
      params: {
        type: typeValue,
        managementEntityId:
          payload?.managementEntityId || auth?.userInfo?.managementEntity?.id,
      },
    });
    if (response?.status === 200) {
      return response.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.vlanList'),
      };
    }
  },
);

const deleteVLAN = createAsyncThunk(
  'deleteVLANs/delete',
  async (
    payload: {
      vlanId?: string | number | null;
      managementEntityId?: string | null;
    } | void,
    thunkAPI,
  ) => {
    let { auth, vlanState }: { auth: AuthState; vlanState: IVLANReduxState } =
      thunkAPI.getState() as any;

    const vlanIdList =
      vlanState?.bulkActionType === VLAN_BULK_ACTIONS.BULK_DELETE
        ? vlanState?.bulkActionSelectedVLANs?.vlanIds
        : [];
    const data = {
      vlanIds: payload?.vlanId ? [payload?.vlanId] : vlanIdList,
      managementEntityId:
        payload?.managementEntityId || auth?.userInfo?.managementEntity?.id,
    };

    const response = await OperatorService.v1.DeleteVLAN({ data });

    if (response?.status === 204) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.vlanDelete'),
      };
    }
  },
);

export const VLANSlice = createSlice({
  name: 'vlan',
  initialState,
  reducers: {
    resetToInit: (state: IVLANReduxState) => {
      state.vlanList = {
        loading: true,
        error: '',
        data: [] as IVLANItem[],
      };
      state.currentTabId = '';
      state.bulkActionType = null;
      state.vlanListPagination = {
        pageToken: 1,
        pageSize: VLAN_DEFAULT_PAGE_SIZE,
      };
    },
    resetVLANState: (state: IVLANReduxState) => {
      state.vlanList = {
        loading: true,
        error: '',
        data: [] as IVLANItem[],
      };
      state.bulkActionType = null;
      state.vlanListPagination = {
        pageToken: 1,
        pageSize: VLAN_DEFAULT_PAGE_SIZE,
      };
    },

    setVLANListPagination: (
      state: IVLANReduxState,
      { payload }: { payload: IPagination },
    ) => {
      state.vlanListPagination = payload;
    },

    setVLANListPageToken: (
      state: IVLANReduxState,
      { payload }: { payload: number },
    ) => {
      state.vlanListPagination.pageToken = payload;
    },

    setCurrentTabId: (state: IVLANReduxState, action) => {
      state.currentTabId = action.payload;
      VLANSlice.caseReducers.resetVLANState(state);
    },
    setVLANBulkActionType: (
      state,
      { payload }: { payload: VLAN_BULK_ACTIONS | null | undefined },
    ) => {
      state.bulkActionType = payload;
    },
    setBulkActionsSelectedVLANId: (
      state: IVLANReduxState,
      { payload }: { payload: IBulkActionSelectedVLANs | null },
    ) => {
      state.bulkActionSelectedVLANs = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVLANList.pending, (state: IVLANReduxState) => {
      state.vlanList = { loading: true, error: null, data: null };
    });
    builder.addCase(
      fetchVLANList.fulfilled,
      (state: IVLANReduxState, { payload }: { payload: IVLANItem[] | any }) => {
        state.vlanList.loading = false;
        if (isEmpty(payload?.error)) {
          state.vlanList.data = payload;
        } else {
          state.vlanList.error = payload?.error;
        }
      },
    );

    builder.addCase(deleteVLAN.pending, (state) => {
      state.vlanDeleteRequestStatus = LOADING;
    });

    builder.addCase(
      deleteVLAN.fulfilled,
      (state, { payload }: { payload: any }) => {
        if (isEmpty(payload?.error)) {
          state.vlanDeleteRequestStatus = null;
        } else {
          state.vlanDeleteRequestStatus = payload.error;
        }
      },
    );
  },
});

export const VLANSliceActions = {
  ...VLANSlice.actions,
  fetchVLANList,
  deleteVLAN,
};

export const VLANReducer = VLANSlice.reducer;
