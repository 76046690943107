import { IReceiptList, IReceiptListState } from 'store/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { BillingRequester } from 'apiUtils/api';

const loadReceiptList = createAsyncThunk(
  'receiptList/fetch',
  async (_, thunkAPI) => {
    const { receiptListScreen }: { receiptListScreen: IReceiptListState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await BillingRequester.get(
      API_END_POINTS.BILLING.receipt,
      {
        params: {
          ...receiptListScreen?.receiptList?.receiptListFilter,
        },
      },
    ).catch(({ response }) => response);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  },
);

const initialState: IReceiptListState = {
  receiptList: {
    hardRefresh: false,
    loading: true,
    error: false,
    data: {} as IReceiptList,
    receiptListFilter: {
      pageToken: 1,
      pageSize: 10,
    },
  },
};

export const receiptListSlice = createSlice({
  name: 'receiptList',
  initialState,
  reducers: {
    // resetToInit: () => initialState,
    resetToInit: (state: IReceiptListState) => {
      state.receiptList = {
        hardRefresh: true,
        loading: true,
        error: false,
        data: {} as IReceiptList,
        receiptListFilter: {
          pageToken: 1,
          pageSize: 10,
        },
      };
    },
    setPageToken: (state, action) => {
      state.receiptList.receiptListFilter.pageToken = action.payload;
    },
    setPageSize: (state, action) => {
      state.receiptList.receiptListFilter.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadReceiptList.fulfilled,
      (state, { payload }: { payload: IReceiptList }) => {
        state.receiptList.loading = false;
        state.receiptList.hardRefresh = false;
        if (payload) {
          state.receiptList.data = payload;
          state.receiptList.error = false;
        } else {
          state.receiptList.error = true;
        }
      },
    );
  },
});

export const receiptListActions = {
  ...receiptListSlice.actions,
  loadReceiptList,
};
export const receiptReducer = receiptListSlice.reducer;
