import { CommonActions, RootState, useAppDispatch } from '../../store/redux';
import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const useBrowserTitle = (title: string) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(CommonActions.setTitle(t(title)));
  }, []);
};

const BrowserTabTitle = () => {
  const { common } = useSelector((state: RootState) => state);
  const title = common.tabTitle;
  const { t } = useTranslation();
  const appName = t(`common.appName.${process.env?.APP_NAME || 'Fii Viz'}`);

  return (
    <Helmet>
      <meta charSet='utf-8' />
      {(title && <title>{`${t(title)} | ${appName}`}</title>) || (
        <title>{appName}</title>
      )}
    </Helmet>
  );
};

export default React.memo(BrowserTabTitle);
