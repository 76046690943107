import { IFiltersDropdownOptions, IPagination } from 'store/types';

import { DeviceState } from './subscriber.types';
import { IOLTListItems } from './olt.types';
import { ReactNode } from 'react';
import i18n from 'i18n';

//  #region - Devices Menu
export interface IDevicesTabItems {
  key: string;
  title: ReactNode;
  icon?: ReactNode;
  hidden?: boolean;
  children: ReactNode;
}
export interface IDeviceState {
  oltList: {
    loading: boolean;
    error: string;
    data: IOLTListItems[];
  };
  overview: {
    loading: boolean;
    error: string;
    stats: IDeviceOverviewStats;
  };
  currentOnboardingActivities: {
    loading: boolean;
    error: string;
    data: ICurrentOnboardingActivities[];
  };
  deviceByProtocolCountStats: {
    loading: boolean;
    error: string;
    data: IDeviceByProtocolCountStats;
  };
  deviceStats: {
    loading: boolean;
    error: string;
    stats: IDeviceStats;
  };
  deviceStatusByOltList: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IDeviceStatusByOltData;
  };
  history: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IOLTHistoryData;
  };

  devicesByManufacturerStats: {
    loading: boolean;
    error: string;
    data: IDevicesByManufacturerStats;
  };
  devicesCountByOUIManufacturer: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IDeviceCountByOUIManufacturerData;
  };
  devicesCountByOUIEquipmentId: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IDeviceCountByOUIEquipmentIdData;
  };
  devicesCountByOUI: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IDeviceCountByOUIData;
  };
  enrolled: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IEnrolledDeviceData;
  };
  pending: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IPendingDeviceData;
  };
  assigned: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IAssignedDeviceData;
  };

  historyListPagination: IPagination;
  assignedListPagination: IPagination;
  enrolledListPagination: IPagination;
  pendingListPagination: IPagination;
  deviceListSearch: { searchText?: string | null };
  deviceFilters: IDeviceFilters;
  isFilterOpen: boolean;
  devicesFilterDropDownList: {
    operatorsList: {
      loading: boolean;
      error?: string | null;
      data: IManagementEntityList[];
    };
    manufacturerList: {
      loading: boolean;
      error?: string | null;
      data: string[];
    };
    hardwareModelList: {
      loading: boolean;
      error?: string | null;
      data: IFiltersDropdownOptions[];
    };
    hardwareVersionList: {
      loading: boolean;
      error?: string | null;
      data: IFiltersDropdownOptions[];
    };
    softwareVersionList: {
      loading: boolean;
      error?: string | null;
      data: IFiltersDropdownOptions[];
    };
    vlanList: {
      loading: boolean;
      error?: string | null;
      data: string[];
    };
  };
  currentTabId: string;
  currentOnboardingCategoryTabId?: string | null;
}
export interface IDeviceByProtocolCountStats {
  total: number;
  cwmp: number;
  agent: number;
  omci?: number;
  agentUpgradable?: number;
  unsupported?: number;
}
export interface IManagementEntityList {
  meId: string;
  name: string;
  type: string;
}

export interface IDeviceFilters {
  managementEntityId?: string | null;
  oltMac?: string | null;
  managementProtocol?: string | null;
  agentUpgradable?: boolean | null;
  deviceAssignmentStatus?: string | null;
  band?: string | null;
  manufacturer?: string | null;
  hwModel?: string | null;
  hwVersion?: string | null;
  vlanIds?: string[] | null;
  selectedVlanIds?: string[]; // Only for UI Purpose
  type?: string | null;
  swVersion?: string | null;
}

//  #region - Device Overview
export interface IDeviceOverviewStats {
  OLTCount: number;
  unsupported?: number;
  ONTOnboardingState: IONTOnboardingState;
}
export interface IONTOnboardingState {
  total: number; // Exclude Unsupported count
  pending: IONTOnboardingPendingState;
  onboarded: IONTOnboarded;
}
export interface IONTOnboarded {
  total: number;
  agent: number;
  cwmp: number;
  omci: number;
  multimode: number;
}
export interface IONTOnboardingPendingState {
  total: number;
  agent: number;
  cwmp: number;
  omci: number;
  multimode: number;
  automatic: number;
  manual: number;
}

export interface IDeviceStats {
  devices: {
    protocol: IDeviceStasProtocol;
    compatibility: {
      compatible: number;
      inCompatible: number;
      total: number;
      compatibleOntsPercentage: string;
    };
    band: {
      single: number;
      dual: number;
      unknown: number;
    };
    onboardingMode: IDeviceStatsOnboardingMode;
    total: number;
  };
}
export interface IDeviceStasProtocol {
  agent: number;
  cwmp: number;
  omci: number;
  multimode: number;
}
export interface IDeviceStatsOnboardingMode {
  automatic: number;
  manual: number;
}

export interface ICurrentOnboardingActivities {
  transactionId: string;
  status: OLT_TRANSACTION_STATUS;
  OLTMAC?: string;
  OLTPort?: string;
  portName?: string;
  portIndex?: string | null;
  createdTime: string;
  target: number;
  success: number;
  failed: number;
  inProgress: number;
}
//  #endregion - Device Overview

//  #region - Onboarding
export interface IDeviceStatusByOltData {
  items: IDeviceStatusByOltItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface IDeviceStatusByOltItems {
  OLTLabel: string;
  OLTMAC: string;
  OLTPort: string;
  portName?: string;
  portIndex?: string | null;
  enrolled: number;
  pending: number;
  online: number;
  offline: number;
}
export interface IOLTHistoryData {
  items: OLTHistoryItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface OLTHistoryItems {
  transactionId: string;
  status: string;
  OLTMAC: string;
  OLTPort: string;
  portName?: string;
  portIndex?: string | null;
  target: number;
  success: number;
  failed: number;
  inProgress: number;
  createdTime: string;
  retryable: boolean;
}
//  #endregion - Onboarding

//  #region - Onboarding Insights
export interface IDevicesByManufacturerStats {
  items: IDevicesByManufacturerStatsItem[];
  total: number;
}
export interface IDevicesByManufacturerStatsItem {
  manufacturer: string;
  count: number;
}
export interface IDeviceCountByOUIData {
  items: IDeviceCountByOUIItem[];
  total: number;
}
export interface IDeviceCountByOUIItem {
  oui: string;
  count: number;
}
export interface IDeviceCountByOUIManufacturerData {
  items: IDeviceCountByOUIManufacturerItem[];
  total: number;
}
export interface IDeviceCountByOUIManufacturerItem {
  oui: string;
  manufacturer: string;
  count: number;
}
export interface IDeviceCountByOUIEquipmentIdData {
  items: IDeviceCountByOUIEquipmentIdItem[];
  total: number;
}
export interface IDeviceCountByOUIEquipmentIdItem {
  oui: string;
  hardwareModel: string;
  hardwareVersion: string;
  equipmentId: string;
  count: number;
}
//  #rendegion - Onboarding Insights

//  #region - Enrolled
export interface IEnrolledDeviceData {
  items: IEnrolledDeviceItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface IEnrolledDeviceItems {
  OLTMAC: string;
  OLTLabel: string;
  OLTPort: string;
  portName?: string;
  portIndex?: string | null;
  ONTMAC: string;
  ONTNumber: string;
  PONSerialNumber: string;
  hwModel: string;
  hwVersion: string;
  subState: OLT_ONBOARDING_EVENTS;
  status: OLT_ONBOARDING_STATUS;
  deviceState: DeviceState | null;
  subscriberName: string;
  subscriberId: string;
  managementProtocol: string;
  vlanId?: string;
}
//  #endregion - Enrolled

//  #region - Pending
export interface IOltPendingTransactionData {
  items: IOltPendingTransactionItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface IOltPendingTransactionItems {
  OLTMAC: string;
  OLTPort: number;
  portName?: string;
  portIndex?: string | null;
  ONTMAC: string;
  ONTNumber: string;
  onboardingStatus: string;
  hwModel: string;
  hwVersion: string;
  band: string;
  manufacturer: string;
}

export interface IPendingDeviceData {
  items: IPendingDeviceItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface IPendingDeviceItems {
  ONTMAC: string;
  PONSerialNumber: string;
  ONTNumber: string;
  OLTMAC: string;
  OLTLabel: string;
  OLTPort: string;
  portName?: string;
  portIndex?: string | null;
  hwVersion: string;
  swVersion: string;
  vlanId?: string;
}
//  #endregion - Pending

//  #region - Assigned
export interface IAssignedDeviceData {
  items: IAssignedDeviceItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface IAssignedDeviceItems {
  OLTMAC: string;
  OLTLabel: string;
  OLTPort: string;
  portName?: string;
  portIndex?: string | null;
  ONTMAC: string;
  ONTNumber: string;
  PONSerialNumber: string;
  hwModel: string;
  hwVersion: string;
}
//  #endregion - Assigned

export interface IONTDeviceList {
  items: IONTDeviceData[];
  pageSize: number;
  pageToken: string;
  total: number;
}

export interface IONTDeviceData {
  portNumber: number;
  ontNumber: string;
  PONSerialNumber: string;
  vendorId?: string;
  hwVersion: string;
  hwModel?: string;
  uptime?: string;
  mainSwVer?: string;
  standbySwVer?: string;
  model?: string;
  equipmentId?: string;
  MAC: string;
  ontState?: string;
  subState?: string;
  status?: string;
}
export interface IFailedDevicesData {
  onboardingTransactionId: string;
  managementEntityId: string;
  oltLabel: string;
  oltMac: string;
  oltPort: string;
  ontList: IONTDeviceData[];
  createdTime: string;
  transactionStatus: string;
  retryable: boolean;
}
export interface IFailedDevicesData {
  onboardingTransactionId: string;
  managementEntityId: string;
  oltLabel: string;
  oltMac: string;
  oltPort: string;
  ontList: IONTDeviceData[];
  createdTime: string;
  transactionStatus: string;
  retryable: boolean;
}
//  #endregion - Devices Menu

//  #region - Enums and Constants

export enum PENDING_TYPE_DISPLAY_NAME {
  SUPPORTED = 'Agent Support',
  CWMP_SUPPORTED = 'TR-069 Support',
  UNSUPPORTED = 'Unsupported',
}

export enum PENDING_TYPE_VALUE {
  SUPPORTED = 'SUPPORTED',
  CWMP_SUPPORTED = 'CWMP_SUPPORTED',
  UNSUPPORTED = 'UNSUPPORTED',
}

export enum OLT_TRANSACTION_STATUS {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ABORTED = 'ABORTED',
}

export enum OLT_TRANSACTION_STATUS_VIEW {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  ABORTED = 'Aborted',
}

export enum OLT_ONBOARDING_STATUS {
  ONBOARDING_NOT_STARTED = 'ONBOARDING_NOT_STARTED',
  ONBOARDING_PENDING = 'ONBOARDING_PENDING',
  ONBOARDING_INPROGRESS = 'ONBOARDING_INPROGRESS',
  ONBOARDING_SUCCESS = 'ONBOARDING_SUCCESS',
  ONBOARDING_FAILED = 'ONBOARDING_FAILED',
  ONBOARDING_ABORTED = 'ONBOARDING_ABORTED',
}

export enum OLT_ONBOARDING_STATUS_VIEW {
  ONBOARDING_NOT_STARTED = 'Onboarding not started',
  ONBOARDING_PENDING = 'Onboarding Pending',
  ONBOARDING_INPROGRESS = 'Onboarding In-progress',
  ONBOARDING_SUCCESS = 'Onboarding Success',
  ONBOARDING_FAILED = 'Onboarding Failed',
  ONBOARDING_ABORTED = 'Onboarding Aborted',
}

export enum ONBOARDING_HISTORY_STATUS_VIEW {
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  TIMED_OUT = 'Timed Out',
  ONBOARDING_COMPLETE = 'Onboarding Complete',
  CANCELLED = 'Cancelled',
}
export enum OLT_ONBOARDING_TRANSACTION_STATUS_VIEW {
  NOT_STARTED = 'Not Started',
  INPROGRESS = 'In Progress',
  SUCCESS = 'Success',
  FAILED = 'Failed',
  ABORTED = 'Aborted',
  TIMED_OUT = 'Timed Out',
  CANCELLED = 'Cancelled',
}
export enum OLT_ONBOARDING_EVENTS {
  UPGRADE_STARTED = 'UPGRADE_STARTED',
  UPGRADE_FAILED = 'UPGRADE_FAILED',
  UPGRADE_SUCCESS = 'UPGRADE_SUCCESS',
  LAUNCHER_SUCCESS = 'LAUNCHER_SUCCESS',
  LAUNCHER_FAILED = 'LAUNCHER_FAILED',
  BOOTSTRAP_SUCCESS = 'NEW_DEVICE_BOOTSTRAP_SUCCESS',
  BOOTSTRAP_FAILED = 'NEW_DEVICE_BOOTSTRAP_FAILED',
  ABORTED = 'ABORTED',
}

export enum DEVICE_ASSIGNMENT_STATUS {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
}

export enum DEVICE_ASSIGNMENT_STATUS_DISPLAY_NAME {
  ASSIGNED = 'Assigned',
  UNASSIGNED = 'Unassigned',
}

export enum DEVICE_ONBOARDING_TYPE {
  AGENT = 'AGENT',
  OMCI = 'OMCI',
  AGENT_AS_OMCI = 'AGENT_AS_OMCI',
  CWMP_AS_OMCI = 'CWMP_AS_OMCI',
}

export enum DEVICE_ONBOARDING_TYPE_VIEW {
  AGENT = 'Agent',
  OMCI = 'OMCI',
  AGENT_AS_OMCI = 'Agent as OMCI',
  CWMP_AS_OMCI = 'CWMP as OMCI',
}

export enum OFFLINE_REASON_CODE_DISPLAY_NAME {
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN',
  POWERED_OFF = 'Powered Off',
  LOS = 'LOS',
  AUTH_FAIL = 'UNKNOWN',
  IP_DOWN = 'LOS',
  LOS_LINK_DOWN = 'LOS link down',
  UNDEFINED = 'UNDEFINED',
}
export type TDeviceTab =
  | 'overview'
  | 'onboarding'
  | 'enrolled'
  | 'pending'
  | 'assigned';

export const DEVICES_TABS = {
  OVERVIEW: 'overview',
  ONBOARDING: 'onboarding',
  ENROLLED: 'enrolled',
  PENDING: 'pending',

  ASSIGNED: 'assigned',
  DEVICE_ONBOARDING_CATEGORY_TABS: {
    OVERVIEW: 'overview',
    ONBOARDING_INSIGHTS: 'onboarding-insights',
  },
};

export enum DEVICE_BY_COUNT_METRICS {
  ENROLLED = 'enrolledDevicesByCount',
  PENDING = 'pendingDevicesByCount',
  BY_MANUFACTURER_OUI = 'byManufacturerOUI',
  BY_HARDWARE = 'byHardware',
  BY_UNKNOWN_HARDWWARE = 'byUnknownHardware',
}

export const ONBOARDING_INSIGHT_OUI_GROUPING_CATEGORIES = {
  BY_OUI: {
    label: i18n.t('labels.devices.unknownManufacturerDevicesCountByOUI'),
    path: 'devicesCountByOUI',
  },
  BY_OUI_MANUFACTURER: {
    label: i18n.t('labels.devices.devicesCountByOUIManufacturers'),
    path: 'devicesCountByOUIManufacturers',
  },
  BY_OUI_EQUIPMENT_ID: {
    label: i18n.t('labels.devices.devicesCountByOUIEquipmentId'),
    path: 'devicesCountByOUIEquipmentId',
  },
};

//  #endregion - Enums and Constants
