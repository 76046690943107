import '../../styles/Loader.less';

import { Layout, Spin, Typography } from 'antd';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { RootState } from 'store/redux';
// import { ReactComponent as SvgLoader } from '../../styles/images/loader.svg';
import { ReactComponent as SvgLoader } from '../../styles/images/fiiLoader.svg';
import { THEME } from 'store/types';
import { useSelector } from 'react-redux';

interface ILoader {
  width?: number;
  style?: React.CSSProperties;
}

const Loader = ({ width, style = {} }: ILoader) => {
  const [showWorkingOnIt, setShowWorkingOnIt] = useState(false);
  const toggleTimerRef: MutableRefObject<NodeJS.Timer | undefined> = useRef();
  const { common } = useSelector((state: RootState) => state);

  if (width !== undefined) {
    width = common.mobileView ? 50 : 60;
  }
  useEffect(() => {
    toggleTimerRef.current = setTimeout(() => {
      setShowWorkingOnIt(true);
    }, 5000);
    return () => {
      clearTimeout(toggleTimerRef.current);
    };
  }, []);

  return (
    <Layout.Content>
      <div className='page-loader' style={style}>
        {process.env?.THEME === THEME.FIIANALYTICS ? (
          <SvgLoader width={width} height={width} />
        ) : (
          <Spin size='large' />
        )}
        {showWorkingOnIt && (
          <Typography.Text type='secondary' className={'message'}>
            {'Working on it...'}
          </Typography.Text>
        )}
      </div>
    </Layout.Content>
  );
};
export default React.memo(Loader);
