import {
  ClientInfoResponse,
  IAddressableConfig,
  IClientsListData,
  IFirmwareStatusData,
  INatsDetails,
  ISubscriberDashboard,
  ISubscriberOverviewFilters,
  OPTICAL_STRENGTH_GRAPH_TYPE,
  OpticalFiberStatistics,
  Radio,
} from 'store/types/subscriberDashboard.types';
import { IPayloadError, OnlineStatus } from 'store/types';
import {
  ISubscriber,
  ISubscriberDeviceWLAN,
} from 'store/types/subscriber.types';
import {
  Subscriber2Requester,
  SubscriberRequester,
  VizRequester,
  formatError,
  formatURL,
} from 'apiUtils/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { COLOR_BY_STRENGTH } from '../.../../../../../constants';
import { VizService } from 'apiUtils/services/vizbff.service';
import { blue } from '@ant-design/colors';
import { isEmpty } from 'lodash';
import { message } from 'antd';

interface IOpticalStatsParams {
  duration: string;
}

const loadSubscriberDetails = createAsyncThunk(
  'subscriberDashboard/loadSubscriberDetails',
  async (payload: { subscriberId: string; polling: boolean }, thunkAPI) => {
    const {
      subscriberDashboard,
    }: { subscriberDashboard: ISubscriberDashboard } =
      thunkAPI.getState() as any;
    if (!subscriberDashboard.autoRefresh && payload.polling) {
      return { skip: true };
    }
    const response: AxiosResponse = await VizService.v2.GetSubscriberById(
      payload,
    );
    if (response?.status === 200) {
      const subscriberData = response.data;

      // //  Mock Handler
      // const subscriberData = SUBSCRIBER_DASHBOARD_MOCK;

      if (subscriberData.statsSupported) {
        const statsSupported: any = {};
        subscriberData.statsSupported?.forEach((stats: string) => {
          statsSupported[stats] = true;
        });
        subscriberData.statsSupported = statsSupported;
      }
      if (subscriberData.featuresAuthorized) {
        const featuresAuthorized: any = {};
        subscriberData.featuresAuthorized?.forEach((stats: string) => {
          featuresAuthorized[stats] = true;
        });
        subscriberData.featuresAuthorized = featuresAuthorized;
      }

      if (response?.data?.featuresSupported) {
        const featuresSupported: any = {};
        subscriberData.featuresSupported?.forEach((feature: string) => {
          featuresSupported[feature] = true;
        });
        subscriberData.featuresSupported = featuresSupported;
      }
      if (subscriberData.device?.radios) {
        let radios: any = {};
        subscriberData.device?.radios?.forEach((radio: Radio) => {
          radios[radio.frequency] = radio;
          response?.data?.device?.wlans?.forEach(
            (wlanData: ISubscriberDeviceWLAN) => {
              if (
                wlanData?.band === radio.frequency &&
                !wlanData?.id?.includes('vap')
              ) {
                radios[radio.frequency].interfaceName = wlanData.id;
                radios[radio.frequency].ssid = wlanData.ssid;
              }
            },
          );
        });
        subscriberData.device.radios = radios;
      }

      return subscriberData;
    } else {
      return false;
    }
  },
);

const loadCpuMemoryStats = createAsyncThunk(
  'subscriberDashboard/loadCpuMemoryStats',
  async (
    payload: {
      subscriberId: string;
      deviceMac: string;
      polling: boolean;
    },
    thunkAPI,
  ) => {
    const {
      subscriberDashboard,
    }: { subscriberDashboard: ISubscriberDashboard } =
      thunkAPI.getState() as any;

    if (!subscriberDashboard.autoRefresh && payload.polling) {
      return { skip: true };
    }
    if (!subscriberDashboard.overview.error) {
      const response: AxiosResponse = await VizRequester.get(
        formatURL(API_END_POINTS.SUBSCRIBER.clientInfo, payload),
        {
          params: {
            refresh: subscriberDashboard.autoRefresh,
          },
        },
      ).catch(({ response }) => response);
      if (response?.status === 200) {
        return response.data;
      }
    }
    return false;
  },
);

const requestNatsDetails = createAsyncThunk(
  'subscriber/natsDetails',
  async (payload, thunkAPI) => {
    const { subscriberDashboard } = thunkAPI.getState() as any;
    const response = await Subscriber2Requester.get(
      formatURL(API_END_POINTS.SUBSCRIBER.natsDetails, {
        subscriberId: subscriberDashboard?.overview?.details?.subscriberId,
      }),
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return thunkAPI.fulfillWithValue(response.data);
    } else {
      return thunkAPI.rejectWithValue(formatError(response));
    }
  },
);

const requestAddressableConfig = createAsyncThunk(
  'subscriber/addressableConfig',
  async (payload, thunkAPI) => {
    const { subscriberDashboard } = thunkAPI.getState() as any;
    const response = await SubscriberRequester.get(
      formatURL(API_END_POINTS.SUBSCRIBER.config, {
        subscriberId: subscriberDashboard?.overview?.details?.subscriberId,
        mac: subscriberDashboard?.overview?.details?.device?.mac,
      }),
    ).catch(({ response }) => response);
    if (response?.status >= 200 && response?.status < 300) {
      return thunkAPI.fulfillWithValue(response.data);
    } else {
      return thunkAPI.rejectWithValue(formatError(response));
    }
  },
);

const loadOpticalStrengthRXStats = createAsyncThunk(
  'opticalStrengthRXStats/fetch',
  async (payload: IOpticalStatsParams, thunkAPI) => {
    const {
      subscriberDashboard,
    }: { subscriberDashboard: ISubscriberDashboard } =
      thunkAPI.getState() as any;

    const response: AxiosResponse = await VizRequester.get(
      formatURL(API_END_POINTS.VIZ.opticalStrength, {
        subscriberId: subscriberDashboard?.overview?.details?.subscriberId,
        mac: subscriberDashboard?.overview?.details?.device?.mac,
      }),
      {
        params: {
          duration: payload.duration,
          type: OPTICAL_STRENGTH_GRAPH_TYPE.RX_POWER,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      const errorMsg: string =
        formatError(response) || 'messages.error.fetchChartData';
      return { error: errorMsg };
    }
  },
);

const loadOpticalStrengthTXStats = createAsyncThunk(
  'opticalStrengthTXStats/fetch',
  async (payload: IOpticalStatsParams, thunkAPI) => {
    const {
      subscriberDashboard,
    }: { subscriberDashboard: ISubscriberDashboard } =
      thunkAPI.getState() as any;

    const response: AxiosResponse = await VizRequester.get(
      formatURL(API_END_POINTS.VIZ.opticalStrength, {
        subscriberId: subscriberDashboard?.overview?.details?.subscriberId,
        mac: subscriberDashboard?.overview?.details?.device?.mac,
      }),
      {
        params: {
          duration: payload.duration,
          type: OPTICAL_STRENGTH_GRAPH_TYPE.TX_POWER,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      const errorMsg: string =
        formatError(response) || 'messages.error.fetchChartData';
      return { error: errorMsg };
    }
  },
);

const loadOpticalStrengthSFPStats = createAsyncThunk(
  'opticalStrengthSFPStats/fetch',
  async (payload: IOpticalStatsParams, thunkAPI) => {
    const {
      subscriberDashboard,
    }: { subscriberDashboard: ISubscriberDashboard } =
      thunkAPI.getState() as any;

    const response: AxiosResponse = await VizRequester.get(
      formatURL(API_END_POINTS.VIZ.opticalStrength, {
        subscriberId: subscriberDashboard?.overview?.details?.subscriberId,
        mac: subscriberDashboard?.overview?.details?.device?.mac,
      }),
      {
        params: {
          duration: payload.duration,
          type: OPTICAL_STRENGTH_GRAPH_TYPE.TEMPERATURE,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      const errorMsg: string =
        formatError(response) || 'messages.error.fetchChartData';
      return { error: errorMsg };
    }
  },
);

const loadOpticalStrengthBiasCurrentStats = createAsyncThunk(
  'opticalStrengthBiasCurrentStats/fetch',
  async (payload: IOpticalStatsParams, thunkAPI) => {
    const {
      subscriberDashboard,
    }: { subscriberDashboard: ISubscriberDashboard } =
      thunkAPI.getState() as any;

    const response: AxiosResponse = await VizRequester.get(
      formatURL(API_END_POINTS.VIZ.opticalStrength, {
        subscriberId: subscriberDashboard?.overview?.details?.subscriberId,
        mac: subscriberDashboard?.overview?.details?.device?.mac,
      }),
      {
        params: {
          duration: payload.duration,
          type: OPTICAL_STRENGTH_GRAPH_TYPE.BIAS_CURRENT,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      const errorMsg: string =
        formatError(response) || 'messages.error.fetchChartData';
      return { error: errorMsg };
    }
  },
);

const loadOpticalStrengthSupplyVoltageStats = createAsyncThunk(
  'opticalStrengthSupplyVoltageStats/fetch',
  async (payload: IOpticalStatsParams, thunkAPI) => {
    const {
      subscriberDashboard,
    }: { subscriberDashboard: ISubscriberDashboard } =
      thunkAPI.getState() as any;

    const response: AxiosResponse = await VizRequester.get(
      formatURL(API_END_POINTS.VIZ.opticalStrength, {
        subscriberId: subscriberDashboard?.overview?.details?.subscriberId,
        mac: subscriberDashboard?.overview?.details?.device?.mac,
      }),
      {
        params: {
          duration: payload.duration,
          type: OPTICAL_STRENGTH_GRAPH_TYPE.SUPPLY_VOLTAGE,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      const errorMsg: string =
        formatError(response) || 'messages.error.fetchChartData';
      return { error: errorMsg };
    }
  },
);

const loadClientsList = createAsyncThunk(
  'subscriberDashboard/loadClientsList',
  async (_, thunkAPI) => {
    const {
      subscriberDashboard,
    }: { subscriberDashboard: ISubscriberDashboard } =
      thunkAPI.getState() as any;
    if (!isEmpty(subscriberDashboard?.overview?.details?.subscriberId)) {
      const response: AxiosResponse = await VizRequester.get(
        formatURL(API_END_POINTS.SUBSCRIBER.clients, {
          subscriberId: subscriberDashboard?.overview?.details?.subscriberId,
        }),
      ).catch(({ response }) => response);
      if (response?.status === 200) {
        return response.data;
      } else {
        return {
          error:
            formatError(response) || 'Something went wrong. Please try again',
        };
      }
    }
    return false;
  },
);

const checkFirmwareUpdateStatus = createAsyncThunk(
  'subscriberDashboard/checkFirmwareStatus',
  async (_, thunkAPI) => {
    const {
      subscriberDashboard,
    }: { subscriberDashboard: ISubscriberDashboard } =
      thunkAPI.getState() as any;
    if (
      !isEmpty(subscriberDashboard?.overview?.details?.subscriberId) &&
      !subscriberDashboard?.isDeviceOffline
    ) {
      const response: AxiosResponse = await VizRequester.get(
        formatURL(API_END_POINTS.VIZ.checkFirmware, {
          subscriberId: subscriberDashboard?.overview?.details?.subscriberId,
        }),
      ).catch(({ response }) => response);
      if (response?.status === 200) {
        return response.data;
      } else {
        return {
          error:
            formatError(response) || 'Something went wrong. Please try again',
        };
      }
    }
    return false;
  },
);

const fetchONUByONT = createAsyncThunk(
  'subscriberDashboard/fetchONUByONT',
  async (_, thunkAPI) => {
    const {
      subscriberDashboard,
    }: { subscriberDashboard: ISubscriberDashboard } =
      thunkAPI.getState() as any;
    if (!isEmpty(subscriberDashboard?.overview?.details?.subscriberId)) {
      const response = await Subscriber2Requester.get(
        formatURL(API_END_POINTS.OLT_GATEWAY.getONUByONT, {
          ontNumber:
            subscriberDashboard?.overview?.details?.device?.oltDetails
              ?.ontNumber,
          oltMac:
            subscriberDashboard?.overview?.details?.device?.oltDetails?.mac,
          portNumber:
            subscriberDashboard?.overview?.details?.device?.oltDetails
              ?.portNumber,
        }),
        {
          params: {
            subscriberId: subscriberDashboard?.overview?.details?.subscriberId,
            portIndex:
              subscriberDashboard?.overview?.details?.device?.oltDetails
                ?.portIndex,
          },
        },
      ).catch(({ response }) => response);
      if (response?.status === 200) {
        if (!subscriberDashboard?.isOnuByOltTriggered) {
          message.success(
            'Request submitted successfully. Please reload the page after few mins',
          );
        }
        return response.data;
      } else {
        const errorMsg: string =
          formatError(response) ||
          'Sorry for the inconvenience. Unable to connect to server. Please try after sometime.';
        if (!subscriberDashboard?.isOnuByOltTriggered) {
          message.error(errorMsg);
        }
        return {
          error: errorMsg,
        };
      }
    }
    return false;
  },
);

const initialState: ISubscriberDashboard = {
  refreshInterval: 10000,
  autoRefresh: false,
  reloading: false,
  overview: {
    loading: true,
    error: false,
    details: {} as ISubscriber,
  },
  opticalRXStats: {
    loading: false,
    error: false,
    data: {} as OpticalFiberStatistics,
  },
  opticalTXStats: {
    loading: true,
    error: false,
    data: {} as OpticalFiberStatistics,
  },
  opticalSFPStats: {
    loading: true,
    error: false,
    data: {} as OpticalFiberStatistics,
  },
  opticalBiasCurrentStats: {
    loading: true,
    error: false,
    data: {} as OpticalFiberStatistics,
  },
  opticalSupplyVoltageStats: {
    loading: true,
    error: false,
    data: {} as OpticalFiberStatistics,
  },
  clientInfo: {
    loading: true,
    error: false,
    data: {} as ClientInfoResponse,
  },
  clientsList: {
    loading: true,
    error: '',
    data: {} as IClientsListData,
  },
  natsDetails: {} as INatsDetails,
  addressableConfig: {} as IAddressableConfig,
  addressableConfigLoading: true,
  reloadPingHistory: false,
  reloadTraceHistory: false,
  reloadSpeedTestHistory: false,
  isDeviceOffline: true,
  firmwareStatus: {
    loading: true,
    error: '',
    isFirmwareNotUptoDate: false,
    data: {} as IFirmwareStatusData,
  },
  onuByOlt: {
    loading: true,
    error: '',
  },
  isOnuByOltTriggered: true,
  overviewFilters: {
    opticalTimeRange: null,
  },
};

export const subscriberDashboardSlice = createSlice({
  name: 'subscriberDashboard',
  initialState,
  reducers: {
    resetToInit: (state: ISubscriberDashboard) => {
      state.autoRefresh = false;
      state.reloading = false;
      state.overview = {
        loading: true,
        error: false,
        details: {} as ISubscriber,
      };
      state.opticalRXStats = {
        loading: false,
        error: false,
        data: {} as OpticalFiberStatistics,
      };
      state.opticalTXStats = {
        loading: false,
        error: false,
        data: {} as OpticalFiberStatistics,
      };
      state.opticalSFPStats = {
        loading: false,
        error: false,
        data: {} as OpticalFiberStatistics,
      };
      state.opticalBiasCurrentStats = {
        loading: false,
        error: false,
        data: {} as OpticalFiberStatistics,
      };
      state.opticalSupplyVoltageStats = {
        loading: false,
        error: false,
        data: {} as OpticalFiberStatistics,
      };
      state.clientInfo = {
        loading: true,
        error: false,
        data: {} as ClientInfoResponse,
      };
      state.clientsList = {
        loading: true,
        error: '',
        data: {} as IClientsListData,
      };
      state.firmwareStatus = {
        loading: true,
        error: '',
        data: {} as IFirmwareStatusData,
        isFirmwareNotUptoDate: false,
      };
      state.onuByOlt = {
        loading: true,
        error: '',
      };
      state.natsDetails = {} as INatsDetails;
      state.addressableConfig = {} as IAddressableConfig;
    },
    setAutoRefresh: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.autoRefresh = payload;
    },
    setReloading: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.reloading = payload;
    },
    setIsOnuByOltTriggered: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.isOnuByOltTriggered = payload;
    },
    setReloadPingHistory: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.reloadPingHistory = payload;
    },
    setReloadTraceHistory: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.reloadTraceHistory = payload;
    },
    setReloadSpeedTestHistory: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.reloadSpeedTestHistory = payload;
    },
    setClientInfoStatus: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.clientInfo.loading = payload;
    },
    setOverviewFilters: (
      state: ISubscriberDashboard,
      { payload }: { payload: ISubscriberOverviewFilters },
    ) => {
      if (isEmpty(payload)) {
        state.overviewFilters = {};
      } else {
        state.overviewFilters = Object.assign(
          state.overviewFilters || {},
          payload,
        );
      }
    },
    setOpticalRXStatsLoading: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.opticalRXStats.loading = payload;
    },
    setOpticalTXStatsLoading: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.opticalTXStats.loading = payload;
    },
    setOpticalSFPStatsLoading: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.opticalSFPStats.loading = payload;
    },
    setOpticalBiasCurrentStatsLoading: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.opticalBiasCurrentStats.loading = payload;
    },
    setOpticalSupplyVoltageStatsLoading: (
      state: ISubscriberDashboard,
      { payload }: { payload: boolean },
    ) => {
      state.opticalSupplyVoltageStats.loading = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      loadSubscriberDetails.fulfilled,
      (state, { payload }: { payload: any }) => {
        if (!payload.skip) {
          state.overview.loading = false;
          state.reloading = false;
          if (payload) {
            state.overview.details = payload;
            state.isDeviceOffline =
              payload?.device?.onlineStatus === OnlineStatus.OFFLINE;
            state.clientInfo.error = false;
            state.overview.error = false;
          } else {
            state.overview.error = true;
          }
        }
      },
    );
    builder.addCase(
      loadCpuMemoryStats.pending,
      (state: ISubscriberDashboard, { payload }: { payload: any }) => {
        if (!state.autoRefresh) {
          state.clientInfo.loading = true;
        }
      },
    );
    builder.addCase(
      loadCpuMemoryStats.fulfilled,
      (state: ISubscriberDashboard, { payload }: { payload: any }) => {
        if (!payload.skip) {
          state.clientInfo.loading = false;
          state.reloading = false;
          if (payload) {
            state.clientInfo.data = payload;
            state.clientInfo.error = false;
          } else {
            state.clientInfo.error = true;
          }
        } else {
          state.clientInfo.loading = false;
        }
      },
    );

    builder.addCase(
      loadOpticalStrengthRXStats.pending,
      (state: ISubscriberDashboard) => {
        if (!state.autoRefresh) {
          state.opticalRXStats.loading = true;
        }
      },
    );
    builder.addCase(
      loadOpticalStrengthRXStats.fulfilled,
      (state: ISubscriberDashboard, { payload }: { payload: any }) => {
        state.opticalRXStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.opticalRXStats.data = payload as OpticalFiberStatistics;
          state.opticalRXStats.data?.timeSeries?.forEach((statsData) => {
            statsData.rxPower = Number(statsData?.rxPower?.toFixed(2));
            statsData.color = COLOR_BY_STRENGTH?.[statsData.strength];
          });
        } else {
          state.opticalRXStats.error = payload?.error;
        }
      },
    );

    builder.addCase(
      loadOpticalStrengthTXStats.pending,
      (state: ISubscriberDashboard) => {
        if (!state.autoRefresh) {
          state.opticalTXStats.loading = true;
        }
      },
    );
    builder.addCase(
      loadOpticalStrengthTXStats.fulfilled,
      (
        state: ISubscriberDashboard,
        { payload }: { payload: OpticalFiberStatistics | IPayloadError | any },
      ) => {
        state.opticalTXStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.opticalTXStats.data = payload as OpticalFiberStatistics;
          state.opticalTXStats.data?.timeSeries?.forEach((statsData) => {
            statsData.txPower = Number(statsData?.txPower?.toFixed(2));
            statsData.color = COLOR_BY_STRENGTH?.[statsData.strength];
          });
        } else {
          state.opticalTXStats.error = payload?.error;
        }
      },
    );

    builder.addCase(
      loadOpticalStrengthSFPStats.pending,
      (state: ISubscriberDashboard) => {
        if (!state.autoRefresh) {
          state.opticalSFPStats.loading = true;
        }
      },
    );
    builder.addCase(
      loadOpticalStrengthSFPStats.fulfilled,
      (
        state: ISubscriberDashboard,
        { payload }: { payload: OpticalFiberStatistics | IPayloadError | any },
      ) => {
        state.opticalSFPStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.opticalSFPStats.data = payload as OpticalFiberStatistics;
          state.opticalSFPStats.data?.timeSeries?.forEach((statsData) => {
            statsData.temperature = Number(statsData?.temperature?.toFixed(2));
            statsData.color = blue[4];
          });
        } else {
          state.opticalSFPStats.error = payload?.error;
        }
      },
    );

    builder.addCase(
      loadOpticalStrengthBiasCurrentStats.pending,
      (state: ISubscriberDashboard) => {
        if (!state.autoRefresh) {
          state.opticalBiasCurrentStats.loading = true;
        }
      },
    );
    builder.addCase(
      loadOpticalStrengthBiasCurrentStats.fulfilled,
      (
        state: ISubscriberDashboard,
        { payload }: { payload: OpticalFiberStatistics | IPayloadError | any },
      ) => {
        state.opticalBiasCurrentStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.opticalBiasCurrentStats.data =
            payload as OpticalFiberStatistics;
          state.opticalBiasCurrentStats.data?.timeSeries?.forEach(
            (statsData) => {
              statsData.temperature = Number(
                statsData?.temperature?.toFixed(2),
              );
              statsData.color = blue[4];
            },
          );
        } else {
          state.opticalBiasCurrentStats.error = payload?.error;
        }
      },
    );

    builder.addCase(
      loadOpticalStrengthSupplyVoltageStats.pending,
      (state: ISubscriberDashboard) => {
        if (!state.autoRefresh) {
          state.opticalSupplyVoltageStats.loading = true;
        }
      },
    );
    builder.addCase(
      loadOpticalStrengthSupplyVoltageStats.fulfilled,
      (
        state: ISubscriberDashboard,
        { payload }: { payload: OpticalFiberStatistics | IPayloadError | any },
      ) => {
        state.opticalSupplyVoltageStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.opticalSupplyVoltageStats.data =
            payload as OpticalFiberStatistics;
          state.opticalSupplyVoltageStats.data?.timeSeries?.forEach(
            (statsData) => {
              statsData.temperature = Number(
                statsData?.temperature?.toFixed(2),
              );
              statsData.color = blue[4];
            },
          );
        } else {
          state.opticalSupplyVoltageStats.error = payload?.error;
        }
      },
    );

    builder.addCase(loadClientsList.pending, (state: ISubscriberDashboard) => {
      if (!state.autoRefresh) {
        state.clientsList.loading = true;
      }
    });
    builder.addCase(
      loadClientsList.fulfilled,
      (
        state: ISubscriberDashboard,
        { payload }: { payload: IClientsListData | IPayloadError | any },
      ) => {
        state.clientsList.loading = false;
        state.reloading = false;
        if (isEmpty(payload?.error)) {
          state.clientsList.data = payload as IClientsListData;
        } else {
          state.clientsList.error = payload?.error;
        }
      },
    );
    builder.addCase(
      requestNatsDetails.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.natsDetails = payload;
      },
    );
    builder.addCase(requestAddressableConfig.pending, (state) => {
      state.addressableConfigLoading = true;
    });
    builder.addCase(
      requestAddressableConfig.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.addressableConfigLoading = false;
        state.addressableConfig = payload;
      },
    );
    builder.addCase(requestAddressableConfig.rejected, (state) => {
      state.addressableConfigLoading = false;
    });

    builder.addCase(
      checkFirmwareUpdateStatus.pending,
      (state: ISubscriberDashboard, { payload }: { payload: any }) => {
        state.firmwareStatus.loading = true;
        state.firmwareStatus.error = '';
      },
    );
    builder.addCase(
      checkFirmwareUpdateStatus.fulfilled,
      (state: ISubscriberDashboard, action) => {
        state.firmwareStatus.loading = false;
        if (isEmpty(action?.payload?.error)) {
          state.firmwareStatus.data = action?.payload as IFirmwareStatusData;
          // state.firmwareStatus.isFirmwareNotUptoDate = [
          //   FIRMWARE_UPGRADE_STATUS.FW_NOT_UP_TO_DATE_UPGRADE_ALLOWED,
          //   FIRMWARE_UPGRADE_STATUS.FW_NOT_UP_TO_DATE_UPGRADE_NOT_ALLOWED,
          // ]?.includes(action?.payload?.status);
        } else {
          state.firmwareStatus.error = action?.payload?.error;
        }
      },
    );

    builder.addCase(
      fetchONUByONT.pending,
      (state: ISubscriberDashboard, { payload }: { payload: any }) => {
        state.onuByOlt.loading = true;
        state.onuByOlt.error = '';
      },
    );
    builder.addCase(
      fetchONUByONT.fulfilled,
      (state: ISubscriberDashboard, action) => {
        state.onuByOlt.loading = false;
        if (isEmpty(action?.payload?.error)) {
          // state.firmwareStatus.data = action?.payload as IFirmwareStatusData;
        } else {
          state.onuByOlt.error = action?.payload?.error;
        }
      },
    );
  },
});

export const SubscriberDashboardActions = {
  ...subscriberDashboardSlice.actions,
  loadSubscriberDetails,
  loadCpuMemoryStats,
  loadOpticalStrengthRXStats,
  loadOpticalStrengthTXStats,
  loadOpticalStrengthSFPStats,
  loadOpticalStrengthBiasCurrentStats,
  loadOpticalStrengthSupplyVoltageStats,
  loadClientsList,
  requestNatsDetails,
  requestAddressableConfig,
  checkFirmwareUpdateStatus,
  fetchONUByONT,
};

export const SubscriberDashboardReducer = subscriberDashboardSlice.reducer;
