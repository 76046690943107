import {
  IManagementEntityDetails,
  IPagination,
  ManagementEntity,
} from 'store/types';
import {
  IconDefinition,
  faBell,
  faBellSlash,
} from '@fortawesome/free-solid-svg-icons';
import { blue, green, grey, orange, red, volcano } from '@ant-design/colors';

import { COLOR_BY_SUBSCRIBER_STATUS } from '../../constants';
import { IOLTListItems } from './olt.types';

export enum ALERT_TYPE_VIEW {
  ONT_LOS = 'Offline due to LOS ',
  ONT_LINK_FAULT = 'ONT Link Fault',
  POOR_SIGNAL = 'Poor Signal',
  TRIAL_EXPIRED = 'Trial Expired',
  ONT_LOS_PER_PORT = 'ONT LOS Per Port',
  ONT_LINK_FAULT_PER_PORT = 'ONT Link Fault Per Port',
  POOR_SIGNAL_PER_PORT = 'Poor Signal Per Port',
  FII_LINK_REACHABILITY = 'FiiLink Reachability',
  FII_LINK_HEALTH = 'FiiLink Health',
}

export enum ALERT_TYPE {
  ONT_LOS = 'ONT_LOS',
  ONT_LINK_FAULT = 'ONT_LINK_FAULT',
  POOR_SIGNAL = 'POOR_SIGNAL',
  TRIAL_EXPIRED = 'TRIAL_EXPIRED',
  ONT_LOS_PER_PORT = 'ONT_LOS_PER_PORT',
  ONT_LINK_FAULT_PER_PORT = 'ONT_LINK_FAULT_PER_PORT',
  POOR_SIGNAL_PER_PORT = 'POOR_SIGNAL_PER_PORT',
  FII_LINK_REACHABILITY = 'FII_LINK_REACHABILITY',
  FII_LINK_HEALTH = 'FII_LINK_HEALTH',
}

export enum ALARM_NAME {
  LOS = 'LOS',
  POOR_SIGNAL_RX = 'POOR_SIGNAL_RX',
  POOR_SIGNAL_TX = 'POOR_SIGNAL_TX',
}

export enum ALARM_EVENT_TYPE {
  OLT = 'OLT',
  ONU = 'ONU',
  ALARM = 'ALARM',
}

export enum ALARM_EVENT_TYPE_VIEW {
  OLT = 'OLT',
  ONU = 'ONU',
  ALARM = 'Alarm',
}

export enum EVENT_STATUS {
  RAISED = 'RAISED',
  RESOLVED = 'RESOLVED',
  NEW = 'NEW',
}

export enum EVENT_STATUS_VIEW {
  RAISED = 'Raised',
  RESOLVED = 'Resolved',
  NEW = 'New',
}

export interface IAlertItem {
  id: string;
  alertType: ALERT_TYPE;
  alertLevel: ALARM_LEVEL;
  msg: string;
  managementEntityId: string;
  managementEntities: ManagementEntity[];
  time: string;
  status: EVENT_STATUS_VIEW;
  filters?: {
    oltMac: string;
    oltPort: string;
  } | null;
}

export interface IAlarmState {
  autoRefresh: boolean;
  alarmEventList: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IAlarmEventData;
  };
  alarmSummaryList: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IAlarmSummaryList[];
  };
  alarmSummaryStats: {
    loading: boolean;
    error: string;
    stats: IAlarmSummaryStats;
  };

  alarmEventListPagination: IPagination;
  alarmEventListPageTokens: string[];
  hasNextPageToken: boolean;
  alarmFilters: IAlarmsFilter;
  isFilterOpen: boolean;
  currentTabId: string;
  alarmFilterDropDownList: {
    oltFilter: {
      loading: boolean;
      error: string;
      oltList: IOLTListItems[];
    };
    managementEntityList: IManagementEntityDetails[];
    supportAlerts: ISupportedAlerts[];
  };
  subscriberIdLoading: boolean;
}

export interface ISupportedAlerts {
  name: string;
  label: string;
}

export interface IAlarmsFilter {
  managementEntityIds?: string;
  eventType?: string;
  oltMac?: string;
  oltPortIndex?: string;
  eventName?: string;
  duration?: string;
  dateRange?: any[];
  startTime?: any;
  endTime?: any;
  status?: string;
}

export interface IAlarmEventData {
  items: IAlarmEventItem[];
  pageSize: number;
  pageToken: number;
  total: number;
}

export interface IAlarmEventItem {
  id: string;
  alertType: ALERT_TYPE;
  msg: string;
  managementEntityId: string;
  managementEntities: ManagementEntity[];
  time: string;
  displayName?: string;
  // status: ALERT_STATUS;

  affectedOltDetails: {
    label: string;
    ponPort: string;
    oltMac: string;
    ontNumber: string;
    ipAddress: string;
  };

  filters?: {
    oltMac: string;
    oltPort: string;
  } | null;

  olt?: string;
  status?: string;
  category?: string;
  ponPort?: number;
  serialNumber?: string;
  reasonCode?: string;
  trapType?: string;
  mac?: string;
  deviceMac?: string;
}

export interface IAlarmSummaryList {
  type: ALERT_TYPE | any;
  displayName: string;
  config: IAlarmStatsConfig;
  affectedSubscriberCount: number;
  affectedOltCount: number;
  status: {
    current: string;
    updatedTime: string;
    previous: string;
  };
  affectedOltDetails: IAffectedOLT[];
}

export interface IAffectedOLT {
  oltName: string;
  oltMac: string;
  affectedDevice: number;
  ponPortDetails: IPonPortDetails[];
  oltIp: string;
  meName: string;
  meType: string;
  meId: string;
  ponPortCount: number;
  affectedSubscriberCount: number;
}

export interface IPonPortDetails {
  ponPort: string;
  affectedSubscribers: string[];
}

export interface IAffectedSubscribers {
  id: string;
  name: string;
  mac: string;
  serialNumber: string;
  portIndex: string;
}

export interface IAlarmSummaryStats {
  olt: {
    total: number;
    ipReachability: number;
    telnetConnectivity: number;
    SNMPConnectivity: number;
    online: number;
  };
  device: {
    online: {
      total: number;
      poorRXSignal: number;
      poorTXSignal: number;
    };
    offline: {
      total: number;
      LOS: number;
      poweredOff: number;
      unknown: number;
    };
  };
}

export interface IAlarmsConfig {
  status: ALARM_CONFIG_STATUS;
  poorSignalRx: IAlarmConfigItem;
  poorSignalTx: IAlarmConfigItem;
  los: IAlarmConfigItem;
}

export interface IAlarmConfigItem {
  isEdit?: boolean; // Only for UI to handle Form editable flag
  state: ALARM_CONFIG_STATUS;
  alertMode?: ALARM_CONFIG_MODE;
  threshold?: IAlarmConfigThreshold;
  intervalInMinutes: number;
  lastUpdated: IAlarmConfigLastUpdated;
  notifications: IAlarmNotifications;
}

export interface IAlarmConfigLastUpdated {
  time: string;
  userId: string;
  userName: string;
  pausedTime?: string;
}

export interface IAlarmConfigThreshold {
  type: ALERT_CONFIG_THRESHOLD_TYPE;
  value: number;
}

export interface IAlarmNotifications {
  pushNotificationEnabled: boolean;
}

export enum ALARM_CONFIG_STATUS {
  UNCONFIGURED = 'UNCONFIGURED',
  DISABLED = 'DISABLED',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
}

export enum ALARM_CONFIG_MODE {
  PON_PORT = 'PON_PORT',
}
export enum ALERT_CONFIG_THRESHOLD_TYPE {
  COUNT = 'COUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum ALARM_FORM_FIELD_NAME {
  POOR_SIGNAL_RX = 'poorSignalRx',
  POOR_SIGNAL_TX = 'poorSignalTx',
  ONT_LOS = 'los',
}

export enum ALERT_CONFIG_STATUS_VIEW {
  UNCONFIGURED = 'Unconfigured',
  DISABLED = 'Disabled',
  ACTIVE = 'Active',
  PAUSED = 'Paused',
}
export enum ALERT_CONFIG_MODE_VIEW {
  OVERALL_NETWORK = 'Overall Network',
  PON_PORT = 'PON',
  ANY_PON_PORT = 'Any PON Port',
}
export enum ALERT_CONFIG_THRESHOLD_TYPE_VIEW {
  COUNT = 'Count',
  PERCENTAGE = 'Percentage',
}

export interface IAlarmStatsConfig {
  state?: ALARM_CONFIG_STATUS;
  alertMode?: ALARM_CONFIG_MODE;
  threshold?: IAlarmConfigThreshold;
  intervalInMinutes?: number;
  notifications: IAlarmNotifications;
  lastUpdated?: IAlarmConfigLastUpdated;
}

export interface IAlarmConfigLastUpdated {
  time: string;
  userId: string;
  userName: string;
}

export interface IAlertStatsAlarm {
  state: ALARM_STATE;
  lastUpdateTime: string;
  stateChangeTime: string;
  status: string;
}

export enum ALARM_STATE {
  ON = 'ON',
  OFF = 'OFF',
}

export enum ALARM_LEVEL {
  INFO = 'INFO',
  CRITICAL = 'CRITICAL',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}
export const Alarm_Tabs = {
  SUMMARY: 'summary',
  EVENTS: 'events',
};

export enum OLT_ALARM_EVENT_VALUE {
  IP_ADDRESS_NOT_REACHABLE = 'IP_ADDRESS_NOT_REACHABLE',
  SNMP_AGENT_NOT_REACHABLE = 'SNMP_AGENT_NOT_REACHABLE',
}

export const COLOR_BY_ALERT_CONFIG_STATUS: {
  [key in ALARM_CONFIG_STATUS]: string;
} = {
  UNCONFIGURED: grey[0],
  DISABLED: grey[1],
  ACTIVE: green[7],
  PAUSED: red[4],
};

export const COLOR_BY_ALARM_NOTIFICATION: {
  [key in 'PAUSED' | 'ACTIVE']: string;
} = {
  PAUSED: red[4],
  ACTIVE: blue[3],
};

export const COLOR_BY_ALARM_STATUS = {
  RAISED: red[4],
  RESOLVED: green[4],
  NEW: green[4],
};

export const ALARM_STATUS_VIEW = {
  RAISED: 'Raised',
  RESOLVED: 'OK',
  NEW: 'OK',
};
export const ALARM_NOTIFICATION_ICON: {
  [key in 'PAUSED' | 'ACTIVE']: IconDefinition;
} = {
  PAUSED: faBellSlash,
  ACTIVE: faBell,
};

export const ALARM_CATEGORY_VIEW = {
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  INVALID: 'Invalid',
};

export const ALARM_CATEGORY_COlOR = {
  ONLINE: COLOR_BY_SUBSCRIBER_STATUS.ONLINE,
  OFFLINE: COLOR_BY_SUBSCRIBER_STATUS.OFFLINE,
  INVALID: '#bfbfbf',
  'Poor Tx Signal': volcano[3],
  'Poor Rx Signal': orange[3],
  'Loss Of Signal': red[3],
};

export const ALARM_AUTO_REFRESH_TIMEOUT_INTERVAL = 30000; // 30 Seconds
