import {
  ILANConfigForm,
  IWLANConfigForm,
  LAN_DNS_MODE,
} from 'store/types/subscriber.types';

export const WLAN_CONFIG_LIST_MOCK: IWLANConfigForm[] = [
  {
    enabled: true,
    bssid: '4c:ae:1c:3a:b4:79',
    encryption: 'WPA2 Mixed',
    wpaEncryptionMode: 'TKIP',
    ieee11iEncryptionMode: 'AES',
    guest: false,
    hidden: false,
    operationMode: 'InfrastructureAccessPoint',
    security: null,
    ssid: 'DBC-Wifi-5G',
    id: '1',
    band: '5.0 GHz',
    channel: 40,
    channelWidth: '80MHz',
    ssidAdvertisementEnabled: true,
    wmmEnable: true,
    blockRelay: false,
    guardInterval: null,
    totalAssociations: 0,
    wpaGroupRekeyTime: null,
    radioPower: '100',
    regulatoryDomain: 'FCC',
  },
  {
    enabled: true,
    bssid: '4c:ae:1c:3a:b4:79',
    encryption: 'WPA2 Mixed',
    wpaEncryptionMode: 'TKIP',
    ieee11iEncryptionMode: 'AES',
    guest: false,
    hidden: false,
    operationMode: 'InfrastructureAccessPoint',
    security: null,
    ssid: 'DBC-Wifi-2.4G',
    id: '5',
    band: '2.4 GHz',
    channel: 6,
    channelWidth: '20MHz',
    ssidAdvertisementEnabled: true,
    wmmEnable: true,
    blockRelay: false,
    guardInterval: null,
    totalAssociations: 0,
    wpaGroupRekeyTime: null,
    radioPower: '100',
    regulatoryDomain: 'FCC',
  },
];

export const LAN_CONFIG_MOCK: ILANConfigForm = {
  ip: '192.168.1.1',
  subnetMask: '255.255.255.0',
  startIp: '192.168.1.33',
  endIp: '192.168.1.254',
  dhcpGwIp: '192.168.1.1',
  leaseTimeInSeconds: '86400',
  dnsMode: LAN_DNS_MODE.DYNAMIC,
  primaryDnsServer: null,
  secondaryDnsServer: null,
  tertiaryDnsServer: null,
};
