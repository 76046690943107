import { CPDashboardState, ICPOverview, IOperatorStats } from 'store/types';
import { OperatorRequester, formatURL } from 'apiUtils/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';

const loadOperatorStats = createAsyncThunk(
  'cpDashboard/loadOperatorStats',
  async (payload: { id: string }, thunkAPI) => {
    const response: AxiosResponse = await OperatorRequester.get(
      API_END_POINTS.OPERATORS.statsCounts,
      {
        params: {
          ...payload,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
    } else {
      return false;
    }
  },
);

const loadCpDetails = createAsyncThunk(
  'cpDashboard/loadCpDetails',
  async (payload: { partnerId: string }, thunkAPI) => {
    const response: AxiosResponse = await OperatorRequester.get(
      formatURL(API_END_POINTS.OPERATORS.channelPartnerByID, payload),
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
    } else {
      return false;
    }
  },
);

const initialState: CPDashboardState = {
  overview: {
    loading: true,
    error: false,
    details: {} as ICPOverview,
  },
  stats: {
    operator: {
      loading: true,
      error: false,
      unknownDevice: 0,
      oltCount: 0,
      unassignedDeviceCount: 0,
      managementEntity: {
        channelPartner: 0,
        franchisee: 0,
        isp: 0,
        // distributor: 0,
      },
    },
  },
  subscriberTrend: {
    loading: true,
  },
  FWStats: {
    loading: true,
  },
  HWStats: {
    loading: true,
  },
  subscriberByPendingStats: {
    loading: true,
  },
  operatorBySubscriberCount: {
    loading: true,
  },
  filter: {
    id: '',
  },
};

export const cpDashboardSlice = createSlice({
  name: 'cpDashboard',
  initialState,
  reducers: {
    resetToInit: (state: CPDashboardState) => {
      state.overview = {
        loading: true,
        error: false,
        details: {} as ICPOverview,
      };
      state.stats = {
        operator: {
          loading: true,
          error: false,
          unknownDevice: 0,
          oltCount: 0,
          unassignedDeviceCount: 0,
          managementEntity: {
            channelPartner: 0,
            franchisee: 0,
            isp: 0,
            // distributor: 0,
          },
        },
      };
      state.FWStats = {
        loading: true,
      };
      state.HWStats = {
        loading: true,
      };
      state.subscriberByPendingStats = {
        loading: true,
      };
      state.subscriberTrend = {
        loading: true,
      };
      state.operatorBySubscriberCount = {
        loading: true,
      };
    },
    setFwStatus: (
      state: CPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.FWStats.loading = payload;
    },
    setHwStatus: (
      state: CPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.HWStats.loading = payload;
    },
    setSubscriberByPendingStatus: (
      state: CPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.subscriberByPendingStats.loading = payload;
    },
    subscriberTrendStatus: (
      state: CPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.subscriberTrend.loading = payload;
    },
    operatorBySubscriberCountStatus: (
      state: CPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.operatorBySubscriberCount.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadOperatorStats.fulfilled,
      (state, { payload }: { payload: IOperatorStats }) => {
        state.stats.operator.loading = false;
        if (payload) {
          state.stats.operator.unknownDevice = payload.unknownDevice;
          state.stats.operator.oltCount = payload.oltCount;
          state.stats.operator.unassignedDeviceCount =
            payload.unassignedDeviceCount;
          state.stats.operator.managementEntity = payload.managementEntity;
          state.stats.operator.error = false;
        } else {
          state.stats.operator.error = true;
        }
      },
    );
    builder.addCase(
      loadCpDetails.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.overview.loading = false;
        if (payload) {
          state.overview.details = payload;

          let filter: any = {
            id: payload.id,
          };
          state.filter = filter;
        } else {
          state.overview.error = true;
        }
      },
    );
  },
});

export const CPDashboardActions = {
  ...cpDashboardSlice.actions,
  loadOperatorStats,
  loadCpDetails,
};

export const CPDashboardReducer = cpDashboardSlice.reducer;
