import { API_VERSIONS, TAPI_VERSION, getRequest } from 'apiUtils/axiosRequest';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { createAxiosInstance, formatURL } from 'apiUtils/api';

import { API_END_POINTS } from 'apiUtils/urls';
import { IManagementEnityIdPathParam } from 'store/types';
import { INetworkHierarchyPathParams } from 'store/types/splitter.types';
import { IOLTDashboardStatsPathParams } from 'store/types/olt.types';

const VIZ_BASE_URL = process.env.API_VIZ?.replace('v1/', '');
const VizInstance = createAxiosInstance(VIZ_BASE_URL);

interface ISubscriberIdPathParam {
  subscriberId: string;
}

export interface IVizService {
  //  #region - Dropdown List
  GetOLTList: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetMgmtEntityOLTList: (
    pathParams: IManagementEnityIdPathParam,
  ) => Promise<AxiosResponse>;
  GetFilters: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetDevicePendingFilters: (
    config: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetTimeRangeOptions: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetOfflineDurationRange: () => Promise<AxiosResponse>;
  GetReportTypes: () => Promise<AxiosResponse>;
  //  #endregion - Dropdown List

  //  #region - Devices
  GetOLTOnboardingStats: (
    pathParams: IManagementEnityIdPathParam,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetDeviceStats: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetDeviceByProtocolCountStats: (
    config: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetOLTPendingActivities: (
    pathParams: IManagementEnityIdPathParam,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetDeviceStatusByOLT: (
    pathParams: IManagementEnityIdPathParam,
  ) => Promise<AxiosResponse>;
  GetOLTHistory: (
    pathParams: IManagementEnityIdPathParam,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetDevicesCountByManufacturerStats: (
    pathParams: { deviceOnboardingCategory: string },
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetDevicesCountByOUI: (
    pathParams: { deviceOnboardingCategory: string },
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetEnrolledList: (
    pathParams: IManagementEnityIdPathParam,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetPendingList: (
    pathParams: IManagementEnityIdPathParam,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetAssignedList: (
    pathParams: IManagementEnityIdPathParam,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  //  #endregion - Devices

  //  #region - OLT Dashboard
  GetOLTCardStats: (
    pathParams: IOLTDashboardStatsPathParams,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetOLTDashboardDeviceStats: (
    pathParams: IOLTDashboardStatsPathParams,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetOLTOpticalStats: (
    pathParams: IOLTDashboardStatsPathParams,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetONTDevicesList: (
    pathParams: IOLTDashboardStatsPathParams,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetNetworkHierarchy: (
    pathParams: INetworkHierarchyPathParams,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetOLTGoogleMap: (
    pathParams: INetworkHierarchyPathParams,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  //  #endregion - OLT Dashboard

  //  #region - Subscriber
  GetSubscriberById: (
    pathParam: ISubscriberIdPathParam,
  ) => Promise<AxiosResponse>;
  //  #endregion - Subscriber
}

export const VizService: { [key in TAPI_VERSION]: IVizService } = Object.keys(
  API_VERSIONS,
)?.reduce((res: any, el: any) => {
  const version = API_VERSIONS[el as keyof typeof API_VERSIONS];

  return {
    ...res,
    [el]: {
      //  #region - Dropdown List
      GetOLTList: async (config: AxiosRequestConfig) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(API_END_POINTS.VIZ.listOlts, config)}`,
        );
      },
      GetMgmtEntityOLTList: async (pathParams: IManagementEnityIdPathParam) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(API_END_POINTS.VIZ.oltList, pathParams)}`,
        );
      },
      GetFilters: async (config: AxiosRequestConfig) => {
        return await getRequest(
          VizInstance,
          `${version}${API_END_POINTS.VIZ.filters}`,
          config,
        );
      },
      GetDevicePendingFilters: async (config: AxiosRequestConfig) => {
        return await getRequest(
          VizInstance,
          `${version}${API_END_POINTS.VIZ.pendingDeviceFilters}`,
          config,
        );
      },
      GetTimeRangeOptions: async (config: AxiosRequestConfig) => {
        return await getRequest(
          VizInstance,
          `${version}${API_END_POINTS.VIZ.timeRangeOptions}`,
          config,
        );
      },
      GetOfflineDurationRange: async () => {
        return await getRequest(
          VizInstance,
          `${version}${API_END_POINTS.VIZ.offlineDurationRange}`,
        );
      },
      GetReportTypes: async () => {
        return await getRequest(
          VizInstance,
          `${version}${API_END_POINTS.VIZ.reportTypes}`,
        );
      },
      //  #endregion - Dropdown List

      //  #region - Devices
      GetOLTOnboardingStats: async (
        pathParams: IManagementEnityIdPathParam,
        config: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(
            API_END_POINTS.VIZ.oltOnboardingStats,
            pathParams,
          )}`,
          config,
        );
      },
      GetDeviceStats: async (config: AxiosRequestConfig) => {
        return await getRequest(
          VizInstance,
          `${version}${API_END_POINTS.VIZ.deviceStats}`,
          config,
        );
      },
      GetDeviceByProtocolCountStats: async (config: AxiosRequestConfig) => {
        return await getRequest(
          VizInstance,
          `${version}${API_END_POINTS.VIZ.deviceByProtocolCountStats}`,
          config,
        );
      },
      GetOLTPendingActivities: async (
        pathParams: IManagementEnityIdPathParam,
        config: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(
            API_END_POINTS.VIZ.oltPendingActivities,
            pathParams,
          )}`,
          config,
        );
      },
      GetDeviceStatusByOLT: async (pathParams: IManagementEnityIdPathParam) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(
            API_END_POINTS.VIZ.deviceStatusByOlt,
            pathParams,
          )}`,
        );
      },
      GetOLTHistory: async (
        pathParams: IManagementEnityIdPathParam,
        config: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(API_END_POINTS.VIZ.oltHistory, pathParams)}`,
          config,
        );
      },
      GetDevicesCountByManufacturerStats: async (
        pathParams: { deviceOnboardingCategory: string },
        config: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(
            API_END_POINTS.VIZ.devicesCountByManufacturerStats,
            pathParams,
          )}`,
          config,
        );
      },
      GetDevicesCountByOUI: async (
        pathParams: { deviceOnboardingCategory: string },
        config: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(
            API_END_POINTS.VIZ.devicesCountByOUI,
            pathParams,
          )}`,
          config,
        );
      },
      GetEnrolledList: async (
        pathParams: IManagementEnityIdPathParam,
        config: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(API_END_POINTS.VIZ.enrolledList, pathParams)}`,
          config,
        );
      },
      GetPendingList: async (
        pathParams: IManagementEnityIdPathParam,
        config: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(API_END_POINTS.VIZ.pendingList, pathParams)}`,
          config,
        );
      },
      GetAssignedList: async (
        pathParams: IManagementEnityIdPathParam,
        config: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(API_END_POINTS.VIZ.assignedList, pathParams)}`,
          config,
        );
      },
      //  #endregion - Devices

      //  #region - OLT Dashboard
      GetOLTCardStats: async (
        pathParams: IOLTDashboardStatsPathParams,
        config?: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(
            API_END_POINTS.VIZ.oltDashboardCardStats,
            pathParams,
          )}`,
          config,
        );
      },
      GetOLTDashboardDeviceStats: async (
        pathParams: IOLTDashboardStatsPathParams,
        config?: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(
            API_END_POINTS.VIZ.oltDashboardDevicesStats,
            pathParams,
          )}`,
          config,
        );
      },
      GetOLTOpticalStats: async (
        pathParams: IOLTDashboardStatsPathParams,
        config?: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(
            API_END_POINTS.VIZ.oltOpticalStats,
            pathParams,
          )}`,
          config,
        );
      },
      GetONTDevicesList: async (
        pathParams: IOLTDashboardStatsPathParams,
        config?: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(API_END_POINTS.VIZ.ontList, pathParams)}`,
          config,
        );
      },

      //  #region - Splitter
      GetNetworkHierarchy: async (
        pathParams: INetworkHierarchyPathParams,
        config?: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(
            API_END_POINTS.VIZ.networkHierarchy,
            pathParams,
          )}`,
          config,
        );
      },
      //  #endregion - Splitter

      GetOLTGoogleMap: async (
        pathParams: INetworkHierarchyPathParams,
        config?: AxiosRequestConfig,
      ) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(API_END_POINTS.VIZ.oltGoogleMap, pathParams)}`,
          config,
        );
      },
      //  #endregion - OLT Dashboard

      //  #region - Subscriber
      GetSubscriberById: async (pathParam: ISubscriberIdPathParam) => {
        return await getRequest(
          VizInstance,
          `${version}${formatURL(API_END_POINTS.VIZ.subscribersById, {
            subscriberId: pathParam?.subscriberId,
          })}`,
        );
      },
      //  #endregion - Subscriber
    } as IVizService,
  };
}, {});
