import {
  IListSorting,
  IOLTScheduleHistoryList,
  IOLTScheduleList,
  IOLTScheduleReduxState,
  IPagination,
  OLT_Schedule_Tabs,
} from 'store/types';
import { OperatorRequester, formatError } from 'apiUtils/api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

const initialState: IOLTScheduleReduxState = {
  oltScheduleList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as IOLTScheduleList,
  },
  oltScheduleListPagination: {} as IPagination,
  oltScheduleHistoryList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as IOLTScheduleHistoryList,
  },
  oltScheduleHistoryListPagination: {} as IPagination,
  oltScheduleHistoryListSorting: {} as IListSorting,
  currentTabId: '',
};

const loadOLTScheduleList = createAsyncThunk(
  'oltScheduleList/fetch',
  async (_, thunkAPI) => {
    const { oltScheduleState }: { oltScheduleState: IOLTScheduleReduxState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await OperatorRequester.get(
      API_END_POINTS.OPERATORS.oltScheduleList,
      {
        params: {
          ...oltScheduleState?.oltScheduleListPagination,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) ||
          'Error in fetching OLT Schedule List. Please try again',
      };
    }
  },
);

const loadOLTScheduleHistoryList = createAsyncThunk(
  'oltScheduleHistoryList/fetch',
  async (_, thunkAPI) => {
    const { oltScheduleState }: { oltScheduleState: IOLTScheduleReduxState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await OperatorRequester.get(
      API_END_POINTS.OPERATORS.oltScheduleHistory,
      {
        params: {
          ...oltScheduleState?.oltScheduleHistoryListPagination,
          ...oltScheduleState?.oltScheduleHistoryListSorting,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) ||
          'Error in fetching OLT Schedule History List. Please try again',
      };
    }
  },
);

export const OLTScheduleSlice = createSlice({
  name: 'oltSchedule',
  initialState,
  reducers: {
    resetToInit: () => initialState,
    resetOLTScheduleListState: (state: IOLTScheduleReduxState) => {
      state.oltScheduleList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IOLTScheduleList,
      };
    },
    resetOLTScheduleHistoryListState: (state: IOLTScheduleReduxState) => {
      state.oltScheduleHistoryList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IOLTScheduleHistoryList,
      };
    },

    setOLTScheduleListPageToken: (
      state: IOLTScheduleReduxState,
      { payload }: { payload: number },
    ) => {
      state.oltScheduleListPagination.pageToken = payload;
    },
    setOLTScheduleListPagination: (
      state: IOLTScheduleReduxState,
      action: PayloadAction<IPagination>,
    ) => {
      state.oltScheduleListPagination = action.payload;
    },

    setOLTScheduleHistoryListPageToken: (
      state: IOLTScheduleReduxState,
      { payload }: { payload: number },
    ) => {
      state.oltScheduleHistoryListPagination.pageToken = payload;
    },
    setOLTScheduleHistoryListPagination: (
      state: IOLTScheduleReduxState,
      action: PayloadAction<IPagination>,
    ) => {
      state.oltScheduleHistoryListPagination = action.payload;
    },
    setOLTScheduleHistoryListSorting: (
      state: IOLTScheduleReduxState,
      { payload }: { payload: string | null | undefined },
    ) => {
      state.oltScheduleHistoryListSorting.sort = payload || undefined;
    },

    setCurrentTabId: (state: IOLTScheduleReduxState, action) => {
      state.currentTabId = action.payload;
      if (state.currentTabId === OLT_Schedule_Tabs.LIST) {
        state.oltScheduleList = {
          loading: true,
          hardReload: true,
          error: '',
          data: {} as IOLTScheduleList,
        };
      }
      if (state.currentTabId === OLT_Schedule_Tabs.HISTORY) {
        state.oltScheduleHistoryList = {
          loading: true,
          hardReload: true,
          error: '',
          data: {} as IOLTScheduleHistoryList,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadOLTScheduleList.pending,
      (state: IOLTScheduleReduxState) => {
        state.oltScheduleList.loading = true;
        state.oltScheduleList.error = '';
      },
    );
    builder.addCase(
      loadOLTScheduleList.fulfilled,
      (
        state: IOLTScheduleReduxState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.oltScheduleList.loading = false;
        state.oltScheduleList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.oltScheduleList.data = payload;
        } else {
          state.oltScheduleList.error = payload?.error;
        }
      },
    );
    builder.addCase(
      loadOLTScheduleHistoryList.pending,
      (state: IOLTScheduleReduxState) => {
        state.oltScheduleHistoryList.loading = true;
        state.oltScheduleHistoryList.error = '';
      },
    );
    builder.addCase(
      loadOLTScheduleHistoryList.fulfilled,
      (
        state: IOLTScheduleReduxState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.oltScheduleHistoryList.loading = false;
        state.oltScheduleHistoryList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.oltScheduleHistoryList.data = payload;
        } else {
          state.oltScheduleHistoryList.error = payload?.error;
        }
      },
    );
  },
});

export const OLTScheduleSliceActions = {
  ...OLTScheduleSlice.actions,
  loadOLTScheduleList,
  loadOLTScheduleHistoryList,
};

export const oltScheduleReducer = OLTScheduleSlice.reducer;
