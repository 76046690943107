import {
  ILeadsList,
  ILeadsStats,
  ILoginStats,
  IOperatorBySubscription,
  IPagination,
  IPaidSubscriptionExpiry,
  IResellerItems,
  ISubscriptionExpiredCount,
  ITrialByAgeItems,
  ITrialByStatus,
} from 'store/types';
import {
  OperatorRequester,
  VizRequester,
  formatError,
  formatURL,
} from 'apiUtils/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { red } from '@ant-design/colors';

const loadResellerDetails = createAsyncThunk(
  'resellerDetails/fetch',
  async (payload: { resellerId: string }, thunkAPI) => {
    const response = await OperatorRequester.get(
      formatURL(API_END_POINTS.OPERATORS.resellerById, payload),
    ).catch(({ response }) => response);
    if (response.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || 'Something went wrong. Please try again',
      };
    }
  },
);

const loadLeadsList = createAsyncThunk(
  'loadLeadsList/fetch',
  async (_, thunkAPI) => {
    const { auth, resellerDashboard } = thunkAPI.getState() as any;

    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.leads,
      {
        params: {
          ...resellerDashboard.leadsListPagination,
          ...resellerDashboard.leadsListFilter,
          resellerId:
            auth?.userInfo?.reseller.id !== resellerDashboard?.resellerId
              ? resellerDashboard?.resellerId
              : undefined,
        },
      },
    ).catch(({ response }) => response);
    if (response.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || 'Something went wrong. Please try again',
      };
    }
  },
);

const loadLeadsStats = createAsyncThunk(
  'loadLeadsStats/fetch',
  async (_, thunkAPI) => {
    const { resellerDashboard } = thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.leadsStats,
      {
        params: {
          resellerId: resellerDashboard.resellerId,
        },
      },
    ).catch(({ response }) => response);
    if (response.status === 200) {
      return response?.data;
    } else {
      // return RESELLER_LEADS_STATS_MOCK;
      return {
        error:
          formatError(response) || 'Something went wrong. Please try again',
      };
    }
  },
);

const loadTrialByAge = createAsyncThunk(
  'loadTrialByAge/fetch',
  async (_, thunkAPI) => {
    const { resellerDashboard } = thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.trialByAge,
      {
        params: {
          resellerId: resellerDashboard?.resellerId,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
    } else {
      // return TRIAL_EXPIRY_DATA_MOCK;
      return {
        error:
          formatError(response) ||
          'Error in fetching chart data. Please try again',
      };
    }
  },
);

const loadOperatorBySubscription = createAsyncThunk(
  'loadOperatorBySubscription/fetch',
  async (_, thunkAPI) => {
    const { resellerDashboard } = thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.subscriptionPlanCount,
      {
        params: {
          resellerId: resellerDashboard?.resellerId,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
    } else {
      // return OPERATOR_BY_SUBSCRIPTION_MOCK;

      return {
        error:
          formatError(response) ||
          'Error in fetching chart data. Please try again',
      };
    }
  },
);

const loadPaidSubscriptionExpiry = createAsyncThunk(
  'loadPaidSubscriptionExpiry/fetch',
  async (_, thunkAPI) => {
    const { resellerDashboard } = thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.paidSubscriptionExpiry,
      {
        params: {
          resellerId: resellerDashboard?.resellerId,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
    } else {
      // return PAID_SUBSCRIPTION_EXPIRY_MOCK;

      return {
        error:
          formatError(response) ||
          'Error in fetching chart data. Please try again',
      };
    }
  },
);

const loadSubscriptionExpiredCount = createAsyncThunk(
  'loadSubscriptionExpiredCount/fetch',
  async (_, thunkAPI) => {
    const { resellerDashboard } = thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.subscriptionExpiredCount,
      {
        params: {
          resellerId: resellerDashboard?.resellerId,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
    } else {
      // return SUBSCRIPTION_EXPIRED_COUNT_MOCK;
      return {
        error:
          formatError(response) || 'Error in fetching data. Please try again',
      };
    }
  },
);

const loadTrialByStatusStats = createAsyncThunk(
  'loadTrialByStatusStats/fetch',
  async (_, thunkAPI) => {
    const { resellerDashboard } = thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.trialByStatus,
      {
        params: {
          resellerId: resellerDashboard?.resellerId,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
    } else {
      // return TRIAL_ACTIVE_PENDING_COUNT_MOCK;
      return {
        error:
          formatError(response) || 'Error in fetching data. Please try again',
      };
    }
  },
);

const loadLoginStats = createAsyncThunk(
  'loadLoginStats/fetch',
  async (_, thunkAPI) => {
    const { resellerDashboard } = thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.loginStats,
      {
        params: {
          resellerId: resellerDashboard?.resellerId,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
    } else {
      // return LOGIN_STATS_MOCK;
      return {
        error:
          formatError(response) || 'Error in fetching data. Please try again',
      };
    }
  },
);

const initialState = {
  overview: {
    loading: true,
    error: '',
    details: {} as any,
  },
  leadsList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as ILeadsList,
  },
  leadsStats: {
    loading: true,
    error: '',
    stats: {} as ILeadsStats,
  },
  trialByAge: { loading: true, error: '', data: [] as ITrialByAgeItems[] },
  operatorBySubscription: {
    loading: true,
    error: '',
    data: {} as IOperatorBySubscription,
  },
  paidSubscriptionExpiry: {
    loading: true,
    error: '',
    data: [] as IPaidSubscriptionExpiry[],
  },
  subscriptionExpiredCount: {
    loading: true,
    error: '',
    data: {} as ISubscriptionExpiredCount,
  },
  trialByStatus: {
    loading: true,
    error: '',
    data: {} as ITrialByStatus,
  },
  loginStats: {
    loading: true,
    error: '',
    data: {} as ILoginStats,
  },
  leadsListPagination: {} as IPagination,
  leadsListFilter: {} as any,
  resellerId: '',
};

export const resellerDashboardSlice = createSlice({
  name: 'resellerDashboard',
  initialState,
  reducers: {
    resetToInit: () => initialState,

    resetLeadsList: (state) => {
      state.leadsList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as ILeadsList,
      };
      state.leadsListFilter = {};
    },

    resetResellerStats: (state) => {
      state.overview = {
        loading: true,
        error: '',
        details: {} as IResellerItems,
      };
      state.leadsStats = {
        loading: true,
        error: '',
        stats: {} as ILeadsStats,
      };
      state.operatorBySubscription = {
        loading: true,
        error: '',
        data: {} as IOperatorBySubscription,
      };
      state.paidSubscriptionExpiry = {
        loading: true,
        error: '',
        data: [] as IPaidSubscriptionExpiry[],
      };
      state.subscriptionExpiredCount = {
        loading: true,
        error: '',
        data: {} as ISubscriptionExpiredCount,
      };

      state.trialByStatus = {
        loading: true,
        error: '',
        data: {} as ITrialByStatus,
      };
      state.trialByAge = {
        loading: true,
        error: '',
        data: [] as ITrialByAgeItems[],
      };
      state.loginStats = {
        loading: true,
        error: '',
        data: {} as ILoginStats,
      };
    },
    setPageToken: (state, action) => {
      state.leadsListPagination.pageToken = action.payload;
    },
    setPagination: (state, action) => {
      state.leadsListPagination = action.payload;
    },
    setLeadListFilter: (state, action) => {
      if (isEmpty(action?.payload)) {
        state.leadsListFilter = {};
      } else {
        state.leadsListFilter = Object.assign(
          state.leadsListFilter,
          action.payload,
        );
      }
      state.leadsList.hardReload = true;
      state.leadsList.error = '';
      state.leadsList.data = {} as ILeadsList;
      state.leadsListPagination.pageToken = 1;
    },
    setTrialByAgeLoading: (state, { payload }: { payload: boolean }) => {
      state.trialByAge.loading = payload;
    },
    setResellerId: (state, { payload }: { payload: string }) => {
      state.resellerId = payload;
    },
    setPaidSubscriptionExpiryLoading: (
      state,
      { payload }: { payload: boolean },
    ) => {
      state.paidSubscriptionExpiry.data = [];
      state.paidSubscriptionExpiry.loading = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadResellerDetails.pending, (state) => {
      state.overview.loading = true;
      state.overview.error = '';
    });

    builder.addCase(
      loadResellerDetails.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.overview.loading = false;
        if (isEmpty(payload?.error)) {
          state.overview.details = payload;
        } else {
          state.overview.error = payload?.error;
        }
      },
    );

    builder.addCase(loadLeadsList.pending, (state) => {
      state.leadsList.loading = true;
      state.leadsList.error = '';
    });

    builder.addCase(
      loadLeadsList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.leadsList.loading = false;
        state.leadsList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.leadsList.data = payload;
        } else {
          state.leadsList.error = payload?.error;
        }
      },
    );

    builder.addCase(loadLeadsStats.pending, (state) => {
      state.leadsStats.loading = true;
      state.leadsStats.error = '';
    });

    builder.addCase(
      loadLeadsStats.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.leadsStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.leadsStats.stats = payload;
        } else {
          state.leadsStats.error = payload?.error;
        }
      },
    );

    builder.addCase(loadTrialByAge.pending, (state) => {
      state.trialByAge.loading = true;
      state.trialByAge.error = '';
    });
    builder.addCase(
      loadTrialByAge.fulfilled,
      (state, { payload }: { payload: AxiosResponse | any }) => {
        state.trialByAge.loading = false;
        if (isEmpty(payload?.error)) {
          state.trialByAge.data = payload;
          if (!isEmpty(state?.trialByAge?.data)) {
            state.trialByAge?.data?.map((val, idx) => {
              return (val.color =
                red[state.trialByAge?.data?.length - idx + 1]);
            });
          }
        } else {
          state.trialByAge.error = payload?.error;
        }
      },
    );

    builder.addCase(loadOperatorBySubscription.pending, (state) => {
      state.operatorBySubscription.loading = true;
      state.operatorBySubscription.error = '';
    });

    builder.addCase(
      loadOperatorBySubscription.fulfilled,
      (state, { payload }: { payload: AxiosResponse | any }) => {
        state.operatorBySubscription.loading = false;
        if (isEmpty(payload?.error)) {
          state.operatorBySubscription.data = payload;
        } else {
          state.operatorBySubscription.error = payload?.error;
        }
      },
    );

    builder.addCase(loadPaidSubscriptionExpiry.pending, (state) => {
      state.paidSubscriptionExpiry.loading = true;
      state.paidSubscriptionExpiry.error = '';
    });

    builder.addCase(
      loadPaidSubscriptionExpiry.fulfilled,
      (state, { payload }: { payload: AxiosResponse | any }) => {
        state.paidSubscriptionExpiry.loading = false;
        if (isEmpty(payload?.error)) {
          state.paidSubscriptionExpiry.data = payload;
        } else {
          state.paidSubscriptionExpiry.error = payload?.error;
        }
      },
    );

    builder.addCase(loadSubscriptionExpiredCount.pending, (state) => {
      state.subscriptionExpiredCount.loading = true;
      state.subscriptionExpiredCount.error = '';
    });

    builder.addCase(
      loadSubscriptionExpiredCount.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.subscriptionExpiredCount.loading = false;
        if (isEmpty(payload?.error)) {
          state.subscriptionExpiredCount.data = payload;
        } else {
          state.subscriptionExpiredCount.error = payload?.error;
        }
      },
    );

    builder.addCase(loadTrialByStatusStats.pending, (state) => {
      state.trialByStatus.loading = true;
      state.trialByStatus.error = '';
    });

    builder.addCase(
      loadTrialByStatusStats.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.trialByStatus.loading = false;
        if (isEmpty(payload?.error)) {
          state.trialByStatus.data = payload;
        } else {
          state.trialByStatus.error = payload?.error;
        }
      },
    );

    builder.addCase(loadLoginStats.pending, (state) => {
      state.loginStats.loading = true;
      state.loginStats.error = '';
    });

    builder.addCase(
      loadLoginStats.fulfilled,
      (state, { payload }: { payload: AxiosResponse | any }) => {
        state.loginStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.loginStats.data = payload;
        } else {
          state.loginStats.error = payload?.error;
        }
      },
    );
  },
});

export const ResellerDashboardActions = {
  ...resellerDashboardSlice.actions,
  loadResellerDetails,
  loadLeadsList,
  loadLeadsStats,
  loadOperatorBySubscription,
  loadTrialByAge,
  loadPaidSubscriptionExpiry,
  loadSubscriptionExpiredCount,
  loadTrialByStatusStats,
  loadLoginStats,
};

export const ResellerDashboardReducer = resellerDashboardSlice.reducer;
