import {
  IReportState,
  IReportTypes,
  IReportsList,
} from 'store/types/reports.types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';
import { IPagination } from 'store/types';
import { ReportsService } from 'apiUtils/services/reports.service';
import { VizService } from 'apiUtils/services/vizbff.service';
import { formatError } from 'apiUtils/api';
import i18n from 'i18n';
// import { REPORTS_LIST_MOCK } from 'mocks/reports.mock';
import { isEmpty } from 'lodash';

const initialState: IReportState = {
  reportsList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as IReportsList,
  },
  reportsListPagination: {},
  reportTypesList: {
    loading: false,
    error: '',
    data: [] as IReportTypes[],
  },
};

export const loadReportsList = createAsyncThunk(
  'reportsList/fetch',
  async (_, thunkAPI) => {
    const { reportState }: { reportState: IReportState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await ReportsService.v1.GetReportsList({
      params: {
        ...reportState?.reportsListPagination,
      },
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.reportsList'),
      };
    }
  },
);

export const loadReportTypesList = createAsyncThunk(
  'reportTypes/fetch',
  async (_, thunkAPI) => {
    const response = await VizService.v1.GetReportTypes();
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.reportTypesList'),
      };
    }
  },
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    resetToInit: (state: IReportState) => {
      state.reportsList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IReportsList,
      };
      state.reportsListPagination = {};
    },
    setReportsListHardReload: (
      state: IReportState,
      { payload }: { payload: boolean },
    ) => {
      state.reportsList.hardReload = payload;
    },
    setReportsListPageToken: (
      state: IReportState,
      { payload }: { payload: number },
    ) => {
      state.reportsListPagination.pageToken = payload;
    },
    setReportsListPageSize: (
      state: IReportState,
      { payload }: { payload: number },
    ) => {
      state.reportsListPagination.pageSize = payload;
    },
    setReportsListPagination: (
      state: IReportState,
      action: PayloadAction<IPagination>,
    ) => {
      state.reportsListPagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadReportsList.pending, (state: IReportState) => {
      state.reportsList.loading = true;
      state.reportsList.error = '';
    });
    builder.addCase(
      loadReportsList.fulfilled,
      (state: IReportState, { payload }: { payload: AxiosResponse | any }) => {
        state.reportsList.loading = false;
        state.reportsList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.reportsList.data = payload;
        } else {
          state.reportsList.error = payload?.error;

          // Handle Mock
          // state.reportsList.data = REPORTS_LIST_MOCK;
        }
      },
    );
    builder.addCase(loadReportTypesList.pending, (state: IReportState) => {
      state.reportTypesList.loading = true;
      state.reportTypesList.error = '';
    });
    builder.addCase(
      loadReportTypesList.fulfilled,
      (state: IReportState, { payload }: { payload: AxiosResponse | any }) => {
        state.reportTypesList.loading = false;
        if (isEmpty(payload?.error)) {
          state.reportTypesList.data = payload?.items;
        } else {
          state.reportTypesList.error = payload?.error;
        }
      },
    );
  },
});

export const ReportsSliceActions = {
  ...reportsSlice.actions,
  loadReportsList,
  loadReportTypesList,
};

export const ReportsReducer = reportsSlice.reducer;
