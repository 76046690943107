import {
  AuthState,
  IListSorting,
  IManagementEntityDetails,
  IOptions,
  LOADING,
} from 'store/types';
import {
  IOLTList,
  IOLTListFilters,
  IOLTListItems,
  IOLTListReduxState,
  IOLTMigrationTransaction,
  IOLTMigrationTransactionData,
  IOLTPendingList,
  IOLTSelectedRows,
  IOLTSupportedModel,
  ISubscriberStatsByOLT,
  OLTListTabs,
  OLT_BULK_ACTIONS,
} from 'store/types/olt.types';
import {
  OLTOnboardingRequester,
  VizRequester,
  formatError,
  formatURL,
} from 'apiUtils/api';
import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { OperatorService } from 'apiUtils/services/operator.service';
import i18n from 'i18n';
import { isEmpty } from 'lodash';
import { removeEmptyValuesFromObject } from 'utils/utils';

let oltListCancelTokenSource = axios.CancelToken.source();

const fetchOLTList = createAsyncThunk('OLTList/fetch', async (_, thunkAPI) => {
  let {
    OLTListState,
    auth,
  }: { OLTListState: IOLTListReduxState; auth: AuthState } =
    thunkAPI.getState() as any;
  oltListCancelTokenSource = axios.CancelToken.source();

  const response: AxiosResponse = await VizRequester.get(
    formatURL(API_END_POINTS.VIZ.olt, {
      managementEntityId: auth?.userInfo?.managementEntity?.id,
    }),

    {
      cancelToken: oltListCancelTokenSource.token,
      params: removeEmptyValuesFromObject({
        ...OLTListState?.oltListPagination,
        ...OLTListState?.oltListSorting,
        ...OLTListState?.oltListFilters,
        ...OLTListState?.oltListSearch,
      }),
    },
  ).catch(({ response }) => response);
  if (response.status === 200) {
    return response?.data;
  } else {
    return {
      error: formatError(response) || 'Something went wrong. Please try again',
    };
  }
});

const fetchOLTMigrationStats = createAsyncThunk(
  'OLTMigrationStats/fetch',
  async (payload: { transactionId: string }, thunkAPI) => {
    const response: AxiosResponse = await OLTOnboardingRequester.get(
      formatURL(API_END_POINTS.OLT_ONBOARDING.migrationStats, payload),
    ).catch(({ response }) => response);
    if (response.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || 'Something went wrong. Please try again',
      };
    }
  },
);

// const checkOLTReachability = createAsyncThunk(
//   'OLTReachability/fetch',
//   async (
//     payload: {
//       managementEntityId: string;
//       macAddress: string;
//       ipAddress: string;
//     },
//     thunkAPI,
//   ) => {
//     const response = await OLTGatewayRequester.post(
//       formatURL(API_END_POINTS.OLT_GATEWAY.initiateReachability, {
//         managementEntityId: payload.managementEntityId,
//       }),
//       {
//         oltMac: payload.macAddress,
//         oltIp: payload.ipAddress,
//       },
//     ).catch(({ response }) => response);
//     if (response.status === 202) {
//       return thunkAPI.fulfillWithValue(response?.data);
//     } else {
//       return thunkAPI.rejectWithValue(
//         formatError(response) || 'Something went wrong. Please try again',
//       );
//     }
//   },
// );

const fetchSubscribersByOLTStats = createAsyncThunk(
  'subscriberByOLTStats/fetch',
  async (
    payload: {
      managementEntityId: string;
      macAddress: string;
    },
    thunkAPI,
  ) => {
    const response = await OLTOnboardingRequester.get(
      formatURL(API_END_POINTS.OLT_ONBOARDING.subscribersStatsByOlt, {
        managementEntityId: payload.managementEntityId,
        oltMac: payload.macAddress,
      }),
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || 'Something went wrong. Please try again',
      };
    }
  },
);

const fetchPendingOLTList = createAsyncThunk(
  'pendingOLTList/fetch',
  async (_, thunkAPI) => {
    let {
      OLTListState,
      auth,
    }: { OLTListState: IOLTListReduxState; auth: AuthState } =
      thunkAPI.getState() as any;
    const response = await OperatorService.v2.getOLTPendingList({
      path: { managementEntityId: auth?.userInfo?.managementEntity?.id },
      query: removeEmptyValuesFromObject({
        ...OLTListState?.oltPendingListPagination,
        ...OLTListState?.oltListFilters,
      }),
    });

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) ||
          i18n.t('Something went wrong. Please try again'),
      };
    }
  },
);

const deletePendingOLTs = createAsyncThunk(
  'deletePendingOLTs/delete',
  async (payload: { ipAddress: string } | void, thunkAPI) => {
    let {
      OLTListState,
    }: { OLTListState: IOLTListReduxState; auth: AuthState } =
      thunkAPI.getState() as any;

    const ipAddressList =
      OLTListState?.bulkActionType === OLT_BULK_ACTIONS.BULK_DELETE
        ? OLTListState?.selectedOLTRows?.rowKeys
        : [];
    const data = {
      ipAddress: payload?.ipAddress ? [payload?.ipAddress] : ipAddressList,
    };

    const response = await OperatorService.v2.DeletePendingOLTs({ data });

    if (response?.status === 204) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.oltDelete'),
      };
    }
  },
);

const initialState: IOLTListReduxState = {
  OLTList: {
    hardRefresh: false,
    loading: true,
    error: '',
    data: {} as IOLTList,
  },
  OLTPendingList: {
    hardRefresh: false,
    loading: true,
    error: '',
    data: {} as IOLTPendingList,
  },
  oltListPagination: {},
  oltListSearch: {},
  oltListFilters: {} as IOLTListFilters,
  oltPendingListPagination: {},
  isFilterOpen: false,
  oltListFilterDropDownList: {
    manufacturerList: [] as IOptions[],
    modelList: [] as IOLTSupportedModel[],
    managementEntityList: [] as IManagementEntityDetails[],
  },
  oltListSorting: {} as IListSorting,
  OLTListOptions: [] as IOLTListItems[],
  OLTMigrationTransactions: {} as IOLTMigrationTransaction,
  selectedOLTSubscriberStats: {
    loading: false,
    data: {} as ISubscriberStatsByOLT,
    error: '',
  },
  currentTabId: '',
  selectedOLTRows: { rowKeys: [], data: [] },
  bulkActionType: null,
  pendingOLTDeleteRequestStatus: null,
};

export const OLTListSlice = createSlice({
  name: 'OLTList',
  initialState,
  reducers: {
    resetToInit: (state: IOLTListReduxState) => {
      state.OLTList = {
        hardRefresh: true,
        loading: true,
        error: '',
        data: {} as IOLTList,
      };
      state.OLTPendingList = {
        hardRefresh: true,
        loading: true,
        error: '',
        data: {} as IOLTPendingList,
      };
      state.oltPendingListPagination = {};
      state.oltListPagination = {};
      state.oltListSorting = {};
      state.oltListSearch = {};
      state.oltListFilters = {};
      state.OLTListOptions = [] as IOLTListItems[];
      state.OLTMigrationTransactions = {} as IOLTMigrationTransaction;
      state.currentTabId = '';
      state.selectedOLTRows = { rowKeys: [], data: [] };
      state.bulkActionType = null;
      state.pendingOLTDeleteRequestStatus = null;
    },
    resetOLTListState: (state: IOLTListReduxState) => {
      state.OLTList = {
        loading: true,
        hardRefresh: true,
        error: '',
        data: {} as IOLTList,
      };
      state.oltListPagination = {};
    },
    resetOLTPendingListState: (state: IOLTListReduxState) => {
      state.OLTPendingList = {
        loading: true,
        hardRefresh: true,
        error: '',
        data: {} as IOLTPendingList,
      };
      state.oltPendingListPagination = {};
    },
    setCurrentTabId: (state: IOLTListReduxState, action) => {
      state.currentTabId = action.payload;
      state.oltListSearch = {};
      state.selectedOLTRows = { rowKeys: [], data: [] };
      state.bulkActionType = null;
      state.pendingOLTDeleteRequestStatus = null;
      if (state.currentTabId === OLTListTabs.CONFIGURED) {
        state.OLTList = {
          loading: true,
          hardRefresh: true,
          error: '',
          data: {} as IOLTList,
        };
        state.oltListPagination = { pageToken: 1, pageSize: 10 };
      } else if (state.currentTabId === OLTListTabs.PENDING) {
        state.OLTPendingList = {
          loading: true,
          hardRefresh: true,
          error: '',
          data: {} as IOLTPendingList,
        };
        state.oltPendingListPagination = { pageToken: 1, pageSize: 10 };
      }
    },
    setPageToken: (state, action) => {
      state.oltListPagination.pageToken = action.payload;
    },
    setPagination: (state, action) => {
      state.oltListPagination = action.payload;
    },
    setPendingOLTListPageToken: (state, action) => {
      state.oltPendingListPagination.pageToken = action.payload;
    },
    setPendingOLTListPagination: (state, action) => {
      state.oltPendingListPagination = action.payload;
    },
    setSearchText: (
      state,
      { payload }: { payload: string | null | undefined },
    ) => {
      state.oltListSearch = { searchText: payload };
      state.oltListPagination.pageToken = 1;
    },
    setIsFilterOpen: (state, { payload }: { payload: boolean }) => {
      state.isFilterOpen = payload;
    },
    setOLTListFilters: (state: IOLTListReduxState, action) => {
      if (isEmpty(action?.payload)) {
        state.oltListFilters = {};
      } else {
        state.oltListFilters = Object.assign(
          state.oltListFilters,
          action.payload,
        );
      }
      if (state.currentTabId === OLTListTabs.CONFIGURED) {
        state.OLTList.hardRefresh = true;
        state.oltListPagination.pageToken = 1;
      } else {
        state.OLTPendingList.hardRefresh = true;
        state.oltPendingListPagination.pageToken = 1;
      }
    },
    setManufacturerList: (
      state: IOLTListReduxState,
      { payload }: { payload: IOptions[] },
    ) => {
      state.oltListFilterDropDownList.manufacturerList = payload;
    },
    setModelList: (
      state: IOLTListReduxState,
      { payload }: { payload: IOLTSupportedModel[] },
    ) => {
      state.oltListFilterDropDownList.modelList = payload;
    },
    setManagementEntityList: (
      state: IOLTListReduxState,
      { payload }: { payload: IManagementEntityDetails[] },
    ) => {
      state.oltListFilterDropDownList.managementEntityList = payload;
    },
    setOLTListSorting: (
      state: IOLTListReduxState,
      { payload }: { payload: string | null | undefined },
    ) => {
      state.oltListSorting.sort = payload || undefined;
    },
    setOLTOptions: (state, action) => {
      state.OLTListOptions = action.payload;
    },
    setMigrationTransactionState: (state, action) => {
      if (!state.OLTMigrationTransactions.loading) {
        state.OLTMigrationTransactions.loading = {};
      }
      state.OLTMigrationTransactions.loading[action.payload['transactionId']] =
        true;
    },
    setSelectedOLTRows: (
      state: IOLTListReduxState,
      { payload }: { payload: IOLTSelectedRows | null },
    ) => {
      state.selectedOLTRows = {
        rowKeys: payload?.rowKeys || [],
        data: payload?.data || [],
      };
    },
    setOLTBulkActionType: (
      state,
      { payload }: { payload: OLT_BULK_ACTIONS | null | undefined },
    ) => {
      state.bulkActionType = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchOLTList.pending, (state) => {
      state.OLTList.loading = true;
      state.OLTList.error = '';
      state.OLTList.data = {} as IOLTList;
      oltListCancelTokenSource.cancel();
    });

    builder.addCase(
      fetchOLTList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.OLTList.loading = false;
        state.OLTList.hardRefresh = false;
        if (isEmpty(payload?.error)) {
          state.OLTList.data = payload;
        } else {
          state.OLTList.error = payload?.error;
        }
      },
    );

    builder.addCase(
      fetchOLTMigrationStats.fulfilled,
      (state, { payload }: { payload: IOLTMigrationTransactionData }) => {
        if (!state.OLTMigrationTransactions.hasOwnProperty('data')) {
          state.OLTMigrationTransactions.data = {};
        }
        state.OLTMigrationTransactions.loading[payload.transactionId] = false;
        state.OLTMigrationTransactions.data[payload.transactionId] = payload;
      },
    );

    builder.addCase(fetchSubscribersByOLTStats.pending, (state) => {
      state.selectedOLTSubscriberStats.loading = true;
      state.selectedOLTSubscriberStats.error = '';
    });

    builder.addCase(
      fetchSubscribersByOLTStats.fulfilled,
      (state, { payload }: { payload: ISubscriberStatsByOLT }) => {
        state.selectedOLTSubscriberStats.data = payload;
        state.selectedOLTSubscriberStats.loading = false;
      },
    );

    builder.addCase(fetchPendingOLTList.pending, (state) => {
      state.OLTPendingList.loading = true;
      state.OLTPendingList.error = '';
      state.OLTPendingList.data = {} as IOLTPendingList;
    });

    builder.addCase(
      fetchPendingOLTList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.OLTPendingList.loading = false;
        state.OLTPendingList.hardRefresh = false;
        if (isEmpty(payload?.error)) {
          state.OLTPendingList.data = payload;
        } else {
          state.OLTPendingList.error = payload?.error;
        }
      },
    );

    builder.addCase(deletePendingOLTs.pending, (state) => {
      state.pendingOLTDeleteRequestStatus = LOADING;
    });

    builder.addCase(
      deletePendingOLTs.fulfilled,
      (state, { payload }: { payload: any }) => {
        if (isEmpty(payload?.error)) {
          state.pendingOLTDeleteRequestStatus = null;
        } else {
          state.pendingOLTDeleteRequestStatus = payload.error;
        }
      },
    );
  },
});

export const OLTListActions = {
  ...OLTListSlice.actions,
  fetchOLTList,
  fetchOLTMigrationStats,
  fetchSubscribersByOLTStats,
  fetchPendingOLTList,
  deletePendingOLTs,
};
export const OLTReducer = OLTListSlice.reducer;
