import { IISP, ISPDashboardState } from 'store/types/isp.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';
import { IOperatorStats } from 'store/types';
import { OperatorService } from 'apiUtils/services/operator.service';

const loadOperatorStats = createAsyncThunk(
  'ispStats/fetch',
  async (payload: { id: string }, thunkAPI) => {
    const response: AxiosResponse =
      await OperatorService.v1.GetOperatorStatsCount({
        params: {
          ...payload,
        },
      });
    if (response?.status === 200) {
      return response.data;
    } else {
      return false;
    }
  },
);

const loadISPDetails = createAsyncThunk(
  'ispDashboard/loadISPDetails',
  async (payload: { ispId: string }, thunkAPI) => {
    const response: AxiosResponse = await OperatorService.v1.GetISPById(
      payload,
    );
    if (response?.status === 200) {
      return response.data;
    } else {
      return false;
    }
  },
);

const initialState: ISPDashboardState = {
  overview: {
    loading: true,
    error: false,
    details: {} as IISP,
  },
  stats: {
    operator: {
      loading: true,
      error: false,
      unknownDevice: 0,
      oltCount: 0,
      unassignedDeviceCount: 0,
      managementEntity: {
        channelPartner: 0,
        franchisee: 0,
        isp: 0,
        // distributor: 0,
      },
    },
  },
  subscriberTrend: {
    loading: true,
  },
  FWStats: {
    loading: true,
  },
  HWStats: {
    loading: true,
  },
  actionStats: {
    loading: true,
  },
  subscriberByOfflineStats: {
    loading: true,
  },
  offlineSubscriberByDuration: {
    loading: true,
  },
  subscriberByPoorOpticalStats: {
    loading: true,
  },
  poorSignalByOLTStats: {
    loading: true,
  },
  subscriberByPendingStats: {
    loading: true,
  },
  offlineReasonCodeByOLT: {
    loading: true,
  },
  filter: {
    id: '',
  },
  showOverview: false,
};

export const ispDashboardSlice = createSlice({
  name: 'ispDashboard',
  initialState,
  reducers: {
    resetToInit: (state: ISPDashboardState) => {
      state.overview = {
        loading: true,
        error: false,
        details: {} as IISP,
      };
      state.stats = {
        operator: {
          loading: true,
          error: false,
          unknownDevice: 0,
          oltCount: 0,
          unassignedDeviceCount: 0,
          managementEntity: {
            channelPartner: 0,
            franchisee: 0,
            isp: 0,
            // distributor: 0,
          },
        },
      };
      state.FWStats = {
        loading: true,
      };
      state.HWStats = {
        loading: true,
      };
      state.actionStats = {
        loading: true,
      };
      state.subscriberTrend = {
        loading: true,
      };
      state.subscriberByOfflineStats = {
        loading: true,
      };
      state.offlineSubscriberByDuration = {
        loading: true,
      };
      state.poorSignalByOLTStats = {
        loading: true,
      };
      state.subscriberByPoorOpticalStats = {
        loading: true,
      };
      state.offlineReasonCodeByOLT = {
        loading: true,
      };
      state.subscriberByPendingStats = {
        loading: true,
      };
    },
    setFwStatus: (
      state: ISPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.FWStats.loading = payload;
    },
    setHwStatus: (
      state: ISPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.HWStats.loading = payload;
    },
    setActionStatus: (
      state: ISPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.actionStats.loading = payload;
    },
    setSubscriberByOfflineStatus: (
      state: ISPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.subscriberByOfflineStats.loading = payload;
    },
    setOfflineSubscriberByDuration: (
      state: ISPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.offlineSubscriberByDuration.loading = payload;
    },
    setPoorSignalByOLTStats: (
      state: ISPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.poorSignalByOLTStats.loading = payload;
    },
    setSubscriberByPoorOpticalStatus: (
      state: ISPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.subscriberByPoorOpticalStats.loading = payload;
    },
    setSubscriberByPendingStatus: (
      state: ISPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.subscriberByPendingStats.loading = payload;
    },
    subscriberTrendStatus: (
      state: ISPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.subscriberTrend.loading = payload;
    },
    setShowOverview: (
      state: ISPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.showOverview = payload;
    },
    setOfflineReasonCodeByOLT: (
      state: ISPDashboardState,
      { payload }: { payload: boolean },
    ) => {
      state.offlineReasonCodeByOLT.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadOperatorStats.fulfilled,
      (state, { payload }: { payload: IOperatorStats }) => {
        state.stats.operator.loading = false;
        if (payload) {
          state.stats.operator.unknownDevice = payload.unknownDevice;
          state.stats.operator.oltCount = payload.oltCount;
          state.stats.operator.unassignedDeviceCount =
            payload.unassignedDeviceCount;
          state.stats.operator.managementEntity = payload.managementEntity;
          state.stats.operator.error = false;
        } else {
          state.stats.operator.error = true;
        }
      },
    );
    builder.addCase(
      loadISPDetails.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.overview.loading = false;
        if (payload) {
          state.overview.details = payload;
          state.filter = {
            id: payload.id,
          };
        } else {
          state.overview.error = true;
        }
      },
    );
  },
});

export const ISPDashboardActions = {
  ...ispDashboardSlice.actions,
  loadOperatorStats,
  loadISPDetails,
};

export const ISPDashboardReducer = ispDashboardSlice.reducer;
