import {
  ACCESS_TYPE_DISPLAY_NAME,
  IPowerRating,
  Radio,
  SIGNAL_STRENGTH,
  StatsSupported,
  featuresAuthorized,
  featuresSupported,
} from './subscriberDashboard.types';
import {
  CONFIGURATION_STATUS,
  FrequencyBand,
  IFiltersDropdown,
  IFiltersDropdownOptions,
  IManagementEntityDetails,
  IOptions,
  IPagination,
  MANAGEMENT_PROTOCOL,
  ManagementEntity,
  OnboardingState,
  OnlineStatus,
} from '../types';

import { IGoogleGeoCoordinates } from './googleMaps.types';
import { IOLTListItems } from './olt.types';
import { ISplitterParentOLTData } from './splitter.types';
import { StepsProps } from 'antd';

//  #region - Subscriber Form
export interface ISubscriberFormSteps {
  stepIndex: number;
  key: SUBSCRIBER_FORM_STEPS;
  title: string;
  icon: JSX.Element;
  hidden?: boolean;
  status?: StepsProps['status'];
  skipAndSubmit?: boolean; //  Skip next sections and submit the current active form. Used for Subscriber edit Individual form submit also.
  children?: JSX.Element;
  stepDescription?: boolean; // Description content to be displayed on right side of form
}

export interface ISubscriberDetailsForm extends IGoogleGeoCoordinates {
  name: string;
  email?: string | null;
  phoneNumber?: string | null;
  countryCode?: string;
  cpeId?: string | null;
  address: ISubscriberAddress;
  franchiseeId?: string | null;
  subGroup?: {
    id: string;
    name: string;
  } | null;
  PPPOEUserName?: string;
  selectedSegment?: string; // UI purpose - to get the segment value

  // band: 'SINGLE' | 'DUAL' | 'NOT_APPLICABLE';
  emailId?: string;
  olt?: IOLTDetails | null;
}

export interface IDeviceDetailsForm {
  hardware: {
    band: FREQUENCY_BAND;
    manufacturer: string;
    model: string;
    version?: string;
    id?: string;
  };
}

export interface IOLTDetailsForm {
  device?: { oltDetails?: IOLTDetails };
  splitter?: ISplitterParentOLTData;
}

export interface IWANConfigForm {
  connectionType: WAN_CONNECTION_TYPE_VALUES;
  connectionTypeValue?: WAN_CONNECTION_TYPE; // Only for UI Purpose
  connectionName?: string | null;
  id?: string | null;
  ipId?: string | null;
  pppoeId?: string | null;
  mac?: string | null;
  connectionStatus?: string;
  ipAddress?: string;
  ipMode?: string | null;
  addressingType?: string | null;
  natEnabled?: boolean | null;
  vlanEnabled?: boolean | null;
  vlanId?: string | null;
  priority?: string | number | null;
  mtu?: string | null;
  serviceList?: string | null;
  static?: IStatic | null;
  pppoe?: IPPPoE | null;
  portBinding?: IPortBinding | null;
  editWANIndex?: number | null; // Only for UI Purpose to set the array index to edit a particular WAN
}
export interface IStatic {
  externalIpAddress?: string | null;
  subnetMask?: string | null;
  defaultGateway?: string | null;
  primaryDNS?: string | null;
  secondaryDNS?: string | null;
}

export interface IPPPoE {
  username?: string | null;
  password?: string | null;
  serviceName?: string | null;
  pppName?: string | null;
}

export interface ILANConfigForm {
  ip: string;
  subnetMask: string;
  dhcpGwIp: string;
  startIp: string;
  endIp: string;
  leaseTimeInSeconds: string;
  dnsMode?: LAN_DNS_MODE | null;
  dnsModeFlag?: boolean | null; // Only for UI Purpose
  primaryDnsServer?: string | null;
  secondaryDnsServer?: string | null;
  tertiaryDnsServer?: string | null;

  title?: string; //  Only for UI purpose
}

export interface IWLANConfigForm {
  enabled?: boolean;
  bssid?: string;
  encryption?: string;
  wpaEncryptionMode?: string;
  ieee11iEncryptionMode?: string;
  wpaEncryptionModeValue?: string[] | null; // Only For UI Form Purpose
  ieee11iEncryptionModeValue?: string[] | null; // Only For UI Form Purpose
  guest?: boolean;
  hidden?: boolean;
  operationMode?: string;
  security?: string | null;
  ssid?: string;
  password?: string;
  id?: string;
  band?: string;
  channel?: number;
  channelWidth?: string;
  ssidAdvertisementEnabled?: boolean;
  wmmEnable?: boolean;
  blockRelay?: boolean;
  guardInterval?: boolean | null;
  totalAssociations?: number;
  wpaGroupRekeyTime?: number | null;
  wpaCipherSuite?: string[];
  wpa2CipherSuite?: string[];
  radioPower?: string;
  regulatoryDomain?: string;

  title?: string; //  Only for UI purpose
}
export interface IWLANForm {
  [FrequencyBand._2_4GHZ]?: IWLANConfigForm | null;
  [FrequencyBand._5GHZ]?: IWLANConfigForm | null;
}

export interface IPortBinding {
  lan?: IPortBindingLAN[];
  wlan?: IPortBindingLAN[];

  // Only for UI purpose
  selectedLanIds?: string[] | null;
  selectedWLAN_2_4Ids?: string[] | null;
  selectedWLAN_5_0Ids?: string[] | null;
}

export interface IPortBindingLAN {
  name: string | null;
  id: string | null;
  band?: string | null;
  enabled?: boolean | null;
}

export interface IWLANBands {
  [FrequencyBand._2_4GHZ]: IPortBindingLAN[];
  [FrequencyBand._5GHZ]?: IPortBindingLAN[];
}

export interface IVoIP {
  voipPortId: string;
  user: IVoIPUserDetails;
  primarySIP: ISIPDetails;
  secondarySIP: ISIPDetails;
  status?: VOIP_STATUS;

  secondarySIPFlag?: boolean | null; // Only for UI Purpose
}
export interface IVoIPUserDetails {
  account: string;
  number: string;
  password: string;
}
export interface ISIPDetails {
  sipDomain: string;
  sipRegisterAddress: string;
  sipProxyAddress: string;
  port: number | null;
  proxyPort: number | null;
  outboundProxyAddress: string;
  outboundProxyPort: number | null;
}

export enum VOIP_STATUS {
  REGISTERING = 'Registering',
  REGISTERED = 'Registered',
  RING = 'Ring',
  CALL_CONNECTED = 'Call Connected',
}

export interface ISupportedEnumValues {
  manufacturer: string;
  hardwareModel: string;
  fields: ISupportedEnumFields;
}

export interface ISupportedEnumFields {
  ieee11iEncryptionMode: IOptions[];
  pppName: IOptions[];
  channel: IOptions[];
  natEnabled?: IOptions[];
  vlanEnabled?: IOptions[];
  channelWidth: IOptions[];
  connectionType: IOptions[];
  encryption: IOptions[];
  ipMode: IOptions[];
  powerLevel: IOptions[];
  regulatoryDomain: IOptions[];
  serviceList: IOptions[];
  wpaEncryptionMode: IOptions[];
}

export interface ISubscriberPayload
  extends ISubscriberDetailsForm,
    IDeviceDetailsForm {
  device?: ISubscriberDevice | null;
  connectionStatus?: ONLINE_STATUS;
  onboardingState?: ONBOARDING_STATE;
  wan?: IWANConfigForm[] | null;
  lan?: ILANConfigForm | null;
  wlan?: IWLANConfigForm[] | null;
  voip?: IVoIP[] | null;
}

export type TFormValues =
  | ISubscriberDetailsForm
  | IDeviceDetailsForm
  | IOLTDetailsForm
  | IWANConfigForm
  | IWANConfigForm[]
  | ILANConfigForm
  | IWLANForm
  | IVoIP[]
  | IVoIP;

export enum SUBSCRIBER_FORM_NAMES {
  SUBSCRIBER_DETAILS = 'subscriber-details',
  OLT_DETAILS = 'olt-details',
  DEVICE_DETAILS = 'device-details',
  WAN_CONFIG = 'wanConfig',
  LAN_CONFIG = 'lanConfig',
  WLAN_CONFIG_2_4 = 'wlanConfig_2_4',
  WLAN_CONFIG_5_0 = 'wlanConfig_5_0',
  VOIP_CONFIG = 'voipConfig',
}

export enum SUBSCRIBER_FORM_STEPS {
  SUBSCRIBER_DETAILS = 'subscriber-details',
  OLT_DETAILS = 'olt-details',
  DEVICE_DETAILS = 'device-details',
  WAN = 'wan',
  LAN = 'lan',
  VOIP = 'voip',
  SUMMARY = 'summary',
}

export const LAN_WLAN_SECTION_KEYS = {
  LAN: 'LAN',
  WLAN_2_4: 'WLAN_2.4 GHz',
  WLAN_5_0: 'WLAN_5.0 GHz',
};

export const WAN_CONFIG_IPMODE = {
  DHCP: 'DHCP',
  STATIC: 'Static',
  PPPOE: 'PPPOE',
};
export enum LAN_DNS_MODE {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}
export enum WLAN_ENCRYPTION {
  WPA = 'WPA',
  WPA2 = 'WPA2',
  'WPA2 Mixed' = 'WPA2 Mixed',
}

export const FORM_STEP_INDEX_MAPPING: {
  [key in SUBSCRIBER_FORM_STEPS]: number;
} = {
  [SUBSCRIBER_FORM_STEPS.SUBSCRIBER_DETAILS]: 0,
  [SUBSCRIBER_FORM_STEPS.OLT_DETAILS]: 1,
  [SUBSCRIBER_FORM_STEPS.DEVICE_DETAILS]: 2,
  [SUBSCRIBER_FORM_STEPS.WAN]: 3,
  [SUBSCRIBER_FORM_STEPS.LAN]: 4,
  [SUBSCRIBER_FORM_STEPS.VOIP]: 5,
  [SUBSCRIBER_FORM_STEPS.SUMMARY]: 6,
};

export interface ILANForm {
  lan?: ILANConfigForm | null;
}

export interface IPortBindingParams {
  hardwareId?: string | null;
  subscriberId?: string | null;
  wanId?: string | null;
  wanConnectionName?: string | null;
}

export const MANUFACTURER_QUERY_PARAMS = {
  CWMP_CAPABILITIES: 'WanConfig',
};
export interface IVoIPPortsSupportedResponse {
  supported: boolean;
  numberOfVoipPorts: number;
}
export interface IVoIPConfigResponse {
  voip: IVoIP[];
}

export const SUBSCRIBER_BULK_UPLOAD_MESSAGES = {
  YET_TO_PROCESS: 'Yet to process',
  VALIDATED: 'Validated',
  VALIDATION_FAILED: 'Failed',
  CREATION_FAILED: 'Failed',
  CREATED: 'Success',
  FAILED: 'Failed',
  SMS_SENT: 'SMS sent',
  SMS_FAILED: 'SMS sending failed',
  SMS_SKIPPED: 'SMS skipped',
};

export const BULK_UPLOAD_MESSAGES = {
  REQUESTED: 'Requested',
  UPLOADED: 'File uploaded sccessfully',
  PROCESSING: 'Processing your request',
  COMPLETED: 'Completed',

  UPLOAD_FAILED: 'Error in uploading the file. Please try again',
  CHECKSUM_FAILED: 'Error in processing your request. Please try again',
  INVALID_FILE: 'Please upload a valid file.',
  REQUEST_FAILED: 'Error in processing your request. Please try again',
  AUTHENTICATION_FAILED: 'Error in processing your request. Please try again',
  DOWNLOAD_FILE_FAILED: 'Error in processing your request. Please try again',
  VALIDATION_FAILED: 'Error in processing your request. Please try again',
  FAILED: 'Error in processing your request. Please try again',
  INVALID_SUBSCRIPTION:
    'Subscription not valid. Please get a valid subscription to create subscribers',
  SUBSCRIBER_COUNT_EXCEEDED:
    'You are trying to create more subscribers than the limit allowed for your subscription',
};
//  #endregion - Subscriber Form

//  #region - Subscriber

export interface ISubscriber
  extends ISubscriberDetailsForm,
    IDeviceDetailsForm {
  subscriberId: string;
  device?: ISubscriberDevice | null;
  connectionStatus?: OnlineStatus;
  onboardingState?: OnboardingState;
  wan?: IWANConfigForm[] | null;
  lan?: ILANConfigForm | null;
  wlan?: IWLANConfigForm[] | null;
  voip?: IVoIP[] | null;

  band?: TBand;
  featuresAuthorized?: featuresAuthorized;
  featuresSupported?: featuresSupported;
  friendly?: boolean;
  invitationId?: string | null;
  managementEntities?: ManagementEntity[];
  onboardingStatus?: string;
  powerRating?: IPowerRating;
  pendingConfigurationCount?: number;
  createdTransactionsCount?: number;
  secondaryPhoneNumber?: string;
  signalStrength?: 'POOR' | 'GOOD' | 'AVERAGE';
  subscriberGroupId?: string;
  statsSupported?: StatsSupported;
  tags?: ISubscriberTags;
  subscriberSince: string;
  logRetentionDays: number;
  olt: IOLTDetails;
  alreadyUnassigned: boolean;

  wanProvision?: IWANProvision; // Only used for subscriber List

  //Same as IWanProvision interface. Used on subscriber get by id endpoint
  PONSerialNumber?: string;
  PPPOEPassword?: string;
  PPPOEServiceName?: string;
  PPPOEUserName?: string;
  isWanProvisioningEnabled?: boolean;
  serviceProfile?: string;
  lineProfile?: string;
  vlanId?: string;
  ponMap?: IPONMap;
}

export interface ISubscriberDevice {
  mac: string;
  deviceId?: string;
  name?: string;
  deviceState?: DEVICE_STATE;
  serialNumber?: string;
  productClass?: string;
  hardwareId?: string;
  hardwareModel?: string;
  hardwareVersion?: string;
  managementEntityId?: string;
  manufacturer?: string;
  PONSerialNumber?: string;

  firmwareVersion?: string;
  agentVersion?: string;
  baseImageVersion?: string;
  isFirmwareUpdateRequired?: string;
  displayModel?: string;
  managementProtocol?: MANAGEMENT_PROTOCOL;
  agentDetails?: IAgentDetails;
  band: ['2.4 GHz', '5.0 GHz'] | ['2.4 GHz'] | null;
  offlineReasonCode?: string;
  deviceStatus?: string;
  isBaseFirmwareUpgradeRequired?: boolean | null;
  baseFirmwareLink?: string | null;
  isFirmwareUpgradeRequired?: string | null;
  firmwareLink?: string | null;
  cloudEnabled?: boolean | null;
  accessType?: ACCESS_TYPE_DISPLAY_NAME;
  onlineStatus: OnlineStatus;
  upTime?: number | null;
  lastSeenTime?: string;
  publicIp?: string;
  wanIpv6?: string;
  wanIp?: string;
  wlans?: ISubscriberDeviceWLAN[];
  radios?: TRadios;
  ports?: Ports;
  wifiEnabled?: boolean;
  ponSerialNumber?: string;
  oltDetails?: IOLTDetails | null;
  ontNumber?: string;
  wanSettings?: IWanSettings;
  optical?: IOptical | null;
  vlanId?: number;
  numberOfRadios: 1 | 2;
}

export interface IOLTDetails {
  label?: string;
  ponPortCount?: number | null;
  portNumber?: string | number | null;
  portName?: string | null;
  portIndex?: string | null;
  model?: string;
  mac?: string | null;
  manufacturer?: string | null;
  ipAddress?: string;
  ontNumber?: string | null;
  distance?: string;
  updatedTime?: string;
}

export interface ISubscriberDeviceWLAN {
  enabled?: boolean;
  band: string;
  bssid: string;
  channel: string | number;
  clientCount: string | number;
  ssid: string;
  id: string;
}
export interface IWANProvision {
  ponType?: string;
  PONSerialNumber?: string;
  PPPOEPassword?: string;
  PPPOEServiceName?: string;
  PPPOEUserName?: string;
  serviceProfile?: string;
  lineProfile?: string;
  olt: {
    label?: string;
    ipAddress?: string;
    manufacturer?: string;
    model?: string;
    mac?: string;
  };
  state?: string;
  vlanId?: string;
}
export interface IWanSettings {
  pppoe: {
    username: string;
  };
}
export interface Ports {
  ethernet: Ethernet[];
  fxs: Fxs[];
  gpon?: Pon;
  epon?: Pon;
}

export interface Ethernet {
  port: number;
  type: string;
  active: boolean;
  disabled: boolean;
}

export interface Fxs {
  index: number;
  name: string;
  supported: boolean;
  active: boolean;
  disabled: boolean;
}

export interface Pon {
  active: boolean;
  disabled: boolean;
}

export interface ISubscriberResponse extends IDeviceDetailsForm {
  subscriberId: string | null;
  name: string | null;
  emailId?: string | null;
  phoneNumber?: string | null;
  secondaryPhoneNumber?: string | null;
  address: ISubscriberAddress;
  onboardingState?: OnboardingState;
  franchiseeId?: string | null;
  subscriberGroupId?: string | null;
  device?: ISubscriberDeviceResponse | null;
  invitationId?: string | null;
  managementEntities?: ManagementEntity[];
  statsSupported?: string[];
  featuresSupported?: string[];
  featuresAuthorized?: string[];
  subscriberSince?: string;
  logRetentionDays?: number | null;
  trialEnabled?: boolean;
  friendly?: boolean;
  tags?: ISubscriberTags;
  cpeId?: string | null;
  powerRating?: IPowerRating;
  pendingConfigurationCount?: number;
  createdTransactionsCount?: number;
  olt?: IOLTDetails | null;
  serviceProfile?: string | null;
  lineProfile?: string | null;
  manufacturer?: string | null;
  PONSerialNumber?: string | null;
  PPPOEServiceName?: string | null;
  PPPOEUserName?: string | null;
  PPPOEPassword?: string | null;
  vlanId?: string | null;
  isWanProvisioningEnabled?: boolean | null;
  ponMap?: IPONMap;

  wan?: IWANConfigForm[] | null;
  lan?: ILANConfigForm | null;
  wlan?: IWLANConfigForm[] | null;
  voip?: IVoIP[] | null;
}

export interface ISubscriberDeviceResponse {
  mac: string;
  serialNumber?: string | null;
  deviceStatus?: string;
  manufacturer?: string;
  managementProtocol?: MANAGEMENT_PROTOCOL;
  onlineStatus?: OnlineStatus;
  upTime?: number | null;
  lastSeenTime?: string;
  publicIp?: string;
  wanIp?: string;
  radios?: IRadio[];
  numberOfRadios?: number | null;
  hardwareId?: string | null;
  hardwareModel?: string | null;
  firmwareVersion?: string | null;
  agentVersion?: string | null;
  isBaseFirmwareUpgradeRequired?: boolean | null;
  baseFirmwareLink?: string | null;
  isFirmwareUpgradeRequired?: boolean | null;
  firmwareLink?: string | null;
  cloudEnabled?: boolean | null;
  ports?: Ports | null;
  accessType?: ACCESS_TYPE_DISPLAY_NAME;
  hardwareVersion?: string | null;
  wlans?: ISubscriberDeviceWLAN[];
  band?: ['2.4 GHz', '5.0 GHz'] | ['2.4 GHz'] | null;
  wifiEnabled?: boolean | null;
  agentDetails?: IAgentDetails | null;
  oltDetails?: IOLTDetails | null;
  wanSettings?: IWanSettings | null;
  optical?: IOptical | null;
  rtt?: string | null;
  vlanId?: number | null;
  offlineReasonCode?: string | null;

  deviceId?: string;
  name?: string;
  deviceState?: DEVICE_STATE;
  productClass?: string;
  managementEntityId?: string;
  baseImageVersion?: string;
  isFirmwareUpdateRequired?: string;
  displayModel?: string;
  ponSerialNumber?: string;
  ontNumber?: string;
}

export interface IOptical {
  rxPower: string | null;
  txPower: string | null;
  strength: SIGNAL_STRENGTH | null;
}

export interface IRadio {
  frequency: TRadioBand;
  band: TRadioBand;
  channel: number;
  clientCount: number;
  channelWidth: string;
}

export interface IPONMap {
  splitter: ISplitter[];
  olt: IOLTDetails;
}

export interface ISplitter {
  id: string;
  serialNumber: string;
  friendlyName: string;
  geoLabel: string;
  port: string;
  isParent: boolean;
  active: boolean;
}

export type TRadioBand = '2.4 GHz' | '5.0 GHz';

//  #endregion - Subscriber

//  #region - Subscriber List

export interface ISubscriberListState {
  hardRefresh: boolean;
  loading: boolean;
  error: string;
  data: ISubscriberList;
  subscriberListFilter: ISubscriberFilters;
  subscriberListPagination: IPagination;
  currentTabId: TSubscriberTab | string;
  subscriberFilterDropDownList: {
    subscriberGroupDropDownList: IFiltersDropdownOptions[];
    managementEntitiesList?: IManagementEntityDetails[];
    OLTList: IOLTListItems[];
    vlanList: string[];
    offlineDurationList: IOptions[];
    offlineReasonCodeList: IOptions[];
    manufacturerList: string[];
    pincodeList: string[];
    hardwareModelList: IFiltersDropdown;
    hardwareVersionList: IFiltersDropdownOptions[];
  };
  selectedSubscriberGroupData: {
    loading: boolean;
    error: string;
    data: ISubscriberGroupListItem;
  };
  subscriberGroupOptions?: {
    sgId?: string | null;
    actionType: SUBSCRIBER_GROUP_ACTIONS | null;
  } | null;
  selectedSubscriberRows: ISubscriber[];
  isFilterOpen: boolean;
}
export interface ISubscriberList {
  items: ISubscriber[];
  pageSize: number;
  pageToken: string;
  pageNumber: number;
  total: number;
}
export interface ISubscriberFilters {
  signalStrength?: string;
  connectionStatus?: string;
  onboardingStatus?: OnboardingState | string;
  band?: TBand;
  protocol?: string;
  friendly?: string;
  firmwareUpgradeStatus?: string;
  manufacturer?: string;
  hwModel?: string;
  hwVersion?: string;
  managementEntity?: string;
  pincode?: string;
  oltMac?: string;
  vlanId?: string;
  selectedVlanIds?: string[]; // Only for Form value purpose (In Form field need to set as array type)
  oltPortNumber?: number | string;
  oltPortIndex?: string;
  offlineDuration?: string;
  offlineReasonCode?: string;
  subGroupId?: string;
  excludeBySubGroupId?: string;
  subscriptionState?: string;
  firmwareUpgradeType?: string;
  configurationStatus?: CONFIGURATION_STATUS;
  splitterMapped?: BOOLEAN;
}

export type TBand = 'single' | 'dual';

export enum BOOLEAN {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}
export interface ISubscriberTags {
  TRIAL: 'true' | 'false';
}

export interface ISubscriberAddress {
  addressLine1?: string;
  areaCode?: string;
}

export interface IAgentDetails {
  mode: FIRMWARE_LAUNCHER_MODE;
  launcherVersion: string;
  fiiCoreVersion: string;
  agentVersion: string | null;
}

export enum FIRMWARE_LAUNCHER_MODE {
  LAUNCHER = 'LAUNCHER',
  FIICORE_LOADED = 'FIICORE_LOADED',
  FIICORE_LITE_LOADED = 'FIICORE_LITE_LOADED',
}

export enum WAN_PROVISIONING_STATUS_VIEW {
  INITIATED = 'Initiated',
  UNSUPPORTED = 'Unsupported',
  FAILED = 'Failed',
  CONFIGURED = 'Configured',
  PENDING = 'Pending',
}

export interface ISubscriberBulkUploadResult {
  id: string;
  status: UPLOAD_STATUS;
  managementEntityName: string;
  managementEntityId: string;
  totalCount: number;
  success: number;
  failed: number;
  requestFile: string;
  responseFile: string;
  subscribers: ISubscriberBulkUpload[];
}

export interface ISubscriberBulkUpload {
  id: string;
  name: string;
  phoneNumber: number;
  email: string;
  address: ISubscriberAddress;
  deviceMac: string;
  statusCode: string;
  status: string;
  message: string;
}

export enum UPLOAD_STATUS {
  REQUESTED = 'REQUESTED',
  UPLOADED = 'UPLOADED',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  PROCESSING = 'PROCESSING',
  CHECKSUM_FAILED = 'CHECKSUM_FAILED',
  INVALID_FILE = 'INVALID_FILE',
  REQUEST_FAILED = 'REQUEST_FAILED',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  DOWNLOAD_FILE_FAILED = 'DOWNLOAD_FILE_FAILED',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export enum SUBSCRIBER_UPLOAD_STATUS {
  YET_TO_PROCESS = 'YET_TO_PROCESS',
  VALIDATED = 'VALIDATED',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  CREATION_FAILED = 'CREATION_FAILED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  SMS_SENT = 'SMS_SENT',
  SMS_FAILED = 'SMS_FAILED',
  SMS_SKIPPED = 'SMS_SKIPPED',
}

export interface BasicFilterOption {
  title: string;
  name: string;
  items?: { label: string; value: string }[];
  info?: string;
  hidden?: boolean;
}
export type TSubscriberTab = 'configured' | 'pending';
// export const Subscriber_Tabs = {
//   CONFIGURED: 'configured',
//   PENDING: 'pending',
// };
//  #endregion - Subscriber List

//  #region - Subscriber Group
export interface ISubscriberGroupReduxState {
  subscriberGroupList: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: ISubscriberGroupList;
  };
  subscriberGroupListPagination: IPagination;
}
export interface ISubscriberGroupList {
  items: ISubscriberGroupListItem[];
  pageSize: number;
  pageToken: number;
  total: number;
  page?: number | null;
}

export interface ISubscriberGroupListItem {
  id: string;
  name: string;
  type: SUBSCRIBER_GROUP_BY_TYPE;
  createdTime: string;
  updatedTime?: string;
  subscriberCount: number;
}

export enum SUBSCRIBER_GROUP_BY_TYPE {
  BY_SUBSCRIBER = 'by_subscribers',
}

export enum SUBSCRIBER_GROUP_BY_TYPE_VIEW {
  by_subscribers = 'Subscriber List',
}

export enum SUBSCRIBER_GROUP_ACTIONS {
  CREATE = 'create',
  EDIT = 'edit',
  ADD_SUBSCRIBERS = 'add-subscribers',
  DROP_SUBSCRIBERS = 'drop-subscribers',
  BULK_DELETE = 'subscriber-bulk-delete', // Subscriber Bulk Delete in Subscriber List Screen
}
//  #endregion - Subscriber Group

//  #region - Enum and Constants

export enum DeviceState {
  ACTIVE = 'ACTIVE', // Device subscriber association
  REGISTERED = 'REGISTERED', // Device created but not mapped to the subscriber
  ONLY_MAC_KNOWN = 'ONLY_MAC_KNOWN', //  (Internal state) # subscriber created but not device created
  UPGRADED = 'UPGRADED', // swap device - upgrade case
  FAULTY = 'FAULTY', // swap device - faulty
}

export enum DeviceStateView {
  ACTIVE = 'Active',
  REGISTERED = 'Registered',
  ONLY_MAC_KNOWN = 'Only MAC known',
  UPGRADED = 'Upgraded',
  FAULTY = 'Faulty',
}

export const SUBSCRIBER_DASHBOARD_TABS = {
  OVERVIEW: 'overview',
  CLIENTS: 'clientsAndTrends',
  DIAGNOSTICS: 'diagnostics',
  SETTINGS: {
    KEY: 'settings',
    LAN: 'lan',
    WAN: 'wan',
    VoIP: 'voip',
  },
  CONFIGURATION_UPDATES: 'configuration-updates',
  TRANSACTIONS: 'transactions',
};

export type TRadios = { [key in '2.4 GHz' | '5.0 GHz']: Radio };

export enum WAN_CONNECTION_TYPE {
  ROUTE = 'ROUTE',
  BRIDGE = 'BRIDGE',
}

export enum WAN_CONNECTION_TYPE_DISPLAY_NAME {
  ROUTE = 'Route',
  BRIDGE = 'Bridge',
}

export enum WAN_CONNECTION_TYPE_VALUES {
  BRIDGE = 'BRIDGE',
  DHCP_ROUTE = 'DHCP_ROUTE',
  STATIC_ROUTE = 'STATIC_ROUTE',
  PPPOE_ROUTE = 'PPPOE_ROUTE',
}

export enum WAN_CONFIG_IP_PROTOCOL_MODE {
  IPv4 = 'IPV4',
  IPv6 = 'IPV6',
  'IPv4/IPv6' = 'IPV4_IPV6',
}

export enum ONBOARDING_STATE {
  LOCATION_MAPPED = 'LOCATION_MAPPED',
  CPE_SWAP_IN_PROGRESS = 'CPE_SWAP_IN_PROGRESS',
  PROVISIONED = 'PROVISIONED',
  CONFIGURED = 'CONFIGURED',
  PENDING = 'PENDING',
}

export enum ONLINE_STATUS {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum ONLINE_STATUS_VIEW {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

export enum DEVICE_STATE {
  ACTIVE = 'ACTIVE', // Device subscriber association
  REGISTERED = 'REGISTERED', // Device created but not mapped to the subscriber
  ONLY_MAC_KNOWN = 'ONLY_MAC_KNOWN', //  (Internal state) # subscriber created but not device created
  UPGRADED = 'UPGRADED', // swap device - upgrade case
  FAULTY = 'FAULTY', // swap device - faulty
}

export enum WAN_CONNECTION_STATUS {
  CONNECTED = 'Connected',
}

export enum FREQUENCY_BAND {
  SINGLE = 'SINGLE',
  DUAL = 'DUAL',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum FREQUENCY_BAND_DISPLAY_NAME {
  SINGLE = 'Single',
  DUAL = 'Dual',
  NOT_APPLICABLE = 'Not Applicable',
}

export const MTU_MIN_VALUE = 576;
export const MTU_MAX_VALUE = 1500;

export enum OFFLINE_REASON_CODE {
  POWERED_OFF = 'POWERED_OFF',
  LOS = 'LOS',
  LOS_LINK_DOWN = 'LOS_LINK_DOWN',
  UNKNOWN = 'UNKNOWN',
}

export enum OFFLINE_REASON_CODE_VIEW {
  POWERED_OFF = 'Powered Off',
  LOS = 'Loss Off Signal',
  LOS_LINK_DOWN = 'Link Fault',
  UNKNOWN = 'Unknown',
}

export const OpticalStrengthTimeFilterOptions = [
  { key: '1h', label: '60 Minutes', value: '1h' },
  { key: '2h', label: '2 Hours', value: '2h' },
  { key: '12h', label: '12 Hours', value: '12h' },
  { key: '24h', label: '24 Hours', value: '24h' },
];
//  #endregion - Enum and Constants
