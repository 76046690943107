import { AuthState, BOOLEAN, IPagination } from 'store/types';
import {
  IOLTDashboardDeviceStats,
  IOLTDashboardFilters,
  IOLTDashboardMapViewTabFilters,
  IOLTDashboardReduxState,
  IOLTDashboardStats,
  IOLTListItems,
  IOLTOpticalTXPONOverviewStats,
  IOLTOpticalTXPONPortTimeSeries,
  IONTDevicesList,
  IPonPorts,
  OLT_DASHBOARD_TABS,
} from 'store/types/olt.types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { IOLTGoogleMapData } from 'store/types/googleMaps.types';
import { OPTICAL_STRENGTH_GRAPH_TYPE } from 'store/types/subscriberDashboard.types';
import { VizService } from 'apiUtils/services/vizbff.service';
import { formatError } from 'apiUtils/api';
import i18n from 'i18n';
import { isEmpty } from 'lodash';
import { removeEmptyValuesFromObject } from 'utils/utils';

interface IOpticalStatsParams {
  duration?: string;
}

const initialState: IOLTDashboardReduxState = {
  overview: {
    loading: true,
    error: '',
    data: {} as IOLTDashboardStats,
  },
  opticalTXPONOverviewStats: {
    loading: true,
    error: null,
    data: [] as IOLTOpticalTXPONOverviewStats[],
  },
  uplinkTrafficStats: {
    downloadStatsLoading: true,
    uploadStatsLoading: true,
  },
  deviceStats: {
    loading: true,
    error: '',
    data: {} as IOLTDashboardDeviceStats,
  },
  ponTrafficStats: {
    downloadStatsLoading: true,
    uploadStatsLoading: true,
  },
  opticalTXPONPortTimeSeries: {
    loading: true,
    error: '',
    data: [] as IOLTOpticalTXPONPortTimeSeries[],
  },
  ontDevicesList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as IONTDevicesList,
  },
  ontDeviceListPagination: {} as IPagination,
  oltDashboardFilters: {} as IOLTDashboardFilters,
  oltDashboardFiltersDropdownList: {
    oltList: { loading: false, error: null, data: [] as IOLTListItems[] },
    ponPortsList: [] as IPonPorts[],
  },
  mapViewDetails: {
    mapViewTabFilters: {
      portIndex: null,
      include_subscriber: BOOLEAN.TRUE,
      subscriberId: null,
    },
    splitterDetailsChartData: { loading: false, error: null, data: [] },
  },
  oltGoogleMapData: {
    loading: true,
    error: null,
    data: [] as IOLTGoogleMapData[],
  },
  currentTabId: '',
  isMobileViewONTDeviceScreen: false,
};

let overviewCancelTokenSource = axios.CancelToken.source();
let oltOpticalTXStatsCancelTokenSource = axios.CancelToken.source();

const fetchOLTList = createAsyncThunk(
  'oltDashboard/oltList/fetch',
  async (_, thunkAPI) => {
    const { auth }: { auth: AuthState } = thunkAPI.getState() as any;

    const response: AxiosResponse = await VizService.v1.GetOLTList({
      params: { managementEntityId: auth?.userInfo?.managementEntity?.id },
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.oltList'),
      };
    }
  },
);

const loadOLTDashboardStats = createAsyncThunk(
  'oltDashboardCardStats/fetch',
  async (_, thunkAPI) => {
    const {
      auth,
      OLTDashboardState,
    }: { auth: AuthState; OLTDashboardState: IOLTDashboardReduxState } =
      thunkAPI.getState() as any;

    if (
      OLTDashboardState?.oltDashboardFilters?.oltMac &&
      auth?.userInfo?.managementEntity?.id
    ) {
      overviewCancelTokenSource = axios.CancelToken.source();

      const response: AxiosResponse = await VizService.v1.GetOLTCardStats(
        {
          managementEntityId: auth?.userInfo?.managementEntity?.id,
          oltMac: OLTDashboardState?.oltDashboardFilters?.oltMac,
        },
        { cancelToken: overviewCancelTokenSource.token },
      );
      if (response?.status === 200) {
        return response?.data;
      } else {
        return {
          error: formatError(response) || i18n.t('messages.error.oltStats'),
        };
      }
    }
  },
);

const fetchOLTOpticalTXStats = createAsyncThunk(
  'oltDashboard/oltOpticalTXStats/fetch',
  async (payload: IOpticalStatsParams | void, thunkAPI) => {
    const {
      auth,
      OLTDashboardState,
    }: { auth: AuthState; OLTDashboardState: IOLTDashboardReduxState } =
      thunkAPI.getState() as any;

    if (
      OLTDashboardState?.oltDashboardFilters?.oltMac &&
      auth?.userInfo?.managementEntity?.id
    ) {
      oltOpticalTXStatsCancelTokenSource = axios.CancelToken.source();

      const response = await VizService.v1.GetOLTOpticalStats(
        {
          managementEntityId: auth?.userInfo?.managementEntity?.id,
          oltMac: OLTDashboardState?.oltDashboardFilters?.oltMac,
        },
        {
          cancelToken: oltOpticalTXStatsCancelTokenSource.token,
          params: {
            opticalType: OPTICAL_STRENGTH_GRAPH_TYPE.TX_POWER,
            duration: payload?.duration || undefined,
            portIndex:
              OLTDashboardState?.oltDashboardFilters?.portIndex || undefined,
          },
        },
      );
      if (response?.status === 200) {
        return response?.data;
      } else {
        return {
          error:
            formatError(response) || i18n.t('messages.error.fetchChartData'),
        };
      }
    }
  },
);

const loadOLTDashboardDevicesStats = createAsyncThunk(
  'oltDashboardDevicesStats/fetch',
  async (_, thunkAPI) => {
    const {
      auth,
      OLTDashboardState,
    }: { auth: AuthState; OLTDashboardState: IOLTDashboardReduxState } =
      thunkAPI.getState() as any;

    if (
      OLTDashboardState?.oltDashboardFilters?.oltMac &&
      auth?.userInfo?.managementEntity?.id
    ) {
      const response: AxiosResponse =
        await VizService.v1.GetOLTDashboardDeviceStats(
          {
            managementEntityId: auth?.userInfo?.managementEntity?.id,
            oltMac: OLTDashboardState?.oltDashboardFilters?.oltMac,
          },
          {
            params: {
              portIndex:
                OLTDashboardState.oltDashboardFilters.portIndex || undefined,
            },
          },
        );
      if (response?.status === 200) {
        return response?.data;
      } else {
        return {
          error: formatError(response) || i18n.t('messages.error.statsData'),
        };
      }
    }
  },
);

const loadONTDevicesList = createAsyncThunk(
  'ontDevicesList/fetch',
  async (_, thunkAPI) => {
    const {
      auth,
      OLTDashboardState,
    }: { auth: AuthState; OLTDashboardState: IOLTDashboardReduxState } =
      thunkAPI.getState() as any;

    if (
      OLTDashboardState?.oltDashboardFilters?.oltMac &&
      auth?.userInfo?.managementEntity?.id
    ) {
      const response: AxiosResponse = await VizService.v1.GetONTDevicesList(
        {
          managementEntityId: auth.userInfo?.managementEntity?.id,
          oltMac: OLTDashboardState?.oltDashboardFilters?.oltMac,
        },
        {
          params: {
            portIndex:
              OLTDashboardState.oltDashboardFilters.portIndex || undefined,
            ...OLTDashboardState?.ontDeviceListPagination,
          },
        },
      );
      if (response?.status === 200) {
        return response?.data;
      } else {
        return {
          error: formatError(response) || i18n.t('messages.error.devicesList'),
        };
      }
    }
  },
);

const fetchSplitterChart = createAsyncThunk(
  'splitterChart/fetch',
  async (_, thunkAPI) => {
    const {
      OLTDashboardState,
    }: { auth: AuthState; OLTDashboardState: IOLTDashboardReduxState } =
      thunkAPI.getState() as any;
    const pathParams = {
      oltMac: OLTDashboardState?.oltDashboardFilters?.oltMac || '',
    };
    const response: AxiosResponse = await VizService.v1.GetNetworkHierarchy(
      pathParams,
      {
        params: { ...OLTDashboardState?.mapViewDetails?.mapViewTabFilters },
      },
    );

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.somethingWentWrong'),
      };
    }
  },
);

const fetchOLTGoogleMaps = createAsyncThunk(
  'oltGoogleMap/fetch',
  async (_, thunkAPI) => {
    const {
      OLTDashboardState,
    }: { auth: AuthState; OLTDashboardState: IOLTDashboardReduxState } =
      thunkAPI.getState() as any;
    const pathParams = {
      oltMac: OLTDashboardState?.oltDashboardFilters?.oltMac || '',
    };
    const mapTabFilters = OLTDashboardState?.mapViewDetails?.mapViewTabFilters;
    const response: AxiosResponse | any = await VizService.v1.GetOLTGoogleMap(
      pathParams,
      {
        params: removeEmptyValuesFromObject(
          {
            ...mapTabFilters,
          },
          'include_subscriber',
        ),
      },
    );

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.somethingWentWrong'),
      };
    }
  },
);

const OLTDashboardSlice = createSlice({
  name: 'OLT Dashboard',
  initialState,
  reducers: {
    resetToInit: () => initialState,

    resetOverviewState: (state: IOLTDashboardReduxState) => {
      state.overview = {
        loading: true,
        error: '',
        data: {} as IOLTDashboardStats,
      };
      state.opticalTXPONOverviewStats = {
        loading: true,
        error: null,
        data: [] as IOLTOpticalTXPONOverviewStats[],
      };
      state.uplinkTrafficStats = {
        downloadStatsLoading: true,
        uploadStatsLoading: true,
      };
      state.deviceStats = {
        loading: true,
        error: '',
        data: {} as IOLTDashboardDeviceStats,
      };
    },
    resetONUTabState: (state: IOLTDashboardReduxState) => {
      state.deviceStats = {
        loading: true,
        error: '',
        data: {} as IOLTDashboardDeviceStats,
      };
      state.opticalTXPONPortTimeSeries = {
        loading: true,
        error: '',
        data: [] as IOLTOpticalTXPONPortTimeSeries[],
      };
      state.ponTrafficStats = {
        downloadStatsLoading: true,
        uploadStatsLoading: true,
      };
      state.ontDevicesList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IONTDevicesList,
      };
    },
    resetMapViewTabState: (state: IOLTDashboardReduxState) => {
      state.overview = {
        loading: true,
        error: '',
        data: {} as IOLTDashboardStats,
      };
      state.mapViewDetails = {
        mapViewTabFilters: {
          portIndex: null,
          include_subscriber: BOOLEAN.TRUE,
          subscriberId: null,
        },
        splitterDetailsChartData: {
          loading: false,
          error: null,
          data: [],
        },
      };
    },
    resetOLTGoogleMapTabState: (state: IOLTDashboardReduxState) => {
      state.oltGoogleMapData = {
        loading: true,
        error: null,
        data: [] as IOLTGoogleMapData[],
      };
      // state.mapViewDetails.mapViewTabFilters = {
      //   subscriberId: null,
      // };
    },
    resetONTDeviceState: (state: IOLTDashboardReduxState) => {
      state.ontDevicesList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IONTDevicesList,
      };
      state.ontDeviceListPagination = {};
    },

    resetOpticalTXPONOverviewStats: (state: IOLTDashboardReduxState) => {
      state.opticalTXPONOverviewStats = {
        loading: true,
        error: null,
        data: [] as IOLTOpticalTXPONOverviewStats[],
      };
    },
    resetOpticalTXPONPortTimeSeries: (state: IOLTDashboardReduxState) => {
      state.opticalTXPONPortTimeSeries = {
        loading: true,
        error: '',
        data: [] as IOLTOpticalTXPONPortTimeSeries[],
      };
    },

    setCurrentTabId: (
      state: IOLTDashboardReduxState,
      { payload }: { payload: string },
    ) => {
      state.currentTabId = payload;
      if (payload === OLT_DASHBOARD_TABS.OVERVIEW) {
        OLTDashboardSlice.caseReducers.resetOverviewState(state);
      } else if (payload === OLT_DASHBOARD_TABS.ONUS) {
        OLTDashboardSlice.caseReducers.resetONUTabState(state);
      } else if (payload === OLT_DASHBOARD_TABS.SPLITTER_MAP_VIEW) {
        OLTDashboardSlice.caseReducers.resetMapViewTabState(state);
      } else if (payload === OLT_DASHBOARD_TABS.OLT_GOOGLE_MAP) {
        OLTDashboardSlice.caseReducers.resetOLTGoogleMapTabState(state);
      }
    },

    setOLTDashboardFilters: (
      state: IOLTDashboardReduxState,
      { payload }: { payload: IOLTDashboardFilters },
    ) => {
      if (isEmpty(payload)) {
        state.oltDashboardFilters = {};
      } else {
        state.oltDashboardFilters = Object.assign(
          state.oltDashboardFilters,
          payload,
        );
      }

      if (state.currentTabId === OLT_DASHBOARD_TABS.ONUS) {
        OLTDashboardSlice.caseReducers.resetONUTabState(state);
        state.ontDeviceListPagination.pageToken = 1;
      }
    },

    setUplinkTrafficDownloadLoading: (
      state: IOLTDashboardReduxState,
      { payload }: { payload: boolean },
    ) => {
      state.uplinkTrafficStats.downloadStatsLoading = payload;
    },
    setUplinkTrafficUploadLoading: (
      state: IOLTDashboardReduxState,
      { payload }: { payload: boolean },
    ) => {
      state.uplinkTrafficStats.uploadStatsLoading = payload;
    },

    setPONTrafficDownloadLoading: (
      state: IOLTDashboardReduxState,
      { payload }: { payload: boolean },
    ) => {
      state.ponTrafficStats.downloadStatsLoading = payload;
    },
    setPONTrafficUploadLoading: (
      state: IOLTDashboardReduxState,
      { payload }: { payload: boolean },
    ) => {
      state.ponTrafficStats.uploadStatsLoading = payload;
    },

    setPONPortsList: (
      state: IOLTDashboardReduxState,
      { payload }: { payload: IPonPorts[] | undefined },
    ) => {
      state.oltDashboardFiltersDropdownList.ponPortsList = payload || [];
      state.oltDashboardFilters.portIndex =
        state.oltDashboardFilters?.portIndex || payload?.[0]?.portIndex;
    },

    setONTDevicesListPageToken: (
      state: IOLTDashboardReduxState,
      { payload }: { payload: number },
    ) => {
      state.ontDeviceListPagination.pageToken = payload;
    },
    setONTDevicesListPagination: (
      state: IOLTDashboardReduxState,
      action: PayloadAction<IPagination>,
    ) => {
      state.ontDeviceListPagination = action.payload;
    },
    setMobileViewONTDeviceScreen: (
      state: IOLTDashboardReduxState,
      { payload }: { payload: boolean },
    ) => {
      state.isMobileViewONTDeviceScreen = payload;
    },

    setMapViewFilters: (
      state: IOLTDashboardReduxState,
      { payload }: { payload: IOLTDashboardMapViewTabFilters },
    ) => {
      if (isEmpty(payload)) {
        state.mapViewDetails.mapViewTabFilters = {};
      } else {
        state.mapViewDetails.mapViewTabFilters = Object.assign(
          state.mapViewDetails.mapViewTabFilters,
          payload,
        );
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchOLTList.pending, (state) => {
      state.oltDashboardFiltersDropdownList.oltList = {
        loading: true,
        error: null,
        data: [],
      };
    });
    builder.addCase(
      fetchOLTList.fulfilled,
      (
        state: IOLTDashboardReduxState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.oltDashboardFiltersDropdownList.oltList.loading = false;
        if (isEmpty(payload?.error)) {
          state.oltDashboardFiltersDropdownList.oltList.data = payload || [];
        } else {
          state.oltDashboardFiltersDropdownList.oltList.error = payload?.error;
        }
      },
    );

    builder.addCase(loadOLTDashboardStats.pending, (state) => {
      state.overview.loading = true;
      overviewCancelTokenSource.cancel();
    });
    builder.addCase(
      loadOLTDashboardStats.fulfilled,
      (
        state: IOLTDashboardReduxState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.overview.loading = false;
        if (isEmpty(payload?.error)) {
          state.overview.data = payload;
        } else {
          state.overview.error = payload?.error;
        }
      },
    );

    builder.addCase(loadOLTDashboardDevicesStats.pending, (state) => {
      state.deviceStats.loading = true;
    });
    builder.addCase(
      loadOLTDashboardDevicesStats.fulfilled,
      (
        state: IOLTDashboardReduxState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.deviceStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.deviceStats.data = payload;
        } else {
          state.deviceStats.error = payload?.error;
        }
      },
    );

    builder.addCase(fetchOLTOpticalTXStats.pending, (state) => {
      if (state.currentTabId === OLT_DASHBOARD_TABS.OVERVIEW) {
        state.opticalTXPONOverviewStats = {
          loading: true,
          error: null,
          data: [],
        };
      } else {
        state.opticalTXPONPortTimeSeries = {
          loading: true,
          error: null,
          data: [],
        };
      }
      oltOpticalTXStatsCancelTokenSource.cancel();
    });
    builder.addCase(
      fetchOLTOpticalTXStats.fulfilled,
      (
        state: IOLTDashboardReduxState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        if (state.currentTabId === OLT_DASHBOARD_TABS.OVERVIEW) {
          state.opticalTXPONOverviewStats.loading = false;
          if (isEmpty(payload?.error)) {
            state.opticalTXPONOverviewStats.data = payload;
          } else {
            state.opticalTXPONOverviewStats.error = payload?.error;
          }

          // //  Mock Handler
          // state.opticalTXPONOverviewStats.data =
          //   OLT_OPTICAL_TX_PON_OVERVIEW_STATS_MOCK;
        } else {
          state.opticalTXPONPortTimeSeries.loading = false;
          if (isEmpty(payload?.error)) {
            state.opticalTXPONPortTimeSeries.data = payload;
          } else {
            state.opticalTXPONPortTimeSeries.error = payload?.error;
          }

          // //  Mock Handler
          // state.opticalTXPONPortTimeSeries.data =
          //   OLT_OPTICAL_TX_PON_PORT_TIME_SERIES_MOCK;
        }
      },
    );

    builder.addCase(loadONTDevicesList.pending, (state) => {
      state.ontDevicesList.loading = true;
    });
    builder.addCase(
      loadONTDevicesList.fulfilled,
      (
        state: IOLTDashboardReduxState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.ontDevicesList.loading = false;
        state.ontDevicesList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.ontDevicesList.data = payload;
        } else {
          state.ontDevicesList.error = payload?.error;
        }
      },
    );

    builder.addCase(fetchSplitterChart.pending, (state) => {
      state.mapViewDetails.splitterDetailsChartData = {
        loading: true,
        error: null,
        data: [],
      };
    });
    builder.addCase(
      fetchSplitterChart.fulfilled,
      (
        state: IOLTDashboardReduxState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.mapViewDetails.splitterDetailsChartData.loading = false;
        if (isEmpty(payload?.error)) {
          state.mapViewDetails.splitterDetailsChartData.data = [payload];
        } else {
          state.mapViewDetails.splitterDetailsChartData.error = payload?.error;
        }
      },
    );

    builder.addCase(fetchOLTGoogleMaps.pending, (state) => {
      state.oltGoogleMapData = {
        loading: true,
        error: null,
        data: [],
      };
    });
    builder.addCase(
      fetchOLTGoogleMaps.fulfilled,
      (
        state: IOLTDashboardReduxState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.oltGoogleMapData.loading = false;
        if (isEmpty(payload?.error)) {
          state.oltGoogleMapData.data = payload;
        } else {
          state.oltGoogleMapData.error = payload?.error;
        }
      },
    );
  },
});

export const OLTDashboardActions = {
  ...OLTDashboardSlice.actions,
  fetchOLTList,
  loadOLTDashboardStats,
  fetchOLTOpticalTXStats,
  loadOLTDashboardDevicesStats,
  loadONTDevicesList,
  fetchSplitterChart,
  fetchOLTGoogleMaps,
};

export const OLTDashboardReducer = OLTDashboardSlice.reducer;
