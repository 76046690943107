import {
  ISMSTransactionListFilters,
  ISMSTransactionsList,
  ISMSTransactionsReduxState,
} from 'store/types/smsTransactions.types';
import { TicketManagerRequester, formatError } from 'apiUtils/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { IPagination } from 'store/types';
import i18n from 'i18n';
import { isEmpty } from 'lodash';

const initialState: ISMSTransactionsReduxState = {
  smsTransactionsList: {
    loading: true,
    error: '',
    data: {} as ISMSTransactionsList,
    hardReload: true,
  },
  hasNextPageToken: false,
  smsTransactionsListPagination: {} as IPagination,
  smsTransactionsListPageTokens: [''],
  smsTransactionListFilters: {} as ISMSTransactionListFilters,
};

const fetchSMSTransactionsList = createAsyncThunk(
  'smsTransactionsList/fetch',
  async (_, thunkAPI) => {
    const {
      smsTransactionsState,
    }: { smsTransactionsState: ISMSTransactionsReduxState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await TicketManagerRequester.get(
      API_END_POINTS.TICKET_MANAGER.tickets,
      {
        params: {
          pageSize:
            smsTransactionsState?.smsTransactionsListPagination?.pageSize,
          pageToken:
            smsTransactionsState?.smsTransactionsListPageTokens[
              (smsTransactionsState?.smsTransactionsListPagination
                ?.pageNumber || 1) - 1
            ],
          ...smsTransactionsState?.smsTransactionListFilters,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.smsTransactionsList'),
      };

      // // Mock Handler
      // return SMS_TRANSACTIONS_MOCK;
    }
  },
);

export const SMSTransactionsSlice = createSlice({
  name: 'smsTransactions',
  initialState,
  reducers: {
    resetToInit: () => initialState,

    setSMSTransactionsListPageNumber: (
      state: ISMSTransactionsReduxState,
      { payload }: { payload: number },
    ) => {
      state.smsTransactionsListPagination.pageToken =
        state.smsTransactionsListPageTokens[payload - 1];
      state.smsTransactionsListPagination.pageNumber = payload;
    },
    setSMSTransactionsListPagination: (
      state,
      { payload }: { payload: IPagination },
    ) => {
      state.smsTransactionsListPagination = payload;
      state.smsTransactionsListPageTokens = [''];
    },
    setSMSTransactionsListFilter: (
      state,
      { payload }: { payload: ISMSTransactionListFilters },
    ) => {
      if (isEmpty(payload)) {
        state.smsTransactionListFilters = {};
      } else {
        state.smsTransactionListFilters = Object.assign(
          state.smsTransactionListFilters,
          payload,
        );
      }
      state.smsTransactionsList.hardReload = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchSMSTransactionsList.pending,
      (state: ISMSTransactionsReduxState) => {
        state.smsTransactionsList = { loading: true, error: null, data: null };
      },
    );
    builder.addCase(
      fetchSMSTransactionsList.fulfilled,
      (
        state: ISMSTransactionsReduxState,
        { payload }: { payload: ISMSTransactionsList | any },
      ) => {
        state.smsTransactionsList.loading = false;
        state.smsTransactionsList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.smsTransactionsList.data = payload;
          if (payload?.pageToken) {
            const currentPageIndex =
              state.smsTransactionsListPagination.pageNumber || 1;
            state.smsTransactionsListPageTokens[currentPageIndex] =
              payload?.pageToken || '';
          }
          state.hasNextPageToken = !!payload?.pageToken;
        } else {
          state.smsTransactionsList.error = payload?.error;
        }
      },
    );
  },
});

export const SMSTransactionsSliceActions = {
  ...SMSTransactionsSlice.actions,
  fetchSMSTransactionsList,
};

export const SMSTransactionsReducer = SMSTransactionsSlice.reducer;
