import {
  API_VERSIONS,
  TAPI_VERSION,
  getRequest,
  postRequest,
} from 'apiUtils/axiosRequest';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { createAxiosInstance, formatURL } from 'apiUtils/api';

import { API_END_POINTS } from 'apiUtils/urls';
import { IGenerateReportFiltersForm } from 'store/types/reports.types';

const REPORTS_BASE_URL = process.env.API_REPORTS?.replace('v1/', '');
const ReportsInstance = createAxiosInstance(REPORTS_BASE_URL);

export interface IReportsService {
  GenerateReport: (
    payload: IGenerateReportFiltersForm,
  ) => Promise<AxiosResponse>;
  DownloadReport: (
    pathParams: { reportId: string },
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  GetReportsList: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
}

export const ReportsService: { [key in TAPI_VERSION]: IReportsService } =
  Object.keys(API_VERSIONS)?.reduce((res: any, el: any) => {
    const version = API_VERSIONS[el as keyof typeof API_VERSIONS];

    return {
      ...res,
      [el]: {
        GenerateReport: async (payload: IGenerateReportFiltersForm) => {
          return await postRequest(
            ReportsInstance,
            `${version}${API_END_POINTS.REPORTS.generateReport}`,
            payload,
          );
        },
        DownloadReport: async (
          pathParams: { reportId: string },
          config?: AxiosRequestConfig,
        ) => {
          return await getRequest(
            ReportsInstance,
            `${version}${formatURL(API_END_POINTS.REPORTS.downloadReport, {
              reportId: pathParams?.reportId,
            })}`,
            { responseType: 'blob', ...config },
          );
        },
        GetReportsList: async (config: AxiosRequestConfig) => {
          return await getRequest(
            ReportsInstance,
            `${version}${API_END_POINTS.REPORTS.reports}`,
            config,
          );
        },
      } as IReportsService,
    };
  }, {});
