import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { FirmwareRequester } from 'apiUtils/api';
import { ManufacturerDetails } from 'store/types';

const initialState = {
  manufacturerDetails: [] as ManufacturerDetails[],
  processedData: {} as any,
  isFilterLoading: false,
};

const getManufacturerDetails = createAsyncThunk(
  'firmware/manufacturer',
  async () => {
    const response = await FirmwareRequester.get(
      API_END_POINTS.FIRMWARE.manufacturer,
    ).catch(({ response }) => response);
    if (response?.status >= 200 && response?.status < 300) {
      return response.data;
    } else {
      return {
        error: true,
      };
    }
  },
);

const processData = (data: any) => {
  const processed: any = { manufacturer: {}, model: {}, firmwareGroup: {} };
  data.forEach((manufacturer: any) => {
    processed.manufacturer[manufacturer.name] = {
      name: manufacturer.name,
      label: manufacturer.label,
    };
    manufacturer.model.forEach((model: any) => {
      if (!processed.model[model]) {
        processed.model[model] = {
          name: model,
          label: model,
          manufacturer: [],
        };
      }
      processed.model[model].manufacturer.push(manufacturer.name);
    });
    manufacturer.firmwareGroup.forEach(({ name, label, model }: any) => {
      if (!processed.firmwareGroup[name]) {
        processed.firmwareGroup[name] = {
          name: name,
          label: label,
          manufacturer: [],
          model: [],
        };
      }
      processed.firmwareGroup[name].model.push(model);
      processed.firmwareGroup[name].manufacturer.push(manufacturer.name);
    });
  });
  return processed;
};

export const firmwareSlice = createSlice({
  name: 'fimrware',
  initialState,
  reducers: {
    updateManufactureDetails: (state, { payload }) => {
      state.manufacturerDetails = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getManufacturerDetails.pending, (state) => {
      state.isFilterLoading = true;
    });
    builder.addCase(getManufacturerDetails.rejected, (state) => {
      state.isFilterLoading = false;
    });
    builder.addCase(
      getManufacturerDetails.fulfilled,
      (state, { payload }: { payload: ManufacturerDetails[] }) => {
        state.isFilterLoading = false;
        state.manufacturerDetails = payload;
        state.processedData = processData(payload);
      },
    );
  },
});

export const FirmwareReducer = firmwareSlice.reducer;
export const FirmwareActions = {
  ...firmwareSlice.actions,
  getManufacturerDetails,
};
