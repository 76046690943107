import {
  IUnsupportedHardwareList,
  IUnsupportedHardwareState,
} from 'store/types';
import { VizRequester, formatError } from 'apiUtils/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

const initialState: IUnsupportedHardwareState = {
  unsupportedHardwareList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as IUnsupportedHardwareList,
  },
  unsupportedHardwareListPagination: {},
};

export const LoadUnsupportedHardwareList = createAsyncThunk(
  'unsupportedHardware/fetch',
  async (_, thunkAPI) => {
    const {
      unsupportedHardwareListState,
    }: { unsupportedHardwareListState: IUnsupportedHardwareState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.unsupportedHardwareList,
      {
        params: {
          ...unsupportedHardwareListState?.unsupportedHardwareListPagination,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      // return UNSUPPORTED_HARDWARE_LIST_MOCK;
      return {
        error:
          formatError(response) ||
          'Error in fetching unsupported hardware list. Please try again',
      };
    }
  },
);

export const unsupportedHardwareSlice = createSlice({
  name: 'unsupportedHardware',
  initialState,
  reducers: {
    resetToInit: () => initialState,
    resetUnsupportedHardwareList: (state) => {
      state.unsupportedHardwareList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IUnsupportedHardwareList,
      };
    },
    setPageToken: (state, action) => {
      state.unsupportedHardwareListPagination.pageToken = action.payload;
    },
    setPageSize: (state, action) => {
      state.unsupportedHardwareListPagination.pageSize = action.payload;
    },
    setPagination: (state, action) => {
      state.unsupportedHardwareListPagination = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(LoadUnsupportedHardwareList.pending, (state) => {
      state.unsupportedHardwareList.loading = true;
      state.unsupportedHardwareList.error = '';
    });

    builder.addCase(
      LoadUnsupportedHardwareList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.unsupportedHardwareList.loading = false;
        state.unsupportedHardwareList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.unsupportedHardwareList.data = payload;
        } else {
          state.unsupportedHardwareList.error = payload?.error;
        }
      },
    );
  },
});

export const unsupportedHardwareListActions = {
  ...unsupportedHardwareSlice.actions,
  LoadUnsupportedHardwareList,
};
export const unsupportedHardwareReducer = unsupportedHardwareSlice.reducer;
