import { AuthState, IFiltersDropdownOptions, IPagination } from 'store/types';
import {
  DEVICES_TABS,
  DEVICE_BY_COUNT_METRICS,
  IAssignedDeviceData,
  ICurrentOnboardingActivities,
  IDeviceByProtocolCountStats,
  IDeviceCountByOUIData,
  IDeviceCountByOUIEquipmentIdData,
  IDeviceCountByOUIManufacturerData,
  IDeviceFilters,
  IDeviceOverviewStats,
  IDeviceState,
  IDeviceStats,
  IDeviceStatusByOltData,
  IDevicesByManufacturerStats,
  IEnrolledDeviceData,
  IManagementEntityList,
  IOLTHistoryData,
  IPendingDeviceData,
} from 'store/types/devices.types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { IOLTListItems } from 'store/types/olt.types';
import { OperatorService } from 'apiUtils/services/operator.service';
import { PERMISSIONS } from 'utils/roles&Permissions';
import { VizService } from 'apiUtils/services/vizbff.service';
import { formatError } from 'apiUtils/api';
import { hasPermission } from 'components/utilities/RoleBasedComponent';
import i18n from 'i18n';
import { isEmpty } from 'lodash';
import { removeEmptyValuesFromObject } from 'utils/utils';

interface IDropdownListQueryParams {
  managementEntityId?: string;
}
let pendingListCancelToken = axios.CancelToken.source();
let enrolledListCancelToken = axios.CancelToken.source();

const initialState: IDeviceState = {
  oltList: {
    loading: false,
    error: '',
    data: [] as IOLTListItems[],
  },
  overview: {
    loading: true,
    error: '',
    stats: {} as IDeviceOverviewStats,
  },
  deviceStats: { loading: true, error: '', stats: {} as IDeviceStats },
  deviceByProtocolCountStats: {
    loading: true,
    error: '',
    data: {} as IDeviceByProtocolCountStats,
  },
  currentOnboardingActivities: {
    loading: true,
    error: '',
    data: [] as ICurrentOnboardingActivities[],
  },
  deviceStatusByOltList: {
    loading: true,
    hardReload: false,
    error: '',
    data: {} as IDeviceStatusByOltData,
  },
  history: {
    loading: true,
    hardReload: false,
    error: '',
    data: {} as IOLTHistoryData,
  },

  devicesByManufacturerStats: {
    loading: true,
    error: '',
    data: {} as IDevicesByManufacturerStats,
  },
  devicesCountByOUIManufacturer: {
    loading: true,
    hardReload: false,
    error: '',
    data: {} as IDeviceCountByOUIManufacturerData,
  },
  devicesCountByOUIEquipmentId: {
    loading: true,
    hardReload: false,
    error: '',
    data: {} as IDeviceCountByOUIEquipmentIdData,
  },
  devicesCountByOUI: {
    loading: true,
    hardReload: false,
    error: '',
    data: {} as IDeviceCountByOUIData,
  },
  enrolled: {
    loading: true,
    hardReload: false,
    error: '',
    data: {} as IEnrolledDeviceData,
  },
  pending: {
    loading: true,
    hardReload: false,
    error: '',
    data: {} as IPendingDeviceData,
  },
  assigned: {
    loading: true,
    hardReload: false,
    error: '',
    data: {} as IAssignedDeviceData,
  },

  historyListPagination: { pageToken: 1, pageSize: 10 },
  assignedListPagination: {},
  enrolledListPagination: {},
  pendingListPagination: {},
  deviceFilters: {} as IDeviceFilters,
  devicesFilterDropDownList: {
    operatorsList: {
      loading: false,
      error: null,
      data: [] as IManagementEntityList[],
    },
    manufacturerList: {
      loading: false,
      error: null,
      data: [] as string[],
    },
    hardwareModelList: {
      loading: false,
      error: null,
      data: [] as IFiltersDropdownOptions[],
    },
    hardwareVersionList: {
      loading: false,
      error: null,
      data: [] as IFiltersDropdownOptions[],
    },
    softwareVersionList: {
      loading: false,
      error: null,
      data: [] as IFiltersDropdownOptions[],
    },
    vlanList: {
      loading: false,
      error: null,
      data: [] as string[],
    },
  },
  deviceListSearch: {},
  currentTabId: '',
  currentOnboardingCategoryTabId: null,
  isFilterOpen: false,
};

//  #region - Dropdown List
const loadOLTList = createAsyncThunk(
  'oltList/fetch',
  async (payload: { id: string }, thunkAPI) => {
    const response: AxiosResponse = await VizService.v1.GetMgmtEntityOLTList({
      managementEntityId: payload?.id,
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.oltList'),
      };
    }
  },
);

const loadOperatorsList = createAsyncThunk(
  'operatorsList/fetch',
  async (_, thunkAPI) => {
    const response: AxiosResponse =
      await OperatorService.v1.GetOLTOperatorsList();
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.operatorList'),
      };
    }
  },
);

const loadManufacturersList = createAsyncThunk(
  'manufacturersList/fetch',
  async (payload: IDropdownListQueryParams | void, thunkAPI) => {
    const { auth }: { auth: AuthState } = thunkAPI.getState() as any;

    const response = await VizService.v1.GetFilters({
      params: {
        data: 'manufacturer',
        managementEntityId:
          payload?.managementEntityId || auth?.userInfo?.managementEntity?.id,
      },
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.manufacturerList'),
      };
    }
  },
);

const loadHardwareModelsList = createAsyncThunk(
  'hwModelsList/fetch',
  async (payload: IDropdownListQueryParams | void, thunkAPI) => {
    const { auth }: { auth: AuthState } = thunkAPI.getState() as any;
    const response = await VizService.v1.GetFilters({
      params: {
        data: 'hardwareModel',
        managementEntityId:
          payload?.managementEntityId || auth?.userInfo?.managementEntity?.id,
      },
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.hardwareModelList'),
      };
    }
  },
);

const loadHardwareVersionsList = createAsyncThunk(
  'hwVersionsList/fetch',
  async (payload: IDropdownListQueryParams | void, thunkAPI) => {
    const {
      auth,
      deviceState,
    }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    const params = {
      data: 'hardwareVersion',
      managementEntityId:
        payload?.managementEntityId || auth?.userInfo?.managementEntity?.id,
    };
    let response: AxiosResponse | any = null;

    if (deviceState?.currentTabId === DEVICES_TABS.ENROLLED) {
      response = await VizService.v1.GetFilters({ params });
    } else if (deviceState?.currentTabId === DEVICES_TABS.PENDING) {
      response = await VizService.v1.GetDevicePendingFilters({ params });
    }

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.hardwareVersionList'),
      };
    }
  },
);

const loadSoftwareVersionsList = createAsyncThunk(
  'swVersionsList/fetch',
  async (payload: IDropdownListQueryParams | void, thunkAPI) => {
    const { auth }: { auth: AuthState } = thunkAPI.getState() as any;
    const response = await VizService.v1.GetDevicePendingFilters({
      params: {
        data: 'softwareVersion',
        managementEntityId:
          payload?.managementEntityId || auth?.userInfo?.managementEntity?.id,
      },
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.softwareVersionList'),
      };
    }
  },
);

const loadVLANsList = createAsyncThunk(
  'vlanList/fetch',
  async (payload: IDropdownListQueryParams | void, thunkAPI) => {
    // const { auth }: { auth: AuthState } = thunkAPI.getState() as any;
    const {
      auth,
      deviceState,
    }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;

    const params = {
      data: 'vlanIds',
      managementEntityId:
        payload?.managementEntityId || auth?.userInfo?.managementEntity?.id,
    };
    let response: AxiosResponse | any = null;

    if (deviceState?.currentTabId === DEVICES_TABS.ENROLLED) {
      response = await VizService.v1.GetFilters({ params });
    } else if (deviceState?.currentTabId === DEVICES_TABS.PENDING) {
      response = await VizService.v1.GetDevicePendingFilters({ params });
    }

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.vlanList'),
      };
    }
  },
);
//  #endregion - Dropdown List

//  #region - Overview Stats Data
const loadOLTStats = createAsyncThunk(
  'oltOnboardingStats/fetch',
  async (_, thunkAPI) => {
    const {
      auth,
      deviceState,
    }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizService.v1.GetOLTOnboardingStats(
      {
        managementEntityId:
          deviceState?.deviceFilters?.managementEntityId ||
          auth?.userInfo?.managementEntity?.id,
      },
      {
        params: {
          oltMac: deviceState?.deviceFilters?.oltMac,
        },
      },
    );
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.oltStats'),
      };
    }
  },
);

const loadDeviceStats = createAsyncThunk(
  'deviceStats/fetch',
  async (_, thunkAPI) => {
    const {
      auth,
      deviceState,
    }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizService.v1.GetDeviceStats({
      params: {
        managementEntityId:
          deviceState?.deviceFilters?.managementEntityId ||
          auth?.userInfo?.managementEntity?.id,
        oltMac: deviceState?.deviceFilters?.oltMac,
      },
    });

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.oltStats'),
      };
    }
  },
);

const loadDeviceByProtocolCountStats = createAsyncThunk(
  'deviceByProtocolCountStats/fetch',
  async (payload: { metrics: string }, thunkAPI) => {
    const { auth }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse =
      await VizService.v1.GetDeviceByProtocolCountStats({
        params: {
          metrics: payload?.metrics,
          managementEntityId: auth?.userInfo?.managementEntity?.id,
        },
      });

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.oltStats'),
      };
    }
  },
);

const loadOLTCurrentOnboardingActivities = createAsyncThunk(
  'onboardingActivities/fetch',
  async (_, thunkAPI) => {
    const {
      auth,
      deviceState,
    }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizService.v1.GetOLTPendingActivities(
      {
        managementEntityId:
          deviceState?.deviceFilters?.managementEntityId ||
          auth?.userInfo?.managementEntity?.id,
      },
      {
        params: {
          oltMac: deviceState?.deviceFilters?.oltMac,
        },
      },
    );
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) ||
          i18n.t('messages.error.onboardingActivities'),
      };
    }
  },
);
//  #endregion - Overview Stats Data

//  #region - Onboarding Tab
const loadDeviceStatusByOLT = createAsyncThunk(
  'deviceStatusByOLT/fetch',
  async (_, thunkAPI) => {
    const { auth }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;

    const response: AxiosResponse = await VizService.v1.GetDeviceStatusByOLT({
      managementEntityId: auth?.userInfo?.managementEntity?.id,
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.somethingWentWrong'),
      };
    }
  },
);

const loadHistoryList = createAsyncThunk(
  'historyOltList/fetch',
  async (_, thunkAPI) => {
    const {
      auth,
      deviceState,
    }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizService.v1.GetOLTHistory(
      {
        managementEntityId: auth?.userInfo?.managementEntity?.id,
      },
      {
        params: {
          ...deviceState?.historyListPagination,
        },
      },
    );
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || i18n.t('messages.error.historyList'),
      };
    }
  },
);
//  #endregion - Onboarding Tab

//  #region - Onboarding Insights
const loadDevicesByManufacturerStats = createAsyncThunk(
  'devicesByManufacturerStats/fetch',
  async (_, thunkAPI) => {
    const { deviceState }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;

    const response: AxiosResponse =
      await VizService.v1.GetDevicesCountByManufacturerStats(
        {
          deviceOnboardingCategory: deviceState?.currentTabId,
        },
        { params: { ...deviceState?.deviceFilters } },
      );
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.somethingWentWrong'),
      };
    }
  },
);

const loadDevicesCountByOUIManufacturer = createAsyncThunk(
  'devicesCountByOUIManufacturer/fetch',
  async (_, thunkAPI) => {
    const { deviceState }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizService.v1.GetDevicesCountByOUI(
      {
        deviceOnboardingCategory: deviceState?.currentTabId,
      },
      {
        params: {
          metric: DEVICE_BY_COUNT_METRICS.BY_MANUFACTURER_OUI,
          ...deviceState?.deviceFilters,
        },
      },
    );

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.somethingWentWrong'),
      };
    }
  },
);

const loadDevicesCountByOUIEquipmentId = createAsyncThunk(
  'devicesCountByOUIEquipmentId/fetch',
  async (_, thunkAPI) => {
    const { deviceState }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizService.v1.GetDevicesCountByOUI(
      {
        deviceOnboardingCategory: deviceState?.currentTabId,
      },
      {
        params: {
          metric: DEVICE_BY_COUNT_METRICS.BY_HARDWARE,
          ...deviceState?.deviceFilters,
        },
      },
    );

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.somethingWentWrong'),
      };
    }
  },
);

const loadDevicesCountByOUI = createAsyncThunk(
  'devicesCountByOUI/fetch',
  async (_, thunkAPI) => {
    const { deviceState }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizService.v1.GetDevicesCountByOUI(
      {
        deviceOnboardingCategory: deviceState?.currentTabId,
      },
      {
        params: {
          metric: DEVICE_BY_COUNT_METRICS.BY_UNKNOWN_HARDWWARE,
          ...deviceState?.deviceFilters,
        },
      },
    );
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.somethingWentWrong'),
      };
    }
  },
);
//  #endregion - Onboarding Insights

//  #region - Enrolled Tab
const loadEnrolledList = createAsyncThunk(
  'enrolledList/fetch',
  async (_, thunkAPI) => {
    const {
      auth,
      deviceState,
    }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    enrolledListCancelToken = axios.CancelToken.source();

    const response: AxiosResponse = await VizService.v1.GetEnrolledList(
      {
        managementEntityId:
          deviceState?.deviceFilters?.managementEntityId ||
          auth?.userInfo?.managementEntity?.id,
      },
      {
        cancelToken: enrolledListCancelToken.token,
        params: {
          ...removeEmptyValuesFromObject({
            ...deviceState?.enrolledListPagination,
            ...deviceState?.deviceFilters,
            ...deviceState?.deviceListSearch,
          }),
        },
      },
    );

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.enrolledDevicesList'),
      };
    }
  },
);
//  #endregion - Enrolled Tab

//  #region - Pending Tab
const loadPendingList = createAsyncThunk(
  'pendingList/fetch',
  async (_, thunkAPI) => {
    const {
      auth,
      deviceState,
    }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    pendingListCancelToken = axios.CancelToken.source();
    const response: AxiosResponse = await VizService.v1.GetPendingList(
      {
        managementEntityId:
          deviceState?.deviceFilters?.managementEntityId ||
          auth?.userInfo?.managementEntity?.id,
      },
      {
        cancelToken: pendingListCancelToken.token,
        params: {
          ...removeEmptyValuesFromObject({
            ...deviceState?.pendingListPagination,
            ...deviceState?.deviceFilters,
            ...deviceState?.deviceListSearch,
          }),
        },
      },
    );
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.pendingDevicesList'),
      };
    }
  },
);
//  #endregion - Pending Tab

//  #region - Assigned Tab
const loadAssignedList = createAsyncThunk(
  'assignedList/fetch',
  async (_, thunkAPI) => {
    const {
      auth,
      deviceState,
    }: { auth: AuthState; deviceState: IDeviceState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizService.v1.GetAssignedList(
      {
        managementEntityId: auth?.userInfo?.managementEntity?.id,
      },
      {
        params: {
          ...deviceState?.assignedListPagination,
        },
      },
    );
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) || i18n.t('messages.error.assignedDevicesList'),
      };
    }
  },
);
//  #endregion- Assigned Tab

export const deviceSlice = createSlice({
  name: 'olt-devices',
  initialState,
  reducers: {
    resetToInit: () => initialState,
    //  #region - Overview Stats Data
    resetOverviewState: (state: IDeviceState) => {
      state.overview = {
        loading: true,
        error: '',
        stats: {} as IDeviceOverviewStats,
      };
      state.deviceStats = {
        loading: true,
        error: '',
        stats: {} as IDeviceStats,
      };
      state.currentOnboardingActivities = {
        loading: true,
        error: '',
        data: [] as ICurrentOnboardingActivities[],
      };
    },
    resetCurrentOnboardingActivityState: (state: IDeviceState) => {
      state.currentOnboardingActivities = {
        loading: true,
        error: '',
        data: [] as ICurrentOnboardingActivities[],
      };
    },
    resetDeviceByProtocolCount: (state: IDeviceState) => {
      state.deviceByProtocolCountStats = {
        loading: true,
        error: '',
        data: {} as IDeviceByProtocolCountStats,
      };
    },
    //  #endregion - Overview Stats Data

    // #region - Onboarding Tab
    resetDeviceStatusByOltList: (state) => {
      state.deviceStatusByOltList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IDeviceStatusByOltData,
      };
    },
    resetOnboardingState: (state: IDeviceState) => {
      state.deviceStatusByOltList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IDeviceStatusByOltData,
      };
      state.history = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IOLTHistoryData,
      };
      state.historyListPagination = { pageToken: 1, pageSize: 10 };
    },
    resetHistoryListState: (state: IDeviceState) => {
      state.history = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IOLTHistoryData,
      };
    },
    //  #endregion - Onboarding Tab

    //  #region - Onboarding Insights tab

    resetDeviceOnboardingInsightsTabState: (state: IDeviceState) => {
      state.devicesByManufacturerStats = {
        loading: true,
        error: '',
        data: {} as IDevicesByManufacturerStats,
      };
      state.devicesCountByOUIManufacturer = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IDeviceCountByOUIManufacturerData,
      };
      state.devicesCountByOUIEquipmentId = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IDeviceCountByOUIEquipmentIdData,
      };
      state.devicesCountByOUI = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IDeviceCountByOUIData,
      };
    },

    resetDevicesManufacturerStatsState: (state: IDeviceState) => {
      state.devicesByManufacturerStats = {
        loading: true,
        error: '',
        data: {} as IDevicesByManufacturerStats,
      };
    },
    resetDevicesCountByOUIManufacturerState: (state: IDeviceState) => {
      state.devicesCountByOUIManufacturer = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IDeviceCountByOUIManufacturerData,
      };
    },
    resetDevicesCountByOUIEquipmentIdState: (state: IDeviceState) => {
      state.devicesCountByOUIEquipmentId = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IDeviceCountByOUIEquipmentIdData,
      };
    },
    resetDevicesCountByOUIState: (state: IDeviceState) => {
      state.devicesCountByOUI = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IDeviceCountByOUIData,
      };
    },
    //  #endregion - Onboarding Insights tab

    resetEnrolledListState: (state: IDeviceState) => {
      state.enrolled = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IEnrolledDeviceData,
      };
      state.enrolledListPagination = {};
      state.deviceFilters = {};
      state.deviceByProtocolCountStats = {
        loading: true,
        error: '',
        data: {} as IDeviceByProtocolCountStats,
      };
    },
    resetPendingListState: (state: IDeviceState) => {
      state.pending = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IPendingDeviceData,
      };
      state.pendingListPagination = {};
      state.deviceFilters = {};
      state.deviceByProtocolCountStats = {
        loading: true,
        error: '',
        data: {} as IDeviceByProtocolCountStats,
      };
    },
    resetAssignedListState: (state: IDeviceState) => {
      state.assigned = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IAssignedDeviceData,
      };
      state.assignedListPagination = {};
    },

    setCurrentTabId: (state: IDeviceState, action) => {
      state.currentTabId = action.payload;
      state.deviceListSearch = {};
      state.deviceFilters = {
        managementEntityId: hasPermission(
          PERMISSIONS.DEVICE_FILTERS.MANAGEMENT_ENTITY,
        )
          ? state?.deviceFilters?.managementEntityId
          : undefined,
        oltMac: state?.deviceFilters?.oltMac,
      };

      if (state.currentTabId === DEVICES_TABS.OVERVIEW) {
        state.overview = {
          loading: true,
          error: '',
          stats: {} as IDeviceOverviewStats,
        };
        state.deviceStats = {
          loading: true,
          error: '',
          stats: {} as IDeviceStats,
        };
        state.currentOnboardingActivities = {
          loading: true,
          error: '',
          data: [] as ICurrentOnboardingActivities[],
        };
      }
      if (state.currentTabId === DEVICES_TABS.ASSIGNED) {
        state.assigned = {
          loading: true,
          hardReload: true,
          error: '',
          data: {} as IAssignedDeviceData,
        };
        state.assignedListPagination = { pageToken: 1, pageSize: 10 };
      } else if (state.currentTabId === DEVICES_TABS.ENROLLED) {
        state.enrolled = {
          loading: true,
          hardReload: true,
          error: '',
          data: {} as IEnrolledDeviceData,
        };
        state.enrolledListPagination = { pageToken: 1, pageSize: 10 };
        state.deviceByProtocolCountStats = {
          loading: true,
          error: '',
          data: {} as IDeviceByProtocolCountStats,
        };
      } else if (state.currentTabId === DEVICES_TABS.PENDING) {
        state.pending = {
          loading: true,
          hardReload: true,
          error: '',
          data: {} as IPendingDeviceData,
        };
        state.pendingListPagination = { pageToken: 1, pageSize: 10 };
        state.deviceByProtocolCountStats = {
          loading: true,
          error: '',
          data: {} as IDeviceByProtocolCountStats,
        };
      } else if (state.currentTabId === DEVICES_TABS.ONBOARDING) {
        state.isFilterOpen = false;
        state.deviceStatusByOltList = {
          loading: true,
          error: '',
          data: {} as IDeviceStatusByOltData,
          hardReload: true,
        };
        state.history = {
          loading: true,
          error: '',
          data: {} as IOLTHistoryData,
          hardReload: true,
        };
      }
    },

    setCurrentOnboardingCategoryTabId: (
      state: IDeviceState,
      { payload }: { payload: string | undefined | null },
    ) => {
      state.currentOnboardingCategoryTabId = payload;
      state.deviceFilters = {
        managementEntityId: hasPermission(
          PERMISSIONS.DEVICE_FILTERS.MANAGEMENT_ENTITY,
        )
          ? state?.deviceFilters?.managementEntityId
          : undefined,
      };
    },

    setIsFilterOpen: (state: IDeviceState, action) => {
      state.isFilterOpen = action.payload;
    },
    setDeviceFilters: (state, action) => {
      if (isEmpty(action?.payload)) {
        state.deviceFilters = {};
      } else {
        state.deviceFilters = Object.assign(
          state.deviceFilters,
          action.payload,
        );
      }
      if (state.currentTabId === DEVICES_TABS.OVERVIEW) {
        state.overview = {
          error: '',
          stats: {} as IDeviceOverviewStats,
          loading: true,
        };
        state.deviceStats = {
          error: '',
          stats: {} as IDeviceStats,
          loading: true,
        };
        state.currentOnboardingActivities = {
          loading: true,
          error: '',
          data: [],
        };
      } else if (state.currentTabId === DEVICES_TABS.ENROLLED) {
        state.enrolled.hardReload = true;
        state.enrolledListPagination.pageToken = 1;
      } else if (state.currentTabId === DEVICES_TABS.PENDING) {
        state.pending.hardReload = true;
        state.pendingListPagination.pageToken = 1;
      }
    },

    resetOperatorBasedDropdownList: (state: IDeviceState) => {
      state.oltList.data = [] as IOLTListItems[];
      state.devicesFilterDropDownList.manufacturerList.data = [] as string[];
      state.devicesFilterDropDownList.hardwareModelList.data =
        [] as IFiltersDropdownOptions[];
      state.devicesFilterDropDownList.hardwareVersionList.data =
        [] as IFiltersDropdownOptions[];
      state.devicesFilterDropDownList.softwareVersionList.data =
        [] as IFiltersDropdownOptions[];
      state.devicesFilterDropDownList.vlanList.data = [] as string[];
    },

    setSearchText: (
      state,
      { payload }: { payload: string | null | undefined },
    ) => {
      state.deviceListSearch = { searchText: payload };
      if (state.currentTabId === DEVICES_TABS.ENROLLED) {
        state.enrolled = {
          error: '',
          data: {} as IEnrolledDeviceData,
          hardReload: true,
          loading: true,
        };
        state.enrolledListPagination.pageToken = 1;
      } else if (state.currentTabId === DEVICES_TABS.PENDING) {
        state.pending = {
          error: '',
          data: {} as IPendingDeviceData,
          hardReload: true,
          loading: true,
        };
        state.pendingListPagination.pageToken = 1;
      }
    },

    setHistoryListPageToken: (
      state: IDeviceState,
      { payload }: { payload: number },
    ) => {
      state.historyListPagination.pageToken = payload;
    },
    setHistoryListPagination: (
      state: IDeviceState,
      action: PayloadAction<IPagination>,
    ) => {
      state.historyListPagination = action.payload;
    },

    setEnrolledListPageToken: (
      state: IDeviceState,
      { payload }: { payload: number },
    ) => {
      state.enrolledListPagination.pageToken = payload;
    },
    setEnrolledListPagination: (
      state: IDeviceState,
      action: PayloadAction<IPagination>,
    ) => {
      state.enrolledListPagination = action.payload;
    },

    setPendingListPageToken: (
      state: IDeviceState,
      { payload }: { payload: number },
    ) => {
      state.pendingListPagination.pageToken = payload;
    },
    setPendingListPagination: (
      state: IDeviceState,
      action: PayloadAction<IPagination>,
    ) => {
      state.pendingListPagination = action.payload;
    },

    setAssignedListPageToken: (
      state: IDeviceState,
      { payload }: { payload: number },
    ) => {
      state.assignedListPagination.pageToken = payload;
    },
    setAssignedListPagination: (
      state: IDeviceState,
      action: PayloadAction<IPagination>,
    ) => {
      state.assignedListPagination = action.payload;
    },

    updateOntOnboardingStatusByTransactionId: (
      state: IDeviceState,
      action: PayloadAction<ICurrentOnboardingActivities>,
    ) => {
      let existingTransactionData =
        state?.currentOnboardingActivities?.data?.find(
          (data: ICurrentOnboardingActivities) =>
            data.transactionId === action.payload.transactionId,
        );
      if (!isEmpty(existingTransactionData)) {
        existingTransactionData.success = action?.payload?.success;
        existingTransactionData.failed = action?.payload?.failed;
        existingTransactionData.inProgress = action?.payload?.inProgress;
        existingTransactionData.status = action?.payload?.status;
      } else {
        state.currentOnboardingActivities.data = [
          ...state?.currentOnboardingActivities?.data,
          action.payload,
        ];
      }
    },
  },

  extraReducers: (builder) => {
    //  #region - Dropdown List
    builder.addCase(loadOLTList.pending, (state: IDeviceState) => {
      state.oltList.loading = true;
      state.oltList.error = '';
    });
    builder.addCase(
      loadOLTList.fulfilled,
      (state: IDeviceState, { payload }: { payload: AxiosResponse | any }) => {
        state.oltList.loading = false;
        if (isEmpty(payload?.error)) {
          state.oltList.data = payload;
        } else {
          state.oltList.error = payload?.error;

          //Handle Mock
          // state.oltList.data = OLT_LIST_MOCK;
        }
      },
    );

    builder.addCase(loadOperatorsList.pending, (state) => {
      state.devicesFilterDropDownList.operatorsList.loading = true;
      state.devicesFilterDropDownList.operatorsList.error = '';
      state.devicesFilterDropDownList.operatorsList.data =
        [] as IManagementEntityList[];
    });
    builder.addCase(
      loadOperatorsList.fulfilled,
      (state, { payload }: { payload: IManagementEntityList[] | any }) => {
        state.devicesFilterDropDownList.operatorsList.loading = false;
        if (isEmpty(payload?.error)) {
          state.devicesFilterDropDownList.operatorsList.data = payload?.items;
        } else {
          state.devicesFilterDropDownList.operatorsList.error = payload?.error;
        }
      },
    );

    builder.addCase(loadManufacturersList.pending, (state) => {
      state.devicesFilterDropDownList.manufacturerList.loading = true;
      state.devicesFilterDropDownList.manufacturerList.error = '';
      state.devicesFilterDropDownList.manufacturerList.data = [] as string[];
    });
    builder.addCase(
      loadManufacturersList.fulfilled,
      (state, { payload }: { payload: string[] | any }) => {
        state.devicesFilterDropDownList.manufacturerList.loading = false;
        if (isEmpty(payload?.error)) {
          state.devicesFilterDropDownList.manufacturerList.data = payload;
        } else {
          state.devicesFilterDropDownList.manufacturerList.error =
            payload?.error;
        }
      },
    );

    builder.addCase(loadHardwareModelsList.pending, (state) => {
      state.devicesFilterDropDownList.hardwareModelList.loading = true;
      state.devicesFilterDropDownList.hardwareModelList.error = '';
      state.devicesFilterDropDownList.hardwareModelList.data =
        [] as IFiltersDropdownOptions[];
    });
    builder.addCase(
      loadHardwareModelsList.fulfilled,
      (state, { payload }: { payload: IFiltersDropdownOptions[] | any }) => {
        state.devicesFilterDropDownList.hardwareModelList.loading = false;
        if (isEmpty(payload?.error)) {
          state.devicesFilterDropDownList.hardwareModelList.data =
            payload?.options;
        } else {
          state.devicesFilterDropDownList.hardwareModelList.error =
            payload?.error;
        }
      },
    );

    builder.addCase(loadHardwareVersionsList.pending, (state) => {
      state.devicesFilterDropDownList.hardwareVersionList.loading = true;
      state.devicesFilterDropDownList.hardwareVersionList.error = '';
      state.devicesFilterDropDownList.hardwareVersionList.data =
        [] as IFiltersDropdownOptions[];
    });
    builder.addCase(
      loadHardwareVersionsList.fulfilled,
      (state, { payload }: { payload: IFiltersDropdownOptions[] | any }) => {
        state.devicesFilterDropDownList.hardwareVersionList.loading = false;
        if (isEmpty(payload?.error)) {
          state.devicesFilterDropDownList.hardwareVersionList.data =
            payload?.options;
        } else {
          state.devicesFilterDropDownList.hardwareVersionList.error =
            payload?.error;
        }
      },
    );

    builder.addCase(loadSoftwareVersionsList.pending, (state) => {
      state.devicesFilterDropDownList.softwareVersionList.loading = true;
      state.devicesFilterDropDownList.softwareVersionList.error = '';
      state.devicesFilterDropDownList.softwareVersionList.data =
        [] as IFiltersDropdownOptions[];
    });
    builder.addCase(
      loadSoftwareVersionsList.fulfilled,
      (state, { payload }: { payload: IFiltersDropdownOptions[] | any }) => {
        state.devicesFilterDropDownList.softwareVersionList.loading = false;
        if (isEmpty(payload?.error)) {
          state.devicesFilterDropDownList.softwareVersionList.data =
            payload?.options;
        } else {
          state.devicesFilterDropDownList.softwareVersionList.error =
            payload?.error;
        }
      },
    );

    builder.addCase(loadVLANsList.pending, (state) => {
      state.devicesFilterDropDownList.vlanList.loading = true;
      state.devicesFilterDropDownList.vlanList.error = '';
      state.devicesFilterDropDownList.vlanList.data = [] as string[];
    });
    builder.addCase(
      loadVLANsList.fulfilled,
      (state, { payload }: { payload: string[] | any }) => {
        state.devicesFilterDropDownList.vlanList.loading = false;
        if (isEmpty(payload?.error)) {
          state.devicesFilterDropDownList.vlanList.data = payload?.vlanIds;
        } else {
          state.devicesFilterDropDownList.vlanList.error = payload?.error;
        }
      },
    );
    //  #endregion - Dropdown List

    //  #region - Stats / Overview
    builder.addCase(loadOLTStats.pending, (state: IDeviceState) => {
      state.overview.loading = true;
      state.overview.error = '';
    });
    builder.addCase(
      loadOLTStats.fulfilled,
      (state: IDeviceState, { payload }: { payload: AxiosResponse | any }) => {
        state.overview.loading = false;
        if (isEmpty(payload?.error)) {
          state.overview.stats = payload;
        } else {
          state.overview.error = payload?.error;

          //Handle Mock
          // state.overview.stats = OLT_OVERVIEW_STATS_MOCK;
        }
      },
    );

    builder.addCase(loadDeviceStats.pending, (state: IDeviceState) => {
      state.deviceStats.loading = true;
      state.deviceStats.error = '';
    });
    builder.addCase(
      loadDeviceStats.fulfilled,
      (state: IDeviceState, { payload }: { payload: AxiosResponse | any }) => {
        state.deviceStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.deviceStats.stats = payload;
        } else {
          state.deviceStats.error = payload?.error;

          // state.deviceStats.stats = DEVICE_STATS_MOCK;
        }
      },
    );

    builder.addCase(
      loadOLTCurrentOnboardingActivities.pending,
      (state: IDeviceState) => {
        state.currentOnboardingActivities.loading = true;
        state.currentOnboardingActivities.error = '';
      },
    );
    builder.addCase(
      loadOLTCurrentOnboardingActivities.fulfilled,
      (state: IDeviceState, { payload }: { payload: AxiosResponse | any }) => {
        state.currentOnboardingActivities.loading = false;
        if (isEmpty(payload?.error)) {
          state.currentOnboardingActivities.data = payload;
        } else {
          state.currentOnboardingActivities.error = payload?.error;

          //Handle Mock
          // state.currentOnboardingActivities.data =
          //   OLT_CURRENT_ONBOARDING_ACTIVITIES_MOCK;
        }
      },
    );

    builder.addCase(
      loadDeviceByProtocolCountStats.pending,
      (state: IDeviceState) => {
        state.deviceByProtocolCountStats.loading = true;
        state.deviceByProtocolCountStats.error = '';
      },
    );
    builder.addCase(
      loadDeviceByProtocolCountStats.fulfilled,
      (state: IDeviceState, { payload }: { payload: AxiosResponse | any }) => {
        state.deviceByProtocolCountStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.deviceByProtocolCountStats.data = payload;
        } else {
          state.deviceByProtocolCountStats.error = payload?.error;
        }
      },
    );
    //  #endregion - Stats / Overview

    //  #region - Onboarding tab
    builder.addCase(loadDeviceStatusByOLT.pending, (state: IDeviceState) => {
      state.deviceStatusByOltList.loading = true;
      state.deviceStatusByOltList.error = '';
    });
    builder.addCase(
      loadDeviceStatusByOLT.fulfilled,
      (state: IDeviceState, { payload }: { payload: AxiosResponse | any }) => {
        state.deviceStatusByOltList.loading = false;
        state.deviceStatusByOltList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.deviceStatusByOltList.data = payload;
          state.deviceStatusByOltList.data.total = payload?.items?.length;
        } else {
          state.deviceStatusByOltList.error = payload?.error;
        }
      },
    );

    builder.addCase(loadHistoryList.pending, (state: IDeviceState) => {
      state.history.loading = true;
      state.history.error = '';
    });
    builder.addCase(
      loadHistoryList.fulfilled,
      (state: IDeviceState, { payload }: { payload: AxiosResponse | any }) => {
        state.history.loading = false;
        state.history.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.history.data = payload;
        } else {
          state.history.error = payload?.error;
        }
      },
    );
    // #endregion - Onboarding tab

    //  #region - Onboarding Insights Tab

    builder.addCase(
      loadDevicesByManufacturerStats.pending,
      (state: IDeviceState) => {
        state.devicesByManufacturerStats.loading = true;
        state.devicesByManufacturerStats.error = '';
        state.devicesByManufacturerStats.data =
          {} as IDevicesByManufacturerStats;
      },
    );
    builder.addCase(
      loadDevicesByManufacturerStats.fulfilled,
      (
        state: IDeviceState,
        { payload }: { payload: IDeviceCountByOUIManufacturerData | any },
      ) => {
        state.devicesByManufacturerStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.devicesByManufacturerStats.data = payload;
        } else {
          state.devicesByManufacturerStats.error = payload?.error;

          // // Mock Handler
          // state.devicesByManufacturerStats.data =
          //   DEVICE_BY_MANUFACTURER_STATS_MOCK;
        }
      },
    );

    builder.addCase(
      loadDevicesCountByOUIManufacturer.pending,
      (state: IDeviceState) => {
        state.devicesCountByOUIManufacturer.loading = true;
        state.devicesCountByOUIManufacturer.error = '';
        state.devicesCountByOUIManufacturer.data =
          {} as IDeviceCountByOUIManufacturerData;
      },
    );
    builder.addCase(
      loadDevicesCountByOUIManufacturer.fulfilled,
      (
        state: IDeviceState,
        { payload }: { payload: IDeviceCountByOUIManufacturerData | any },
      ) => {
        state.devicesCountByOUIManufacturer.loading = false;
        state.devicesCountByOUIManufacturer.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.devicesCountByOUIManufacturer.data = payload;
        } else {
          state.devicesCountByOUIManufacturer.error = payload?.error;

          // // Mock Handler
          // state.devicesCountByOUIManufacturer.data =
          //   DEVICES_COUNT_BY_OUI_MANUFACTURER_MOCK;
        }
      },
    );

    builder.addCase(
      loadDevicesCountByOUIEquipmentId.pending,
      (state: IDeviceState) => {
        state.devicesCountByOUIEquipmentId.loading = true;
        state.devicesCountByOUIEquipmentId.error = '';
        state.devicesCountByOUIEquipmentId.data =
          {} as IDeviceCountByOUIEquipmentIdData;
      },
    );
    builder.addCase(
      loadDevicesCountByOUIEquipmentId.fulfilled,
      (
        state: IDeviceState,
        { payload }: { payload: IDeviceCountByOUIEquipmentIdData | any },
      ) => {
        state.devicesCountByOUIEquipmentId.loading = false;
        state.devicesCountByOUIEquipmentId.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.devicesCountByOUIEquipmentId.data = payload;
        } else {
          state.devicesCountByOUIEquipmentId.error = payload?.error;

          // // Mock Handler
          // state.devicesCountByOUIEquipmentId.data =
          //   DEVICES_COUNT_BY_OUI_EQUIPMENTID_MOCK;
        }
      },
    );

    builder.addCase(loadDevicesCountByOUI.pending, (state: IDeviceState) => {
      state.devicesCountByOUI.loading = true;
      state.devicesCountByOUI.error = '';
      state.devicesCountByOUI.data = {} as IDeviceCountByOUIData;
    });
    builder.addCase(
      loadDevicesCountByOUI.fulfilled,
      (
        state: IDeviceState,
        { payload }: { payload: IDeviceCountByOUIData | any },
      ) => {
        state.devicesCountByOUI.loading = false;
        state.devicesCountByOUI.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.devicesCountByOUI.data = payload;
        } else {
          state.devicesCountByOUI.error = payload?.error;

          // // Mock Handler
          // state.devicesCountByOUI.data = DEVICES_COUNT_BY_OUI_MOCK;
        }
      },
    );
    // #endregion - Onboarding Insights Tab

    builder.addCase(loadEnrolledList.pending, (state: IDeviceState) => {
      state.enrolled.loading = true;
      state.enrolled.error = '';
      state.enrolled.data = {} as IEnrolledDeviceData;
      enrolledListCancelToken.cancel();
    });
    builder.addCase(
      loadEnrolledList.fulfilled,
      (state: IDeviceState, { payload }: { payload: AxiosResponse | any }) => {
        state.enrolled.loading = false;
        state.enrolled.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.enrolled.data = payload;
        } else {
          state.enrolled.error = payload?.error;

          //Handle Mock
          // state.enrolled.data = ENROLLED_LIST_MOCK;
        }
      },
    );

    builder.addCase(loadPendingList.pending, (state: IDeviceState) => {
      state.pending.loading = true;
      state.pending.data = {} as IPendingDeviceData;
      pendingListCancelToken.cancel();
      state.pending.error = '';
    });
    builder.addCase(
      loadPendingList.fulfilled,
      (state: IDeviceState, { payload }: { payload: AxiosResponse | any }) => {
        state.pending.loading = false;
        state.pending.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.pending.data = payload;
        } else {
          state.pending.error = payload?.error;

          //Handle Mock
          // state.pending.data = PENDING_DEVICES_LIST_MOCK;
        }
      },
    );

    builder.addCase(loadAssignedList.pending, (state: IDeviceState) => {
      state.assigned.loading = true;
      state.assigned.error = '';
      state.assigned.data = {} as IAssignedDeviceData;
    });
    builder.addCase(
      loadAssignedList.fulfilled,
      (state: IDeviceState, { payload }: { payload: AxiosResponse | any }) => {
        state.assigned.loading = false;
        state.assigned.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.assigned.data = payload;
        } else {
          state.assigned.error = payload?.error;

          //Handle Mock
          // state.assigned.data = ASSIGNED_LIST_MOCK;
        }
      },
    );
  },
});

export const DeviceSliceActions = {
  ...deviceSlice.actions,
  loadOLTList,
  loadOperatorsList,
  loadManufacturersList,
  loadHardwareModelsList,
  loadHardwareVersionsList,
  loadSoftwareVersionsList,
  loadVLANsList,
  loadOLTStats,
  loadOLTCurrentOnboardingActivities,
  loadDeviceStats,
  loadDeviceByProtocolCountStats,
  loadDeviceStatusByOLT,
  loadDevicesByManufacturerStats,
  loadDevicesCountByOUIManufacturer,
  loadDevicesCountByOUIEquipmentId,
  loadDevicesCountByOUI,
  loadHistoryList,
  loadEnrolledList,
  loadPendingList,
  loadAssignedList,
};

export const DeviceStateReducer = deviceSlice.reducer;
