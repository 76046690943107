import { COUNTRY_CODE } from '../constants';
import Countries from '../lookup/countiresSupported.json';
import { LabeledValue } from 'antd/lib/select';
import States from '../lookup/states.json';

interface ICountry {
  name: string;
  countryCode: string;
}

export const countryList = () => {
  const options: LabeledValue[] = [];
  Countries.forEach((val: ICountry, idx: number) => {
    const data = {
      key: `country-${idx}`,
      label: val.name,
      value: val.countryCode,
    };
    options.push(data);
  });
  return options;
};

export const getStatesByCountryCode = (countryCode: 'in') => {
  return States[countryCode] || [];
};
export const getCountryName = (code: string) => {
  const countryValue = Countries.find(
    (val: ICountry) => val?.countryCode?.toLowerCase() === code?.toLowerCase(),
  );
  return countryValue?.name || code;
};

export function splitCountryCodeAndPhoneNumber(value: string) {
  const countryCodeLength = COUNTRY_CODE.length;
  let phone = {
    countryCode: COUNTRY_CODE,
    phoneNumber: value,
  };
  const countryCodeValue = value?.substring(0, countryCodeLength);
  if (value && countryCodeValue === COUNTRY_CODE) {
    phone = {
      countryCode: countryCodeValue,
      phoneNumber: value?.substring(countryCodeLength, value?.length),
    };
  }
  return phone;
}
