import {
  AlertsRequester,
  VizRequester,
  formatError,
  formatURL,
} from 'apiUtils/api';
import {
  FIRMWARE_UPGRADE_TYPE,
  IConfigurationPendingStats,
  IDashboardStatsState,
  IOfflineReasonCodeStats,
  IPendingFirmwareUpdateByHWModel,
  IPendingFirmwareUpdateStats,
  ISubscriberCount,
} from 'store/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { IAlarmSummaryList } from 'store/types/alarm.types';
import { isEmpty } from 'lodash';

interface IStatsQueryParam {
  managementEntityId?: string | null;
}

const fetchSubscriberData = createAsyncThunk(
  'subscriber/fetch',
  async (payload: { id: string; metric: string }, thunkAPI) => {
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.subscriberStats,
      {
        params: {
          id: payload.id,
          metric: payload.metric,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      if (!response.data) {
        response.data = { total: 0, down: 0, up: 0, pending: 0 };
        return response.data;
      } else {
        return response.data;
      }
    } else {
      return false;
    }
  },
);

const loadAlertStats = createAsyncThunk(
  'alertStats/fetch',
  async (payload: { managementEntityId: string }, thunkAPI) => {
    const response: AxiosResponse = await AlertsRequester.get(
      formatURL(API_END_POINTS.ALERTS.alarmSummary, {
        managementEntityId: payload?.managementEntityId,
      }),
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) ||
          'Error in fetching alert stats. Please try again',
      };
      // return ALARM_SUMMAR_LIST_MOCK;
    }
  },
);

const fetchPendingFirmwareStats = createAsyncThunk(
  'pendingFirmwareStats/fetch',
  async (
    payload: { managementEntityId: string; upgradeType: FIRMWARE_UPGRADE_TYPE },
    thunkAPI,
  ) => {
    const response: AxiosResponse = await VizRequester.get(
      formatURL(API_END_POINTS.VIZ.firmwarePending, {
        managementEntityId: payload?.managementEntityId,
      }),
      {
        params: { firmwareUpgradeType: payload.upgradeType },
      },
    ).catch(({ response }) => response);

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) ||
          'Error in fetching firmware stats. Please try again',
      };
    }
  },
);

const loadConfigurationPendingStats = createAsyncThunk(
  'configurationPendingStats/fetch',
  async (payload: IStatsQueryParam | void, thunkAPI) => {
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.configurationPendingStats,
      {
        params: { managementEntityId: payload?.managementEntityId },
      },
    ).catch(({ response }) => response);

    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error: formatError(response) || 'messages.error.statsData',
      };
    }
  },
);

const loadOfflineReasonCodeStats = createAsyncThunk(
  'offlineReasonCodeStats/fetch',
  async (payload: IStatsQueryParam | void, thunkAPI) => {
    const response: AxiosResponse = await VizRequester.get(
      formatURL(API_END_POINTS.VIZ.offlineReasonCodeStats, {
        managementEntityId: payload?.managementEntityId,
      }),
    ).catch(({ response }) => response);

    if (response?.status === 200) {
      return response?.data;
    } else {
      // return OFFLINE_REASON_CODE_PIE_MOCK;

      return {
        error: formatError(response) || 'messages.error.statsData',
      };
    }
  },
);

const initialState: IDashboardStatsState = {
  subscriber: {
    error: false,
    loading: true,
    data: {} as ISubscriberCount,
  },
  alarmSummaryStats: {
    loading: true,
    error: '',
    data: [] as IAlarmSummaryList[],
  },
  pendingFirmwareUpdateStats: {
    loading: true,
    details: {} as IPendingFirmwareUpdateStats,
    error: '',
  },
  configurationPendingStats: {
    loading: true,
    error: null,
    data: {} as IConfigurationPendingStats,
  },
  offlineReasonCodeStats: {
    loading: true,
    error: '',
    data: {} as IOfflineReasonCodeStats,
  },
};

export const dashboardStatsSlice = createSlice({
  name: 'dashboardStats',
  initialState,
  reducers: {
    resetToInit: (state: IDashboardStatsState) => {
      state.subscriber = {
        error: false,
        loading: true,
        data: {} as ISubscriberCount,
      };
      state.alarmSummaryStats = {
        loading: true,
        error: '',
        data: [] as IAlarmSummaryList[],
      };
      state.configurationPendingStats = {
        loading: true,
        error: null,
        data: {} as IConfigurationPendingStats,
      };
      state.offlineReasonCodeStats = {
        loading: true,
        error: '',
        data: {} as IOfflineReasonCodeStats,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchSubscriberData.fulfilled,
      (state, { payload }: { payload: ISubscriberCount }) => {
        state.subscriber.loading = false;
        if (payload) {
          state.subscriber.data = payload;
          state.subscriber.error = false;
        } else {
          state.subscriber.error = true;
        }
      },
    );
    builder.addCase(loadAlertStats.pending, (state: IDashboardStatsState) => {
      state.alarmSummaryStats.loading = true;
      state.alarmSummaryStats.error = '';
    });
    builder.addCase(
      loadAlertStats.fulfilled,
      (
        state: IDashboardStatsState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.alarmSummaryStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.alarmSummaryStats.data = payload;
        } else {
          state.alarmSummaryStats.error = payload?.error;
        }
      },
    );

    builder.addCase(
      fetchPendingFirmwareStats.pending,
      (state: IDashboardStatsState) => {
        state.pendingFirmwareUpdateStats.loading = true;
        state.pendingFirmwareUpdateStats.error = '';
      },
    );
    builder.addCase(
      fetchPendingFirmwareStats.fulfilled,
      (
        state: IDashboardStatsState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.pendingFirmwareUpdateStats.loading = false;
        if (isEmpty(payload?.error)) {
          let overallCount = 0;
          payload?.firmwareStatsByManufacturer.forEach(
            (stats: IPendingFirmwareUpdateByHWModel) => {
              overallCount += stats.count;
            },
          );
          state.pendingFirmwareUpdateStats.details = payload;
          state.pendingFirmwareUpdateStats.details.overallCount = overallCount;
        } else {
          state.pendingFirmwareUpdateStats.error = payload?.error;
        }
      },
    );
    builder.addCase(
      loadConfigurationPendingStats.pending,
      (state: IDashboardStatsState) => {
        state.configurationPendingStats.loading = true;
        state.configurationPendingStats.error = null;
      },
    );
    builder.addCase(
      loadConfigurationPendingStats.fulfilled,
      (
        state: IDashboardStatsState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.configurationPendingStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.configurationPendingStats.data = payload;
        } else {
          state.configurationPendingStats.error = payload?.error;
        }
      },
    );

    builder.addCase(
      loadOfflineReasonCodeStats.pending,
      (state: IDashboardStatsState) => {
        state.offlineReasonCodeStats.loading = true;
        state.offlineReasonCodeStats.error = '';
      },
    );
    builder.addCase(
      loadOfflineReasonCodeStats.fulfilled,
      (
        state: IDashboardStatsState,
        { payload }: { payload: AxiosResponse | any },
      ) => {
        state.offlineReasonCodeStats.loading = false;
        if (isEmpty(payload?.error)) {
          state.offlineReasonCodeStats.data = payload;
        } else {
          state.offlineReasonCodeStats.error = payload?.error;
        }
      },
    );
  },
});

export const dashboardStatsAction = {
  ...dashboardStatsSlice.actions,
  fetchSubscriberData,
  loadAlertStats,
  fetchPendingFirmwareStats,
  loadConfigurationPendingStats,
  loadOfflineReasonCodeStats,
};
export const dashboardStatsReducer = dashboardStatsSlice.reducer;

export const NumberFormatterMinValue = 9999;
