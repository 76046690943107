import { IResellerList, IResellerState } from 'store/types';
import { OperatorRequester, formatError } from 'apiUtils/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

const initialState: IResellerState = {
  resellerList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as IResellerList,
  },
  resellerListPagination: {},
};

export const LoadResellerList = createAsyncThunk(
  'loadResellerList/fetch',
  async (_, thunkAPI) => {
    const { resellerListState }: { resellerListState: IResellerState } =
      thunkAPI.getState() as any;

    const response: AxiosResponse = await OperatorRequester.get(
      API_END_POINTS.OPERATORS.resellers,
      {
        params: {
          ...resellerListState.resellerListPagination,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) ||
          'Error in fetching reseller list. Please try again',
      };
    }
  },
);

export const resellerSlice = createSlice({
  name: 'reseller',
  initialState,
  reducers: {
    resetToInit: (state) => {
      state.resellerList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IResellerList,
      };
      state.resellerListPagination = {};
    },
    resetResellerList: (state) => {
      state.resellerList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IResellerList,
      };
    },
    setPageToken: (state, action) => {
      state.resellerListPagination.pageToken = action.payload;
    },
    setPagination: (state, action) => {
      state.resellerListPagination = action.payload;
    },
    setHardRefresh: (state, action) => {
      state.resellerList.hardReload = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(LoadResellerList.pending, (state) => {
      state.resellerList.loading = true;
      state.resellerList.error = '';
    });

    builder.addCase(
      LoadResellerList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.resellerList.loading = false;
        state.resellerList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.resellerList.data = payload;
        } else {
          state.resellerList.error = payload?.error;
        }
      },
    );
  },
});

export const resellerListActions = {
  ...resellerSlice.actions,
  LoadResellerList,
};
export const resellerReducer = resellerSlice.reducer;
