import {
  EntityType,
  IListSorting,
  LIST_SORT,
  ROLES,
  ROLES_VIEW,
  TLIST_SORT,
} from 'store/types';
import { cloneDeep, isEmpty } from 'lodash';

import moment from 'moment';

export function isEmptyObject(obj: object | undefined) {
  return obj && Object.keys(obj).length === 0;
}

export const getKeyFromEnumValue = (
  value: string | number | null,
  enumObj: any,
) => {
  const enumKey =
    value || (typeof value === 'number' && value === 0)
      ? Object.keys(enumObj).find((val) => enumObj[val] === value)
      : '';
  return enumKey || '';
};

export function hslToHex(h: number, s: number, l: number) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

//Filter property from the object which has null or undefined  as value

export function removeEmptyValuesFromObject(
  obj?: object,
  excludeKeys?: string | string[],
) {
  if (!isEmpty(obj)) {
    const cloneObj: any = cloneDeep(obj);
    const ignoreKeys: string[] | undefined =
      typeof excludeKeys === 'string' ? [excludeKeys] : excludeKeys;
    const data = Object.keys(cloneObj).reduce(
      (output, key) =>
        ignoreKeys?.includes(key)
          ? output
          : { ...output, [key]: cloneObj[key] },
      {},
    );

    const value = Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) => value != null && value !== undefined && value !== '',
      ),
    );
    return value as any;

    // const value = Object.fromEntries(
    //   Object.entries(obj).filter(
    //     ([_, value]) => value != null && value !== undefined && value !== '',
    //   ),
    // );
    // return value;
  }
  return {};
}

//  #region - Table Sorter
const TableDateTimeSort = (dateA: any, dateB: any, fieldName: string) =>
  moment(dateA[fieldName]).unix() - moment(dateB[fieldName]).unix();

const TableDefaultSort = (a: any, b: any, fieldName: string) => {
  const valueA =
    typeof a[fieldName] === 'string'
      ? a[fieldName]?.toLowerCase() || ''
      : a[fieldName] || null;
  const valueB =
    typeof b[fieldName] === 'string'
      ? b[fieldName]?.toLowerCase() || ''
      : b[fieldName] || null;
  if (valueA < valueB) return -1;
  if (valueB < valueA) return 1;
  return 0;
};

export const Sorter = {
  DEFAULT: TableDefaultSort,
  DATE: TableDateTimeSort,
};
//  #endregion - Table Sorter

export function getRolesDisplayValue(
  role: ROLES,
  managementEntityType: EntityType,
) {
  let rolesValue = ROLES_VIEW[role];
  if (role === ROLES.BILLING_ADMIN && managementEntityType) {
    rolesValue =
      managementEntityType === EntityType.ISP
        ? ROLES_VIEW.ISP_BILLING_ADMIN
        : ROLES_VIEW.FRANCHISEE_BILLING_ADMIN;
  }
  return rolesValue;
}

interface ISorter {
  columnKey: string;
  field: string;
  order: TLIST_SORT;
}

export function getSorterValue(value: IListSorting['sort'], fieldName: string) {
  let sorterOrder: TLIST_SORT = null;
  let sorterObject: any = {};
  value?.split(',')?.forEach((sortKey) => {
    const sortVal = sortKey?.split(':');
    sorterObject = { ...sorterObject, [sortVal[0]]: sortVal[1] };
  });
  sorterOrder = getKeyFromEnumValue(
    sorterObject[fieldName],
    LIST_SORT,
  ) as TLIST_SORT;
  return sorterOrder;
}

export function constructSorterValueAsString(value: ISorter | ISorter[]) {
  // Covert Object into Array type for looping over the keys
  if (!(value instanceof Array) && value instanceof Object) {
    value = [value];
  }
  let sorterValue = null;
  sorterValue = value
    ?.map(
      (sortVal) =>
        sortVal?.order &&
        `${sortVal?.columnKey}:${
          LIST_SORT[sortVal?.order as keyof typeof LIST_SORT]
        }`,
    )
    ?.join(',');
  return sorterValue;
}

//  #region - Google Maps
export const getGoogleMapDefaultCenter = () => {
  // Default Map Center position - Chennai Lat Lng - 13.083666332 80.268665592
  let geoLocation: google.maps.LatLngLiteral = {
    lat: 13.083666332,
    lng: 80.268665592,
  };
  if (!isEmpty(navigator?.geolocation)) {
    navigator?.geolocation?.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        geoLocation = { lat: latitude, lng: longitude };
      },
    );
    // navigator?.geolocation?.watchPosition((position) => {
    //   console.log(position, 'position');
    // });
  }
  return geoLocation;
};
//  #endregion - Google Maps
