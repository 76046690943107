import {
  IOperatorList,
  IOperatorListFilters,
  IOperatorState,
  IPagination,
  IResellerItems,
} from 'store/types';
import { VizRequester, formatError } from 'apiUtils/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

const initialState: IOperatorState = {
  operatorList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as IOperatorList,
  },
  operatorListPagination: {} as IPagination,
  operatorListFilters: {} as IOperatorListFilters,
  operatorFilterDropDownList: {
    resellerList: [] as IResellerItems[],
  },
  operatorListSort: '',
  isFilterOpen: false,
};

export const LoadOperatorList = createAsyncThunk(
  'loadOperatorList/fetch',
  async (_, thunkAPI) => {
    const { operatorListState }: { operatorListState: IOperatorState } =
      thunkAPI.getState() as any;

    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.operators,
      {
        params: {
          ...operatorListState.operatorListPagination,
          ...operatorListState.operatorListFilters,
          sort: operatorListState.operatorListSort || undefined,
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) ||
          'Error in fetching operator list. Please try again',
      };
    }
  },
);

export const operatorSlice = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    resetToInit: (state) => {
      state.operatorList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IOperatorList,
      };
      state.operatorListPagination = {} as IPagination;
      state.operatorListFilters = {} as IOperatorListFilters;
      state.isFilterOpen = false;
      state.operatorListSort = '';
      state.operatorFilterDropDownList = {
        resellerList: [] as IResellerItems[],
      };
    },
    resetOperatorList: (state) => {
      state.operatorList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as IOperatorList,
      };
      state.operatorListPagination = {} as IPagination;
    },
    setPageToken: (state, { payload }: { payload: number }) => {
      state.operatorListPagination.pageToken = payload;
    },
    setPagination: (state, { payload }: { payload: IPagination }) => {
      state.operatorListPagination = payload;
    },
    setHardRefresh: (state, { payload }: { payload: boolean }) => {
      state.operatorList.hardReload = payload;
    },
    setOperatorListFilters: (
      state,
      { payload }: { payload: IOperatorListFilters },
    ) => {
      if (isEmpty(payload)) {
        state.operatorListFilters = {};
      } else {
        state.operatorListFilters = Object.assign(
          state.operatorListFilters,
          payload,
        );
      }
      state.operatorList.hardReload = true;
    },
    setFilterOpen: (state, { payload }: { payload: boolean }) => {
      state.isFilterOpen = payload;
    },
    setOperatorSort: (state, { payload }: { payload: string }) => {
      state.operatorListSort = payload;
    },
    setResellerList: (state, { payload }: { payload: IResellerItems[] }) => {
      state.operatorFilterDropDownList.resellerList = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(LoadOperatorList.pending, (state) => {
      state.operatorList.loading = true;
      state.operatorList.error = '';
      state.operatorList.data = {} as IOperatorList;
    });

    builder.addCase(
      LoadOperatorList.fulfilled,
      (state, { payload }: { payload: AxiosResponse | any }) => {
        state.operatorList.loading = false;
        state.operatorList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.operatorList.data = payload;
        } else {
          state.operatorList.error = payload?.error;
        }
      },
    );
  },
});

export const operatorListActions = {
  ...operatorSlice.actions,
  LoadOperatorList,
};
export const OperatorListReducer = operatorSlice.reducer;

export const excludeOperatorFilterFields = [
  'subscriberToCount',
  'subscriptionToDate',
];
