import {
  API_VERSIONS,
  TAPI_VERSION,
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from 'apiUtils/axiosRequest';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  IGetOLTPendingListParam,
  IOLTBasicDetailsForm,
  IOLTConfigurationForm,
  IOLTForm,
  IOLTPathParams,
} from 'store/types/olt.types';
import {
  ISplitterData,
  ISplitterGetQueryParams,
} from 'store/types/splitter.types';
import { createAxiosInstance, formatURL } from 'apiUtils/api';

import { API_END_POINTS } from 'apiUtils/urls';
import { IFranchiseeFormData } from 'store/types/franchisee.types';
import { IManagementEnityIdPathParam } from 'store/types';
import { IVLANForm } from 'store/types/vlan.types';

const OPERATOR_BASE_URL = process.env.API_OPERATOR?.replace('v1/', '');
const OperatorInstance = createAxiosInstance(OPERATOR_BASE_URL);

interface IOLTByMACParams {
  managementEntityId: string;
  oltMac: string;
}
interface IFranchiseeByIDParams {
  franchiseeId: string;
}
interface IISPByIDParams {
  ispId: string;
}

export interface IOperatorService {
  // #region - Franchisee
  GetFranchiseeList: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetFranchiseeById: (params: IFranchiseeByIDParams) => Promise<AxiosResponse>;
  UpdateFranchisee: (
    params: IFranchiseeByIDParams,
    data: IFranchiseeFormData,
  ) => Promise<AxiosResponse>;
  // #endregion - Franchisee

  // #region - ISP
  GetISPList: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetISPById: (params: IISPByIDParams) => Promise<AxiosResponse>;
  // #endregion - ISP

  // #region - Dropdown
  GetManagementEntities: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetManagementEntityById: (
    params: IManagementEnityIdPathParam,
  ) => Promise<AxiosResponse>;
  GetOLTOperatorsList: (config?: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetResellerList: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetOperatorStatsCount: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetOperatorSubscriptionStats: (
    config: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  VerifyManagementEntityDelete: (
    params: IManagementEnityIdPathParam,
  ) => Promise<AxiosResponse>;
  DeleteManagementEntity: (
    params: IManagementEnityIdPathParam,
  ) => Promise<AxiosResponse>;
  // #endregion - Dropdown

  // #region -OLT List
  GetOLTByMAC: (pathParam: IOLTByMACParams) => Promise<AxiosResponse>;
  getOLTPendingList: (
    params: IGetOLTPendingListParam,
  ) => Promise<AxiosResponse>;
  DeletePendingOLTs: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  DeleteConfigureOLT: (pathParam: IOLTByMACParams) => Promise<AxiosResponse>;
  //  #endregion - OLT List

  //  #region - OLT Form
  GetOLTSupportedManufacturer: () => Promise<AxiosResponse>;
  GetOLTSupportedModel: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetOLTAccessTypeList: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetVLANListByMgmtEntity: (
    pathParam: IManagementEnityIdPathParam,
  ) => Promise<AxiosResponse>;
  CreateAdvancedOLT: (
    params: IManagementEnityIdPathParam,
    payload: IOLTForm[],
  ) => Promise<AxiosResponse>;
  UpdateAdvancedOLT: (
    pathParam: IOLTByMACParams,
    payload: IOLTForm,
  ) => Promise<AxiosResponse>;
  CreateOLTBasicDetails: (
    params: IOLTPathParams,
    payload: IOLTBasicDetailsForm,
  ) => Promise<AxiosResponse>;
  UpdateOLTBasicDetails: (
    params: IOLTPathParams,
    payload: IOLTBasicDetailsForm,
  ) => Promise<AxiosResponse>;
  GetPendingOLTByIpAddress: (params: IOLTPathParams) => Promise<AxiosResponse>;
  MigrateToConfigured: (params: IOLTPathParams) => Promise<AxiosResponse>;
  ConfigureOLT: (payload: IOLTConfigurationForm) => Promise<AxiosResponse>;
  // #endregion - OLT Form

  //  #region - Splitter
  GetSplittersList: () => Promise<AxiosResponse>;
  GetSplitterById: (
    splitterId: string,
    params: ISplitterGetQueryParams,
  ) => Promise<AxiosResponse>;
  GetSplitterDropdownValues: () => Promise<AxiosResponse>;
  CreateSplitter: (payload: ISplitterData) => Promise<AxiosResponse>;
  UpdateSplitter: (
    splitterId: string,
    payload: ISplitterData,
  ) => Promise<AxiosResponse>;
  DeleteSplitter: (splitterId: string) => Promise<AxiosResponse>;

  // #region - VLAN
  GetVLANList: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetVLANById: (pathParam: {
    managementEntityId: string;
    vlanId: string;
  }) => Promise<AxiosResponse>;
  CreateVLAN: (data: IVLANForm) => Promise<AxiosResponse>;
  UpdateVLAN: (
    pathParam: { vlanId: string },
    data: IVLANForm,
  ) => Promise<AxiosResponse>;
  // DeleteVLAN: (pathParam: {
  //   vlanId: string | number;
  // }) => Promise<AxiosResponse>;
  DeleteVLAN: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  // #endregion - VLAN
}

export const OperatorService: { [key in TAPI_VERSION]: IOperatorService } =
  Object.keys(API_VERSIONS)?.reduce((res: any, el: any) => {
    const version = API_VERSIONS[el as keyof typeof API_VERSIONS];

    return {
      ...res,
      [el]: {
        //  #region - Franchisee
        GetFranchiseeList: async (config: AxiosRequestConfig) => {
          const params = {
            ...config?.params,
            showActiveME: 'true',
          };
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.franchisee}`,
            { ...config, params },
          );
        },
        GetFranchiseeById: async (params: IFranchiseeByIDParams) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.franchiseeByID, {
              franchiseeId: params?.franchiseeId || '',
            })}`,
          );
        },
        UpdateFranchisee: async (
          params: IFranchiseeByIDParams,
          data: IFranchiseeFormData,
        ) => {
          return await putRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.franchiseeByID, {
              franchiseeId: params?.franchiseeId || '',
            })}`,
            data,
          );
        },
        //  #endregion - Franchisee

        // #region - ISP
        GetISPList: async (config: AxiosRequestConfig) => {
          const params = {
            ...config?.params,
            showActiveME: 'true',
          };
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.ISPS}`,
            { ...config, params },
          );
        },
        GetISPById: async (params: IISPByIDParams) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.ISPByID, {
              ispId: params?.ispId || '',
            })}`,
          );
        },
        // #endregion - ISP

        // #region - Dropdown
        GetManagementEntities: async (config: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.managementEntity}`,
            config,
          );
        },
        GetManagementEntityById: async (
          params: IManagementEnityIdPathParam,
        ) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.managementEntityById,
              params,
            )}`,
          );
        },
        GetOLTOperatorsList: async (config?: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.OLT_ManagementEntity}`,
            config,
          );
        },
        GetResellerList: async (config: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.resellers}`,
            config,
          );
        },
        // #endregion - Dropdown

        GetOperatorStatsCount: async (config: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.statsCounts}`,
            config,
          );
        },
        GetOperatorSubscriptionStats: async (config: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.subscriptionStats}`,
            config,
          );
        },
        VerifyManagementEntityDelete: async (
          params: IManagementEnityIdPathParam,
        ) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.verify_ME_delete,
              params,
            )}`,
          );
        },
        DeleteManagementEntity: async (params: IManagementEnityIdPathParam) => {
          return await deleteRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.managementEntityById,
              params,
            )}`,
          );
        },

        //  #region -OLT List
        GetOLTByMAC: async (pathParam: IOLTByMACParams) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.OLTByMac,
              pathParam,
            )}`,
          );
        },
        getOLTPendingList: async (params: IGetOLTPendingListParam) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.oltPendingList,
              params?.path,
            )}`,
            { params: params?.query },
          );
        },
        DeletePendingOLTs: async (config: AxiosRequestConfig) => {
          return await deleteRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.pendingOLTs}`,
            config,
          );
        },
        DeleteConfigureOLT: async (pathParam: IOLTByMACParams) => {
          return await deleteRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.OLTByMac,
              pathParam,
            )}`,
          );
        },

        //  #endregion - OLT List

        //  #region - OLT Form
        GetOLTSupportedManufacturer: async () => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.olt_supportedManufacturer}`,
          );
        },
        GetOLTSupportedModel: async (config: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.olt_supportedModel}`,
            config,
          );
        },
        GetOLTAccessTypeList: async (config: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.olt_access_type}`,
            config,
          );
        },
        GetVLANListByMgmtEntity: async (
          pathParam: IManagementEnityIdPathParam,
        ) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.vlanListByMgmtEntity,
              { managementEntityId: pathParam?.managementEntityId },
            )}`,
          );
        },

        CreateAdvancedOLT: async (
          params: IManagementEnityIdPathParam,
          payload: IOLTForm[],
        ) => {
          return await postRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.createOLT,
              params,
            )}`,
            payload,
          );
        },
        UpdateAdvancedOLT: async (
          pathParam: IOLTByMACParams,
          payload: IOLTForm,
        ) => {
          return await patchRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.OLTByMac,
              pathParam,
            )}`,
            payload,
          );
        },

        CreateOLTBasicDetails: async (
          params: IOLTPathParams,
          payload: IOLTBasicDetailsForm,
        ) => {
          return await postRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.oltInfo, params)}`,
            payload,
          );
        },

        UpdateOLTBasicDetails: async (
          params: IOLTPathParams,
          payload: IOLTBasicDetailsForm,
        ) => {
          return await putRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.oltInfoByIpAddress,
              params,
            )}`,
            payload,
          );
        },

        GetPendingOLTByIpAddress: async (params: IOLTPathParams) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.pendingOLTByIpAddress,
              params,
            )}`,
          );
        },

        MigrateToConfigured: async (params: IOLTPathParams) => {
          return await postRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.migrateToConfigured,
              params,
            )}`,
            {},
          );
        },

        ConfigureOLT: async (payload: IOLTConfigurationForm) => {
          return await postRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OLT_GATEWAY.oltConfiguration}`,
            payload,
          );
        },
        // #endregion - OLT Form

        //  #region - Splitter
        GetSplittersList: async () => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.splitters}`,
          );
        },
        GetSplitterById: async (splitterId: string) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.splitterById, {
              splitterId,
            })}`,
          );
        },
        GetSplitterDropdownValues: async () => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.splitterDropdownData}`,
          );
        },
        CreateSplitter: async (payload: ISplitterData) => {
          return await postRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.splitters}`,
            payload,
          );
        },
        UpdateSplitter: async (splitterId: string, payload: ISplitterData) => {
          return await patchRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.splitterById, {
              splitterId,
            })}`,
            payload,
          );
        },
        DeleteSplitter: async (splitterId: string) => {
          return await deleteRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.splitterById, {
              splitterId,
            })}`,
          );
        },
        //  #endregion - Splitter

        // #region - VLAN
        GetVLANList: async (config: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.VIZ.vlans}`,
            config,
          );
        },
        GetVLANById: async (pathParam: {
          managementEntityId: string;
          vlanId: string;
        }) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.VIZ.vlanById, pathParam)}`,
          );
        },
        CreateVLAN: async (data: IVLANForm) => {
          return await postRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.vlans}`,
            data,
          );
        },
        UpdateVLAN: async (pathParam: { vlanId: string }, data: IVLANForm) => {
          return await putRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.vlanById, {
              vlanId: pathParam?.vlanId,
            })}`,
            data,
          );
        },
        // DeleteVLAN: async (pathParam: { vlanId: string }) => {
        //   return await deleteRequest(
        //     OperatorInstance,
        //     `${version}${formatURL(API_END_POINTS.OPERATORS.vlanById, {
        //       vlanId: pathParam?.vlanId,
        //     })}`,
        //   );
        // },
        DeleteVLAN: async (config: AxiosRequestConfig) => {
          return await deleteRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.vlans}`,
            config,
          );
        },
        // #endregion - VLAN
      } as IOperatorService,
    };
  }, {});
