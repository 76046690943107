import {
  IIpReachability,
  IOLTBridgeStatus,
  IOLTList,
  IOLTTelnetReachabilityStatus,
  OLT_BRIDGE_STATUS,
  OLT_MIGRATION_STATUS,
  OLT_ONBOARDING_PROGRESS_STATUS,
  PON_TYPE,
} from 'store/types/olt.types';

export const PON_PORTS_COUNT_SERIES_MOCK = [2, 4, 8, 16, 32, 64];

export const OLT_LIST_MOCK: IOLTList = {
  items: [
    {
      deviceCount: {
        total: 4,
        online: 4,
        offline: 0,
      },
      onuTelemetryConfig: {
        enable: true,
        pollingInterval: '15',
        ponPortsCount: 20,
      },
      managementEntityId: 'f0f9ba21ed544c89aec54d4484cdd763',
      ponType: PON_TYPE.EPON,
      manufacturer: 'Netlink',
      model: 'V1600G',
      ipAddress: '192.168.8.200',
      label: 'Office1',
      sysName: 'FiiLab-gpon-olt',
      serialNum: 'V2103260210',
      hwVersion: 'V2.1.4',
      swVersion: 'V2.2.5R',
      macAddress: '14:A7:2B:A2:AF:75',
      license: 'Permanent',
      numberOfUplinkPort: 6,
      migration: {
        transactionId: 'TRANS993021',
        status: OLT_MIGRATION_STATUS.FAILED,
      },
      onboarding: {
        status: OLT_ONBOARDING_PROGRESS_STATUS.COMPLETED,
      },
      reachability: { status: true, error: '' },
      uplinkPortInfo: [
        {
          name: 'GE1',
          number: 1,
          type: 'fiber',
          linkStatus: 'down',
          speed: '-',
          portIndex: '1',
        },
        {
          name: 'GE2',
          number: 2,
          type: 'fiber',
          linkStatus: 'down',
          speed: '-',
          portIndex: '2',
        },
        {
          name: 'GE3',
          number: 3,
          type: 'copper',
          linkStatus: 'up',
          speed: '1000M Full',
          portIndex: '3',
        },
        {
          name: 'GE4',
          number: 4,
          type: 'copper',
          linkStatus: 'down',
          speed: '-',
          portIndex: '4',
        },
        {
          name: 'GE5',
          number: 5,
          type: 'copper',
          linkStatus: 'down',
          speed: '-',
          portIndex: '5',
        },
        {
          name: 'GE6',
          number: 6,
          type: 'copper',
          linkStatus: 'up',
          speed: '1000M Full',
          portIndex: '6',
        },
      ],
      discoveryInProgress: false,
      discoveryTime: '2023-07-04 10:55:19.385Z',
      numberOfPonPort: 4,

      ponPortInfo: [
        {
          name: 'PON1',
          number: 1,
          linkStatus: 'up',
        },
        {
          name: 'PON2',
          number: 2,
          linkStatus: 'down',
        },
        {
          name: 'PON3',
          number: 3,
          linkStatus: 'down',
        },
        {
          name: 'PON4',
          number: 4,
          linkStatus: 'down',
        },
      ],
      lineProfileInfo: [
        { name: 'line1' },
        { name: 'line2' },
        { name: 'line3' },
      ],
      serviceProfileInfo: [
        { name: 'service1' },
        { name: 'service2' },
        { name: 'service3' },
      ],
    },
    {
      deviceCount: {
        total: 4,
        online: 4,
        offline: 0,
      },
      onuTelemetryConfig: {
        enable: true,
        pollingInterval: '15',
        ponPortsCount: 50,
      },
      ponType: PON_TYPE.GPON,
      lineProfileInfo: [
        { name: 'line1' },
        { name: 'line2' },
        { name: 'line3' },
      ],
      serviceProfileInfo: [
        { name: 'service1' },
        { name: 'service2' },
        { name: 'service3' },
      ],
      managementEntityId: 'f0f9ba21ed544c89aec54d4484cdd764',
      manufacturer: 'Netlink',
      model: 'V1600G',
      ipAddress: '192.168.8.201',
      label: 'Office2',
      sysName: 'FiiLab-gpon-olt',
      serialNum: 'V2103260211',
      hwVersion: 'V2.1.4',
      swVersion: 'V2.2.5R',
      macAddress: '14:A7:2B:A2:AD:63',
      license: 'Permanent',
      numberOfUplinkPort: 6,
      discoveryInProgress: false,
      discoveryTime: '2023-07-04 10:55:19.385Z',
      uplinkPortInfo: [
        {
          name: 'GE1',
          number: 1,
          type: 'fiber',
          linkStatus: 'down',
          speed: '-',
          portIndex: '1',
        },
        {
          name: 'GE2',
          number: 2,
          type: 'fiber',
          linkStatus: 'down',
          speed: '-',
          portIndex: '2',
        },
        {
          name: 'GE3',
          number: 3,
          type: 'copper',
          linkStatus: 'up',
          speed: '1000M Full',
          portIndex: '3',
        },
        {
          name: 'GE4',
          number: 4,
          type: 'copper',
          linkStatus: 'down',
          speed: '-',
          portIndex: '4',
        },
        {
          name: 'GE5',
          number: 5,
          type: 'copper',
          linkStatus: 'down',
          speed: '-',
          portIndex: '5',
        },
        {
          name: 'GE6',
          number: 6,
          type: 'copper',
          linkStatus: 'up',
          speed: '1000M Full',
          portIndex: '6',
        },
      ],
      numberOfPonPort: 4,
      reachability: { status: true, error: '' },
      ponPortInfo: [
        {
          name: 'PON1',
          number: 1,
          linkStatus: 'up',
        },
        {
          name: 'PON2',
          number: 2,
          linkStatus: 'down',
        },
        {
          name: 'PON3',
          number: 3,
          linkStatus: 'down',
        },
        {
          name: 'PON4',
          number: 4,
          linkStatus: 'down',
        },
      ],
    },
    {
      deviceCount: {
        total: 4,
        online: 4,
        offline: 0,
      },
      onuTelemetryConfig: {
        enable: false,
        pollingInterval: '15',
        ponPortsCount: 20,
      },
      ponType: PON_TYPE.OTHERS,
      lineProfileInfo: [
        { name: 'line1' },
        { name: 'line2' },
        { name: 'line3' },
      ],
      serviceProfileInfo: [
        { name: 'service1' },
        { name: 'service2' },
        { name: 'service3' },
      ],
      managementEntityId: 'f0f9ba21ed544c89aec54d4484cdd765',
      manufacturer: 'Netlink',
      model: 'V1600G',
      ipAddress: '192.168.8.202',
      label: 'Office3',
      sysName: 'FiiLab-gpon-olt',
      serialNum: 'V2103260211',
      hwVersion: 'V2.1.4',
      swVersion: 'V2.2.5R',
      macAddress: '14:A7:2B:A2:AD:64',
      license: 'Permanent',
      numberOfUplinkPort: 6,
      discoveryInProgress: false,
      discoveryTime: '2023-07-04 10:55:19.385Z',
      uplinkPortInfo: [
        {
          name: 'GE1',
          number: 1,
          type: 'fiber',
          linkStatus: 'down',
          speed: '-',
          portIndex: '1',
        },
        {
          name: 'GE2',
          number: 2,
          type: 'fiber',
          linkStatus: 'down',
          speed: '-',
          portIndex: '2',
        },
        {
          name: 'GE3',
          number: 3,
          type: 'copper',
          linkStatus: 'up',
          speed: '1000M Full',
          portIndex: '3',
        },
        {
          name: 'GE4',
          number: 4,
          type: 'copper',
          linkStatus: 'down',
          speed: '-',
          portIndex: '4',
        },
        {
          name: 'GE5',
          number: 5,
          type: 'copper',
          linkStatus: 'down',
          speed: '-',
          portIndex: '5',
        },
        {
          name: 'GE6',
          number: 6,
          type: 'copper',
          linkStatus: 'up',
          speed: '1000M Full',
          portIndex: '6',
        },
      ],
      numberOfPonPort: 4,
      reachability: { status: false, error: '' },
      migration: {
        transactionId: 'TRANS993022',
        status: OLT_MIGRATION_STATUS.INITIATED,
      },
      onboarding: {
        status: OLT_ONBOARDING_PROGRESS_STATUS.COMPLETED,
      },
      ponPortInfo: [
        {
          name: 'PON1',
          number: 1,
          linkStatus: 'up',
        },
        {
          name: 'PON2',
          number: 2,
          linkStatus: 'down',
        },
        {
          name: 'PON3',
          number: 3,
          linkStatus: 'down',
        },
        {
          name: 'PON4',
          number: 4,
          linkStatus: 'down',
        },
      ],
    },
  ],
  pageSize: 10,
  pageToken: 1,
  total: 2,
};

export const OLT_DETAIL_LIST_MOCK = {
  items: [
    {
      portNumber: 1,
      ontNumber: '3',
      PONSerialNumber: 'GPON009138CE',
      vendorId: 'MONU',
      hwVersion: 'V3.7',
      uptime: '95.00 s',
      mainSwVer: '2.0.11',
      standbySwVer: '2.0.11',
      model: 'MONUH213',
      equipmentId: 'MONUH213',
      MAC: '14:A7:2B:91:38:D3',
      ontState: 'OFFLINE',
    },
    {
      portNumber: 1,
      ontNumber: '23',
      PONSerialNumber: 'GPON009138CE',
      vendorId: 'MONU',
      hwVersion: 'V3.7',
      uptime: '163.00 s',
      mainSwVer: '2.0.11',
      standbySwVer: '2.0.11',
      model: 'MONUH213',
      equipmentId: 'MONUH213',
      MAC: '14:A7:2B:64:15:A5',
      ontState: 'WORKING',
    },
  ],
  pageSize: 10,
  pageToken: 1,
  total: 2,
};

export const OLT_STATS_MOCK = {
  total: 20,
  offlineCount: 10,
  onlineCount: 5,
  losCount: 5,
  dyingGaspCount: 0,
};

export const OLT_MIGRATION_STATS_MOCK_1 = {
  transactionId: 'TRANS993021',
  oltMac: '14:A7:2B:A2:AF:75',
  oltLabel: 'Office',
  sourceMEId: 'c842a86db7724843a5081378458cda22',
  sourceMEName: 'ISP1',
  sourceMEType: '',
  destMEId: '212245a0fe004fffad2ebf61d1ca896f',
  destMEName: 'Franchisee1',
  destMEType: '',
  total: 146,
  success: 84,
  failure: 0,
  pending: 62,
};

export const OLT_MIGRATION_STATS_MOCK_2 = {
  transactionId: 'TRANS993022',
  oltMac: '14:A7:2B:A2:AF:75',
  oltLabel: 'Office',
  sourceMEId: 'c842a86db7724843a5081378458cda22',
  sourceMEName: 'ISP1',
  sourceMEType: '',
  destMEId: '212245a0fe004fffad2ebf61d1ca896f',
  destMEName: 'Franchisee1',
  destMEType: '',
  total: 130,
  success: 68,
  failure: 0,
  pending: 62,
};

export const OLT_BRIDGE_STATUS_MOCK: IOLTBridgeStatus = {
  status: OLT_BRIDGE_STATUS.NOT_REGISTERED,
  time: '2024-09-18T13:51:35Z',
  onuTelemetryConfig: {
    enable: false,
    pollingInterval: null,
    ponPortsCount: null,
    ponPortsCategory: null,
    ponPorts: null,
  },
  oltTelemetryConfig: {
    enable: false,
    pollingInterval: null,
    ponPortsCount: null,
    ponPortsCategory: null,
    ponPorts: null,
  },
};

export const OLT_IP_REACHABILITY_MOCK: IIpReachability = {
  ipAddress: '172.16.3.107',
  managementEntityId: '12334354354545',
  reachable: true,
  errorReason: '',
};

export const OLT_TELNET_SNMP_STATUS = {
  ipAddress: '172.16.3.107',
  managementEntityId: '12334354354545',
  SNMP: {
    configurable: true,
    trapEnabled: true,
    version: 'v1',
    communityString: 'Private',
    username: 'Test',
    password: 'test',
    reachable: true,
  },
  oltMac: 'A8:E2:07:44:5D:F2',
  oltModel: 'V1600D',
  ponPortCount: 1,
  ponPortType: '',
};

export const OLT_TELNET_STATUS_MOCK: IOLTTelnetReachabilityStatus = {
  managementEntityId: '4ed11dcd7f964561a1647ad150d366e4',
  ipAddress: '172.30.109.136',
  manufacturer: 'zte',
  oltModel: 'ZXA10C320',
  reachable: true,
  adminAccess: true,
  errorReason: 'NONE',
};

export const OLT_PENDING_LIST_MOCK = {
  items: [
    {
      id: '123456',
      ipAddress: '172.16.3.107',
      ipReachable: true,
      name: 'OLT1',
      label: 'OLT1',
      manufacturer: 'Netlink',
      status: 'INITIATED',
      managementEntity: {
        id: '214234234245454545',
        name: 'ISP1',
        type: 'ISP',
      },
      assignedTo: {
        id: '52736a2efc7c47fca39429a2eb367b14',
        name: 'Franchisee1',
        type: 'FRANCHISEE',
      },
      telnet: {
        ipAddress: '172.16.3.107',
        errorReason: '',
        username: 'Test',
        password: 'Test@123',
        reachable: true,
        adminAccess: true,
      },
      SNMP: {
        version: 'v1',
        communityString: 'Private',
        username: 'Test',
        password: 'Test@123',
        trapEnabled: false,
        reachable: true,
        configurable: true,
      },
      ipReachabilityStatus: {
        reachable: true,
        errorReason: '',
      },
      oltMac: '',
      oltModel: '',
      ponPortCount: 0,
      ponPortType: '',
    },
  ],
  pageSize: 10,
  pageToken: 1,
  total: 2,
};
