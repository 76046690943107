import {
  IChannelPartners,
  IPagination,
  ITrialByAgeItems,
  ITrialDashboardFilters,
  ITrialList,
  ITrialListFilters,
  ITrialStats,
  ITrialsState,
  Trials_Tabs,
} from 'store/types';
import {
  OperatorRequester,
  TrialRequester,
  VizRequester,
  formatError,
} from 'apiUtils/api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { red } from '@ant-design/colors';
import { removeEmptyValuesFromObject } from 'utils/utils';

const loadReseller = createAsyncThunk('resellerList/fetch', async () => {
  const response: AxiosResponse = await OperatorRequester.get(
    API_END_POINTS.OPERATORS.resellers,
    { params: { pageToken: 1, pageSize: 1000 } },
  ).catch(({ response }) => response);
  if (response?.status === 200) {
    return response.data;
  } else {
    return {
      error:
        formatError(response) ||
        'Error in fetching reseller list. Please try again',
    };
  }
});

const loadTrialDashboard = createAsyncThunk(
  'trials/loadTrialDashboard',
  async (_, thunkAPI) => {
    const { trialState }: { trialState: ITrialsState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.trialDashboard,
      { params: trialState?.trialDashboardFilters },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
    } else {
      return {
        error:
          formatError(response) ||
          'Error in fetching trial stats. Please try again',
      };
    }
  },
);

const loadTrialByAge = createAsyncThunk(
  'trials/loadTrialByAge',
  async (_, thunkAPI) => {
    const { trialState }: { trialState: ITrialsState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await VizRequester.get(
      API_END_POINTS.VIZ.trialByAge,
      { params: trialState?.trialDashboardFilters },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
    } else {
      return {
        error:
          formatError(response) ||
          'Error in fetching chart data. Please try again',
      };
    }
  },
);

export const loadTrialsList = createAsyncThunk(
  'trialsList/fetch',
  async (_, thunkAPI) => {
    const { trialState }: { trialState: ITrialsState } =
      thunkAPI.getState() as any;
    const response: AxiosResponse = await TrialRequester.get(
      API_END_POINTS.TRIAL.list,
      {
        params: {
          ...trialState?.trialListPagination,
          ...removeEmptyValuesFromObject(trialState?.trialListFilters),
        },
      },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return {
        error:
          formatError(response) ||
          'Error in fetching the list of trial ISP/Franchisee. Please try after sometime.',
      };
    }
  },
);

const initialState: ITrialsState = {
  resellerList: {
    loading: true,
    error: '',
    data: [] as IChannelPartners[],
  },
  trialDashboardFilters: {} as ITrialDashboardFilters,
  overview: { loading: true, error: '', stats: {} as ITrialStats },
  trialByAge: { loading: true, error: '', data: [] as ITrialByAgeItems[] },
  trialList: {
    loading: true,
    hardReload: true,
    error: '',
    data: {} as ITrialList,
  },
  trialListPagination: {},
  trialListFilters: {},
  currentTabId: '',
};

export const trialSlice = createSlice({
  name: 'trials',
  initialState,
  reducers: {
    resetToInit: () => initialState,
    resetOverviewState: (state: ITrialsState) => {
      state.overview = {
        loading: true,
        error: '',
        stats: {} as ITrialStats,
      };
      state.trialByAge = {
        loading: true,
        error: '',
        data: [] as ITrialByAgeItems[],
      };
    },
    resetTrialListState: (state: ITrialsState) => {
      state.trialList = {
        loading: true,
        hardReload: true,
        error: '',
        data: {} as ITrialList,
      };
      state.trialListPagination = {};
      state.trialListFilters = {};
    },
    setCurrentTabId: (state: ITrialsState, action) => {
      state.currentTabId = action.payload;
      if (state.currentTabId === Trials_Tabs.OVERVIEW) {
        state.overview = {
          loading: true,
          error: '',
          stats: {} as ITrialStats,
        };
        state.trialByAge = {
          loading: true,
          error: '',
          data: [] as ITrialByAgeItems[],
        };
      } else if (state.currentTabId === Trials_Tabs.LIST) {
        state.trialList = {
          loading: true,
          hardReload: true,
          error: '',
          data: {} as ITrialList,
        };
        state.trialListPagination = { pageToken: 1, pageSize: 10 };
        state.trialListFilters = {};
      }
    },
    setTrialDashboardFilters: (
      state: ITrialsState,
      action: PayloadAction<ITrialDashboardFilters>,
    ) => {
      if (isEmpty(action?.payload)) {
        state.trialDashboardFilters = {};
      } else {
        state.trialDashboardFilters = Object.assign(
          state.trialDashboardFilters,
          action.payload,
        );
      }
    },
    setStatsLoading: (
      state: ITrialsState,
      { payload }: { payload: boolean },
    ) => {
      state.overview = {
        loading: payload,
        error: '',
        stats: {} as ITrialStats,
      };
    },
    setTrialByAgeLoading: (
      state: ITrialsState,
      { payload }: { payload: boolean },
    ) => {
      state.trialByAge.loading = payload;
    },

    setTrialListPageToken: (
      state: ITrialsState,
      { payload }: { payload: number },
    ) => {
      state.trialListPagination.pageToken = payload;
    },
    setTrialListPageSize: (
      state: ITrialsState,
      { payload }: { payload: number },
    ) => {
      state.trialListPagination.pageSize = payload;
    },
    setTrialListPagination: (
      state: ITrialsState,
      action: PayloadAction<IPagination>,
    ) => {
      state.trialListPagination = action.payload;
    },
    setTrialListFilters: (
      state: ITrialsState,
      action: PayloadAction<ITrialListFilters>,
    ) => {
      if (isEmpty(action?.payload)) {
        state.trialListFilters = {};
      } else {
        state.trialListFilters = Object.assign(
          state.trialListFilters,
          action.payload,
        );
      }
      state.trialList.hardReload = true;
      state.trialList.error = '';
      state.trialList.data = {} as ITrialList;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadReseller.pending, (state: ITrialsState) => {
      state.resellerList.loading = true;
      state.resellerList.error = '';
    });
    builder.addCase(
      loadReseller.fulfilled,
      (state: ITrialsState, { payload }: { payload: AxiosResponse | any }) => {
        state.resellerList.loading = false;
        if (isEmpty(payload?.error)) {
          state.resellerList.data = payload?.items;
        } else {
          state.resellerList.error = payload?.error;
        }
      },
    );

    builder.addCase(loadTrialDashboard.pending, (state: ITrialsState) => {
      state.overview.loading = true;
      state.overview.error = '';
    });
    builder.addCase(
      loadTrialDashboard.fulfilled,
      (state: ITrialsState, { payload }: { payload: AxiosResponse | any }) => {
        state.overview.loading = false;
        if (isEmpty(payload?.error)) {
          state.overview.stats = payload;
        } else {
          state.overview.error = payload?.error;
        }
      },
    );

    builder.addCase(loadTrialByAge.pending, (state: ITrialsState) => {
      state.trialByAge.loading = true;
      state.trialByAge.error = '';
    });
    builder.addCase(
      loadTrialByAge.fulfilled,
      (state: ITrialsState, { payload }: { payload: AxiosResponse | any }) => {
        state.trialByAge.loading = false;
        if (isEmpty(payload?.error)) {
          state.trialByAge.data = payload;
          if (state?.trialByAge?.data?.length) {
            state.trialByAge.data.map((val, idx) => {
              return (val.color = red[state.trialByAge?.data?.length - idx]);
            });
          }
        } else {
          state.trialByAge.error = payload?.error;
        }
      },
    );

    builder.addCase(loadTrialsList.pending, (state: ITrialsState) => {
      state.trialList.loading = true;
      state.trialList.error = '';
    });
    builder.addCase(
      loadTrialsList.fulfilled,
      (state: ITrialsState, { payload }: { payload: AxiosResponse | any }) => {
        state.trialList.loading = false;
        state.trialList.hardReload = false;
        if (isEmpty(payload?.error)) {
          state.trialList.data = payload;
        } else {
          state.trialList.error = payload?.error;
        }
      },
    );
  },
});

export const TrialSliceActions = {
  ...trialSlice.actions,
  loadReseller,
  loadTrialDashboard,
  loadTrialByAge,
  loadTrialsList,
};

export const TrialsStateReducer = trialSlice.reducer;
